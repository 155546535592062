import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    comp_date: null,
    matl_date: null,
    pain_date: null,
    irpt_date: null,
    ship_date: null,
    plan_memo: '',
};

const planDataSource = fwUtil.generateDataSource();

export function PlanRegistUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);        
    // 발주 그리드
    const planRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowData = fwUtil.findSelectedRowData(mainGrid);
        if (!selectedRowData) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            planDataSource.store().insert({ init: true }).then(() => planDataSource.reload());
            
            for (const item of selectedRowData) {
                const newData = {
                    planNumb: item.plan_numb || '',
                    ordeNumb: item.orde_numb || '',
                    clieName: item.clie_name || '',
                    projName: item.proj_name || '',
                    compDate: item.comp_date || null,
                    deliDate: item.deli_date || null,
                    clieOrde: item.clie_orde || '',
                    citeCode: item.cite_code || '',
                    citeName: item.cite_name || '',
                    ordeQtys: item.orde_qtys || 0.000,
                    matlDate: item.matl_date || null,
                    painDate: item.pain_date || null,
                    irptDate: item.irpt_date || null,
                    shipDate: item.ship_date || null,
                    planMemo: item.plan_memo || '',                    
                };
                planDataSource.store().insert(newData);
            }
            
            dataUpdate(defaultData);
            planDataSource.reload();
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const planData = planRef?.current?.instance?.getDataSource()?.items();
        const postPremData = planData.map((item) => ({
            planNumb: item.plan_numb || '',
            ordeNumb: item.orde_numb || '',
        }));        
        const postData = {
            plan_numb: data.plan_numb || null,
            orde_numb: data.orde_numb || null,
            comp_date: data.comp_date || null,
            matl_date: data.matl_date || null,
            pain_date: data.pain_date || null,
            irpt_date: data.irpt_date || null,
            ship_date: data.ship_date || null,
            plan_memo: data.plan_memo || '',
            setAmaps: postPremData || [],
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [data, updateApi, refresh, hiding, alertHide, planRef]);

    return (
        <>
        <PopupForm
            title={'생산계획 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall || isSmall ? 300 : isMedium ? 720 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'선택 항목'}
                        gridRef={planRef} 
                        gridDataSource={planDataSource}
                    >
                        {CommCol({ dataField: "plan_numb", caption: "계획번호" })}
                        {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                        {CommCol({ dataField: "clie_name", caption: "고객명" })}
                        {CommCol({ dataField: "proj_name", caption: "프로젝트명" })}
                        {DateCol({ dataField: "comp_date", caption: "생산완료일" })}
                        {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                        {CommCol({ dataField: "clie_orde", caption: "오더번호" })}
                        {CommCol({ dataField: "cite_code", caption: "품목번호" })}
                        {CommCol({ dataField: "cite_name", caption: "품명" })}
                        {DeciCol({ dataField: "orde_qtys", caption: "수량" })}
                        {DateCol({ dataField: "matl_date", caption: "소재입고일" })}
                        {DateCol({ dataField: "pain_date", caption: "피막완료일" })}
                        {DateCol({ dataField: "irpt_date", caption: "성적서작성일" })}
                        {DateCol({ dataField: "ship_date", caption: "납품일" })}
                        {CommCol({ dataField: "plan_memo", caption: "비고" })}
                    </AddingGrid>                 
                </div>
            }         
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 3 : 5 }>
                {FormDate({ value: data.comp_date, onValueChange: updateField('comp_date'), label: '생산완료일', required: true })}
                {FormDate({ value: data.matl_date, onValueChange: updateField('matl_date'), label: '소재입고일', required: true })}
                {FormDate({ value: data.pain_date, onValueChange: updateField('pain_date'), label: '피막완료일', required: true })}
                {FormDate({ value: data.irpt_date, onValueChange: updateField('irpt_date'), label: '성적서작성일', required: true })}
                {FormDate({ value: data.ship_date, onValueChange: updateField('ship_date'), label: '납품일', required: true })}                 
            </GroupItem>
            {FormMemo({ value: data.plan_memo, onValueChange: updateField('plan_memo'), label: '비고' })}
        </PopupForm>
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 수정 하시겠습니까?'}
        />  
        </>
    );
}