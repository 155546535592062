import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    proc_date: fwUtil.getToDayText(),
    proc_memo: "",
};

const procDataSource = fwUtil.generateDataSource();

export function ProcloseRegistInsert(props) {
    const {
        mainGrid,
        mainRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 발주 그리드
    const procRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedLastRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('매입 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            procDataSource.store().insert({ init: true }).then(() => procDataSource.reload());
            const procSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const proc of procSelected) {
                const newData = {
                    clie_name: proc.clie_name || '', 
                    proc_kind: proc.proc_kind || '', 
                    work_numb: proc.work_numb || '', 
                    rout_numb: proc.rout_numb || '', 
                    rout_name: proc.rout_name || '', 
                    last_rout: proc.last_rout || false, 
                    matl_code: proc.matl_code || '', 
                    matl_name: proc.matl_name || '', 
                    matl_spec: proc.matl_spec || '', 
                    lots_numb: proc.lots_numb || '', 
                    proc_qtys: proc.proc_qtys || 0.000, 
                    proc_weig: proc.proc_weig || 0.000, 
                    proc_pric: proc.proc_pric || 0.00, 
                    proc_kwon: proc.proc_kwon || 0.00, 
                    proc_date: proc.proc_date || null, 
                };
                procDataSource.store().insert(newData);
            }
            
            procDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const procData = procRef?.current?.instance?.getDataSource()?.items();
        const postProcData = procData.map((item) => ({
            clieName: item.clie_name || '', 
            procKind: item.proc_kind || '', 
            workNumb: item.work_numb || '', 
            routNumb: item.rout_numb || '', 
            routName: item.rout_name || '', 
            lastRout: item.last_rout || false, 
            matlCode: item.matl_code || '', 
            matlName: item.matl_name || '', 
            matlSpec: item.matl_spec || '', 
            lotsNumb: item.lots_numb || '', 
            procQtys: item.proc_qtys || 0.000, 
            procWeig: item.proc_weig || 0.000, 
            procPric: item.proc_pric || 0.00, 
            procKwon: item.proc_kwon || 0.00, 
            procDate: item.proc_date || null,       
        }));        
        const postData = {
            procDate: data.proc_date || fwUtil.getToDayText(),
            procMemo: data.proc_memo || '',
            setAmaps: postProcData || [],
        };

        await fwUtil.submitData(postData, insertApi, mainRefresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [data, insertApi, mainRefresh, hiding, alertHide, procRef]);

    return (
        <>
        <PopupForm
            title={'매입마감 등록'}       
            type={'마감'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'매입목록'}
                        gridRef={procRef} 
                        gridDataSource={procDataSource}
                    >
                        {CommCol({ dataField: "clie_name", caption: "업체명" })}
                        {CommCol({ dataField: "proc_kind", caption: "기종" })}
                        {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                        {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                        {CommCol({ dataField: "rout_name", caption: "공정명" })}
                        {BoolCol({ dataField: "last_rout", caption: "완료공정" })}
                        {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                        {CommCol({ dataField: "matl_name", caption: "자재명" })}
                        {CommCol({ dataField: "matl_spec", caption: "규격" })}
                        {CommCol({ dataField: "lots_numb", caption: "LOT NO." })}
                        {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                        {DeciCol({ dataField: "ware_weig", caption: "입고중량" })}
                        {DeciCol({ dataField: "ware_pric", caption: "입고단가" })}
                        {DeciCol({ dataField: "ware_kwon", caption: "입고금액" })}
                        {DateCol({ dataField: "ware_date", caption: "입고일자" })}  
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormDate({ value: data.proc_date, onValueChange: updateField('proc_date'), label: '확정일자' })}
            </GroupItem>
            {FormMemo({ value: data.proc_memo, onValueChange: updateField('proc_memo'), label: '비고' })}
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 건들을 매입마감 처리 하시겠습니까?'}
        />  
        </>
    );
}