import { useEffect, useState } from "react";
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { jsPDF as JsPdf } from 'jspdf';
import saveAs from 'file-saver';
import notify from "devextreme/ui/notify";
import axios from 'axios';
import DataSource from "devextreme/data/data_source";

export function axiosInstanceCreate() {
    const axiosInstance = axios.create(); // 빌드할 때
    //const axiosInstance = axios.create({ baseURL: 'https://localhost:8889', withCredentials: true }); // 혼자할 때
    //const axiosInstance = axios.create({ baseURL: 'http://192.168.0.05:8888', withCredentials: true }); 
    //const axiosInstance = axios.create({ baseURL: 'http://focuswin.iptime.org:8888', withCredentials: true });
    axiosInstance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export const startSearch = async (sendData, setDataList, api, gridInstance) => {
    try {
        const axiosInstance = axiosInstanceCreate();
        gridInstance?.current?.instance?.beginCustomLoading("");
        const response = await axiosInstance.post(`${api}`, sendData);
        if (response.data.status === true) {
            setDataList(response.data.dataSet);
        } else {
            setDataList([]);
        }
    }
    catch (error) {
        return {
            message: "데이터 조회에 오류가 발생했습니다", error: error,
        }
    } finally {
        gridInstance?.current?.instance?.endCustomLoading();
    };
};

// 데이터 조회
export const searchData = async (sendData, api, setData) => {
    try {
        const axiosInstance = axiosInstanceCreate();
        const response = await axiosInstance.post(`${api}`, sendData);
        if (response.data.status === true) {
            const resData = response.data.dataSet;
            setData(resData);
        }
        return [];
    }
    catch (error) {
        notify("데이터 조회 중 오류가 발생했습니다", 'error', 2000);
    }
};

// 타겟 조회
export const targetData = async (sendData, api) => {
    if (sendData) {
        try {
            const axiosInstance = axiosInstanceCreate();
            const response = await axiosInstance.post(`${api}`, sendData);
            return response.data.dataSet;
        }
        catch (error) {
            console.log('getTargetData.error === ', error);
            notify('사용자 정보 조회 중 오류가 발생했습니다', 'error', 2000)
            return null;
        }
    };
};

// 데이터 검증
export const valditData = (validationGroup) => {
    const validationResult = validationGroup?.validate();
    if (!validationResult.isValid) {
        validationResult.brokenRules[0].validator.focus();
        notify(`${validationResult.brokenRules[0].message}`, 'error', 2000);
        return false;
    }
    return true;
};

// 데이터 발송
export const submitData = async (postData, api, refresh) => {
    try {
        console.log('submit start');
        const axiosInstance = axiosInstanceCreate();
        const response = await axiosInstance.post(`${api}`, postData);
        notifyResponse(response.data);
        refresh && refresh();
        console.log('response === ', response);
    }
    catch (error) {
        console.log('error === ', error);
        notify('데이터 전송 중 오류가 발생했습니다', 'error', 2000);
    }
};

// 데이터 추출
export const exportData = (fileName) => (e) => {
    if (e.format === 'pdf') {
        const doc = new JsPdf();
        exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
        }).then(() => {
            doc.save(`${fileName}.pdf`);
        });
    } else {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(`${fileName}`);

        exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
            });
        });
        e.cancel = true;
    }
};

// 데이터 삭제
export const deleteData = async (grid, api, deleKey, refresh) => {
    try {
        const selectedRowKeys = grid?.current?.instance?.getSelectedRowKeys();
        if (selectedRowKeys) {
            grid.current.instance.deselectAll();
            await Promise.all(selectedRowKeys.map(async (item) => {
                const postData = deleKey(item);
                await deleteRow(api, postData);
            }));
        } else {
            notify("1개 이상의 데이터를 선택 후 시도해주세요", 'warning', 2000);
        }
    } catch (error) {
        console.log('error === ', error);
    } finally {
        refresh();
    }
};

const deleteRow = async (api, postData) => {
    try {
        const axiosInstance = axiosInstanceCreate();
        const response = await axiosInstance.post(api, postData);
        notifyResponse(response.data);
        console.log('delete response === ', response);
    } catch (error) {
        console.log('error === ', error);
        throw new Error('Failed to delete data');
    }
};

export const replaceText = (value) => value || '';
export const replaceNumb = (value) => value || 0;
export const replaceDate = (value) => value || null;
export const replaceFile = (value) => value || null;

export function SetClock({ elementId }) {
    const [clockDateTime, setClockDateTime] = useState(window.getToDate() + " " + window.getToTime());

    useEffect(() => {
        const timerID = setInterval(() => { setClockDateTime(window.getToDate() + " " + window.getToTime()); }, 1000);
        return () => { clearInterval(timerID); };
    }, []);

    useEffect(() => {
        const element = document.getElementById(elementId);
        if (element) { element.textContent = window.getToDate() + " " + window.getToTime() }
    }, [elementId, clockDateTime]);

    return null;
};

export function classNames(classMap) {
    return Object.entries(classMap)
    .filter(([key, value]) => value)
    .map(([key, value]) => key)
    .join(' ');
};

export function getToMonthText() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
};

export function getToDayText() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function getToDateTimeText() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hour = String(today.getHours()).padStart(2, '0');
    const minute = String(today.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}`;
};

export function getToTimeText() {
    const today = new Date();
    const hour = String(today.getHours()).padStart(2, '0');
    const minute = String(today.getMinutes()).padStart(2, '0');
    return `${hour}:${minute}`;
};

export function getFirstDayText() {
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const year = firstDate.getFullYear();
    const month = (firstDate.getMonth() + 1).toString().padStart(2, '0');
    const day = firstDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function getLastDayText() {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const year = lastDay.getFullYear();
    const month = (lastDay.getMonth() + 1).toString().padStart(2, '0');
    const day = lastDay.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function getFirstDateTimeText() {
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const year = firstDate.getFullYear();
    const month = (firstDate.getMonth() + 1).toString().padStart(2, '0');
    const day = firstDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day} 00:00`;
};

export function getLastDateTimeText() {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const year = lastDay.getFullYear();
    const month = (lastDay.getMonth() + 1).toString().padStart(2, '0');
    const day = lastDay.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day} 23:59`;
};

export function ternaryFunction(defaultValue, value) {
    if(isNumber(value)) {
        return nunToNumber(value) ? defaultValue : value
    } else if(isString(value)) {
        return nuebCheck(value) ? defaultValue : value
    } else {
        return nueCheck(value) ? defaultValue : value
    }
};

export function ternFunc(defaultValue, value) {
    if(isNumber(value)) {
        return nunToNumber(value) ? defaultValue : value
    } else if(isString(value)) {
        return nuebCheck(value) ? defaultValue : value
    } else {
        return nueCheck(value) ? defaultValue : value
    }
};

export function nueCheck(value) {
    return (isNull(value) || isUndefined(value) || value === '') ? true : false;
};

export function nuebCheck(value) {
    return (isNull(value) || isUndefined(value) || value.trim() === '') ? true : false;
};

export function codeDecomposer(data) {
    const regex = /(\w+): (.*)/;
    const match = data.match(regex);

    if (!match) {
        return { error: "데이터가 주어진 형식에 맞지 않습니다." };
    }

    const code = match[1];
    const name = match[2];

    return { code, name };
};

export function convertToCamelCase(str) {
    return str.replace(/_([a-z])/g, (match, letter) => {
        return letter.toUpperCase();
    });
};

export function nuToNumber(value) {
    return (isNull(value) || isUndefined(value)) ? true : false;
};

function nunToNumber(value) {
    return (isNull(value) || isUndefined(value) || isNaN(value)) ? true : false;
};

function isString(value) {
    return typeof value === 'string';
};

function isNumber(value) {
    return typeof value === 'number';
};

function isObject(value) {
    return typeof value === 'object';
};

function isArray(value) {
    return Array.isArray(value);
};

function isNull(value) {
    return value === null;
};

function isUndefined(value) {
    return typeof value === 'undefined';
};

export function EmptyToNull(value) {
    if (isNull(value) || isUndefined(value)) {
        return null;
    } else if (isString(value)) {
        return value.trim() === "" ? null : value;
    } else if (isArray(value)) {
        return value.length === 0 ? null : value;
    } else { return value; }
};

export function bufferToBlob(data) {
    if (data && data.type === "Buffer" && data.data) {
        const uint8Array = new Uint8Array(data.data);
        const blob = new Blob([uint8Array], { type: 'image/jpeg' });
        const file = new File([blob], 'filename.jpg', { lastModified: new Date() });
        return file;
    }
    return data;
};

export function notifyResponse(response) {
    if (response) {
        if (response.status) {
            notify(`${response.message}`, 'success', 2000);
        } else {
            notify(`${response.message}`, 'error', 2000);
        }
    }
};

export function formatContactNumb(contactNumb) {
    const onlyNumbers = contactNumb.replace(/\D/g, '');
    if (onlyNumbers.length === 10) {
        return onlyNumbers.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else if (onlyNumbers.length === 11) {
        return onlyNumbers.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
        return onlyNumbers;
    };
};

export const isImageFile = (file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    const isImage = ['jpg', 'jpeg', 'gif', 'png'].includes(extension);
    if (isImage) {
        return true;
    }
    return false;
};

export const findSelectedRowData = (grid) => {
    const getSelectedRowData = grid?.current?.instance?.getSelectedRowsData() || [];
    
    if (getSelectedRowData.length > 0) {
        return getSelectedRowData;
    }
    return null;
};

export const findSelectedRowKey = (grid) => {
    const getSelectedRowKeys = grid?.current?.instance?.getSelectedRowKeys();
    
    if (isArray(getSelectedRowKeys)) {
        if (getSelectedRowKeys.length >= 2) {
            return getSelectedRowKeys;
        } else {
            return getSelectedRowKeys[0];
        }
    } else {
        return undefined;
    }
};

export const findSelectedLastRowKey = (grid) => {
    const getSelectedRowKeys = grid?.current?.instance?.getSelectedRowKeys();
    
    if (isArray(getSelectedRowKeys)) {
        if (getSelectedRowKeys.length >= 2) {
            return getSelectedRowKeys[getSelectedRowKeys.length - 1];
        } else {
            return getSelectedRowKeys[0];
        }
    } else {
        return undefined;
    }
};

export function localizingDateTime(e) {
    const date = new Date(e);
    date.setHours(date.getHours() + 9);
    const formattedDate = date.toISOString().replace("T", " ").replace("Z", "");
    return formattedDate;
};

export const handleSelectionChanged = (selectedKeys, setSelectedRowKey) => {
    setSelectedRowKey(ternFunc('', selectedKeys.selectedRowKeys[0]));
};

export const getAlertData = (selectedData) => {
    if (isObject(selectedData)) {
        if (Object.keys(selectedData).length >= 2) {
            return Object.values(selectedData);
        }
    } else {
        return  [selectedData];
    }   
};

export function findDefaultDropGridKey(grid, codeName, data) {
    for (let i = 0; i < grid.length; i++) {
        if (grid[i][codeName] === data) {
            return i; 
        }
    }
};

export function generateRandomKey() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getDefaultDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    
    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); 
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return [formatDate(firstDayOfMonth), formatDate(lastDayOfMonth)];
};

export function singleSelectRow(grid, keys, selected) {
    if (grid.current.instance) {
        grid.current.instance.deselectAll();
        if (!selected) {
            grid.current.instance.selectRows(keys, true);
        }
    }
};

export function multipleSelectRow(grid, keys, selected) {
    if (grid.current.instance) {
        if (!selected) {
            grid.current.instance.selectRows(keys, true);
        } else {
            grid.current.instance.deselectRows(keys);
        }
    }
};

export const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    return `${hour}:${minute}:${second}`;
};

export const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const generateDataSource = () => {
    const dataSource = new DataSource({
        store: [],
        key: 'rowKey',
        load: function() {
            return this.store;
        },
        insert: function(values) {
            const rowKey = generateRandomKey();
            values.rowKey = rowKey;
            if (values.init) {
                this.store = [];
                return;
            } else if (this.store) {
                this.store.push(values);
            } else {
                this.store = [values]
            }
        },
        remove: function(key) {
            const index = this.store.findIndex(item => item.rowKey === key);
            if (index !== -1) {
                this.store.splice(index, 1);
            }
        },
        update: function(key, values) {
            const index = this.store.findIndex(item => item.rowKey === key);
            console.log(index)
            if (index !== -1) {
                this.store[index] = { ...this.store[index], ...values };
            }
        },
    });

    return dataSource;
};