// PreMaintRegist - 항목 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PreMaintRegistInsert } from './Regist-insert';
import { PreMaintRegistUpdate } from './Regist-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "항목";
const mainRole = "등록";
const subsTitle = "항목";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                     // 페이지 메뉴 구분
const code = "F31";                                     // 페이지 메뉴 코드
const mainKey = 'main_numb';                            // 메인 데이터 키
const subsKey = ['main_numb', 'chck_seri'];             // 서브 데이터 키

const subsDeleKey = (data) => {                         // 삭제 데이터 키
    return { 
        mainNumb: data.main_numb, 
        chckSeri: data.chck_seri 
    }
};

const mainSearchApi = 'privat/com/code/main/search';    // 메인 조회 api
const subsSearchApi = 'privat/com/code/subs/search';    // 서브 조회 api
const subsInsertApi = 'privat/com/code/subs/insert';    // 서브 등록 api
const subsUpdateApi = 'privat/com/code/subs/update';    // 서브 수정 api
const subsDeleteApi = 'privat/com/code/subs/delete';    // 서브 삭제 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    { main_numb: "001", equi_numb: "001-001", equi_gubu: "A", equi_name: "설비 1" },
    { main_numb: "002", equi_numb: "001-002", equi_gubu: "B", equi_name: "설비 2" },
];

const tempSubsData = [
    { main_numb: "001", chck_seri: "001", chck_part: "Part1", chck_gubu: "Gubu1", chck_term: "Term1", chck_desc: "Desc1", chck_resu: "Resu1" },
    { main_numb: "001", chck_seri: "002", chck_part: "Part2", chck_gubu: "Gubu2", chck_term: "Term2", chck_desc: "Desc2", chck_resu: "Resu2" },
    { main_numb: "002", chck_seri: "001", chck_part: "Part3", chck_gubu: "Gubu3", chck_term: "Term3", chck_desc: "Desc3", chck_resu: "Resu3" }
];

function PreMaintRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { mainNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            subsGridDataUpdate(tempSubsData.filter(item => item.main_numb === keyData));
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = subsGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.deleteData(subsGridRef, subsDeleteApi, subsDeleKey, subsHandleSearch);
        }
        subsDeleteAlertHide();
    }, [subsGridRef, subsHandleSearch, subsDeleteAlertHide]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "설비명", value: equiName, valueChange: equiNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                notify('상세코드를 선택 후 시도해주세요.', 'warning', 2000);
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            notify('등록코드를 선택 후 시도해주세요.', 'warning', 2000);
            return;
        }

        handleSubsAction();

    }, [mainGridRef, subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}           
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_numb", caption: "관리번호" })}
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "chck_seri", caption: "순번" })}
                {CommCol({ dataField: "chck_part", caption: "점검PART" })}
                {CommCol({ dataField: "chck_gubu", caption: "잠감구분" })}
                {CommCol({ dataField: "chck_term", caption: "점검주기" })}
                {CommCol({ dataField: "chck_desc", caption: "점검항목" })}
                {CommCol({ dataField: "chck_resu", caption: "비고" })}      
            </SubsGrid>         
            <PreMaintRegistInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                targetApi={subsSearchApi}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />
            <PreMaintRegistUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />                      
        </div>
    );
};


export default PreMaintRegist;