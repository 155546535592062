import { useScreenSize } from '../../../../utils/media-query';
import * as fowinUtil from '../../util';
import { startDateBox, complDateBox } from '../date-time-box/date-box';
import { SearchBtn, CheckBox  } from '../data-grid/data-grid-header/grid-header-items';

const ComSearchPanel = (props) => {
    const duraYesn = fowinUtil.ternaryFunction(true, props.duraYesn); 
    const { isXSmall } = useScreenSize();
    const pickerTypeValues = () => { return isXSmall ? 'rollers' : 'calendar' }

    return (
        <>
            {!props.disabledDataSearch &&
            <div className='grid-search-date fw-row-flex-box'>
                {startDateBox({ pickerType: pickerTypeValues(), onValueChanged: props.startChang, disabled: !duraYesn })}
                {complDateBox({ pickerType: pickerTypeValues(), onValueChanged: props.complChang, disabled: !duraYesn })}
                {CheckBox({ value: duraYesn, onValueChanged: props.checkChang })}
            </div>
            }
                {props.children}
            {SearchBtn({ onClick: props.searchClick })}
        </>
    )
}

export { 
    ComSearchPanel 
}