import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';

function KpiInfoStatus() {


    return ( 
        < div>미구현</div>
    );
}

export default KpiInfoStatus;

