import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextBox, DateBox, FileUploader, NumberBox, SelectBox, CheckBox, TextArea, Button, Autocomplete } from 'devextreme-react';
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import { classNames, nueCheck, ternFunc } from '../../util';
import { DrawingButton } from '../custom-drawing/custom-drawing';
import { useScreenSize } from '../../../../utils/media-query';
import { Item } from 'devextreme-react/cjs/form';
import { Button as TextButton } from 'devextreme-react/cjs/text-box';
import notify from 'devextreme/ui/notify';
import './form-items.scss';


const allowedImageFileExtensions = ['image/jpg', 'image/jpeg', 'image/png'];

const FormImag = (props) => {
    const { label, labelHide, size, circle, isEditing, value, onValueChange, horizontalLabel, allowDrawing, disabled, required } = props;
    const [image, setImage] = useState('');
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const onDropZoneEnter = useCallback(() => setIsDropZoneActive(true), [setIsDropZoneActive]);
    const onDropZoneLeave = useCallback(() => setIsDropZoneActive(false), [setIsDropZoneActive]);
    const targetRef = useRef(null);
    const formLabel = required ? `${label} *` : label;

    // 이미지 변경 후 적용
    useEffect(() => {
        const file = value;
        if (!file || file === '') {
            setIsDropZoneActive(false);
            setImage('');
            return;
        } 
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageDataUrl = e.target.result;
            setImage(imageDataUrl);
            setIsDropZoneActive(false);
        };
        reader.readAsDataURL(file);
    }, [value]);

    const onValueChanged = (e) => {
        if (!e.value || !e.value[0]) return;
        const file = e.value[0];
        const fileExtension = file.type.toLowerCase();
        if (!allowedImageFileExtensions.includes(fileExtension)) {
            notify('파일 형식이 옳바르지 않습니다', 'error', 2000);
            return;
        }
        onValueChange(file);
    };

    return (
        <Item cssClass={classNames({ 'fw-imag-item': true, 'accent': required, 'circle': circle })}>
            <div className={`form-image-view ${horizontalLabel ? 'fw-row-flex-box' : 'fw-column-flex-box'}`}>
            { label && !labelHide &&
                <span className={horizontalLabel ? 'fw-label fw-horizontal-label' : 'fw-label fw-vertical-label'}>{formLabel}</span>
            }
                <div 
                    className={`form-image ${!disabled && !isEditing ? 'isEditing' : ''}`}
                    style={{
                        width: size,
                        height: size,
                        maxHeight: size,
                    }}
                >
                    <div
                        ref={targetRef}
                        className={`fw-dropzone ${isDropZoneActive ? 'dropzone-active' : ''}`}
                        title={ !disabled && !isEditing ? '이미지 가져오기' : '' }
                    >
                        {nueCheck(value) ? (
                            <div className="fw-dropzone-text fw-column-flex-box">
                                { !disabled && !isEditing ? (
                                    <span>업로드할 파일을 <br/>드래그 앤 드롭 하시거나 <br/>클릭해 주세요</span>
                                ) : (
                                    <span>표시할 이미지가 <br/>없습니다</span>
                                )}
                            </div>
                        ) : (
                            <img className='fw-dropzone-image' src={image} alt={label} />
                        )}                       
                        <i className='dx-icon-photooutline' />
                    </div>
                </div>
                { !disabled && !isEditing &&
                    <FileUploader
                        selectButtonText="찾아보기"
                        uploadMode='useButtons'
                        dialogTrigger={targetRef.current}
                        dropZone={targetRef.current}
                        visible={false}
                        multiple={false}
                        onValueChanged={onValueChanged}
                        onDropZoneEnter={onDropZoneEnter}
                        onDropZoneLeave={onDropZoneLeave}
                    />
                }
                { allowDrawing &&
                    <DrawingButton 
                        width={size}
                        disabled={isEditing}
                        saveCanvas={(file) => onValueChange(file)}
                    />
                }
            </div>            
        </Item>
    );
};

const FormText = (props) => {
    const { label, horizontalLabel, value, maxLength, mask, isEditing, onValueChange, required, disabled } = props;
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-text-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{label}</span>
                }
                <TextBox
                    label={formLabel}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    readOnly={isEditing}
                    disabled={disabled}
                    maxLength={maxLength}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                    placeholder='-'
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormAuto = (props) => {
    const { label, horizontalLabel, value, dataSource, maxLength, mask, isEditing, onValueChange, required, disabled } = props;
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-text-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{label}</span>
                }
                <Autocomplete
                    label={formLabel}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    dataSource={dataSource}
                    readOnly={isEditing}
                    disabled={disabled}
                    maxLength={maxLength}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                    placeholder='-'
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </Autocomplete>
            </div>
        </Item>
    )
};

const FormMemo = (props) => {
    const { label, horizontalLabel, value, mask, isEditing, onValueChange, required, disabled } = props;
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-memo-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{label}</span>
                }
                <TextArea
                    label={formLabel}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                    placeholder='-'
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </TextArea>
            </div>
        </Item>
    )
};

const FormBool = (props) => {
    const { label, horizontalLabel, value, isEditing, onValueChange, disabled } = props;
    const booleanValue = value === 1 || value === true;

    return (
        <Item cssClass={'fw-bool-item'}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{label}</span>
                }
                <CheckBox 
                    text={label}
                    stylingMode='outlined'
                    value={booleanValue}
                    onValueChange={onValueChange}
                    readOnly={isEditing}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    disabled={disabled}
                />
            </div>
        </Item>
    )
};

const FormRegx = (props) => {
    const { label, horizontalLabel, value, mask, isEditing, onValueChange, required, disabled, rule, message } = props;

    const regxMessage = ternFunc(`${label} 형식이 잘못되었습니다.`, message);
    const formLabel = required ? `${label} *` : label;

    const validateValue = (value) => {
        return value === "" ? true : (rule ? rule.test(value) : false);
    };

    return (
        <Item cssClass={classNames({ 'fw-regx-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <TextBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                    placeholder='-'
                >
                    <Validator>
                        {required && <RequiredRule />}
                        <CustomRule 
                            message={regxMessage}
                            validationCallback={(e) => validateValue(e.value)}
                        />
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormNumb = (props) => {
    const { label, horizontalLabel, value, length, suffix, isEditing, onValueChange, required, disabled } = props;

    const formLabel = required ? `${label} *` : label;
    let format = '';
    const [integerLength, decimalLength] = length || [null, null];
    const integerPart = integerLength !== null ? '0'.repeat(integerLength) : '#0';
    const decimalPart = decimalLength !== null && decimalLength !== 0 ? `.${'0'.repeat(decimalLength)}` : '';
    const suffixPart = suffix ? ` ${suffix}` : '';
    const numericValue = typeof value === 'string' ? Number(value) : value;
    format = `${integerPart}${decimalPart}${suffixPart}`;
    

    return (
        <Item cssClass={classNames({ 'fw-numb-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <NumberBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    format={format}
                    value={numericValue}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                    showSpinButtons
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </NumberBox>
            </div>
        </Item>
    )
};

const FormMont = (props) => {
    const { label, horizontalLabel, mask, isEditing, value, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-date-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <DateBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    type='date'
                    calendarOptions={{
                        maxZoomLevel: 'year'
                    }}
                    displayFormat={'yyyy-MM'}
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormTime = (props) => {
    const { label, horizontalLabel, mask, isEditing, value, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const formLabel = required ? `${label} *` : label;
    const calendarOptions = { visible: false };

    return (
        <Item cssClass={classNames({ 'fw-date-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <DateBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    calendarOptions={calendarOptions}
                    displayFormat={'HH:mm:ss'}
                    stylingMode='filled'
                    type='datetime'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormDate = (props) => {
    const { label, horizontalLabel, mask, isEditing, value, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-date-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <DateBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    type='date'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormDtme = (props) => {
    const { label, horizontalLabel, value, mask, isEditing, onValueChange, required, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-dtme-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <DateBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    pickerType={pickerType}
                    readOnly={isEditing}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    type='datetime'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>            
        </Item>
    )
};

const FormSelc = (props) => {
    const { label, horizontalLabel, items, displayExpr, valueExpr, value, mask, isEditing, onValueChange, required, disabled } = props;
    const formLabel = required ? `${label} *` : label;

    return (
        <Item cssClass={classNames({ 'fw-selc-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{formLabel}</span>
                }
                <SelectBox
                    label={label}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    items={items}
                    displayExpr={displayExpr}
                    value={value}
                    valueExpr={valueExpr}
                    mask={mask}
                    readOnly={isEditing}
                    disabled={disabled}
                    placeholder='-'
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </SelectBox>
            </div>            
        </Item>
    )
};

const FormPass = (props) => {
    const { label, horizontalLabel, value, maxLength, mask, isEditing, onValueChange, confirm, password, required, disabled } = props;
    const [passwordMode, setPasswordMode] = useState('password');
    const formLabel = required ? `${label} *` : label;
    const buttonOpt = {
        icon: 'eyeopen',
        stylingMode: 'text',
        onClick: () => {
            setPasswordMode((prev) => (prev === 'text' ? 'password' : 'text'));
        },
    };

    const confirmPassword = useCallback(
        ({ value }) => {
            const currentPassword = password;
            if ((!value || value.trim() === '') && (!currentPassword || currentPassword.trim() === '')) {
                return true;
            }
            return value === currentPassword;
        }, [password]
    );

    return (
        <Item cssClass={classNames({ 'fw-text-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{label}</span>
                }
                <TextBox
                    label={formLabel}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    mode={passwordMode}
                    value={value}
                    mask={mask}
                    readOnly={isEditing}
                    disabled={disabled}
                    maxLength={maxLength}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    onValueChange={onValueChange}
                    placeholder='-'
                >
                    <TextButton
                        name={`fw-${label}-password`}
                        location='after'
                        options={buttonOpt}
                    />                    
                    <Validator>
                        { required && <RequiredRule /> }
                        { confirm && 
                            <CustomRule 
                                message="비밀번호가 일치하지 않습니다." 
                                validationCallback={confirmPassword}
                            /> 
                        }
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormGrid = (props) => {
    const { label, horizontalLabel, value, mask, isEditing, required, disabled, onClick } = props;
    const formLabel = required ? `${label} *` : label;
    const buttonOpt = {
        icon: 'search',
        stylingMode: 'text',
        onClick: !isEditing && onClick
    };
    const onKeyPress = (e) => {
        e.event.preventDefault();  
    };

    return (
        <Item cssClass={classNames({ 'fw-grid-item': true, 'accent': required })}>
            <div className={horizontalLabel ? 'fw-row-flex-box fw-input-wrap' : 'fw-input-wrap'}>
                { horizontalLabel &&
                    <span className='fw-horizontal-label'>{label}</span>
                }
                <TextBox
                    label={formLabel}
                    labelMode={horizontalLabel ? 'hidden' : 'floating'}
                    value={value}
                    mask={mask}
                    disabled={disabled}
                    readOnly={isEditing}
                    onKeyDown={onKeyPress}
                    onKeyUp={onKeyPress}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='filled'
                    placeholder='-'
                >
                    <TextButton
                        name={`fw-${label}-grid-opener`}
                        location='after'
                        options={buttonOpt}
                    />
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormBtns = (props) => {
    const { icon, text, onClick, disabled } = props;

    return (
        <Item cssClass={'fw-btns-item'}>
            <div className={'fw-btns-wrapper'}>
                <Button
                    icon={icon}
                    text={text}
                    onClick={onClick}
                    disabled={disabled}
                />
            </div>
        </Item>
    )
};

export {
    FormImag,
    FormText,
    FormAuto,
    FormMemo,
    FormBool,
    FormRegx,
    FormNumb,
    FormMont,
    FormTime,
    FormDate,
    FormDtme,
    FormSelc,
    FormGrid,
    FormPass,
    FormBtns,
};