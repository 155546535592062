// WorkerStatus - 작업자 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { WorkerStatusInsert } from './Status-insert';
import { WorkerStatusUpdate } from './Status-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "작업자";
const role = "관리";
const gridSize = 10;

const gubu = "mes";                             // 페이지 메뉴 구분
const code = "A5";                              // 페이지 메뉴 코드
const mainKey = 'work_code';                    // 데이터 키
const deleKey = (data) => {                     // 삭제 데이터 키
    return { workCode: data }
};                                                         

const searchApi = 'privat/com/worker/search';   // 조회 api
const targetApi = 'privat/com/worker/target';   // 타겟 api
const insertApi = 'privat/com/worker/insert';   // 등록 api
const updateApi = 'privat/com/worker/update';   // 수정 api
const deleteApi = 'privat/com/worker/delete';   // 삭제 api

const exportFormats = ['xlsx', 'pdf'];
const enabStat = ['사용중', '미사용'];

function WorkerStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [workName, setWorkName] = useState("");  
    const [enabYesn, setEnabYesn] = useState("사용중");    
    const workNameChange = useCallback((e) => { setWorkName(e) }, []);
    const enabYesnChange = useCallback((e) => { setEnabYesn(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { workName: workName, enabYesn: enabYesn === "사용중" ? true : false };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [workName, enabYesn, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "작업자명", value: workName, valueChange: workNameChange, search: handleSearch })}
                {ToolbarSelcBox({ caption: "사용여부", value: enabYesn, valueChange: enabYesnChange, search: handleSearch, items: enabStat })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "work_code", caption: "작업자코드" })}
                {CommCol({ dataField: "work_name", caption: "작업자명" })}
                {CommCol({ dataField: "work_dept", caption: "작업부서" })}
                {CommCol({ dataField: "mach_kind", caption: "기종" })}
                {BoolCol({ dataField: "enab_yesn", caption: "적용유무" })}
                {DeciCol({ dataField: "work_pric", caption: "작업단가" })}
                {CommCol({ dataField: "rout_code", caption: "공정코드" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "work_memo", caption: "비고" })}
            </PagingGrid>
            <WorkerStatusInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <WorkerStatusUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 작업자를 삭제하시겠습니까?'}
            />             
        </div>
    );
};


export default WorkerStatus;