import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { getMainEqui as getEqui, getMainClie as getClie } from '../../../../../api/set';
import { FormBool, FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    main_numb: '',
    main_seri: '',
    equi_numb: '',
    equi_name: '',
    brok_date: null,
    brok_desc: '',
    next_date: null,
    self_main: false,
    clie_code: '',
    clie_name: '',
    main_date: fwUtil.getToDayText(),
    main_desc: '',
    main_kwon: 0.00,
    main_memo: '',
}

const tempEqui = [
    { equi_numb: "EQ001", equi_name: "설비 1" },
    { equi_numb: "EQ002", equi_name: "설비 2" },
    { equi_numb: "EQ003", equi_name: "설비 3" }
];

const tempMain = [
    { clie_code: "C001", clie_name: "정비업체 1" },
    { clie_code: "C002", clie_name: "정비업체 2" },
    { clie_code: "C003", clie_name: "정비업체 3" }
];

export function MaintRegistInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 설비 그리드
    const equiGridRef = useRef(null);
    const [equiData, setEquiData] = useState([]);
    const equiGridUpdate = useCallback((e) => setEquiData(e), []);
    const equiKeyExpr = 'equi_numb';
    // 업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';    
    // 그리드 팝업
    const [equiPopupVisible, setEquiPopupVisible] = useState(false);
    const equiPopupHiding = useCallback(() => setEquiPopupVisible(false), []);
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 설비 조회
    const equiHandleSearch = useCallback(async() => {
        const sendData = { equiNumb: "", equiName: "" };
        const res = await getEqui(sendData);
        //equiGridUpdate(res);
        equiGridUpdate(tempEqui);
    }, [equiGridUpdate]);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await getClie(sendData);
        //clieGridUpdate(res);
        clieGridUpdate(tempMain);
    }, [clieGridUpdate]);    

    // 설비 팝업 열릴 때
    const equiPopupOnShowing = useCallback(() => {
        equiHandleSearch();
    }, [equiHandleSearch]);

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 설비 팝업 더블 클릭
    const equiOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, equi_numb: e.data.equi_numb, equi_name: e.data.equi_name });
        equiPopupHiding();
    }, [data, dataUpdate, equiPopupHiding]);

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);    

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        if (field === 'self_main') {
            if (value) {
                dataUpdate({ ...data, [field]: value, clie_code: '1000', clie_name: '(유)세기개발' });
            } else {
                dataUpdate({ ...data, [field]: value });
            }
            return;
        }
        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainNumb: data.main_numb || '',
            mainSeri: data.main_seri || '',
            equiNumb: data.equi_numb || '',
            equiName: data.equi_name || '',
            brokDate: data.brok_date || null,
            brokDesc: data.brok_desc || '',
            nextDate: data.next_date || null,
            selfMain: data.self_main || false,
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            mainDate: data.main_date || fwUtil.getToDayText(),
            mainDesc: data.main_desc || '',
            mainKwon: data.main_kwon || 0.00,
            mainMemo: data.main_memo || '',
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        onHiding();
    }, [data, insertApi, refresh, onHiding]);

    return (
        <>
        <PopupGrid
            title={'설비번호 선택'}   
            type={'선택'}
            visible={equiPopupVisible}  
            showing={equiPopupOnShowing}  
            hiding={equiPopupHiding}
        >   
            <ScrollGrid
                title={'설비'}
                role={'목록'}
                gridDataSource={equiData}
                gridRef={equiGridRef}
                mainKey={equiKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={equiOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupGrid
            title={'정비업체 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            showing={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'정비업체'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={clieOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "업체코드" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'고장정비 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormGrid({ value: data.equi_numb, onClick: () => setEquiPopupVisible(true), label: '설비번호', required: true })}
                {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), label: '설비명', isEditing: true })}
                {FormDate({ value: data.brok_date, onValueChange: updateField('brok_date'), label: '고장일자', required: true })}
                {FormDate({ value: data.next_date, onValueChange: updateField('next_date'), label: '차기점검일' })}
                <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                    {FormMemo({ value: data.brok_desc, onValueChange: updateField('brok_desc'), label: '고장내역', required: true })}
                </GroupItem>
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '정비업체코드', required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '정비업체명', isEditing: true })}
                {FormDate({ value: data.main_date, onValueChange: updateField('main_date'), label: '정비일자', required: true })}
                {FormBool({ value: data.self_main, onValueChange: updateField('self_main'), label: '자체정비' })}
                <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                    {FormMemo({ value: data.main_desc, onValueChange: updateField('main_desc'), label: '정비내역', required: true })}
                </GroupItem>                                
                {FormNumb({ value: data.main_kwon, onValueChange: updateField('main_kwon'), label: '수리/부품비' })}
            </GroupItem>
            {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}