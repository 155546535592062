import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    insp_numb: '',
    work_numb: '',
    insp_gubu: '',
    rout_numb: '',
    rout_name: '',
    lots_qtys: 0.00,
    clie_code: '',
    clie_name: '',
    item_code: '',
    item_name: '',
    insp_date: fwUtil.getToDayText(),
};

const inspDataSource = fwUtil.generateDataSource();

export function InspRoutRegistInsert(props) {
    const {
        mainGrid,
        mainRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 검사품목 그리드
    const inspRef = useRef(null);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedRowData = fwUtil.findSelectedRowData(mainGrid);
        if (selectedRowData) {
            const newData = {
                insp_numb: selectedRowData[0].insp_numb || '',
                work_numb: selectedRowData[0].work_numb || '',
                insp_gubu: selectedRowData[0].insp_gubu || '',
                rout_numb: selectedRowData[0].rout_numb || '',
                rout_name: selectedRowData[0].rout_name || '',
                clie_code: selectedRowData[0].clie_code || '',
                clie_name: selectedRowData[0].clie_name || '',
                item_code: selectedRowData[0].item_code || '',
                item_name: selectedRowData[0].item_name || '',
                lots_qtys: selectedRowData[0].work_qtys || 0.00,
                insp_date: selectedRowData[0].comp_date || fwUtil.getToDayText(),
            }
            inspDataSource.store().insert({ init: true }).then(() => inspDataSource.reload());
            dataUpdate(newData);
        } else {
            notify('선택 항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        }

    }, [dataUpdate, hiding, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const inspData = inspRef?.current?.instance?.getDataSource()?.items();
        const postMatlData = inspData.map((item) => ({
            inspSeri: item.insp_seri || '',
            inspCate: item.insp_cate || '',
            inspSpec: item.insp_spec || '',
            inspMath: item.insp_math || '',
            inspResu: item.insp_resu || '',
            itemRemk: item.item_remk || '',
            workName: item.work_name || '',
            itemMemo: item.item_memo || '',            
        }));        
        const postData = {
            inspNumb: data.insp_numb || '',
            workNumb: data.work_numb || '',
            inspGubu: data.insp_gubu || '',
            routNumb: data.rout_numb || '',
            routName: data.rout_name || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            lotsQtys: data.lots_qtys || 0.00,
            inspDate: data.insp_date || fwUtil.getToDayText(),
            setaMaps: postMatlData || [],
        };

        await fwUtil.submitData(postData, insertApi, mainRefresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, mainRefresh, hiding]);

    return (
        <PopupForm
            title={'중간검사 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : isMedium ? 600 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'검사 품목'}
                        gridRef={inspRef} 
                        gridDataSource={inspDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "insp_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "insp_cate", caption: "검사항목", check: true })}
                        {CommCol({ dataField: "insp_spec", caption: "기준(규격)", check: true })}
                        {CommCol({ dataField: "insp_math", caption: "검사방법", check: true })}
                        {CommCol({ dataField: "insp_resu", caption: "검사결과", check: true })}
                        {CommCol({ dataField: "item_remk", caption: "특기사항", check: true })}
                        {CommCol({ dataField: "work_name", caption: "작업자", check: true })}
                        {CommCol({ dataField: "item_memo", caption: "비고", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 4 }>
                {FormText({ value: data.insp_numb, onValueChange: updateField('insp_numb'), label: '검사번호[자동]', disabled: true })}
                {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', isEditing: true, required: true })}
                {FormText({ value: data.rout_numb, onValueChange: updateField('rout_numb'), label: '공정번호', isEditing: true })}
                {FormText({ value: data.rout_name, onValueChange: updateField('rout_name'), label: '공정명', isEditing: true })}
                {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '고객번호', isEditing: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', isEditing: true })}
                {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '품목번호', isEditing: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '품목명', isEditing: true })}
                {FormNumb({ value: data.lots_qtys, onValueChange: updateField('lots_qtys'), label: '검사수량', required: true, length: [15, 2] })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자', required: true })}
            </GroupItem>
        </PopupForm>   
    );
}
