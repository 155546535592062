// BomStatus - 제품 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "BOM";
const mainRole = "관리";
const subsTitle = "BOM";
const subsRole = "상세";
const gridSize = 5;

const gubu = "mes";                                             // 페이지 메뉴 구분
const code = "A43";                                             // 페이지 메뉴 코드
const mainKey = 'item_code';                                    // 메인 데이터 키
const subsKey = ['item_code', 'matl_code'];                     // 서브 데이터 키

const mainSearchApi = 'privat/com/bom/status/header/search';    // 메인 조회 api
const subsSearchApi = 'privat/com/bom/status/detail/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

function BomStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [itemCode, setBomCode] = useState("");
    const [itemName, setBomName] = useState(""); 
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const itemCodeChange = useCallback((e) => { setBomCode(e) }, []);
    const itemNameChange = useCallback((e) => { setBomName(e) }, []);  

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, itemCode: itemCode, itemName: itemName };
        await fwUtil.searchData(sendData, mainSearchApi, mainGridDataUpdate);
    }, [dateValue, duraYesn, itemCode, itemName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { itemCode: keyData };
            await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeBoms = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품코드", value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch })}
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, before: mainToolbarBeforeBoms }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "BOM제품번호" })}
                {CommCol({ dataField: "item_name", caption: "품명및규격" })}
                {DeciCol({ dataField: "boms_qtys", caption: "구성수량" })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
                {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용유무" })}
                {CommCol({ dataField: "boms_memo", caption: "비고" })}                                        
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {NumbCol({ dataField: "boms_leve", caption: "LEVEL", allowHeaderFiltering: false, allowSorting: false, maxLength: 2 })}
                {CommCol({ dataField: "matl_code", caption: "구성품번", allowHeaderFiltering: false, allowSorting: false })}
                {CommCol({ dataField: "matl_name", caption: "구성품명", allowHeaderFiltering: false, allowSorting: false })}
                {DeciCol({ dataField: "boms_qtys", caption: "구성수량", allowHeaderFiltering: false, allowSorting: false })}
                {CommCol({ dataField: "matl_gubu", caption: "품목구분", allowHeaderFiltering: false, allowSorting: false })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
                {CommCol({ dataField: "matl_memo", caption: "비고", allowHeaderFiltering: false, allowSorting: false })} 
            </SubsGrid>                            
        </div>
    );
};


export default BomStatus;