
import { CheckBox, DateRangeBox } from 'devextreme-react';
import { Toolbar, Item } from "devextreme-react/data-grid"
import { ToolbarButton } from './grid-toolbar-items';
import './grid-toolbar.scss';

function GridToolbar(props) {
    const {
        title, 
        dateValue, dateValueChange, duraYesn, duraYesnChange, 
        ins, upd, del, exp, 
        defaultItems, toolbarBeforeItems, toolbarAfterItems
    } = props;

    return (
        <Toolbar>
            <Item location='before'>
                <div className='fw-grid-header'>{title}</div>
            </Item>            
            { defaultItems &&
            <Item location='before' locateInMenu='auto' cssClass='fw-grid-toolbar-calendar-item'>
                <div className='fw-grid-header-date-search-panel fw-row-flex-box'>
                    <DateRangeBox 
                        width={245}
                        applyValueMode='useButtons'
                        value={dateValue}
                        onValueChanged={dateValueChange}
                        acceptCustomValue={false}
                        labelMode='hidden'
                        disabled={!duraYesn}
                        displayFormat={'yyyy-MM-dd'}
                        dateSerializationFormat={'yyyy-MM-dd'}
                    />
                    <CheckBox 
                        value={duraYesn}
                        onValueChanged={duraYesnChange}
                        elementAttr={{'aria-label': 'Handle value change'}}
                    />               
                </div>             
            </Item>    
            }
            { toolbarBeforeItems && toolbarBeforeItems() }
            { toolbarAfterItems && toolbarAfterItems() }
            { ins &&
            <Item location='after' locateInMenu='auto'>
                <ToolbarButton 
                    icon='plus'
                    text='등록'
                    visible={!ins.hidden}
                    disabled={ins.disabled}    
                    onClick={ins.onClick}            
                />
            </Item>      
            } 
            { upd &&
            <Item location='after' locateInMenu='auto'>
                <ToolbarButton
                    icon='edit'
                    text='수정'
                    visible={!upd.hidden}
                    disabled={upd.disabled}                        
                    onClick={upd.onClick}
                />
            </Item>      
            }             
            { del &&
            <Item location='after' locateInMenu='auto'>
                <ToolbarButton
                    icon='trash'
                    text='삭제'
                    visible={!del.hidden}
                    disabled={del.disabled}                        
                    onClick={del.onClick}
                />
            </Item>   
            }          
            <Item name='exportButton' disabled={exp && exp.disabled} />
            <Item name='columnChooserButton' locateInMenu='auto' />
            <Item name='searchPanel' locateInMenu='auto' />
        </Toolbar>        
    )
};

export { 
    GridToolbar 
};