import 'devextreme/dist/css/dx.light.css';
// 모듈과 스타일 import
import './focuswin/style/focuswin.variables.css';
import React, { useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './dx-styles.scss';
import './focuswin/style/focuswin.theme.base.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { ThemeProvider, useTheme } from './focuswin/script/contexts/theme';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';

// 한글 딕셔너리 추가
import koMessages from "./ko.json";
import { locale, loadMessages } from "devextreme/localization";

// 라이센스
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import userInfo from './utils/default-user';

config({ licenseKey });

// 언어 설정
loadMessages(koMessages);
locale("ko");

// App 컴포넌트 정의
function App() {
  const { user, loading } = useAuth();
  const { setTheme, getTheme } = useTheme();
  useEffect(() => setTheme(getTheme()), [setTheme, getTheme]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
};

// Root 컴포넌트 정의
export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
            <NavigationProvider>
              <div className={`app ${screenSizeClass}`}>
                <App />
              </div>
            </NavigationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}
