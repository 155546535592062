// ItemStatus - 제품 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "제품";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                  // 페이지 메뉴 구분
const code = "A41";                                  // 페이지 메뉴 코드
const mainKey = ['item_code', 'item_gubu'];          // 데이터 키                                                

const searchApi = 'privat/com/item/status/search';   // 조회 api

const exportFormats = ['xlsx', 'pdf'];

function ItemStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");  
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, itemCode: itemCode, itemName: itemName };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [dateValue, duraYesn, itemCode, itemName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품코드", value: itemCode, valueChange: itemCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {CommCol({ dataField: "item_quli", caption: "재질" })}
                {CommCol({ dataField: "item_unit", caption: "제품단위" })}
                {DeciCol({ dataField: "item_pric", caption: "제품단가" })}
                {CommCol({ dataField: "data_gubu", caption: "제품분류", groupIndex: 0 })}
                {CommCol({ dataField: "cite_numb", caption: "고객품번" })}
                {CommCol({ dataField: "proj_code", caption: "프로젝트코드" })}
                {CommCol({ dataField: "proj_name", caption: "프로젝트명" })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}       
            </PagingGrid>        
        </div>
    );
};


export default ItemStatus;