import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react';
import '../detail-panel.scss';

export function PanelHeader(props) {
    const {title, status, isPinned, onPinClick, onClosePanelClick, isLarge, isMedium} = props;
    return (
        <Toolbar className='fw-detail-pandel-toolbar'>
            <ToolbarItem location='before'>
                <span className='fw-detail-panel-title'>{title || "데이터없음"}</span>
            </ToolbarItem>
            <ToolbarItem location='before'>
                { status && 
                <div className={status.info}>
                    <span>{status.message}</span>
                </div>
                }
            </ToolbarItem>
            <ToolbarItem
                location='after'
                visible={isLarge || isMedium}
            >
            <Button
                icon={isPinned ? 'pin' : 'unpin'}
                stylingMode='text'
                onClick={onPinClick}
            />
            </ToolbarItem>
            <ToolbarItem location='after'>
                <Button
                    icon='close'
                    stylingMode='text'
                    onClick={onClosePanelClick}
                />
            </ToolbarItem>                
        </Toolbar>
    )
}