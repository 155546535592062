import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    star_date: fwUtil.getToDayText(),
};

const planDataSource = fwUtil.generateDataSource();

export function WorkOrderRegistInsert(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);        
    // 발주 그리드
    const planRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowData = fwUtil.findSelectedRowData(mainGrid);
        if (!selectedRowData) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            planDataSource.store().insert({ init: true }).then(() => planDataSource.reload());
            
            for (const item of selectedRowData) {
                const newData = {
                    plan_numb: item.plan_numb || '',
                    orde_numb: item.orde_numb || '',
                    clie_name: item.clie_name || '',
                    proj_name: item.proj_name || '',
                    deli_date: item.deli_date || null,
                    clie_orde: item.clie_orde || '',
                    cite_code: item.cite_code || '',
                    cite_name: item.cite_name || '',
                    work_qtys: item.orde_qtys || 0.00,
                };
                planDataSource.store().insert(newData);
            }
            
            dataUpdate(defaultData);
            planDataSource.reload();
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const planData = planRef?.current?.instance?.getDataSource()?.items();
        const postPremData = planData.map((item) => ({
            planNumb: item.plan_numb || '',
            ordeNumb: item.orde_numb || '',
            workQtys: item.work_qtys || 0.00,
        }));        
        const postData = {
            starDate: data.star_date || fwUtil.getToDayText(),
            setAmaps: postPremData || [],
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [data, insertApi, refresh, hiding, alertHide, planRef]);

    return (
        <>
        <PopupForm
            title={'작업지시 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall || isSmall ? 300 : isMedium ? 720 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'선택 항목'}
                        gridRef={planRef} 
                        gridDataSource={planDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "plan_numb", caption: "계획번호", allowEditing: false })}
                        {CommCol({ dataField: "orde_numb", caption: "수주번호", allowEditing: false })}
                        {CommCol({ dataField: "clie_name", caption: "고객명", allowEditing: false })}
                        {CommCol({ dataField: "proj_name", caption: "프로젝트명", allowEditing: false })}
                        {DateCol({ dataField: "deli_date", caption: "납기일자", allowEditing: false })}
                        {CommCol({ dataField: "clie_orde", caption: "오더번호", allowEditing: false })}
                        {CommCol({ dataField: "cite_code", caption: "품목번호", allowEditing: false })}
                        {CommCol({ dataField: "cite_name", caption: "품명", allowEditing: false })}
                        {DeciCol({ dataField: "work_qtys", caption: "작업수량", check: true })}
                    </AddingGrid>                 
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 }>
                {FormDate({ value: data.star_date, onValueChange: updateField('star_date'), label: '작업시작일', required: true })}                
            </GroupItem>
        </PopupForm>
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 등록 하시겠습니까?'}
        />  
        </>
    );
}