// WareHousingNList - 미입고 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "미입고";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "C25";                                         // 페이지 메뉴 코드
const mainKey = 'ppro_numb';                                // 데이터 키                                                

const searchApi = 'privat/mat/warehousing/nlist/search';   // 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "ppro_numb": "A123-001",
        "clie_name": "ABC Corp",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_name": "Steel Rod",
        "ppro_date": "2024-05-20",
        "pdel_date": "2024-05-25",
        "ppro_qtys": 100,
        "rece_qtys": 70,
        "nwar_qtys": 30,
        "damd_name": "John Doe",
        "reqe_dept": "Production",
        "ppro_remk": "Urgent",
        "ppro_stat": "Pending"
    },
    {
        "ppro_numb": "B456-002",
        "clie_name": "XYZ Ltd",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_name": "Aluminum Sheet",
        "ppro_date": "2024-05-21",
        "pdel_date": "2024-05-26",
        "ppro_qtys": 200,
        "rece_qtys": 150,
        "nwar_qtys": 50,
        "damd_name": "Jane Smith",
        "reqe_dept": "Warehouse",
        "ppro_remk": "Check for quality",
        "ppro_stat": "In Progress"
    },
    {
        "ppro_numb": "C789-003",
        "clie_name": "LMN Inc",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_name": "Copper Wire",
        "ppro_date": "2024-05-22",
        "pdel_date": "2024-05-27",
        "ppro_qtys": 300,
        "rece_qtys": 200,
        "nwar_qtys": 100,
        "damd_name": "Alice Brown",
        "reqe_dept": "Quality Control",
        "ppro_remk": "Handle with care",
        "ppro_stat": "Completed"
    },
    {
        "ppro_numb": "D012-004",
        "clie_name": "OPQ Partners",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_name": "Plastic Tubes",
        "ppro_date": "2024-05-23",
        "pdel_date": "2024-05-28",
        "ppro_qtys": 400,
        "rece_qtys": 350,
        "nwar_qtys": 50,
        "damd_name": "Bob White",
        "reqe_dept": "Logistics",
        "ppro_remk": "Check dimensions",
        "ppro_stat": "Pending"
    },
    {
        "ppro_numb": "E345-005",
        "clie_name": "RST Corp",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_name": "Rubber Gaskets",
        "ppro_date": "2024-05-24",
        "pdel_date": "2024-05-29",
        "ppro_qtys": 500,
        "rece_qtys": 450,
        "nwar_qtys": 50,
        "damd_name": "Eve Black",
        "reqe_dept": "Manufacturing",
        "ppro_remk": "High priority",
        "ppro_stat": "In Progress"
    }
]


function WareHousingNList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [seleGubu, setSeleGubu] = useState("구매");
    const [wareNumb, setWareNumb] = useState("");
    const [prosNumb, setProsNumb] = useState("");
    const [matlName, setMatlName] = useState("");
    const [clieName, setClieName] = useState("");
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { seleGubu: seleGubu, wareNumb: wareNumb, prosNumb: prosNumb, matlName: matlName, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [seleGubu, wareNumb, prosNumb, matlName, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, seleGubu]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "입고유형", value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: ['구매', '외주'] })}
                {ToolbarTextBox({ caption: "입고번호", value: wareNumb, valueChange: wareNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "ppro_numb", caption: "발주번호" })} 
                {CommCol({ dataField: "clie_name", caption: "업체명" })} 
                {CommCol({ dataField: "matl_name", caption: "자재명" })} 
                {DateCol({ dataField: "ppro_date", caption: "발주일자" })} 
                {CommCol({ dataField: "pdel_date", caption: "납품요구일" })} 
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })} 
                {DeciCol({ dataField: "rece_qtys", caption: "입고수량" })} 
                {DeciCol({ dataField: "nwar_qtys", caption: "미입고량", check: true })} 
                {CommCol({ dataField: "damd_name", caption: "발주담당" })} 
                {CommCol({ dataField: "reqe_dept", caption: "수요처" })} 
                {CommCol({ dataField: "ppro_remk", caption: "참고사항" })} 
                {CommCol({ dataField: "ppro_stat", caption: "진행상태" })} 
            </PagingGrid>        
        </div>
    );
};


export default WareHousingNList;