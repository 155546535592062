// ProductChange - 작업실적 변경
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { ProductChangeUpdate } from './Change-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "작업실적";
const role = "변경";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "D33";                                         // 페이지 메뉴 코드
const mainKey = ['work_numb', 'prod_seri'];                 // 데이터 키   
const deleKey = (data) => {                                 // 삭제 데이터 키
    return { 
        workNumb: data.work_numb,
        prodSeri: data.prod_seri,
    }
};                                                                                                  

const searchApi = 'privat/mfg/product/change/search';       // 조회 api
const updateApi = 'privat/mfg/product/change/update';       // 수정 api
const deleteApi = 'privat/mfg/product/change/delete';       // 삭제 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        work_numb: 'WO001',
        prod_seri: 1,
        item_code: 'ITM001',
        item_name: 'Product A',
        comp_date: '2024-05-26',
        star_time: '08:00:00',
        clos_time: '16:00:00',
        work_qtys: 100,
        rout_numb: 'R01: Assembly',
        work_name: 'John Doe',
        last_rout: true,
        infe_qtys: 5,
        infe_ca01: false,
        infe_ca02: false,
        infe_ca03: false,
        infe_ca04: false,
        infe_ca05: false,
        infe_ca06: false,
        infe_ca07: false,
        infe_ca08: false,
        infe_ca09: false,
        infe_ca10: true,
        ca10_memo: 'Defect found during assembly.'
    },
    {
        work_numb: 'WO002',
        prod_seri: 1,
        item_code: 'ITM002',
        item_name: 'Product B',
        comp_date: '2024-05-26',
        star_time: '09:00:00',
        clos_time: '17:00:00',
        work_qtys: 80,
        rout_numb: 'R02: Testing',
        work_name: 'Jane Smith',
        last_rout: false,
        infe_qtys: 0,
        infe_ca01: false,
        infe_ca02: false,
        infe_ca03: false,
        infe_ca04: false,
        infe_ca05: false,
        infe_ca06: false,
        infe_ca07: false,
        infe_ca08: false,
        infe_ca09: false,
        infe_ca10: false,
        ca10_memo: ''
    }
];

function ProductChange() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [workNumb, setWorkNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, workNumb: workNumb };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, workNumb, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "작업지시번호", value: workNumb, valueChange: workNumbChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);    

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 변경/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: 'work_numb', caption: '작업지시번호' })}
                {CommCol({ dataField: 'prod_seri', caption: '일보순번' })}
                {CommCol({ dataField: 'item_code', caption: '품목번호' })}
                {CommCol({ dataField: 'item_name', caption: '품목명' })}
                {DateCol({ dataField: 'comp_date', caption: '작업일자' })}
                {TimeCol({ dataField: 'star_time', caption: '시작시간' })}
                {TimeCol({ dataField: 'clos_time', caption: '종료시간' })}
                {DeciCol({ dataField: 'work_qtys', caption: '완료수량' })}
                {CommCol({ dataField: 'rout_numb', caption: '공정명' })}
                {CommCol({ dataField: 'work_name', caption: '작업자명' })}
                {CommCol({ dataField: 'last_rout', caption: '최종공정' })}
                {CommCol({ dataField: 'infe_qtys', caption: '불량수량' })}
                {BoolCol({ dataField: 'infe_ca01', caption: '사유1' })}
                {BoolCol({ dataField: 'infe_ca02', caption: '사유2' })}
                {BoolCol({ dataField: 'infe_ca03', caption: '사유3' })}
                {BoolCol({ dataField: 'infe_ca04', caption: '사유4' })}
                {BoolCol({ dataField: 'infe_ca05', caption: '사유5' })}
                {BoolCol({ dataField: 'infe_ca06', caption: '사유6' })}
                {BoolCol({ dataField: 'infe_ca07', caption: '사유7' })}
                {BoolCol({ dataField: 'infe_ca08', caption: '사유8' })}
                {BoolCol({ dataField: 'infe_ca09', caption: '사유9' })}
                {BoolCol({ dataField: 'infe_ca10', caption: '기타' })}
                {CommCol({ dataField: 'ca10_memo', caption: '기타사유' })}
            </PagingGrid>        
            <ProductChangeUpdate
                mainGrid={gridRef}
                refresh={handleSearch}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 작업실적을 삭제하시겠습니까?'}
            />                         
        </div>
    );
};


export default ProductChange;