import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupGrid } from '../../../../script/components/popup/popup';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { Item } from 'devextreme-react/cjs/data-grid';
import { ToolbarTextBox, SearchButton } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const keyExpr = 'matl_code';  // 데이터 키

export function PproSubsSelect(props) {
    const {
        mainGrid,
        refresh,
        selectApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const matlGridRef = useRef(null);
    // 검색 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    
    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { matlCode: matlCode,  matlName: matlName };
        await fwUtil.searchData(sendData, selectApi, dataUpdate);
    }, [matlCode, matlName, dataUpdate, selectApi]);    

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 열릴 때
    const onShowing = () => {
        setLoading(true);
    };

    // 선택 데이터 조회
    const onShown = useCallback(async () => {
        await handleSearch();
        setLoading(false);
    }, [handleSearch, setLoading]);

    // 제출
    const onSubmit = useCallback(async () => {
        const selectPproKey = fwUtil.findSelectedRowKey(mainGrid);
        const selectedRowKey = fwUtil.findSelectedRowKey(matlGridRef);
        if (selectedRowKey) {
            setLoading(true);
            const selectedData = matlGridRef.current.instance.getSelectedRowsData() || [];

            const convertdData = selectedData.map((item) => ({
                pproSeri: item.ppro_seri || '',
                matlCode: item.matl_code || '',
                pproQtys: item.ppro_qtys || 0.000,
                pproPric: item.ppro_pric || 0.00,
                reqeDept: item.reqe_dept || '',
                pproMemo: item.ppro_memo || '',
                pursNumb: item.purs_numb || '',
            }));
            const postData = { pproNumb: selectPproKey, setaMaps: convertdData }
            await fwUtil.submitData(postData, insertApi, refresh);
            matlGridRef.current.instance.deselectAll();
            setLoading(false);
            hiding();
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 200);
        }
                
    }, [insertApi, hiding, matlGridRef, refresh]);

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(matlGridRef, key, isSelected);
    }, [matlGridRef]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = useCallback(() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품번호", value: matlCode, valueChange: matlCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "제품명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        );
    }, [matlCode, matlCodeChange, matlName, matlNameChange, handleSearch]);

    const gridBody = useMemo(() => (
        <ScrollGrid
            title="자재"
            role="목록"
            gridDataSource={data}
            gridRef={matlGridRef}
            mainKey={keyExpr}
            width={isXSmall ? 300 : isSmall ? 550 : 860}
            height={600}
            onRowClick={onRowClick}
            selection={'multiple'}
            toolbar={{ default: false, before: toolbarBeforeItems }}
        >
            {CommCol({ dataField: "purs_numb", caption: "요구번호" })}
            {DateCol({ dataField: "purs_date", caption: "요청일자" })}            
            {CommCol({ dataField: "matl_code", caption: "자재번호" })}
            {CommCol({ dataField: "matl_name", caption: "자재품명" })}
            {DeciCol({ dataField: "pros_qtys", caption: "요청수량" })}
            {CommCol({ dataField: "pros_unit", caption: "단위" })}
            {DeciCol({ dataField: "pros_pric", caption: "단가" })}
            {DeciCol({ dataField: "pros_cwon", caption: "금액" })}
            {CommCol({ dataField: "reqe_dept", caption: "수요처" })}
            {DateCol({ dataField: "deli_date", caption: "납기요청일" })}
            {CommCol({ dataField: "prus_memo", caption: "비고" })}
        </ScrollGrid>
    ), [data, matlGridRef, onRowClick, toolbarBeforeItems, isXSmall, isSmall]);

    return ( 
        <PopupGrid
            title={'발주 상세품목'}   
            type={'추가'}
            loading={loading}
            visible={visible}  
            showing={onShowing}
            shown={onShown}  
            hiding={hiding}
            onSubmit={onSubmit}
        >   
            {gridBody}
        </PopupGrid>
    );
}