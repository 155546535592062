// PProStatus - 발주 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { getPproClosGubu as getClos, getPproDateGubu as getDate } from '../../../../../api/set';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "발주";
const mainRole = "현황";
const subsTitle = "발주상세";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                              // 페이지 메뉴 구분
const code = "C14";                                              // 페이지 메뉴 코드
const mainKey = 'ppro_numb';                                     // 메인 데이터 키
const subaKey = ['ppro_numb', 'ppro_seri'];                      // 서브 데이터 키 (메인 데이터 키 값 존재 시)
const subbKey = ['purs_numb', 'matl_code'];                      // 서브 데이터 키 (메인 데이터 키 값 부재 시)

const mainSearchApi = 'privat/pro/ppro/status/header/search';    // 메인 조회 api
const subsSearchApi = 'privat/pro/ppro/status/detail/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];
const convClos = (clos) => {
    return clos === "발주진행" ? 0 : clos === "입고완료" ? 1 : 2;
}

function PProStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [closGubu, setClosGubu] = useState("")
    const [dateGubu, setDateGubu] = useState("")
    const [clieName, setClieName] = useState("");
    const [pproNumb, setPproNumb] = useState("");    
    const [closGubuList, setClosGubuList] = useState([]);
    const [dateGubuList, setDateGubuList] = useState([]);    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const closGubuChange = useCallback((e) => { setClosGubu(e) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const pproNumbChange = useCallback((e) => { setPproNumb(e) }, []);
    const closGubuListChange = useCallback((e) => setClosGubuList(e), []);    
    const dateGubuListChange = useCallback((e) => setDateGubuList(e), []);    

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [closResult, dateResult] = await Promise.all([getClos(), getDate()]);
                if (closResult && closResult.length > 0) {
                    closGubuListChange(closResult);
                    closGubuChange(closResult[0]);
                }   
                if (dateResult && dateResult.length > 0) {
                    dateGubuListChange(dateResult);
                    dateGubuChange(dateResult[0]);
                }                  
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [closGubuChange, closGubuListChange, dateGubuChange, dateGubuListChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        if (closGubu && dateGubu) {
            const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, closGubu: convClos(closGubu), dateGubu: dateGubu, clieName: clieName, pproNumb: pproNumb };
            await fwUtil.searchData(sendData, mainSearchApi, mainGridDataUpdate);
        }
    }, [dateValue, duraYesn, closGubu, dateGubu, clieName, pproNumb, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        const sendData = { pproNumb: keyData };
        await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainGridRef?.current?.instance?.deselectAll();
        subsGridRef?.current?.instance?.deselectAll();
        mainHandleSearch();
        subsHandleSearch();
    }, [mainHandleSearch, subsHandleSearch, closGubu, dateGubu, mainGridRef, subsGridRef]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.singleSelectRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "진행구분", value: closGubu, valueChange: closGubuChange, search: mainHandleSearch, items: closGubuList })}
                {ToolbarSelcBox({ caption: "일자구분", value: dateGubu, valueChange: dateGubuChange, search: mainHandleSearch, items: dateGubuList })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: pproNumb, valueChange: pproNumbChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'none'}
            >
                {CommCol({ dataField: "ppro_numb", caption: "발주번호" })}
                {CommCol({ dataField: "damd_name", caption: "발주담당" })}
                {DateCol({ dataField: "ppro_date", caption: "발주일자" })}
                {CommCol({ dataField: "ppro_usag", caption: "용도" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "clie_teln", caption: "업체전화" })}
                {CommCol({ dataField: "clie_faxn", caption: "업체팩스" })}
                {CommCol({ dataField: "clie_damd", caption: "업체담당" })}
                {DateCol({ dataField: "pdel_date", caption: "납품일자" })}
                {CommCol({ dataField: "pays_cond", caption: "지불형태" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "insp_damd", caption: "검수자(방법)" })}
                {CommCol({ dataField: "ppro_remk", caption: "특기사항" })}
                {CommCol({ dataField: "ppro_stat", caption: "진행상태" })}
                {CommCol({ dataField: "afte_conf", caption: "결재진행" })}
                {DateCol({ dataField: "appr_date", caption: "승인요청일" })}
                {DateCol({ dataField: "rele_date", caption: "발주처리일" })}
                {CommCol({ dataField: "prin_yesn", caption: "발주서출력여부" })}
                {CommCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {CommCol({ dataField: "ppro_cwon", caption: "발주금액" })}                                                
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={fwUtil.findSelectedRowKey(mainGridRef) ? subaKey : subbKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
                edit
            >           
                {CommCol({ dataField: "ppro_seri", caption: "순번", allowEditing: false, visible: fwUtil.findSelectedRowKey(mainGridRef) ? true : false })}
                {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
                {CommCol({ dataField: "matl_name", caption: "자재품명", allowEditing: false })}
                {CommCol({ dataField: "matl_spec", caption: "자재규격", allowEditing: false })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량", check: true })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위", allowEditing: false })}
                {DeciCol({ dataField: "ppro_pric", caption: "발주단가", check: true })}
                {DeciCol({ dataField: "ppro_cwon", caption: "발주금액", allowEditing: false })}
                {CommCol({ dataField: "ppro_memo", caption: "비고", check: true })}
                {CommCol({ dataField: "reqe_dept", caption: "수요처", allowEditing: false })}
            </SubsGrid>                      
        </div>
    );
};


export default PProStatus;