import { SelectBox } from 'devextreme-react';
import { ternaryFunction, nuebCheck } from '../../../util';
import { Button, Column } from 'devextreme-react/data-grid';

const defaultAlignment = 'left';

const CountCol = (props) => {
    const { fixed, hidingPriority } = props;

    const rowIndexTemplate = (cellInfo) => {
        return (
            <span className='fw-grid-count'>{cellInfo.row.rowIndex}</span>
        )
    };

    const isFixed = ternaryFunction(true, fixed);

    return (
        <Column
            dataField='rowNumber'
            caption='순번'
            dataType='number'
            alignment={defaultAlignment}
            width={60}
            allowFiltering={false}
            allowEditing={false}
            cellRender={rowIndexTemplate}
            fixed={isFixed}
            hidingPriority={hidingPriority}
        />
    );
};

const CommCol = (props) => {
    const { key, dataField, caption, width, minWidth, maxLength, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority, check, groupIndex } = props;

    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key}
            dataField={dataField}
            caption={caption}
            dataType={'string'}
            width={width}
            minWidth={minWidth}
            editorOptions={{
                maxLength: maxLength
            }}
            alignment={defaultAlignment}
            visible={isVisible}
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            fixed={isFixed}
            hidingPriority={hidingPriority}
            cssClass={check ? "fw-check-column" : ""}
            groupIndex={groupIndex}
        />
    );
};

const TimeCol = (props) => {
    const { key, dataField, caption, width, maxLength, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority } = props;

    const widthValue = ternaryFunction(120, width);
    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key}
            dataField={dataField}
            caption={caption}
            dataType={'date'}
            alignment={defaultAlignment}
            format={'HH:mm:ss'}
            width={widthValue}
            visible={isVisible}
            editorOptions={{
                max: Math.pow(10, maxLength - 1),
                min: 0
            }}            
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            fixed={isFixed}
            hidingPriority={hidingPriority}
        />
    );
};    

const DateCol = (props) => {
    const { key, dataField, caption, width, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority } = props;

    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key}
            dataField={dataField}
            caption={caption}
            dataType={'date'}
            alignment={defaultAlignment}
            format={'yyyy-MM-dd'}
            width={width}
            visible={isVisible}
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            fixed={isFixed}
            hidingPriority={hidingPriority}
        />
    );
};

const DtmeCol = (props) => {
    const { key, dataField, caption, width, maxLength, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority } = props;

    const widthValue = ternaryFunction(120, width);
    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key}
            dataField={dataField}
            caption={caption}
            dataType={'date'}
            alignment={defaultAlignment}
            format={'yyyy-MM-dd HH:mm:ss.SSS'}
            width={widthValue}
            visible={isVisible}
            editorOptions={{
                max: Math.pow(10, maxLength - 1),
                min: 0
            }}            
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            fixed={isFixed}
            hidingPriority={hidingPriority}
        />
    );
};    


const NumbCol = (props) => {
    const { key, dataField, caption, width, maxLength, Suffix, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority, check } = props;

    const calculateCellValue = (data) => {
        let fielddata = data && data[dataField];
        return nuebCheck(Suffix) ? fielddata : fielddata + ' ' + Suffix;
    };

    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key}
            dataField={dataField}
            caption={caption}
            dataType={'number'}
            alignment={defaultAlignment}
            width={width}
            editorOptions={{
                maxLength: maxLength
            }}
            visible={isVisible}
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            calculateDisplayValue={calculateCellValue}
            fixed={isFixed}
            hidingPriority={hidingPriority}
            cssClass={check ? "fw-check-column" : ""}
        />
    );
};

const DeciCol = (props) => {
    const { key, dataField, caption, width, Suffix, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority, customCellValue, check, maxLength } = props;

    const calculateCellValue = (data) => {
        let fielddata = data && data[dataField];
        if (customCellValue) {
            fielddata = customCellValue(data);
        }
        let toNumber = '0.0';
        if (typeof fielddata === 'number' && !isNaN(fielddata)) {
            toNumber = fielddata.toLocaleString();
        } else {
            let parsedFloat = parseFloat(fielddata);
            if (!isNaN(parsedFloat)) {
                toNumber = parsedFloat.toLocaleString();
            }
        }
        return nuebCheck(Suffix) ? toNumber : toNumber + " " + Suffix;
    };
    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key}
            dataField={dataField}
            caption={caption}
            dataType={'number'}
            alignment={defaultAlignment}
            width={width}
            visible={isVisible}
            allowFiltering={isHeaderFilteringAllowed}
            editorOptions={{
                max: Math.pow(10, maxLength - 1),
                min: 0
            }}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            calculateDisplayValue={calculateCellValue}
            fixed={isFixed}
            hidingPriority={hidingPriority}
            cssClass={check ? "fw-check-column" : ""}
        />
    );
};

const BoolCol = (props) => {
    const { key, dataField, caption, width, falseText, trueText, visible, allowHeaderFiltering, allowSorting, fixed, hidingPriority, headerCellRender } = props;

    const widthValue = ternaryFunction(120, width);
    const falseTextValue = ternaryFunction('false', falseText);
    const trueTextValue = ternaryFunction('true', trueText);

    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(false, allowSorting);
    const isFixed = ternaryFunction(false, fixed);

    const calculateCellValue = (data) => {
        if (data && data[dataField]) {
            if (data[dataField] === 1 || data[dataField] === true) {
                return true;
            }
        }
        return false;
    };

    return (
        <Column 
            key={key} 
            dataField={dataField}
            caption={caption}
            dataType={'boolean'}
            alignment={'center'}
            visible={isVisible}
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            fixed={isFixed}
            width={widthValue}
            calculateCellValue={calculateCellValue}
            falseText={falseTextValue}
            trueText={trueTextValue}
            hidingPriority={hidingPriority}
            headerCellRender={headerCellRender}
        />
    );    
};

const SelcCol = (props) => {
    const { key, dataField, caption, width, minWidth, visible, allowHeaderFiltering, allowSorting, allowEditing, fixed, hidingPriority, placeholder, items } = props;

    const isVisible = ternaryFunction(true, visible);
    const isHeaderFilteringAllowed = ternaryFunction(true, allowHeaderFiltering);
    const isSortingAllowed = ternaryFunction(true, allowSorting);
    const isEditingAllowed = ternaryFunction(true, allowEditing);
    const isFixed = ternaryFunction(false, fixed);
    const placeholderMessage = ternaryFunction(placeholder, '선택해주세요');

    const selcEditorRender = (cell) => {
        const onValueChanged = (e) => cell.setValue(e.value);
        return (
            <SelectBox
                defaultValue={cell.value}
                {...cell.column.lookup}
                onValueChanged={onValueChanged}
                placeholder={placeholderMessage}
                items={items}
            />
        );
    };

    return (
        <Column
            key={key} 
            dataField={dataField}
            caption={caption}
            dataType={'string'}
            width={width}
            minWidth={minWidth}
            alignment={defaultAlignment}
            visible={isVisible}
            allowFiltering={isHeaderFilteringAllowed}
            allowSorting={isSortingAllowed}
            allowEditing={isEditingAllowed}
            fixed={isFixed}
            hidingPriority={hidingPriority}
            editCellRender={selcEditorRender}
        />
    );
};

const BtnsCol = (props) => {
    const { key, dataField, caption, width, minWidth, visible, fixed, hidingPriority, onClick, allowDelete } = props;

    const isVisible = ternaryFunction(true, visible);
    const isFixed = ternaryFunction(false, fixed);

    return (
        <Column
            key={key} 
            dataField={dataField}
            caption={caption}
            type='buttons'
            width={width}
            minWidth={minWidth}
            visible={isVisible}
            fixed={isFixed}
            hidingPriority={hidingPriority}
        >
            { allowDelete && <Button name='delete' /> }
            <Button
                hint='제품검색'
                icon='search'
                onClick={onClick}
            />
        </Column>
    );
};

export { 
    CountCol,
    CommCol,
    TimeCol,
    DateCol,
    DtmeCol,
    NumbCol,
    DeciCol,
    BoolCol,
    SelcCol,
    BtnsCol,
}