// WareHousingCancle - 입고 취소
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as CancleAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "입고";
const role = "취소";
const gridSize = 10;

const gubu = "mes";                                            // 페이지 메뉴 구분
const code = "C24";                                            // 페이지 메뉴 코드
const mainKey = 'ware_numb';                                   // 데이터 키                                                
    
const searchApi = 'privat/mat/warehousing/cancle/search';      // 조회 api
const cancleApi = 'privat/mat/warehousing/cancle/cancle';      // 취소 api

const tempData = [
    {
        "ware_numb": "WP12345",
        "pros_numb": "PP20240522-001",
        "clie_name": "ABC Company",
        "ware_date": "2024-05-22",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M123",
        "matl_name": "Material A",
        "matl_spec": "Spec A",
        "lots_numb": "LOT001",
        "ppro_qtys": 100,
        "ware_qtys": 100,
        "ware_pric": 10.5,
        "stoc_qtys": 200,
        "ware_memo": "Received without issues"
    },
    {
        "ware_numb": "WP12346",
        "pros_numb": "PP20240522-002",
        "clie_name": "XYZ Corporation",
        "ware_date": "2024-05-22",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M124",
        "matl_name": "Material B",
        "matl_spec": "Spec B",
        "lots_numb": "LOT002",
        "ppro_qtys": 150,
        "ware_qtys": 150,
        "ware_pric": 12.75,
        "stoc_qtys": 180,
        "ware_memo": "Received on time"
    },
    {
        "ware_numb": "WP12347",
        "pros_numb": "PP20240522-003",
        "clie_name": "LMN Industries",
        "ware_date": "2024-05-22",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M125",
        "matl_name": "Material C",
        "matl_spec": "Spec C",
        "lots_numb": "LOT003",
        "ppro_qtys": 200,
        "ware_qtys": 200,
        "ware_pric": 15.25,
        "stoc_qtys": 220,
        "ware_memo": "Received with quality check"
    },
    {
        "ware_numb": "WP12348",
        "pros_numb": "PP20240522-004",
        "clie_name": "PQR Ltd.",
        "ware_date": "2024-05-22",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M126",
        "matl_name": "Material D",
        "matl_spec": "Spec D",
        "lots_numb": "LOT004",
        "ppro_qtys": 120,
        "ware_qtys": 120,
        "ware_pric": 11.0,
        "stoc_qtys": 180,
        "ware_memo": "Received in good condition"
    }
]

function WareHousingCancle() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [seleGubu, setSeleGubu] = useState("구매");
    const [wareNumb, setWareNumb] = useState("");
    const [prosNumb, setProsNumb] = useState("");
    const [matlName, setMatlName] = useState("");
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 취소 알림창
    const [cancleAlertVisible, setCancleAlertVisible] = useState(false);
    const cancleAlertHide = useCallback(() => { setCancleAlertVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, seleGubu: seleGubu, wareNumb: wareNumb, prosNumb: prosNumb, matlName: matlName, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, seleGubu, wareNumb, prosNumb, matlName, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, seleGubu]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 취소 경고 확인 클릭
    const onCancleClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            wareNumb: item.ware_numb,
            prosNumb: item.pros_numb,
            matlCode: item.matl_code,
            lotsNumb: item.lots_numb,
            wareQtys: item.ware_qtys,
            warePric: item.ware_pric,
            wareDate: item.ware_date,
        }));
        const postData = { seleGubu: seleGubu, setaMaps: convertdData }
        await fwUtil.submitData(postData, cancleApi, handleSearch);
        gridRef.current.instance.endCustomLoading();
        cancleAlertHide();
    }, [seleGubu, gridRef, handleSearch, cancleAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "입고유형", value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: ['구매', '외주'] })}
                {ToolbarTextBox({ caption: "입고번호", value: wareNumb, valueChange: wareNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };
    const toolbarAfterItems = () => {
        return (
            <Item location='after' locateInMenu='auto'>
                {ToolbarButton({ icon: 'trash', text: '취소', hidden: false, disabled: authList.auth_dele === 0, onClick: handleCancleClick })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 취소 버튼 클릭
    const handleCancleClick = useCallback(async () => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);
        if (selectedRowKey) {
            setCancleAlertVisible(true);
            return;
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);    

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}             
                toolbar={{ default: true, before: toolbarBeforeItems, after: toolbarAfterItems } }
                selection={'multiple'}
            >
                {CommCol({ dataField: "pros_numb", caption: "발주번호" })}
                {DateCol({ dataField: "ware_date", caption: "입고일" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "lots_numb", caption: "Lot No." })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "stoc-qtys", caption: "현재고량" })}
                {CommCol({ dataField: "ware_memo", caption: "비고" })}
            </PagingGrid>        
            <CancleAlert 
                grid={gridRef}
                visible={cancleAlertVisible}
                hiding={cancleAlertHide}
                confirm={onCancleClick}
                message={'해당 입고를 취소하시겠습니까?'}
            />               
        </div>
    );
};


export default WareHousingCancle;