// InspRoutChange - 중간검사 변경
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";
import { InspRoutChangeUpdate } from './Change-update';

const mainTitle = "중간검사";
const mainRole = "변경";
const subsTitle = "검사품목";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                                  // 페이지 메뉴 구분
const code = "E23";                                                  // 페이지 메뉴 코드
const mainKey = 'insp_numb';                                         // 메인 데이터 키
const subsKey = ['insp_numb', 'insp_seri'];                          // 서브 데이터 키
const subsDeleKey = (data) => {                                      // 삭제 데이터 키
    return { 
        inspNumb: data.insp_numb, 
        inspSeri: data.insp_seri 
    }
};

const mainSearchApi = 'privat/qcl/rout/change/header/search';        // 메인 조회 api
const subsSearchApi = 'privat/qcl/rout/change/detail/search';        // 서브 조회 api
const subsUpdateApi = 'privat/qcl/rout/change/detail/update';        // 서브 수정 api
const subsDeleteApi = 'privat/qcl/rout/change/detail/delete';        // 서브 삭제 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        insp_numb: 'INSP001',
        work_numb: 'WORK001',
        matl_code: 'MATL001',
        matl_name: 'Material A',
        rout_numb: 'ROUT001',
        rout_seri: '001',
        rout_name: 'Process A',
        insp_date: '2024-01-01',
        lots_qtys: 100,
        insp_memo: 'Memo A'
    },
    {
        insp_numb: 'INSP002',
        work_numb: 'WORK002',
        matl_code: 'MATL002',
        matl_name: 'Material B',
        rout_numb: 'ROUT002',
        rout_seri: '002',
        rout_name: 'Process B',
        insp_date: '2024-01-02',
        lots_qtys: 200,
        insp_memo: 'Memo B'
    }
];

const tempSubsData = [
    {
        insp_numb: 'INSP001',
        insp_seri: '001',
        insp_cate: 'Category A',
        insp_spec: 'Spec A',
        insp_math: 'Method A',
        insp_resu: 'Pass',
        item_remk: 'Remark A',
        work_name: 'Worker A',
        item_memo: 'Memo A'
    },
    {
        insp_numb: 'INSP001',
        insp_seri: '002',
        insp_cate: 'Category B',
        insp_spec: 'Spec B',
        insp_math: 'Method B',
        insp_resu: 'Fail',
        item_remk: 'Remark B',
        work_name: 'Worker B',
        item_memo: 'Memo B'
    },
    {
        insp_numb: 'INSP002',
        insp_seri: '001',
        insp_cate: 'Category C',
        insp_spec: 'Spec C',
        insp_math: 'Method C',
        insp_resu: 'Pass',
        item_remk: 'Remark C',
        work_name: 'Worker C',
        item_memo: 'Memo C'
    }
];

function InspRoutChange() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [workNumb, setWorkNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);    
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 수정 팝업창
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, workNumb: workNumb };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, workNumb, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { inspNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            const convertedData = tempSubsData.filter(item => item.insp_numb === keyData);
            subsGridDataUpdate(convertedData);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = subsGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.deleteData(subsGridRef, subsDeleteApi, subsDeleKey, subsHandleSearch);
        }
        subsDeleteAlertHide();
    }, [subsGridRef, subsHandleSearch, subsDeleteAlertHide]);    

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "작업지시번호", value: workNumb, valueChange: workNumbChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        const handleSubsAction = () => {
            if (!subsSelectedRowKey) {
                notify('상세 데이터를 선택 후 시도해주세요.', 'warning', 2000);
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            notify('메인 데이터를 선택 후 시도해주세요.', 'warning', 2000);
            return;
        }

        handleSubsAction();

    }, [mainGridRef, subsGridRef]);
    

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}      
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}            
                onRowClick={mainGridClick}           
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "matl_code", caption: "제품번호" })}
                {CommCol({ dataField: "matl_name", caption: "제품명" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_seri", caption: "공정순번" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {DeciCol({ dataField: "lots_qtys", caption: "검사수량" })}
                {CommCol({ dataField: "insp_memo", caption: "비고" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}                
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "insp_seri", caption: "순번" })}
                {CommCol({ dataField: "insp_cate", caption: "검사항목" })}
                {CommCol({ dataField: "insp_spec", caption: "기준(규격)" })}
                {CommCol({ dataField: "insp_math", caption: "검사방법" })}
                {CommCol({ dataField: "insp_resu", caption: "검사결과" })}
                {CommCol({ dataField: "item_remk", caption: "특기사항" })}
                {CommCol({ dataField: "work_name", caption: "작업자" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}
            </SubsGrid>         
            <InspRoutChangeUpdate
                mainGrid={mainGridRef}
                subsGrid={subsGridRef}
                refresh={subsHandleSearch}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}         
            />             
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />              
        </div>
    );
};


export default InspRoutChange;