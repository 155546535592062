// SaleStatus - 매출 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "매출";
const mainRole = "현황";
const subsTitle = "상세";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                            // 페이지 메뉴 구분
const code = "B33";                                            // 페이지 메뉴 코드
const mainKey = 'sale_numb';                                   // 메인 데이터 키
const subsKey = ['sale_numb', 'sale_seri'];                    // 서브 데이터 키

const mainSearchApi = 'privat/sal/sale/status/main/search';    // 메인 조회 api
const subsSearchApi = 'privat/sal/sale/status/subs/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        "sale_numb": "S001",
        "clie_name": "Client A",
        "prep_mont": "2024-01",
        "sale_date": "2024-05-01",
        "prep_gubu": "Type A",
        "ship_kwon": 1000000,
        "prep_kwon": 900000,
        "taxs_kwon": 100000,
        "sche_date": "2024-05-10",
        "ship_numb": "SH001",
        "sale_remk": "Remark A",
        "sale_memo": "Memo A"
    },
    {
        "sale_numb": "S002",
        "clie_name": "Client B",
        "prep_mont": "2024-02",
        "sale_date": "2024-05-02",
        "prep_gubu": "Type B",
        "ship_kwon": 2000000,
        "prep_kwon": 1800000,
        "taxs_kwon": 200000,
        "sche_date": "2024-05-11",
        "ship_numb": "SH002",
        "sale_remk": "Remark B",
        "sale_memo": "Memo B"
    }
]

const tempSubsData = [
    {
        "sale_numb": "S001",
        "sale_seri": 1,
        "matl_code": "M001",
        "matl_name": "Material A",
        "matl_spec": "Spec A",
        "ship_qtys": 100,
        "sale_pric": 10000,
        "sale_kwon": 1000000,
        "taxs_kwon": 100000,
        "sale_remk": "Remark A"
    },
    {
        "sale_numb": "S001",
        "sale_seri": 2,
        "matl_code": "M002",
        "matl_name": "Material B",
        "matl_spec": "Spec B",
        "ship_qtys": 200,
        "sale_pric": 5000,
        "sale_kwon": 1000000,
        "taxs_kwon": 100000,
        "sale_remk": "Remark B"
    },
    {
        "sale_numb": "S002",
        "sale_seri": 1,
        "matl_code": "M003",
        "matl_name": "Material C",
        "matl_spec": "Spec C",
        "ship_qtys": 150,
        "sale_pric": 10000,
        "sale_kwon": 1500000,
        "taxs_kwon": 150000,
        "sale_remk": "Remark C"
    },
    {
        "sale_numb": "S002",
        "sale_seri": 2,
        "matl_code": "M004",
        "matl_name": "Material D",
        "matl_spec": "Spec D",
        "ship_qtys": 50,
        "sale_pric": 10000,
        "sale_kwon": 500000,
        "taxs_kwon": 50000,
        "sale_remk": "Remark D"
    }
]

function SaleStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { saleNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            subsGridDataUpdate(tempSubsData.filter(item => item.sale_numb === keyData));
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.multipleSelectRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "고객사명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}  
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: true, before: mainToolbarBeforeItems }} 
                selection={'multiple'}
            >
                {CommCol({ dataField: "sale_numb", caption: "매출번호" })}   
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DateCol({ dataField: "prep_mont", caption: "기성월" })}
                {CommCol({ dataField: "sale_date", caption: "확정일자" })}
                {CommCol({ dataField: "prep_gubu", caption: "기성구분" })}
                {DeciCol({ dataField: "ship_kwon", caption: "납품금액" })}
                {DeciCol({ dataField: "prep_kwon", caption: "기성금액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "부가세액" })}
                {DateCol({ dataField: "sche_date", caption: "수금예정일" })}
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {CommCol({ dataField: "sale_remk", caption: "특기사항" })}
                {CommCol({ dataField: "sale_memo", caption: "비고" })}       
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "sale_numb", caption: "매출번호" })}
                {CommCol({ dataField: "sale_seri", caption: "순번" })}
                {CommCol({ dataField: "matl_code", caption: "제품번호" })}
                {CommCol({ dataField: "matl_name", caption: "제품명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {DeciCol({ dataField: "ship_qtys", caption: "닙픔수량" })}
                {DeciCol({ dataField: "sale_pric", caption: "닙픔단가" })}
                {DeciCol({ dataField: "sale_kwon", caption: "납품금액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "sale_remk", caption: "특기사항" })}
            </SubsGrid>                     
        </div>
    );
};


export default SaleStatus;