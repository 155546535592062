import React, { useEffect, useState, useCallback } from 'react';
import { GroupItem } from 'devextreme-react/form';
import { FormDtme, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { useScreenSize } from '../../../../../utils/media-query';
import { PanelHeader } from '../../../../script/components/detail-panel/detail-panel-header/PanelHeader';
import { PanelBody } from '../../../../script/components/detail-panel/detail-panel-body/PanelBody';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    user_idno: "",
    user_name: "",
    logs_date: fwUtil.getToDayText(),
    scre_name: "",
    logs_gubu: "",
    logs_ipno: "",
    data_size: 0,
    send_remk: "",
    send_erro: ""
};

export function LogPanel(props) {
    const { 
        gridRef, keyData, refresh,
        tar, upd, 
        isPanelOpened, panelOpendChange 
    } = props;

    const { isLarge, isMedium } = useScreenSize();
    // 패널 상태값
    const [isPinned, setIsPinned] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 핀
    useEffect(() => {
        changePanelPinned();
    }, [isPinned]);

    const changePanelPinned = useCallback(() => {
        gridRef.current?.instance.updateDimensions();
    }, [gridRef]);

    const onPinClick = useCallback(() => {
        setIsPinned(!isPinned);
        gridRef.current?.instance.updateDimensions();
    }, [isPinned, gridRef]);

    // 패널 닫기
    const onClosePanelClick = useCallback(() => {
        panelOpendChange();
        setIsPinned(false);
    }, [panelOpendChange]);

    // 수정 상태 전환
    const toggleEditHandler = useCallback(() => {
        setIsEditing(!isEditing);
    }, [isEditing]);

    // 선택 데이터 조회
    useEffect(() => {
        const fetchData = async () => {
            if (keyData) {
                const sendData = { userIdno: keyData.user_idno, logsDate: keyData.logs_date };
                const res = await fwUtil.targetData(sendData, tar.api);
                const data = {
                    user_idno: res.user_idno || "",
                    user_name: res.user_name || "",
                    logs_date: res.logs_date || fwUtil.getToDayText(),
                    scre_name: res.scre_name || "",
                    logs_gubu: res.logs_gubu || "",
                    logs_ipno: res.logs_ipno || "",
                    data_size: res.data_size || 0,
                    send_remk: res.send_remk || "",
                    send_erro: res.send_erro || ""
                };
                dataUpdate(data);   
            }
        };
        fetchData();
    }, [keyData, tar, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        setData({ ...data, [field]: value });
    };

    // 저장 버튼 클릭
    const onSaveClick = ({ validationGroup }) => {
        const validationResult = fwUtil.valditData(validationGroup);
        if (validationResult) {
            onSubmit();
            setIsEditing(!isEditing);    
        }
    };

    // 수정
    const onSubmit = useCallback(async () => {
        
        const postData = {
            userIdno: data.user_idno || "",
            logsDate: fwUtil.localizingDateTime(data.logs_date) || null,
            screName: data.scre_name || "",
            logsGubu: data.logs_gubu || "",
            logsIpno: data.logs_ipno || "",
            dataSize: data.data_size || 0,
            sendRemk: data.send_remk || "",
            sendErro: data.send_erro || ""
        };

        fwUtil.submitData(postData, upd?.api, refresh);
    }, [data, upd, refresh]);

    return (
        <div id='fw-mes-sys-user-detail-panel' className={fwUtil.classNames({ 'fw-detail-panel': true, 'open': isPanelOpened, 'pin': isPinned && (isLarge || isMedium) })}>
            <div className='fw-panel-wrapper'>
                <PanelHeader
                    title={`${data.scre_name} - ${data.logs_gubu}` || ''}
                    status={{
                        info: data.send_erro ? 'status status-red' : 'status status-green',
                        message: data.send_erro ? '오류' : '동작',
                    }}
                    isPinned={isPinned}
                    onPinClick={onPinClick}
                    onClosePanelClick={onClosePanelClick}
                    isLarge={isLarge}
                    isMedium={isMedium}
                />
                <PanelBody
                    isEditing={isEditing}
                    toggleEditHandler={toggleEditHandler}
                    onSaveClick={onSaveClick}
                    upd={upd}
                >
                    <GroupItem cssClass='contact-fields-group single-row'>      
                        {FormText({ value: data.user_idno, isEditing: !isEditing, label: '사용자ID', horizontalLabel: true, disabled: true })}
                        {FormText({ value: data.user_name, isEditing: !isEditing, label: '사용자명', horizontalLabel: true, disabled: true })}
                        {FormText({ value: data.logs_ipno, onValueChange: updateField('logs_ipno'), isEditing: !isEditing, label: '접속 IP', horizontalLabel: true })}
                        {FormNumb({ value: data.data_size, onValueChange: updateField('data_size'), isEditing: !isEditing, label: '데이터크기', horizontalLabel: true, suffix: 'Kbyte' })}                    
                        {FormDtme({ value: data.logs_date, isEditing: !isEditing, label: '로그일시', horizontalLabel: true, disabled: true })}
                        {FormText({ value: data.send_remk, onValueChange: updateField('send_remk'), isEditing: !isEditing, label: '비고', horizontalLabel: true })}
                        {FormText({ value: data.send_erro, onValueChange: updateField('send_erro'), isEditing: !isEditing, label: '오류내용', horizontalLabel: true })}                                                                                
                    </GroupItem>
                </PanelBody>
            </div>
        </div>
    )
};