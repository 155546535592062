import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { getClientVehicleMatl as getMatl, getClientVehicleGubu as getGubu } from '../../../../../api/set';
import { FormBool, FormGrid, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { Item } from 'devextreme-react/cjs/data-grid';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    clie_code: '',
    cars_numb: '',
    matl_code: '',
    enab_yesn: true,
    aver_qtys: 0.000,
    aver_weig: 0.000
}

export function VehicleStatusInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 자재(제품) 그리드
    const matlGridRef = useRef(null);
    const [matlData, setMatlData] = useState([]);
    const matlGridUpdate = useCallback((e) => setMatlData(e), []);
    const matlKeyExpr = 'matl_code';
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const [matlGubu, setMatlGubu] = useState('');
    const [gubuList, setGubuList] = useState([]);
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const matlGubuChange = useCallback((e) => setMatlGubu(e), []);
    const gubuChange = useCallback((e) => setGubuList(e), []);
    // 그리드 팝업
    const [matlPopupVisible, setMatlPopupVisible] = useState(false);
    const matlPopupHiding = useCallback(() => setMatlPopupVisible(false), []);

    // 자재(제품) 조회
    const matlHandleSearch = useCallback(async() => {
        if (matlGubu) {
            const sendData = { matlCode: matlCode, matlName: matlName, matlGubu: matlGubu };
            const res = await getMatl(sendData);
            matlGridUpdate(res);
        }
    }, [matlCode, matlName, matlGubu, matlGridUpdate]);

    useEffect(() => {
        matlHandleSearch();
    }, [matlGubu, matlHandleSearch]);

    // 자재(제품) 팝업 열릴 때
    const matlPopupOnShowing = useCallback(() => {
        matlHandleSearch();
    }, [matlHandleSearch]);

    // 자재(제품) 팝업 더블 클릭
    const matlOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, matl_code: e.data.matl_code });
        matlPopupHiding();
    }, [data, dataUpdate, matlPopupHiding]);

    // 그리드 추가 툴바 아이템
    const toolbarBeforeItems = useCallback(() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "자재구분", value: matlGubu, valueChange: matlGubuChange, search: matlHandleSearch, items: gubuList })}
                {ToolbarTextBox({ caption: "자재코드(제품번호)", value: matlCode, valueChange: matlCodeChange, search: matlHandleSearch })}
                {ToolbarTextBox({ caption: "자재명(제품명)", value: matlName, valueChange: matlNameChange, search: matlHandleSearch })}
                {SearchButton({ search: matlHandleSearch })}
            </Item>
        );
    }, [matlGubu, matlGubuChange, matlHandleSearch, gubuList, matlCode, matlCodeChange, matlName, matlNameChange]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [gubuResult] = await Promise.all([getGubu()]);
                if (gubuResult && gubuResult.length > 0) {
                    gubuChange(gubuResult);
                    matlGubuChange(gubuResult[0]);
                }                                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuChange, matlGubuChange]);

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('거래처 정보를 불러오지 못했습니다', 'error', 2000);
            onHiding();
        } else {
            const updatedData = {
                ...defaultData,
                clie_code: selectedRowKey
            };
            dataUpdate(updatedData);
        }
    }, [onHiding, dataUpdate, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            clieCode: data.clie_code || '',
            carsNumb: data.cars_numb || '',
            matlCode: data.matl_code || '',
            enabYesn: data.enab_yesn || false,
            averQtys: data.aver_qtys || 0.000,
            averWeig: data.aver_weig || 0.000 
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        onHiding();
    }, [data, insertApi, refresh, onHiding]);

    return (
        <>
        <PopupGrid
            title={'계량대 자재(제품) 선택'}   
            type={'선택'}
            visible={matlPopupVisible}  
            showing={matlPopupOnShowing}  
            hiding={matlPopupHiding}
        >   
            <ScrollGrid
                title={'자재(제품)'}
                role={'목록'}
                gridDataSource={matlData}
                gridRef={matlGridRef}
                mainKey={matlKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={matlOnRowDblClick}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_gubu", caption: "자재구분" })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupForm
            title={'차량 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '거래처번호', required: true, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.cars_numb, onValueChange: updateField('cars_numb'), label: '차량번호', required: true })}
                {FormGrid({ value: data.matl_code, onClick: () => setMatlPopupVisible(true), label: '자재코드' })}
                {FormNumb({ value: data.aver_qtys, onValueChange: updateField('aver_qtys'), label: '평균입고(예상)수량', length: [12, 3] })}
                {FormNumb({ value: data.aver_weig, onValueChange: updateField('aver_weig'), label: '평균입고(예상)중량', length: [15, 3] })}
            </GroupItem>
        </PopupForm>        
        </>
    );
}