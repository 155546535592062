import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from '../../../../../contexts/auth';
import { Button, Column } from 'devextreme-react/tree-list';
import notify from "devextreme/ui/notify";
import { createMenuTreeList } from "../../../../../app-navigation";
import { ReOrdingTree } from "../../../../script/components/tree-list/tree-body";

const onDragChange = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.menu_keys);
    let targetNode = visibleRows[e.toIndex].node;
    while (targetNode && targetNode.data) {
        if (targetNode.data.menu_keys === sourceNode.data.menu_keys) {
            e.cancel = true;
            break;
        }
        targetNode = targetNode.parent;
    }
};

function MenuInfoStatus() {
    const [menuInfo, setMenuInfo] = useState([]);
    const treeListInstance = useRef(null);
    const { getMenu } = useAuth();
    useEffect(() => {
        const userMenu = getMenu();
        const userTreeMenu = createMenuTreeList(userMenu);
        setMenuInfo(userTreeMenu);
    }, []);
    
    const onReorder = useCallback((e) => {
        const visibleRows = e.component.getVisibleRows();
        const fromData = visibleRows[e.fromIndex].data;
        const toData = visibleRows[e.toIndex].data;
        const modNum = e.fromIndex > e.toIndex? -1:1;
        if (e.itemData.menu_gubu === "root") {
            return notify("홈, MES, APS의 위치는 변경할 수 없습니다." ,'info', 2000);
        } else if (fromData.menu_parn !== toData.menu_parn) {
            return notify("상위 메뉴가 같은 경우에만 변경할 수 있습니다" ,'info', 2000);
        } else {
            // 서버 처리 로직 작성 (아래는 클라이언트 임시로직)
            reOrding(fromData, toData, modNum);
        }
    }, [menuInfo, setMenuInfo], );

    const handleReOrdingBtnClick = useCallback((e, type) => {
        const modNum = type === "up" ? 1 : -1;
        const visibleRows = e.component.getVisibleRows();
        const fromData = visibleRows[e.row.rowIndex].data;
        const toData = visibleRows[e.row.rowIndex - modNum ].data;
        if (e.row.data.menu_gubu === "root") {
            return notify("홈, MES, APS의 위치는 변경할 수 없습니다." ,'info', 2000);
        } else if (fromData.menu_parn !== toData.menu_parn) {
            return notify("상위 메뉴가 같은 경우에만 변경할 수 있습니다" ,'info', 2000);
        } else {
            // 서버 처리 로직 작성 (아래는 클라이언트 임시로직)
            reOrding(fromData, toData, modNum);
        }
    }, [menuInfo, setMenuInfo]);

    const isOrderIconVisible = (e) => {
        return e.row.data.menu_gubu !== "root";
    };

    // 클라이언트 가상 로직
    const reOrding = (from, to, modNum) => {
        const updatedMenuInfo = [...menuInfo];
        const indexToRemove = updatedMenuInfo.findIndex(item => item.menu_keys === from.menu_keys);
        if (indexToRemove !== -1) {
            updatedMenuInfo.splice(indexToRemove, 1);
        }
        const indexToInsert = updatedMenuInfo.findIndex(item => item.menu_keys === to.menu_keys);
        if (indexToInsert !== -1) {
            updatedMenuInfo.splice(indexToInsert + modNum, 0, from);
        }
        setMenuInfo(updatedMenuInfo);
    };

    return (
        <Fragment>
            <div className={'only-title'}>메뉴관리</div>
            <ReOrdingTree
                treeInstance={treeListInstance}
                dataSource={menuInfo}
                keyExpr={'menu_keys'}
                parentIdExpr={'menu_parn'}
                onDragChange={onDragChange}
                onReorder={onReorder}
            >
                <Column dataField={'menu_name'} caption="메뉴명" />
                <Column dataField={'menu_code'} caption="메뉴코드" />
                <Column dataField={'menu_high'} caption="상위메뉴코드" />
                <Column dataField={'menu_part'} caption="정렬메인코드" />
                <Column dataField={'menu_sort'} caption="정렬상세코드" />
                <Column type={'buttons'}>
                <Button 
                    icon="spinup" cssClass={'fw-cell-button'} 
                    visible={isOrderIconVisible}
                    onClick={(e) => handleReOrdingBtnClick(e, "up")}
                />
                <Button 
                    icon="spindown" cssClass={'fw-cell-button'} 
                    visible={isOrderIconVisible}
                    onClick={(e) => handleReOrdingBtnClick(e, "down")}
                />                
            </Column>
            </ReOrdingTree>
        </Fragment>
    )
};

export default MenuInfoStatus;