import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './account-panel.scss';
import MainImg from '../../../assets/main.png';

export default function AccountPanel({ title, description, children, company }) {
  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
      <div className={'fw-single-card fw-column-flex-box'}>
        <div className={'fw-wrapper fw-row-flex-box'}>
          <div className={'fw-column-flex-box'}>
              <img src={MainImg} alt='포커스윈 MES 시스템' className={'main-img'}/>
              <div className='fw-row-flex-box logo-content'>
                <img src={company.logo} alt='로고' className='logo-img'/>
                <div className='logo-title'>{company.name}</div>
              </div>
          </div>
          <div className={'fw-content'}>
            <div className={'header'}>
              <div className={'title'}>{title}</div>
              <div className={'description'}>{description}</div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </ScrollView>
)}
