// ShipChange - 출하 변경
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as UpdateAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "출하";
const mainRole = "변경";
const subsTitle = "출하상세";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                            // 페이지 메뉴 구분
const code = "B23";                                            // 페이지 메뉴 코드
const mainKey = 'ship_numb';                                   // 메인 데이터 키
const subsKey = ['ship_numb', 'ship_seri'];                    // 서브 데이터 키

const mainSearchApi = 'privat/sal/sale/change/main/search';    // 메인 조회 api
const subsSearchApi = 'privat/sal/sale/change/subs/search';    // 서브 조회 api
const subsUpdateApi = 'privat/sal/sale/change/subs/update';    // 서브 수정 api
const subsDeleteApi = 'privat/sal/sale/change/subs/delete';    // 서브 삭제 api

const tempMainData = [
    {
        ship_numb: "SHP001",
        ship_date: "2024-05-01",
        clie_code: "CL001",
        clie_name: "Client A",
        item_coun: 2,
        ship_kwon: 1000.00,
        taxs_kwon: 100.00,
        ship_memo: "First shipment"
    },
    {
        ship_numb: "SHP002",
        ship_date: "2024-05-02",
        clie_code: "CL002",
        clie_name: "Client B",
        item_coun: 3,
        ship_kwon: 2000.00,
        taxs_kwon: 200.00,
        ship_memo: "Second shipment"
    }
];

const tempSubsData = [
    // Subdata for SHP001
    {
        ship_numb: "SHP001",
        ship_seri: "001",
        orde_numb: "ORD001",
        item_code: "ITM001",
        item_name: "Item A",
        item_spec: "Spec A",
        ship_qtys: 10,
        ship_pric: 50.00,
        ship_kwon: 500.00,
        taxs_kwon: 50.00,
        ship_remk: "Remark A"
    },
    {
        ship_numb: "SHP001",
        ship_seri: "002",
        orde_numb: "ORD002",
        item_code: "ITM002",
        item_name: "Item B",
        item_spec: "Spec B",
        ship_qtys: 10,
        ship_pric: 50.00,
        ship_kwon: 500.00,
        taxs_kwon: 50.00,
        ship_remk: "Remark B"
    },
    // Subdata for SHP002
    {
        ship_numb: "SHP002",
        ship_seri: "001",
        orde_numb: "ORD003",
        item_code: "ITM003",
        item_name: "Item C",
        item_spec: "Spec C",
        ship_qtys: 20,
        ship_pric: 75.00,
        ship_kwon: 1500.00,
        taxs_kwon: 150.00,
        ship_remk: "Remark C"
    },
    {
        ship_numb: "SHP002",
        ship_seri: "002",
        orde_numb: "ORD004",
        item_code: "ITM004",
        item_name: "Item D",
        item_spec: "Spec D",
        ship_qtys: 10,
        ship_pric: 50.00,
        ship_kwon: 500.00,
        taxs_kwon: 50.00,
        ship_remk: "Remark D"
    },
    {
        ship_numb: "SHP002",
        ship_seri: "003",
        orde_numb: "ORD005",
        item_code: "ITM005",
        item_name: "Item E",
        item_spec: "Spec E",
        ship_qtys: 5,
        ship_pric: 50.00,
        ship_kwon: 250.00,
        taxs_kwon: 25.00,
        ship_remk: "Remark E"
    }
];

function ShipChange() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 수정 경고창
    const [subsUpdateAlertVisible, setSubsUpdateAlertVisible] = useState(false);
    const subsUpdateAlertHide = useCallback(() => { setSubsUpdateAlertVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { shipCode: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            subsGridDataUpdate(tempSubsData.filter(item => item.ship_numb === keyData));
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        subsGridRef.current.instance.beginCustomLoading();
        const mainSelectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const subsSelectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = subsSelectedData.map((item) => ({
            shipNumb: item.ship_numb,
            shipSeri: item.ship_seri,
            ordeNumb: item.orde_numb,
            itemCode: item.item_code,
            shipQtys: item.ship_qtys,
            shipPric: item.ship_pric,
            shipKwon: item.ship_kwon,
            taxsKwon: item.taxs_kwon,
            shipRemk: item.ship_remk,
        }));
        const postData = { shipData: mainSelectedData.ship_date, setaMaps: convertdData }
        await fwUtil.submitData(postData, subsUpdateApi, subsHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        subsGridRef.current.instance.endCustomLoading();
        mainHandleSearch();
        subsUpdateAlertHide();
    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, subsUpdateAlertHide]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        subsGridRef.current.instance.beginCustomLoading();
        const mainSelectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const subsSelectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = subsSelectedData.map((item) => ({
            shipNumb: item.ship_numb,
            shipSeri: item.ship_seri,
            ordeNumb: item.orde_numb,
            itemCode: item.item_code,
            shipQtys: item.ship_qtys,
            shipPric: item.ship_pric,
            shipKwon: item.ship_kwon,
            taxsKwon: item.taxs_kwon,
            shipRemk: item.ship_remk,
        }));
        const postData = { shipData: mainSelectedData.ship_date, setaMaps: convertdData }
        await fwUtil.submitData(postData, subsDeleteApi, subsHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        subsGridRef.current.instance.endCustomLoading();
        mainHandleSearch();
        subsDeleteAlertHide();
    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, subsDeleteAlertHide]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "고객사명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);
        if (subsSelectedRowKey) {
            switch (act) {
                case'upd':
                    setSubsUpdateAlertVisible(true);
                    break;
                case'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        } else {
            notify('출하 상세 목록을 선택 후 시도해주세요', 'warning', 2000);
        }

    }, [subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}          
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {DateCol({ dataField: "ship_date", caption: "출하일자" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DeciCol({ dataField: "item_coun", caption: "품목수" })}
                {DeciCol({ dataField: "ship_kwon", caption: "공급가액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "ship_memo", caption: "비고" })}                
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "ship_numb", caption: "출하번호", allowEditing: false })}
                {CommCol({ dataField: "ship_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호", allowEditing: false })}
                {CommCol({ dataField: "item_code", caption: "제품번호", allowEditing: false })}
                {CommCol({ dataField: "item_name", caption: "제품명", allowEditing: false })}
                {CommCol({ dataField: "item_spec", caption: "규격", allowEditing: false })}
                {DeciCol({ dataField: "ship_qtys", caption: "출하수량", check: true })}
                {DeciCol({ dataField: "ship_pric", caption: "출하단가", check: true })}
                {DeciCol({ dataField: "ship_kwon", caption: "출하금액", check: true })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액", check: true })}
                {CommCol({ dataField: "ship_remk", caption: "특기사항", check: true })}
            </SubsGrid>  
            <UpdateAlert
                grid={subsGridRef}
                visible={subsUpdateAlertVisible}
                hiding={subsUpdateAlertHide}
                confirm={onUpdateClick}
                message={'해당 출하를 수정하시겠습니까?'}
            />                     
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 출하를 삭제하시겠습니까?'}
            />                      
        </div>
    );
};


export default ShipChange;