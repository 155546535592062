import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormMont, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    sale_numb: '',
    ship_numb: '',
    clie_code: '',
    clie_name: '',
    prep_mont: fwUtil.getToMonthText(),
    prep_kwon: 0.00,
    sale_date: fwUtil.getToDayText(),
    ship_kwon: 0.00,
    taxs_yesn: true,
    sche_date: fwUtil.getToDayText(),
    prep_gubu: '',
    sale_remk: '',
    sale_memo: '',
};

export function SaleRegistInsert(props) {
    const {
        mainGrid,
        mainRefresh,
        subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const shipSelected = mainGrid.current.instance.getSelectedRowsData() || [];
        if (shipSelected.length < 0) {
            notify('납품 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            const updatedData = {
                ...defaultData,
                ship_numb: shipSelected[0].ship_numb || '',
                clie_code: shipSelected[0].clie_code || '',
                clie_name: shipSelected[0].clie_name || '',
                prep_kwon: shipSelected[0].ship_kwon || 0.00,
                ship_kwon: shipSelected[0].ship_kwon || 0.00,
            }
            dataUpdate(updatedData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		


    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            saleNumb: data.sale_numb || '',
            shipNumb: data.ship_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            prepMont: data.prep_mont || fwUtil.getToMonthText(),
            prepKwon: data.prep_kwon || 0.00,
            saleDate: data.sale_date || fwUtil.getToDayText(),
            shipKwon: data.ship_kwon || 0.00,
            taxsYesn: data.taxs_yesn || false,
            scheDate: data.sche_date || fwUtil.getToDayText(),
            prepGubu: data.prep_gubu || '',
            saleRemk: data.sale_remk || '',
            saleMemo: data.sale_memo || '',
        };

        await fwUtil.submitData(postData, insertApi, mainRefresh);
        subsRefresh();
        setLoading(false);
        hiding();
    }, [data, insertApi, mainRefresh, subsRefresh, hiding]);

    return (
            <PopupForm
                title={'매출 등록'}       
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 300 : 600}  
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                    {FormText({ value: data.sale_numb, onValueChange: updateField('sale_numb'), label: '매출번호[자동생성]', disabled: true })}
                    {FormText({ value: data.ship_numb, onValueChange: updateField('ship_numb'), label: '납품번호', disabled: true })}
                    {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '고객코드', disabled: true })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명' })}
                    {FormMont({ value: data.prep_mont, onValueChange: updateField('prep_mont'), label: '기성월' })}
                    {FormNumb({ value: data.prep_kwon, onValueChange: updateField('prep_kwon'), label: '기성금액', length: [15, 2] })}
                    {FormDate({ value: data.sale_date, onValueChange: updateField('sale_date'), label: '확정일자' })}
                    {FormNumb({ value: data.ship_kwon, onValueChange: updateField('ship_kwon'), label: '납품금액', length: [15, 2] })}
                    {FormDate({ value: data.sche_date, onValueChange: updateField('sche_date'), label: '수금예정일' })}
                    {FormBool({ value: data.taxs_yesn, onValueChange: updateField('taxs_yesn'), label: '부가세적용' })}
                </GroupItem>
                {FormText({ value: data.prep_gubu, onValueChange: updateField('prep_gubu'), label: '기성구분' })}
                {FormMemo({ value: data.sale_remk, onValueChange: updateField('sale_remk'), label: '특기사항' })}
                {FormMemo({ value: data.sale_memo, onValueChange: updateField('sale_memo'), label: '비고' })}                
            </PopupForm>            
    );
}