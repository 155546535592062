import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormNumb, FormGrid, FormDate, FormText, FormMemo } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { getOrderClie as getClie, getOrderItem as getItem } from '../../../../../api/set';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { Item } from 'devextreme-react/cjs/data-grid';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    orde_numb: '',
    clie_code: '',
    clie_name: '',
    clie_orde: '',
    orde_line: '',
    item_code: '',
    item_name: '',
    cite_code: '',
    cite_name: '',
    cite_spec: '',
    orde_date: fwUtil.getToDayText(),
    deli_date: null,
    orde_qtys: 0.00,
    orde_unit: '',
    orde_pric: 0.00,
    orde_kwon: 0.00,
    deli_plac: '',
    pays_cond: '',
    orde_memo: '',
}

export function OrderRegistInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const clieGridRef = useRef(null);
    const itemGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const clieKeyExpr = 'clie_code';
    const itemKeyExpr = 'item_code';
    // 그리드 조회 조건
    const [clieName, setClieName] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const clieNameChange = useCallback((e) => setClieName(e), []);
    const itemCodeChange = useCallback((e) => setItemCode(e), []);
    const itemNameChange = useCallback((e) => setItemName(e), []);
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    // 그리드 추가 툴바 아이템
    const clieToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "거래처명", value: clieName, valueChange: clieNameChange, search: clieHandleSearch })}
                {SearchButton({ search: clieHandleSearch })}
            </Item>
        );
    };
    
    const itemToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품번호", value: itemCode, valueChange: itemCodeChange, search: itemHandleSearch })}
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: itemHandleSearch })}
                {SearchButton({ search: itemHandleSearch })}
            </Item>
        );
    };
    
    // 열릴 때
    const onShowing = useCallback(async() => {
        dataUpdate(defaultData)
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true)
        const postData = {
            ordeNumb: data.orde_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            clieOrde: data.clie_orde || '',
            ordeLine: data.orde_line || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            citeCode: data.cite_code || '',
            citeName: data.cite_name || '',
            citeSpec: data.cite_spec || '',
            ordeDate: data.orde_date ? fwUtil.formatDate(data.orde_date) : null,
            deliDate: data.deli_date ? fwUtil.formatDate(data.deli_date) : null,
            ordeQtys: data.orde_qtys || 0.00,
            ordeUnit: data.orde_unit || '',
            ordePric: data.orde_pric || 0.00,
            ordeKwon: data.orde_kwon || 0.00,
            deliPlac: data.deli_plac || '',
            paysCond: data.pays_cond || '',
            ordeMemo: data.orde_memo || '',
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    // 팝업 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieName: clieName };
        const res = await getClie(sendData);
        clieGridUpdate(res);  
    }, [clieName, clieGridUpdate]);

    const itemHandleSearch = useCallback(async() => {
        const sendData = { itemCode: itemCode, itemName: itemName };
        const res = await getItem(sendData);
        itemGridUpdate(res);  
    }, [itemCode, itemName, itemGridUpdate]);

    // 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);
    
    // 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, ['clie_code']: e.data.clie_code, ['clie_name']: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, ['item_code']: e.data.item_code, ['item_name']: e.data.item_name });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);    

    const clieGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'고객'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false, before: clieToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "고객구분" })}
                {CommCol({ dataField: "clie_name", caption: "고객명[한글]" })}
                {CommCol({ dataField: "clie_enam", caption: "고객명[영문]" })}
                {CommCol({ dataField: "clie_memo", caption: "비고" })}
            </ScrollGrid>            
        )
    }, [clieData, clieGridRef, clieKeyExpr, clieOnRowDblClick, clieToolbarBeforeItems]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false, before: itemToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {CommCol({ dataField: "item_unit", caption: "제품단위" })}
                {CommCol({ dataField: "qtys_weig", caption: "제품중량" })}
                {CommCol({ dataField: "item_pric", caption: "제품단가" })}
                {CommCol({ dataField: "item_gubu", caption: "제품구분" })}
            </ScrollGrid>            
        )
    }, [itemData, itemGridRef, itemKeyExpr, itemOnRowDblClick, itemToolbarBeforeItems]);

    return (
        <>
        <PopupGrid
            title={'고객 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            showing={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            {clieGrid}
        </PopupGrid>      
        <PopupGrid
            title={'제품 선택'}   
            type={'선택'}
            visible={itemPopupVisible}  
            showing={itemPopupOnShowing}  
            hiding={itemPopupHiding}
        >   
            {itemGrid}
        </PopupGrid>              
        <PopupForm
            title={'수주 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormText({ value: data.orde_numb, onValueChange: updateField('orde_numb'), label: '수주번호[자동입력]', disabled: true })}
                <GroupItem colCount={3}>
                    <GroupItem colSpan={2}>
                        {FormText({ value: data.clie_orde, onValueChange: updateField('clie_orde'), label: '오더번호', required: true, maxLength: 10 })}
                    </GroupItem>
                    {FormText({ value: data.orde_line, onValueChange: updateField('orde_line'), label: '라인번호', required: true, maxLength: 2 })}
                </GroupItem>
                {FormGrid({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '고객번호', required: true, onClick: () => setCliePopupVisible(true) })}
                {FormText({ value: data.clie_name, onValueChange: updateField('orde_numb'), label: '고객명', disabled: true })}
                {FormGrid({ value: data.item_code, onValueChange: updateField('item_code'), label: '품목번호', required: true, onClick: () => setItemPopupVisible(true) })}
                {FormText({ value: data.item_name, onValueChange: updateField('orde_numb'), label: '품목명', disabled: true })}
                {FormText({ value: data.cite_code, onValueChange: updateField('cite_code'), label: '고객품목번호' })}
                {FormText({ value: data.cite_name, onValueChange: updateField('cite_name'), label: '고객품명' })}
                <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                    {FormText({ value: data.cite_spec, onValueChange: updateField('cite_spec'), label: '고객규격' })}
                </GroupItem>
                {FormDate({ value: data.orde_date, onValueChange: updateField('orde_date'), label: '수주일자', required: true })}
                {FormDate({ value: data.deli_date, onValueChange: updateField('deli_date'), label: '납기일자', required: true })}
                {FormNumb({ value: data.orde_qtys, onValueChange: updateField('orde_qtys'), label: '오더수량', length: [10, 2] })}
                {FormText({ value: data.orde_unit, onValueChange: updateField('orde_unit'), label: '단위' })}
                {FormNumb({ value: data.orde_pric, onValueChange: updateField('orde_pric'), label: '수주단가', length: [15, 2] })}
                {FormNumb({ value: data.orde_kwon, onValueChange: updateField('orde_kwon'), label: '수주금액', length: [15, 2] })}
                {FormText({ value: data.deli_plac, onValueChange: updateField('deli_plac'), label: '납품장소' })}
                {FormText({ value: data.pays_cond, onValueChange: updateField('pays_cond'), label: '결재조건' })}                
            </GroupItem>
            {FormMemo({ value: data.orde_memo, onValueChange: updateField('orde_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}