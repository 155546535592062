import { Routes, Route, Navigate } from 'react-router-dom';
import { AccountPanel, LoginForm, CreateAccountForm } from './focuswin/script/components';
import { company } from './focuswin/script/constant-list';

export default function UnauthenticatedContent() {
  return (
    <Routes>
      <Route
        path='/login' 
        element={
          <AccountPanel title="로그인" company={company}>
            <LoginForm />
          </AccountPanel>
        }
      />
      <Route
        path='/create-account'
        element={
          <AccountPanel title="계정 생성" company={company}>
            <CreateAccountForm />
          </AccountPanel>
        }
      />
      <Route path='*' element={<Navigate to={'/login'} />}></Route>
    </Routes>
  );
}
