// MaintRegist - 정비이력 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { MaintRegistInsert } from './Regist-insert';
import { MaintRegistUpdate } from './Regist-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "정비 이력";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                   // 페이지 메뉴 구분
const code = "F21";                                   // 페이지 메뉴 코드
const mainKey = ['main_numb', 'main_seri'];           // 데이터 키
const deleKey = (data) => {                           // 삭제 데이터 키
    return { 
        mainNumb: data.main_numb,
        mainSeri: data.main_seri,
    }
};                                                         

const searchApi = 'privat/equ/main/register/search';   // 조회 api
const targetApi = 'privat/equ/main/register/target';   // 타겟 api
const insertApi = 'privat/equ/main/register/insert';   // 등록 api
const updateApi = 'privat/equ/main/register/update';   // 수정 api
const deleteApi = 'privat/equ/main/register/delete';   // 삭제 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "main_numb": 1,
        "equi_numb": "EQ001",
        "main_seri": "S001",
        "equi_name": "Pump",
        "brok_date": "2023-05-10",
        "brok_desc": "Motor failure",
        "clie_code": "C001",
        "clie_name": "Client A",
        "main_date": "2023-05-15",
        "main_desc": "Motor replacement",
        "main_kwon": 500.00,
        "main_days": 6,
        "next_date": "2024-05-15",
        "self_main": false,
        "main_memo": "Regular maintenance scheduled"
    },
    {
        "main_numb": 2,
        "equi_numb": "EQ002",
        "main_seri": "S002",
        "equi_name": "Compressor",
        "brok_date": "2023-06-20",
        "brok_desc": "Pressure leak",
        "clie_code": "C002",
        "clie_name": "Client B",
        "main_date": "2023-06-25",
        "main_desc": "Seal replacement",
        "main_kwon": 800.00,
        "main_days": 6,
        "next_date": "2024-06-25",
        "self_main": false,
        "main_memo": "Follow up required"
    },
    {
        "main_numb": 3,
        "equi_numb": "EQ003",
        "main_seri": "S003",
        "equi_name": "Generator",
        "brok_date": "2023-07-30",
        "brok_desc": "Fuel filter clogged",
        "clie_code": "C003",
        "clie_name": "Client C",
        "main_date": "2023-08-02",
        "main_desc": "Filter replacement",
        "main_kwon": 300.00,
        "main_days": 4,
        "next_date": "2024-08-02",
        "self_main": true,
        "main_memo": "No further action needed"
    },
    {
        "main_numb": 4,
        "equi_numb": "EQ004",
        "main_seri": "S004",
        "equi_name": "Valve",
        "brok_date": "2023-08-15",
        "brok_desc": "Leakage",
        "clie_code": "C004",
        "clie_name": "Client D",
        "main_date": "2023-08-20",
        "main_desc": "Replacement of gasket",
        "main_kwon": 200.00,
        "main_days": 5,
        "next_date": "2024-08-20",
        "self_main": true,
        "main_memo": "Scheduled for inspection"
    }
]

function MaintRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [equlName, setEqulName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equlNameChange = useCallback((e) => { setEqulName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equlName: equlName };
        //await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, equlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "설비명", value: equlName, valueChange: equlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}    
                onRowClick={onRowClick}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "main_numb", caption: "보수번호" })}
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "main_seri", caption: "순번" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {DateCol({ dataField: "brok_date", caption: "고장일자" })}
                {CommCol({ dataField: "brok_desc", caption: "고장내역" })}
                {CommCol({ dataField: "clie_code", caption: "정비업체코드" })}
                {CommCol({ dataField: "clie_name", caption: "정비업체명" })}
                {DateCol({ dataField: "main_date", caption: "보수일자" })}
                {CommCol({ dataField: "main_desc", caption: "보수내역" })}
                {DeciCol({ dataField: "main_kwon", caption: "수리/부품비" })}
                {NumbCol({ dataField: "main_days", caption: "수리기간" })}
                {DateCol({ dataField: "next_date", caption: "차기점검일" })}
                {BoolCol({ dataField: "self_main", caption: "자체정비" })}
                {CommCol({ dataField: "main_memo", caption: "비고" })}
            </PagingGrid>
            <MaintRegistInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <MaintRegistUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 정비내역을 삭제하시겠습니까?'}
            />             
        </div>
    );
};


export default MaintRegist;