import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../../../contexts/auth';

export default function LoginForm() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ userIdno: '', passWord: '' });

  // 로그인
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { userIdno, passWord } = formData.current;
    setLoading(true);

    const result = await signIn(userIdno, passWord);
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
  }, [signIn]);

  // 계정 생성 이동
  const onCreateAccountClick = useCallback(() => {
    navigate('/create-account');
  }, [navigate]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'userIdno'}
          editorType={'dxTextBox'}
          editorOptions={idEditorOptions}
        >
          <RequiredRule message="아이디를 입력해주세요" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'passWord'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="비밀번호를 입력해주세요" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : '로그인'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <ButtonItem>
          <ButtonOptions
            text={'계정 생성'}
            width={'100%'}
            onClick={onCreateAccountClick}
          />
        </ButtonItem>
      </Form>
    </form>
  );
}

const idEditorOptions = { stylingMode: 'filled', placeholder: '아이디', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: '비밀번호', mode: 'password' };
const rememberMeEditorOptions = { text: '아이디 저장', elementAttr: { class: 'form-text' } };
