// PrusRegist - 요청 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as InsertAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PursRegistSelect } from './Regist-select';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "요청";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                          // 페이지 메뉴 구분
const code = "C12";                                          // 페이지 메뉴 코드
const mainKey = ['matl_code', 'matl_seri'];                  // 데이터 키          
const deleKey = (data) => {                                  // 삭제 데이터 키
    return {
        setaMaps: [{ matlCode: data.matl_code, matlSeri: data.matl_seri }]
    }
};                  

const searchApi = 'privat/pro/purs/register/search';         // 조회 api
const insertApi = 'privat/pro/purs/register/insert';         // 등록 api
const deleteApi = 'privat/pro/purs/register/delete';         // 삭제 api
const selectApi = 'privat/pro/purs/select/search';           // 요청 품목 조회 api
const selinsApi = 'privat/pro/purs/select/insert';           // 요청 품목 등록 api

const exportFormats = ['xlsx', 'pdf'];

function PrusRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [matlName, setMatlName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    // 등록 알림창
    const [insertAlertVisible, setInsertAlertVisible] = useState(false);
    const insertAlertHide = useCallback(() => { setInsertAlertVisible(false); }, []);
    // 삭제 알림창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);    
    // 요청 품목 팝업창
    const [selectPopupVisible, setSelectPopupVisible] = useState(false);
    const selectPopupHide = useCallback(() => { setSelectPopupVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, matlName: matlName };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [dateValue, duraYesn, matlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 등록 경고 확인 클릭
    const onInsertClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            matlCode: item.matl_code || '',
            matlSeri: item.matl_seri || '',
            itemGubu: item.item_gubu || '',
            stocQtys: item.stoc_qtys || 0.000,
            requQtys: item.requ_qtys || 0.000,
            prosUnit: item.pros_unit || '',
            prosPric: item.pros_pric || 0.00,
            recePlac: item.rece_plac || '',
            deliDate: item.deli_date || null,
            pursMemo: item.purs_memo || '',
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.submitData(postData, insertApi, handleSearch);
        gridRef.current.instance.endCustomLoading();
        insertAlertHide();
    }, [gridRef, handleSearch, insertAlertHide]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };
    const toolbarAfterItems = () => {
        return (
            <Item location='after' locateInMenu='auto'>
                {ToolbarButton({ icon: 'add', text: '자재추가', hidden: false, disabled: authList.auth_inse === 0, onClick: () => setSelectPopupVisible(true) })}
                {ToolbarButton({ icon: 'upload', text: '등록', hidden: false, disabled: authList.auth_inse === 0, onClick: () => handleActionClick('ins') })}
            </Item>
        )
    };

    // 등록/삭제 버튼 클릭
    const handleActionClick = useCallback(async (type) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);
        if (selectedRowKey) {
            if (type === 'ins') {
                setInsertAlertVisible(true);
                return;
            }
            if (type === 'del') {
                setDeleteAlertVisible(true);
                return;
            }
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems, after: toolbarAfterItems }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
                {CommCol({ dataField: "matl_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "matl_name", caption: "자재명", allowEditing: false })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고량", allowEditing: false })}
                {DeciCol({ dataField: "requ_qtys", caption: "요청수량", check: true, maxLength: 15 })}
                {CommCol({ dataField: "pros_unit", caption: "발주단위", check: true })}
                {CommCol({ dataField: "rece_plac", caption: "입고처", check: true })}
                {CommCol({ dataField: "purs_memo", caption: "비고", check: true })}
            </PagingGrid>
            <PursRegistSelect
                refresh={handleSearch}
                selectApi={selectApi}
                insertApi={selinsApi}
                visible={selectPopupVisible}
                hiding={selectPopupHide}
            />  
            <InsertAlert 
                grid={gridRef}
                visible={insertAlertVisible}
                hiding={insertAlertHide}
                confirm={onInsertClick}
                message={'해당 요청를 등록하시겠습니까?'}
            />  
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 요청을 삭제하시겠습니까?'}
            />                       
        </div>
    );
};


export default PrusRegist;