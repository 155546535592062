// ProductRegist -작업지시 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, NumbCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { ProductRegistInsert } from './Regist-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "작업지시";
const mainRole = "등록";
const subsTitle = "작업진행";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                                            // 페이지 메뉴 구분
const code = "D32";                                                            // 페이지 메뉴 코드
const mainKey = 'work_numb';                                                   // 메인 데이터 키
const subsKey = ['work_numb', 'prod_seri'];                                    // 서브 데이터 키

const mainSearchApi = 'privat/mfg/product/regist/header/search';               // 메인 조회 api
const mainInsertApi = 'privat/mfg/product/regist/header/insert';               // 메인 등록 api
const subsSearchApi = 'privat/mfg/product/regist/detail/search';               // 서브 조회 api

const tempMainData = [
    {
      work_numb: "W123456",
      plan_numb: "P123456",
      orde_numb: "O123456",
      proj_code: "PRJ001",
      proj_name: "Project Alpha",
      item_code: "ITM001",
      item_name: "Item A",
      file_qtys: 5,
      work_qtys: 100,
      work_date: "2023-05-01",
      star_date: "2023-05-02",
      clos_date: "2023-05-10",
      work_clos: true,
      indx_keys: "WO123456"
    },
    {
      work_numb: "W123457",
      plan_numb: "P123457",
      orde_numb: "O123457",
      proj_code: "PRJ002",
      proj_name: "Project Beta",
      item_code: "ITM002",
      item_name: "Item B",
      file_qtys: 3,
      work_qtys: 200,
      work_date: "2023-06-01",
      star_date: "2023-06-02",
      clos_date: "2023-06-12",
      work_clos: false,
      indx_keys: "WO123457"
    },
    {
      work_numb: "W123458",
      plan_numb: "P123458",
      orde_numb: "O123458",
      proj_code: "PRJ003",
      proj_name: "Project Gamma",
      item_code: "ITM003",
      item_name: "Item C",
      file_qtys: 7,
      work_qtys: 150,
      work_date: "2023-07-01",
      star_date: "2023-07-03",
      clos_date: "2023-07-15",
      work_clos: true,
      indx_keys: "WO123458"
    }
];

const tempSubsData = [
    {
        "work_numb": "W123456",
        "prod_seri": "001",
        "comp_date": "2024-05-27",
        "rout_numb": "R001",
        "rout_name": "Assembly",
        "star_time": "08:00",
        "clos_time": "12:00",
        "work_name": "Worker A",
        "prod_memo": "Initial assembly completed"
    },
    {
        "work_numb": "W123456",
        "prod_seri": "002",
        "comp_date": "2024-05-28",
        "rout_numb": "R002",
        "rout_name": "Painting",
        "star_time": "13:00",
        "clos_time": "17:00",
        "work_name": "Worker B",
        "prod_memo": "Painting completed"
    },
    {
        "work_numb": "W123457",
        "prod_seri": "001",
        "comp_date": "2024-06-02",
        "rout_numb": "R003",
        "rout_name": "Inspection",
        "star_time": "09:00",
        "clos_time": "11:00",
        "work_name": "Worker C",
        "prod_memo": "Inspection completed"
    }
]

function ProductRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [workNumb, setWorkNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, workNumb: workNumb };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, workNumb, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { workNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            const convertedData = tempSubsData.filter(item => item.work_numb === keyData);
            subsGridDataUpdate(convertedData);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);
 
    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "품목번호", value: workNumb, valueChange: workNumbChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록 버튼 클릭
    const handleInsertClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);

        if (selectedRowKey) {
            setInsertPopupVisible(true);
            return;
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [mainGridRef]);    

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}             
                ins={{ onClick: handleInsertClick, disabled: authList.auth_inse === 0 }}
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "proj_code", caption: "프로젝트코드" })}
                {CommCol({ dataField: "proj_name", caption: "프로젝트명" })}
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "item_name", caption: "품목명" })}
                {NumbCol({ dataField: "file_qtys", caption: "첨부파일수" })}
                {NumbCol({ dataField: "work_qtys", caption: "지시수량" })}
                {DateCol({ dataField: "work_date", caption: "작업지시일" })}
                {DateCol({ dataField: "star_date", caption: "생산시작일" })}
                {DateCol({ dataField: "clos_date", caption: "생산완료일" })}
                {BoolCol({ dataField: "work_clos", caption: "생산완료여부" })}         
            </MainGrid>
            <ProductRegistInsert
                mainGrid={mainGridRef}
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />             
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "prod_seri", caption: "일보순번" })}
                {DateCol({ dataField: "comp_date", caption: "작업일자" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {TimeCol({ dataField: "star_time", caption: "작업시작" })}
                {TimeCol({ dataField: "clos_time", caption: "작업종료" })}
                {CommCol({ dataField: "work_name", caption: "작업자" })}
                {CommCol({ dataField: "prod_memo", caption: "비고" })}
            </SubsGrid>                            
        </div>
    );
};


export default ProductRegist;