// StockSemiStatus - 반제품재고 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item, Summary } from 'devextreme-react/data-grid';
import { TotalCntItem, TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "반제품재고";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                        // 페이지 메뉴 구분
const code = "C44";                                        // 페이지 메뉴 코드
const mainKey = 'matl_code';                               // 데이터 키

const searchApi = 'privat/mat/stock/status/semi/search';   // 조회 api

const seleItems = ["재고량 > 0", "재고량 = 0", "전체"];

const tempData = [
    {
        "matl_code": "P001",
        "matl_name": "반제품 A",
        "matl_desc": "규격 A",
        "matl_quli": "재질 A",
        "stoc_qtys": 100.0,
        "stoc_weig": 200.0,
        "stoc_unit": "kg",
        "stoc_qpri": 5000.0,
        "stoc_kwon": 500000.0,
        "stoc_loca": "창고 A",
        "stoc_memo": "비고 A"
    },
    {
        "matl_code": "P002",
        "matl_name": "반제품 B",
        "matl_desc": "규격 B",
        "matl_quli": "재질 B",
        "stoc_qtys": 150.0,
        "stoc_weig": 300.0,
        "stoc_unit": "kg",
        "stoc_qpri": 6000.0,
        "stoc_kwon": 900000.0,
        "stoc_loca": "창고 B",
        "stoc_memo": "비고 B"
    },
    {
        "matl_code": "P003",
        "matl_name": "반제품 C",
        "matl_desc": "규격 C",
        "matl_quli": "재질 C",
        "stoc_qtys": 200.0,
        "stoc_weig": 400.0,
        "stoc_unit": "kg",
        "stoc_qpri": 7000.0,
        "stoc_kwon": 1400000.0,
        "stoc_loca": "창고 C",
        "stoc_memo": "비고 C"
    }
];

const exportFormats = ['xlsx', 'pdf'];

function StockItemStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [seleGubu, setSeleGubu] = useState("");    
    const [matlCode, setMatlCode] = useState("");    
    const [matlName, setMatlName] = useState("");    
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { seleGubu: seleGubu, matlCode: matlCode, matlName: matlName };
        //await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [seleGubu, matlCode, matlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);


    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "재고량", value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: seleItems })}
                {ToolbarTextBox({ caption: "자재번호", value: matlCode, valueChange: matlCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}   
                onRowClick={onRowClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "matl_code", caption: "반제품코드" })}
                {CommCol({ dataField: "matl_name", caption: "반제품명" })}
                {CommCol({ dataField: "matl_desc", caption: "규격" })}
                {CommCol({ dataField: "matl_quli", caption: "재질" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
                {DeciCol({ dataField: "stoc_weig", caption: "재고중량" })}
                {CommCol({ dataField: "stoc_unit", caption: "재고단위" })}
                {DeciCol({ dataField: "stoc_qpri", caption: "재고단가" })}
                {DeciCol({ dataField: "stoc_kwon", caption: "재고금액" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                {CommCol({ dataField: "stoc_memo", caption: "비고" })}
                <Summary >
                    {TotalCntItem({ dataField: 'matl_code', caption: "재고 품목수" })}
                    {TotalSumItem({ dataField: 'stoc_kwon', caption: "재고 총금액" })}
                </Summary>                
            </PagingGrid> 
        </div>
    );
};


export default StockItemStatus;