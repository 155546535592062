// ClientStatus - 거래처 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { ClientStatusInsert } from './Status-insert';
import { ClientStatusUpdate } from './Status-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "거래처";
const role = "관리";
const gridSize = 10;

const gubu = "mes";                           // 페이지 메뉴 구분
const code = "R12";                           // 페이지 메뉴 코드
const mainKey = 'clie_code';                  // 데이터 키
const deleKey = (data) => {                   // 삭제 데이터 키
    return { clieCode: data }
};                                                         

const searchApi = 'privat/com/clie/search';   // 조회 api
const targetApi = 'privat/com/clie/target';   // 타겟 api
const insertApi = 'privat/com/clie/insert';   // 등록 api
const updateApi = 'privat/com/clie/update';   // 수정 api
const deleteApi = 'privat/com/clie/delete';   // 삭제 api

const exportFormats = ['xlsx', 'pdf'];
const enabStat = ['사용중', '미사용'];

function ClientStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [clieName, setClieName] = useState("");    
    const [enabYesn, setEnabYesn] = useState("사용중");    
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const enabYesnChange = useCallback((e) => { setEnabYesn(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { clieName: clieName, enabYesn: enabYesn === "사용중" ? true : false };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [clieName, enabYesn, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: " 거래처명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {ToolbarSelcBox({ caption: " 사용여부", value: enabYesn, valueChange: enabYesnChange, search: handleSearch, items: enabStat })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명(한글)" })}
                {CommCol({ dataField: "addr_knam", caption: "주소(한글)" })}  
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "업체구분" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}
                {CommCol({ dataField: "upta_name", caption: "업태" })}
                {CommCol({ dataField: "jong_moks", caption: "종목" })}                              
                {CommCol({ dataField: "work_code", caption: "생산지시코드" })}
                {CommCol({ dataField: "damd_name", caption: "담당자명" })}
                {CommCol({ dataField: "dept_name", caption: "부서명" })}
                {CommCol({ dataField: "posi_name", caption: "직위명" })}
                {CommCol({ dataField: "mail_idno", caption: "이메일ID" })}
                {CommCol({ dataField: "damd_teln", caption: "담당자전화" })}
                {CommCol({ dataField: "damd_faxn", caption: "담당자팩스" })}
                {CommCol({ dataField: "damd_cell", caption: "담당자H.P" })}
                {CommCol({ dataField: "tele_numb", caption: "전화번호" })}
                {CommCol({ dataField: "faxs_numb", caption: "팩스번호" })}
                {CommCol({ dataField: "zips_code", caption: "우편번호" })}
                {CommCol({ dataField: "clie_enam", caption: "거래처명(영문)" })}
                {CommCol({ dataField: "addr_enam", caption: "주소(영문)" })}
                {CommCol({ dataField: "home_page", caption: "홈페이지 URL" })}
                {CommCol({ dataField: "bank_name", caption: "거래은행" })}
                {CommCol({ dataField: "acco_numb", caption: "계좌번호" })}
                {CommCol({ dataField: "hold_name", caption: "예금주" })}
                {CommCol({ dataField: "clie_memo", caption: "메모" })}
                {CommCol({ dataField: "clie_sert", caption: "인증서보유현황" })}
                {DateCol({ dataField: "eval_date", caption: "업체평가일" })}
                {CommCol({ dataField: "eval_scor", caption: "평가점수" })}
                {CommCol({ dataField: "eval_term", caption: "평가주기" })}
                {CommCol({ dataField: "eval_memo", caption: "평가비고" })}
                {CommCol({ dataField: "taxb_name", caption: "세금계산서 담당자" })}
                {CommCol({ dataField: "taxb_teln", caption: "세금계산서 전화" })}
                {CommCol({ dataField: "taxb_mail", caption: "세금계산서 이메일" })}
            </PagingGrid>
            <ClientStatusInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <ClientStatusUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 거래처를 삭제하시겠습니까?'}
            />             
        </div>
    );
};


export default ClientStatus;