import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { getForwMatl as getMatl } from '../../../../../api/set';
import { FormBool, FormGrid, FormNumb, FormText, FormSelc, FormDate, FormMemo } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { Item } from 'devextreme-react/cjs/data-grid';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    none_rout: false, 
    work_numb: '', 
    rout_numb: '', 
    rout_name: '', 
    proj_name: '',
    item_name: '',
    forw_dept: '관리부', 
    matl_code: '', 
    matl_name: '', 
    item_gubu: '', 
    forw_date: '', 
    stoc_qtys: 0.00, 
    forw_qtys: 0.00, 
    forw_memo: '', 
};

const tempData = [
    {
        "chck_type": 1,
        "matl_code": "M12345",
        "item_gubu": "02",
        "gubu_name": "반제품",
        "matl_name": "Material A",
        "stoc_qtys": 100.50,
        "stoc_qpri": 5.75
    },
    {
        "chck_type": 1,
        "matl_code": "M67890",
        "item_gubu": "03",
        "gubu_name": "원자재",
        "matl_name": "Material B",
        "stoc_qtys": 250.00,
        "stoc_qpri": 3.50
    },
    {
        "chck_type": 1,
        "matl_code": "M54321",
        "item_gubu": "02",
        "gubu_name": "반제품",
        "matl_name": "Material C",
        "stoc_qtys": 75.75,
        "stoc_qpri": 4.25
    }
];

export function ForwardingRegistInsert(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 자재(제품) 그리드
    const matlGridRef = useRef(null);
    const [matlData, setMatlData] = useState([]);
    const matlGridUpdate = useCallback((e) => setMatlData(e), []);
    const matlKeyExpr = ['matl_code', 'gubu_name'];
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    // 그리드 팝업
    const [matlPopupVisible, setMatlPopupVisible] = useState(false);
    const matlPopupHiding = useCallback(() => setMatlPopupVisible(false), []);

    // 자재(제품) 조회
    const matlHandleSearch = useCallback(async() => {
        const sendData = { matlCode: matlCode, matlName: matlName };
        const res = await getMatl(sendData);
        //matlGridUpdate(res);
        matlGridUpdate(tempData);
    }, [matlCode, matlName, matlGridUpdate]);

    // 그리드 추가 툴바 아이템
    const toolbarBeforeItems = useCallback(() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "자재번호", value: matlCode, valueChange: matlCodeChange, search: matlHandleSearch })}
                {ToolbarTextBox({ caption: "자재품명", value: matlName, valueChange: matlNameChange, search: matlHandleSearch })}
                {SearchButton({ search: matlHandleSearch })}
            </Item>
        )
    },[matlCode, matlName, matlHandleSearch, matlCodeChange, matlNameChange]);    

    // 자재(제품) 팝업 열릴 때
    const matlPopupOnShowing = useCallback(() => {
        matlHandleSearch();
    }, [matlHandleSearch]);

    // 자재(제품) 팝업 더블 클릭
    const matlOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 
            matl_code: e.data.matl_code, 
            matl_name: e.data.matl_name, 
            item_gubu: e.data.gubu_name, 
            stoc_qtys: e.data.stoc_qtys 
        });
        matlPopupHiding();
    }, [data, dataUpdate, matlPopupHiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedRowData = mainGrid?.current?.instance?.getSelectedRowsData() || [];
        if (selectedRowData.length > 0) {
            dataUpdate({
                ...defaultData,
                work_numb: selectedRowData[0].work_numb || '', 
                rout_numb: selectedRowData[0].rout_numb || '', 
                rout_name: selectedRowData[0].rout_name || '', 
                proj_name: selectedRowData[0].proj_name || '',
                item_name: selectedRowData[0].item_name || '',
            });
        } else {
            notify('선택 불출 정보를 가져오는데 실패하였습니다', 'error', 2000);
            hiding();
        }
    }, [mainGrid, dataUpdate, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            noneRout: data.none_rout ? 1 : 0, 
            workNumb: data.work_numb || '', 
            routNumb: data.rout_numb || '', 
            routName: data.rout_name || '', 
            forwDept: data.forw_dept || '', 
            matlCode: data.matl_code || '', 
            itemGubu: data.item_gubu || '', 
            forwDate: data.forw_date || '', 
            forwQtys: data.forw_qtys || 0.00, 
            forwMemo: data.forw_memo || '', 
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupGrid
            title={'불출 자재(제품) 선택'}   
            type={'선택'}
            visible={matlPopupVisible}  
            showing={matlPopupOnShowing}  
            hiding={matlPopupHiding}
        >   
            <ScrollGrid
                title={'자재(제품)'}
                role={'목록'}
                gridDataSource={matlData}
                gridRef={matlGridRef}
                mainKey={matlKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={matlOnRowDblClick}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "gubu_name", caption: "구분" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "stoc_qtys", caption: "재고수량" })}
                {CommCol({ dataField: "stoc_qpri", caption: "재고단가" })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'불출 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : isMedium ? 600 : 1200 }  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 4}>
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2 } colSpan={isXSmall || isSmall ? 1 : 2 } cssClass='remk-form forw-loca'>
                    {FormText({ value:data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', disabled: true })}                        
                    {FormBool({ value:data.none_rout, onValueChange: updateField('none_rout'), label: '일반자재(관리소모품, 장비 등)' })}
                    {FormText({ value:data.proj_name, onValueChange: updateField('proj_name'), label: '프로젝트명', disabled: true })}
                    {FormSelc({ value:data.forw_dept, onValueChange: updateField('forw_dept'), label: '불출부서', items: ['관리부', '생산부', '품질부'] })}
                    {FormText({ value:data.item_name, onValueChange: updateField('item_name'), label: '제품명', disabled: true })}
                    <GroupItem colCount={3}>
                        {FormText({ value:data.rout_numb, onValueChange: updateField('rout_numb'), label: '공정번호', maxLength: 3 })}
                        <GroupItem colSpan={2}>
                            {FormText({ value:data.rout_name, onValueChange: updateField('rout_name'), label: '공정명' })}
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2 } colSpan={isXSmall || isSmall ? 1 : 2 } cssClass='remk-form forw-matl'>
                    {FormGrid({ value:data.matl_code, onClick: () => setMatlPopupVisible(true), label: '자재번호', required: true })}
                    {FormDate({ value:data.forw_date, onValueChange: updateField('forw_date'), label: '불출일자', required: true })}
                    {FormText({ value:data.matl_code, onValueChange: updateField('matl_name'), label: '자재명', disabled: true })}
                    {FormText({ value:data.item_gubu, onValueChange: updateField('item_gubu'), label: '자재구분', disabled: true })}
                    {FormNumb({ value:data.stoc_qtys, onValueChange: updateField('stoc_qtys'), label: '재고수량', disabled: true })}
                    {FormNumb({ value:data.forw_qtys, onValueChange: updateField('forw_qtys'), label: '불출수량', required: true, length: [12, 2] })}
                    <GroupItem colSpan={isXSmall || isSmall ? 1 : 2 }>
                        {FormMemo({ value:data.forw_memo, onValueChange: updateField('forw_memo'), label: '비고' })}
                    </GroupItem>
                </GroupItem>
            </GroupItem>
        </PopupForm>    
        </>
    );
}