import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    user_idno: "",
    user_name: "",
    logs_date: fwUtil.getToDayText(),
    scre_name: "",
    logs_gubu: "",
    logs_ipno: "",
    data_size: 0,
    send_remk: "",
    send_erro: ""
};

export function LogStatusInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            userIdno: data.user_idno || "",
            logsDate: fwUtil.localizingDateTime(data.logs_date) || null,
            screName: data.scre_name || "",
            logsGubu: data.logs_gubu || "",
            logsIpno: data.logs_ipno || "",
            dataSize: data.data_size || 0,
            sendRemk: data.send_remk || "",
            sendErro: data.send_erro || ""
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return (
        <PopupForm
            title={'로그 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormText({ value: data.scre_name, onValueChange: updateField('scre_name'), label: '화면명' })}
                {FormText({ value: data.logs_gubu, onValueChange: updateField('logs_gubu'), label: '사용구분' })}
                {FormText({ value: data.logs_ipno, onValueChange: updateField('logs_ipno'), label: '접속 IP' })}
                {FormNumb({ value: data.data_size, onValueChange: updateField('data_size'), label: '데이터크기', length: [11, 0], suffix: 'Kbyte' })}
            </GroupItem>
            {FormMemo({ value: data.send_remk, onValueChange: updateField('send_remk'), label: '비고' })}
            {FormMemo({ value: data.send_erro, onValueChange: updateField('send_erro'), label: '오류내용' })}
        </PopupForm>
    );
}