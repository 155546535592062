import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { currentTheme, refreshTheme } from 'devextreme/viz/themes';
import { storageKey } from '../../../utils/theme-constants';
import notify from 'devextreme/ui/notify';


const ThemeContext = createContext({});
const useTheme = () => useContext(ThemeContext);

function ThemeProvider({ theme, ...props }) {

    const [_theme, setTheme] = useState(theme);

    // 사용가능한 테마 색상 배열
    const colors = [
        { text: "스카이 블루", value: "blue", color: "#6192FF"},
        { text: "파일 그린", value: "green", color: "#5fc362"},
        { text: "모카", value: "moka", color: "#c59d6c"},
        { text: "바이올렛", value: "purple", color: "#a65ee9"},
        { text: "틸 그린", value: "teal", color: "#008080"},
    ];

    // 테마 정보 가져오는 함수
    const getTheme = useCallback(() => _theme || window.localStorage[storageKey] || "blue.light", 
    [_theme]);

    // 테마 색상 가져오는 함수
    const getAccent = useCallback(() => {
        const currentTheme = getTheme();
        const matchResult = currentTheme.match(/^([^\.]+)\.([^\.]+)$/);

        if (matchResult && matchResult.length === 3) {
            return matchResult[1];
        } else {
            return 'blue';
        }
    }, [getTheme]);

    // 테마 모드 가져오는 함수
    const getMode = useCallback(() => {
        const currentTheme = getTheme();
        const matchResult = currentTheme.match(/^([^\.]+)\.([^\.]+)$/);

        if (matchResult && matchResult.length === 3) {
            return matchResult[2];
        } else {
            return 'light';
        }
    }, [getTheme]);

    // 테마 적용 함수 1. 선택 테마 활성화 및 accent 컬러 설정
    const applyBaseTheme = useCallback((theme, themeMarker)=>{
        for(let index in document.styleSheets) {
            let styleSheet = document.styleSheets[index],
                href = styleSheet.href;
            if(href) {
                let themeMarkerPosition = href.indexOf(themeMarker);
                if(themeMarkerPosition >= 0) {
                    let startPosition = themeMarkerPosition + themeMarker.length,
                        endPosition = href.indexOf(".css"),
                        fileNamePart = href.substring(startPosition, endPosition);
                    if (fileNamePart === theme) {
                        for (let i=0; i<styleSheet.cssRules.length;i++){
                            let cssRule = styleSheet.cssRules.item(i)
                            if (cssRule?.selectorText === ".dx-theme-accent-as-text-color") {
                                document.documentElement.style.setProperty('--base-accent',cssRule.style.color)
                            }                        
                        }
                    }
                    styleSheet.disabled = fileNamePart != theme;
                }
            }
        }
    },[]);

    //테마 적용 함수 2. 모드 구분 색상 설정
    const applySwatchVariables = useCallback((accent)=>{
        if (accent === 'light') {
            document.documentElement.style.setProperty('--base-border-color', '#F3F3F3');
            document.documentElement.style.setProperty('--base-bg', '#d7d7d729');
            document.documentElement.style.setProperty('--base-text-color', '#000000DE');
            document.documentElement.style.setProperty('--icon-color', '#0000008A');
        
            document.documentElement.style.setProperty('--primary-mode-color', '#fbfbfb');
            document.documentElement.style.setProperty('--secondary-mode-color', '#ebebeb');
            document.documentElement.style.setProperty('--mode-accent-color', '#f2c138');
        } else {
            document.documentElement.style.setProperty('--base-border-color', '#515159');
            document.documentElement.style.setProperty('--base-bg', 'rgba(255, 255, 255, 0.10)');
            document.documentElement.style.setProperty('--base-text-color', 'rgba(255, 255, 255, 0.87)');
            document.documentElement.style.setProperty('--icon-color', 'rgba(255, 255, 255, 0.87)');
        
            document.documentElement.style.setProperty('--primary-mode-color', '#363640');
            document.documentElement.style.setProperty('--secondary-mode-color', '#4f4f4f');
            document.documentElement.style.setProperty('--mode-accent-color', '#25afee');
        }
    },[]);

    // 테마 적용 함수 3. 테마 활성화 및 비활성화
    const applySwatchTheme = useCallback((accent, themeMarker)=>{
        for(let index in document.styleSheets) {
            let styleSheet = document.styleSheets[index],
                href = styleSheet.href;
            if(href) {
                let themeMarkerPosition = href.indexOf(themeMarker);
                
                if(themeMarkerPosition >= 0) {
                    let startPosition = themeMarkerPosition + themeMarker.length,
                        endPosition = href.indexOf(".css"),
                        fileNamePart = href.substring(startPosition, endPosition);
                        styleSheet.disabled = !(accent === fileNamePart.substring(fileNamePart.indexOf('.')+1))
                }
            }
        }
    },[]);

    // 테마 적용 함수 0. 작용
    const applyTheme = useCallback((theme) => {
        theme = getTheme();
        applyBaseTheme(`${theme}.compact`,"dx.material.")
        
        let accent = theme?.substring(theme?.indexOf('.')+1)
        applySwatchVariables(accent)

        applySwatchTheme(accent,"theme.additional")

        window.localStorage[storageKey] = theme;
        currentTheme('material.' + theme);
        refreshTheme();
    }, [getTheme]);

    // 테마 색상 변경
    const applyColor = useCallback((color) => {
        const mode = getMode();
        const theme = `${color}.${mode}`;
        setTheme(theme);
    }, [getMode]);

    // 테마 모드 변경
    const applyMode = useCallback((mode) => {
        const accent = getAccent();
        const theme = `${accent}.${mode}`;
        setTheme(theme);
        if (mode === "light") {
            notify("라이트 모드가 활성화 되었습니다.", 'info', 1000);
        } else if (mode === "dark") {
            notify("다크 모드가 활성화 되었습니다.", 'info', 1000);
        }
    }, [getAccent]);

    useEffect(() => {
        applyTheme(_theme);
    }, [_theme, applyTheme]);

    return (
        <ThemeContext.Provider
            value={{ colors, getTheme, setTheme, applyColor, applyMode, getAccent, getMode }}
            {...props}
        />
    );
}

export {
    ThemeProvider,
    useTheme
}