// InspShipRegist - 출하검사 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { InspShipRegistInsert } from './Regist-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "출하검사";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "E32";                                         // 페이지 메뉴 코드
const mainKey = ['ship_numb', 'ship_seri'];                 // 데이터 키   

const searchApi = 'privat/qcl/ship/regist/search';          // 조회 api
const insertApi = 'privat/qcl/ship/regist/insert';          // 등록 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "chck_type": 0,
        "ship_numb": "S12345",
        "ship_seri": "001",
        "clie_code": "C001",
        "clie_name": "ABC Company",
        "item_code": "I001",
        "item_name": "Product A",
        "ship_date": "2024-05-28",
        "ship_qtys": 100.50,
        "ship_memo": "Sample memo 1",
        "user_name": "John Doe",
        "user_date": "2024-05-28",
        "modi_name": "Jane Smith",
        "modi_date": "2024-05-29"
    },
    {
        "chck_type": 0,
        "ship_numb": "S12346",
        "ship_seri": "002",
        "clie_code": "C002",
        "clie_name": "XYZ Corporation",
        "item_code": "I002",
        "item_name": "Product B",
        "ship_date": "2024-05-29",
        "ship_qtys": 150.75,
        "ship_memo": "Sample memo 2",
        "user_name": "Alice Johnson",
        "user_date": "2024-05-29",
        "modi_name": "Bob Brown",
        "modi_date": "2024-05-30"
    }
];

function InspShipRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [shipNumb, setShipNumb] = useState("");
    const shipNumbChange = useCallback((e) => { setShipNumb(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { shipNumb: shipNumb };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [shipNumb, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = useCallback (() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "출하번호", value: shipNumb, valueChange: shipNumbChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    }, [shipNumb, shipNumbChange, handleSearch]);

    // 등록 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {CommCol({ dataField: "ship_seri", caption: "출하순번" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "ship_date", caption: "출하일자" })}
                {DeciCol({ dataField: "ship_qtys", caption: "출하수량" })}
                {CommCol({ dataField: "ship_memo", caption: "비고" })}
            </PagingGrid>        
            <InspShipRegistInsert
                mainGrid={gridRef}
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />                 
        </div>
    );
};

export default InspShipRegist;