import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid, PopupWarn } from '../../../../script/components/popup/popup';
import { getPremDamd as getDamd } from '../../../../../api/set';
import { FormDate, FormGrid } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid, ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const listDataSource = fwUtil.generateDataSource();

const defaultData = {
    plan_date: fwUtil.getToDayText(),
    prem_date: fwUtil.getToDayText(),
    prem_damd: '',
};

const tempDamd = [
    { damd_code: "D0!", damd_dept: "생산부", damd_posi: "사원", damd_name: "김철수" },
    { damd_code: "D02", damd_dept: "품질부", damd_posi: "부장", damd_name: "차주익" },
    { damd_code: "D03", damd_dept: "관리부", damd_posi: "과장", damd_name: "창원유" },
    { damd_code: "D04", damd_dept: "관리부", damd_posi: "팀장", damd_name: "홍길동" },
];

export function PreMaintResultUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);    
    // 발주 그리드
    const listRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);
    // 업체 그리드
    const damdGridRef = useRef(null);
    const [damdData, setDamdData] = useState([]);
    const damdGridUpdate = useCallback((e) => setDamdData(e), []);
    const damdKeyExpr = 'damd_code';    
    // 그리드 팝업
    const [damdPopupVisible, setDamdPopupVisible] = useState(false);
    const damdPopupHiding = useCallback(() => setDamdPopupVisible(false), []);    

    // 업체 조회
    const damdHandleSearch = useCallback(async() => {
        const sendData = { damdName: "" };
        const res = await getDamd(sendData);
        //damdGridUpdate(res);
        damdGridUpdate(tempDamd);
    }, [damdGridUpdate]);

    useEffect(() => {
        damdHandleSearch();
    }, [damdHandleSearch]);

    // 업체 팝업 열릴 때
    const damdPopupOnShowing = useCallback(() => {
        damdHandleSearch();
    }, [damdHandleSearch]);

    // 업체 팝업 더블 클릭
    const damdOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, prem_damd: e.data.damd_name });
        damdPopupHiding();
    }, [data, dataUpdate, damdPopupHiding]);    

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            listDataSource.store().insert({ init: true }).then(() => listDataSource.reload());
            const selectedRowData = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const item of selectedRowData) {
                const newData = {
                    chck_seri: item.chck_seri || '',
                    chck_part: item.chck_part || '',
                    chck_gubu: item.chck_gubu || '',
                    chck_term: item.chck_term || '',
                    chck_desc: item.chck_desc || '',
                    chck_resu: item.chck_resu || '',
                    chck_remk: item.chck_remk || '',
                    chck_memo: item.chck_memo || '',                    
                };
                listDataSource.store().insert(newData);
            }
            
            listDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mainKey = fwUtil.findSelectedRowKey(mainGrid);
        const listData = listRef?.current?.instance?.getDataSource()?.items();
        const postPremData = listData.map((item) => ({
            chckSeri: item.chck_seri || '',
            chckPart: item.chck_part || '',
            chckGubu: item.chck_gubu || '',
            chckTerm: item.chck_term || '',
            chckDesc: item.chck_desc || '',
            chckResu: item.chck_resu || '',
            chckRemk: item.chck_remk || '',
            chckMemo: item.chck_memo || '',
        }));        
        const postData = {
            mainNumb: mainKey.main_numb || '',
            premSeri: mainKey.prem_seri || '',
            planDate: data.plan_date || null,
            premDate: data.prem_date || null,
            premDamd: data.prem_damd || '',
            setAmaps: postPremData || [],
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [updateApi, refresh, hiding, alertHide, listRef, mainGrid, data]);

    return (
        <>
        <PopupGrid
            title={'점검자 선택'}   
            type={'선택'}
            visible={damdPopupVisible}  
            showing={damdPopupOnShowing}  
            hiding={damdPopupHiding}
        >   
            <ScrollGrid
                title={'점검자'}
                role={'목록'}
                gridDataSource={damdData}
                gridRef={damdGridRef}
                mainKey={damdKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={damdOnRowDblClick}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "damd_dept", caption: "부서명" })}
                {CommCol({ dataField: "damd_posi", caption: "직위명" })}
                {CommCol({ dataField: "damd_name", caption: "성명" })}
            </ScrollGrid>
        </PopupGrid>          
        <PopupForm
            title={'예방정비 결과 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'예방정비 결과 항목'}
                        gridRef={listRef} 
                        gridDataSource={listDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART", allowEditing: false })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분", allowEditing: false })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기", allowEditing: false })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목", allowEditing: false })}
                        {CommCol({ dataField: "chck_resu", caption: "점검결과", check: true })}
                        {CommCol({ dataField: "chck_remk", caption: "특이사항", check: true })}
                        {CommCol({ dataField: "chck_memo", caption: "비고", check: true })}                      
                    </AddingGrid>                    
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormDate({ value: data.plan_date, onValueChange: updateField('plan_date'), label: '계획일자' })}
                {FormDate({ value: data.prem_date, onValueChange: updateField('prem_date'), label: '점검일자' })}
                {FormGrid({ value: data.prem_damd, onClick: () => setDamdPopupVisible(true), label: "점검자", required: true })}
            </GroupItem>
        </PopupForm>
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 수정 하시겠습니까?'}
        />  
        </>
    );
}