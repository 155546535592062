// WareHousingList - 입고 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item, Summary } from 'devextreme-react/data-grid';
import { TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "입고";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "C21";                                         // 페이지 메뉴 코드
const mainKey = 'ware_numb';                                // 데이터 키                                                

const searchApi = 'privat/mat/warehousing/list/search';     // 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "ware_numb": "WP20230101",
        "pros_numb": "P1234567890",
        "ware_date": "2024-01-01",
        "clie_name": "ABC Corporation",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M001",
        "matl_name": "Steel Plate",
        "matl_spec": "SP-001",
        "lots_numb": "LOT1234",
        "ware_qtys": 100,
        "ware_pric": 50.0,
        "ware_kwon": 5000.0,
        "ware_memo": "First batch of steel plates"
    },
    {
        "ware_numb": "WP20230102",
        "pros_numb": "P1234567891",
        "ware_date": "2024-01-02",
        "clie_name": "XYZ Industries",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M002",
        "matl_name": "Copper Wire",
        "matl_spec": "CW-002",
        "lots_numb": "LOT1235",
        "ware_qtys": 200,
        "ware_pric": 20.0,
        "ware_kwon": 4000.0,
        "ware_memo": "Second batch of copper wires"
    },
    {
        "ware_numb": "WP20230103",
        "pros_numb": "P1234567892",
        "ware_date": "2024-01-03",
        "clie_name": "LMN Tech",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M003",
        "matl_name": "Aluminum Sheet",
        "matl_spec": "AS-003",
        "lots_numb": "LOT1236",
        "ware_qtys": 150,
        "ware_pric": 30.0,
        "ware_kwon": 4500.0,
        "ware_memo": "First batch of aluminum sheets"
    },
    {
        "ware_numb": "WP20230104",
        "pros_numb": "P1234567893",
        "ware_date": "2024-01-04",
        "clie_name": "DEF Supplies",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M004",
        "matl_name": "Plastic Granules",
        "matl_spec": "PG-004",
        "lots_numb": "LOT1237",
        "ware_qtys": 500,
        "ware_pric": 5.0,
        "ware_kwon": 2500.0,
        "ware_memo": "Bulk order of plastic granules"
    },
    {
        "ware_numb": "WP20230105",
        "pros_numb": "P1234567894",
        "ware_date": "2024-01-05",
        "clie_name": "GHI Materials",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M005",
        "matl_name": "Rubber Sheets",
        "matl_spec": "RS-005",
        "lots_numb": "LOT1238",
        "ware_qtys": 300,
        "ware_pric": 15.0,
        "ware_kwon": 4500.0,
        "ware_memo": "Batch of rubber sheets"
    },
    {
        "ware_numb": "WP20230106",
        "pros_numb": "P1234567895",
        "ware_date": "2024-01-06",
        "clie_name": "JKL Components",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "M006",
        "matl_name": "Glass Panels",
        "matl_spec": "GP-006",
        "lots_numb": "LOT1239",
        "ware_qtys": 80,
        "ware_pric": 40.0,
        "ware_kwon": 3200.0,
        "ware_memo": "First batch of glass panels"
    }
]


function WareHousingList() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [seleGubu, setSeleGubu] = useState("구매");
    const [wareNumb, setWareNumb] = useState("");
    const [prosNumb, setProsNumb] = useState("");
    const [matlName, setMatlName] = useState("");
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, seleGubu: seleGubu, wareNumb: wareNumb, prosNumb: prosNumb, matlName: matlName, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, seleGubu, wareNumb, prosNumb, matlName, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, seleGubu]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "입고유형", value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: ['구매', '외주'] })}
                {ToolbarTextBox({ caption: "입고번호", value: wareNumb, valueChange: wareNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "pros_numb", caption: "발주번호" })}
                {DateCol({ dataField: "ware_date", caption: "입고일" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "lots_numb", caption: "Lot No." })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "ware_pric", caption: "입고단가" })}
                {DeciCol({ dataField: "ware_kwon", caption: "입고금액" })}
                {CommCol({ dataField: "ware_memo", caption: "비고" })}
                <Summary >
                    {TotalSumItem({ dataField: 'ware_qtys', caption: "입고 총수량" })}
                    {TotalSumItem({ dataField: 'ware_kwon', caption: "입고 총금액" })}
                </Summary>
            </PagingGrid>        
        </div>
    );
};


export default WareHousingList;