import React, { useState, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../contexts/auth';
import { LoadPanel } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import IpStatus from './IpStatus';
import UriStatus from './UriStatus';

const gubu = "mes";              // 페이지 메뉴 구분
const code = "R5";               // 페이지 메뉴 코드

function AddrInfoStatus() {
    const { isXSmall, isSmall } = useScreenSize();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth]);

    if (loading) {
        return <LoadPanel visible={true} />;
    }

    return ( 
        <div className={(isXSmall || isSmall)? 'fw-horizon-grid-wrapper fw-column-flex-box' : 'fw-horizon-grid-wrapper fw-row-flex-box' }>
            <IpStatus  authList={authList} />
            <UriStatus authList={authList} />
        </div>        
    );
}

export default AddrInfoStatus;

