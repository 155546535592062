// ProductStatus -작업실적 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol, NumbCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "작업실적";
const mainRole = "현황";
const subsTitle = "작업진행";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                                            // 페이지 메뉴 구분
const code = "D31";                                                            // 페이지 메뉴 코드
const mainKey = 'work_numb';                                                   // 메인 데이터 키
const subsKey = ['work_numb', 'prod_seri'];                                    // 서브 데이터 키

const mainSearchApi = 'privat/mfg/workOrder/status/header/search';             // 메인 조회 api
const subsSearchApi = 'privat/mfg/workOrder/status/detail/search';             // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        chck_type: 0,
        work_numb: 'WO001',
        plan_numb: 'PLAN001',
        orde_numb: 'ORDER001',
        proj_code: 'PROJ001',
        proj_name: 'Project A',
        item_code: 'ITEM001',
        item_name: 'Product A',
        file_qtys: 2,
        work_qtys: 100,
        work_date: '2024-05-26',
        star_date: '2024-05-26',
        clos_date: '2024-05-27',
        work_clos: 1,
        indx_keys: 'INDEX001',
        user_name: 'John Doe',
        user_date: '2024-05-26',
        modi_name: 'Jane Smith',
        modi_date: '2024-05-27'
    },
    {
        chck_type: 0,
        work_numb: 'WO002',
        plan_numb: 'PLAN002',
        orde_numb: 'ORDER002',
        proj_code: 'PROJ002',
        proj_name: 'Project B',
        item_code: 'ITEM002',
        item_name: 'Product B',
        file_qtys: 1,
        work_qtys: 80,
        work_date: '2024-05-27',
        star_date: '2024-05-27',
        clos_date: '2024-05-28',
        work_clos: 1,
        indx_keys: 'INDEX002',
        user_name: 'Alice Johnson',
        user_date: '2024-05-27',
        modi_name: 'Bob Brown',
        modi_date: '2024-05-28'
    }
];

const tempSubsData = [
    {
        chck_type: 0,
        work_numb: 'WO001',
        prod_seri: '001',
        item_code: 'ITEM001',
        item_name: 'Product A',
        comp_date: '2024-05-26',
        star_time: '08:00:00',
        clos_time: '16:00:00',
        work_qtys: 50,
        rout_numb: 'ROUT001',
        work_name: 'John Doe',
        last_rout: 'Yes',
        infe_qtys: 2,
        infe_ca01: true,
        infe_ca02: false,
        infe_ca03: false,
        infe_ca04: false,
        infe_ca05: false,
        infe_ca06: false,
        infe_ca07: false,
        infe_ca08: false,
        infe_ca09: false,
        infe_ca10: false,
        ca10_memo: 'Defect description'
    },
    {
        chck_type: 0,
        work_numb: 'WO001',
        prod_seri: '002',
        item_code: 'ITEM001',
        item_name: 'Product A',
        comp_date: '2024-05-27',
        star_time: '08:00:00',
        clos_time: '16:00:00',
        work_qtys: 50,
        rout_numb: 'ROUT002',
        work_name: 'John Doe',
        last_rout: 'No',
        infe_qtys: 0,
        infe_ca01: false,
        infe_ca02: false,
        infe_ca03: false,
        infe_ca04: false,
        infe_ca05: false,
        infe_ca06: false,
        infe_ca07: false,
        infe_ca08: false,
        infe_ca09: false,
        infe_ca10: false,
        ca10_memo: ''
    }
];

function ProductStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, itemName: itemName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, itemName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { workNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            const convertedData = tempSubsData.filter(item => item.work_numb === keyData);
            subsGridDataUpdate(convertedData);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.multipleSelectRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
        }
    }, [subsGridRef]);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);
 
    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "작업지시번호", value: itemName, valueChange: itemNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "plan_numb", caption: "게획번호" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "proj_code", caption: "프로젝트코드" })}
                {CommCol({ dataField: "proj_name", caption: "프로젝트명" })}
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "item_name", caption: "품목명" })}
                {NumbCol({ dataField: "file_qtys", caption: "첨부파일수" })}
                {DeciCol({ dataField: "work_qtys", caption: "지시수량" })}
                {DateCol({ dataField: "work_date", caption: "작업지시일" })}
                {DateCol({ dataField: "star_date", caption: "생솬시작일" })}
                {DateCol({ dataField: "clos_date", caption: "생산완료일" })}
                {BoolCol({ dataField: "work_clos", caption: "생산완료여부" })}                            
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}  
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "prod_seri", caption: "일보순번" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "comp_date", caption: "작업일자" })}
                {TimeCol({ dataField: "star_time", caption: "시작시간" })}
                {TimeCol({ dataField: "clos_time", caption: "종료시간" })}
                {DeciCol({ dataField: "work_qtys", caption: "완료수량" })}
                {CommCol({ dataField: "rout_numb", caption: "공정명" })}
                {CommCol({ dataField: "work_name", caption: "작업자명" })}
                {BoolCol({ dataField: "last_rout", caption: "최종공정" })}
                {DeciCol({ dataField: "infe_qtys", caption: "불량수량" })}
                {BoolCol({ dataField: "infe_ca01", caption: "소재불량" })}
                {BoolCol({ dataField: "infe_ca02", caption: "셋팅불량" })}
                {BoolCol({ dataField: "infe_ca03", caption: "외관불량" })}
                {BoolCol({ dataField: "infe_ca04", caption: "치수불량" })}
                {BoolCol({ dataField: "infe_ca05", caption: "탭/나사불량" })}
                {BoolCol({ dataField: "infe_ca06", caption: "용접불량" })}
                {BoolCol({ dataField: "infe_ca07", caption: "전/후처리불량" })}
                {BoolCol({ dataField: "infe_ca08", caption: "조립/작동불량" })}
                {BoolCol({ dataField: "infe_ca09", caption: "취급부주의불량" })}
                {BoolCol({ dataField: "infe_ca10", caption: "기타불량" })}
                {CommCol({ dataField: "ca10_memo", caption: "기타사유" })}
            </SubsGrid>                            
        </div>
    );
};


export default ProductStatus;