// ShipHistory - 출하 이력현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "출하";
const role = "이력 현황";
const gridSize = 10;

const gubu = "mes";                                      // 페이지 메뉴 구분
const code = "B25";                                      // 페이지 메뉴 코드
const mainKey = 'ship_numb';                             // 데이터 키                                    

const searchApi = '/privat/sal/ship/history/search';    // 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "chck_type": 0,
        "ship_numb": "SN001",
        "ship_seri": 1,
        "ship_date": "2024-05-24",
        "clie_name": "Client A",
        "item_code": "IC001",
        "item_name": "Item X",
        "item_spec": "Specification 1",
        "ship_qtys": 10,
        "ship_pric": 50.00,
        "ship_kwon": 500.00,
        "taxs_kwon": 50.00,
        "ship_remk": "Remarks 1",
        "orde_numb": "ON001",
        "user_name": "User A",
        "user_date": "2024-05-24",
        "modi_name": "User B",
        "modi_date": "2024-05-25"
    },
    {
        "chck_type": 0,
        "ship_numb": "SN002",
        "ship_seri": 2,
        "ship_date": "2024-05-25",
        "clie_name": "Client B",
        "item_code": "IC002",
        "item_name": "Item Y",
        "item_spec": "Specification 2",
        "ship_qtys": 15,
        "ship_pric": 60.00,
        "ship_kwon": 900.00,
        "taxs_kwon": 90.00,
        "ship_remk": "Remarks 2",
        "orde_numb": "ON002",
        "user_name": "User C",
        "user_date": "2024-05-25",
        "modi_name": "User D",
        "modi_date": "2024-05-26"
    }
];    

function ShipHistory() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [clieName, setClieName] = useState("");
    const [itemCode, setItemCode] = useState("");         
    const [itemName, setItemName] = useState("");         
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []); 
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);  
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName, itemCode: itemCode, itemName: itemName };
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, clieName, itemCode, itemName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "제품번호", value: itemCode, valueChange: itemCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {CommCol({ dataField: "ship_seri", caption: "순번" })}
                {DateCol({ dataField: "ship_date", caption: "출하일자" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_spec", caption: "규격" })}
                {DeciCol({ dataField: "ship_qtys", caption: "출하수량" })}
                {DeciCol({ dataField: "ship_pric", caption: "출하단가" })}
                {DeciCol({ dataField: "ship_kwon", caption: "출하금액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "ship_remk", caption: "특기사항" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
            </PagingGrid> 
        </div>
    );
};


export default ShipHistory;