// PProRegist - 발주 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { getPproClosGubu as getClos, getPproDateGubu as getDate } from '../../../../../api/set';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as UpdateAler } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PproMainInsert } from './Regist-main-insert';
import { PproMainUpdate } from './Regist-main-update';
import { PproSubsSelect } from './Regist-subs-select';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "발주";
const mainRole = "관리";
const subsTitle = "발주상세";
const subsRole = "관리";
const gridSize = 5;

const gubu = "mes";                                     // 페이지 메뉴 구분
const code = "C15";                                     // 페이지 메뉴 코드
const mainKey = 'ppro_numb';                            // 메인 데이터 키
const subaKey = ['ppro_numb', 'ppro_seri'];             // 서브 데이터 키 (메인 데이터 키 값 존재 시)
const subbKey = ['purs_numb', 'matl_code'];             // 서브 데이터 키 (메인 데이터 키 값 부재 시)
const mainDeleKey = (data) => {                         // 삭제 데이터 키
    return { 
        pproNumb: data, 
    }
};
const subsDeleKey = (data) => {                         // 삭제 데이터 키
    return { 
        pproNumb: data.ppro_numb, 
        setaMaps: [{
            pproSeri: data.ppro_seri,  
            pursNumb: data.purs_numb, 
            matlCode: data.matl_code,
        }]
    }
};

const mainSearchApi = 'privat/pro/ppro/register/header/search';    // 메인 조회 api
const mainTargetApi = 'privat/pro/ppro/register/header/target';    // 메인 타겟 api
const mainInsertApi = 'privat/pro/ppro/register/header/insert';    // 메인 등록 api
const mainUpdateApi = 'privat/pro/ppro/register/header/update';    // 메인 수정 api
const mainDeleteApi = 'privat/pro/ppro/register/header/delete';    // 메인 삭제 api
const subsSearchApi = 'privat/pro/ppro/register/detail/search';    // 서브 조회 api
const subsSelectApi = 'privat/pro/ppro/register/select/search';    // 서브 품목 조회 api
const subsInsertApi = 'privat/pro/ppro/register/detail/insert';    // 서브 등록 api
const subsUpdateApi = 'privat/pro/ppro/register/detail/update';    // 서브 수정 api
const subsDeleteApi = 'privat/pro/ppro/register/detail/delete';    // 서브 삭제 api

const exportFormats = ['xlsx', 'pdf'];
const convClos = (clos) => {
    return clos === "발주진행" ? 0 : clos === "입고완료" ? 1 : 2;
}

function PProRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [closGubu, setClosGubu] = useState("")
    const [dateGubu, setDateGubu] = useState("")
    const [clieName, setClieName] = useState("");
    const [pproNumb, setPproNumb] = useState("");    
    const [closGubuList, setClosGubuList] = useState([]);
    const [dateGubuList, setDateGubuList] = useState([]);    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const closGubuChange = useCallback((e) => { setClosGubu(e) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const pproNumbChange = useCallback((e) => { setPproNumb(e) }, []);
    const closGubuListChange = useCallback((e) => setClosGubuList(e), []);    
    const dateGubuListChange = useCallback((e) => setDateGubuList(e), []);    
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsSelectPopupVisible, setSubsSelectPopupVisible] = useState(false);
    const subsSelectPopupHide = useCallback(() => { setSubsSelectPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);    
    const [subsUpdateAlertVisible, setSubsUpdateAlertVisible] = useState(false);
    const subsUpdateAlertHide = useCallback(() => { setSubsUpdateAlertVisible(false); }, []);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [closResult, dateResult] = await Promise.all([getClos(), getDate()]);
                if (closResult && closResult.length > 0) {
                    closGubuListChange(closResult);
                    closGubuChange(closResult[0]);
                }   
                if (dateResult && dateResult.length > 0) {
                    dateGubuListChange(dateResult);
                    dateGubuChange(dateResult[0]);
                }                  
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [closGubuChange, closGubuListChange, dateGubuChange, dateGubuListChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        if (closGubu && dateGubu) {
            const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, closGubu: convClos(closGubu), dateGubu: dateGubu, clieName: clieName, pproNumb: pproNumb };
            await fwUtil.searchData(sendData, mainSearchApi, mainGridDataUpdate);
        }
    }, [dateValue, duraYesn, closGubu, dateGubu, clieName, pproNumb, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        const sendData = { pproNumb: keyData };
        await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainGridRef?.current?.instance?.deselectAll();
        subsGridRef?.current?.instance?.deselectAll();
        mainHandleSearch();
        subsHandleSearch();
    }, [mainHandleSearch, subsHandleSearch, closGubu, dateGubu, mainGridRef, subsGridRef]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.singleSelectRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        subsGridRef.current.instance.beginCustomLoading();
        const selectedPpro = fwUtil.findSelectedRowKey(mainGridRef) || '';
        const selectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            pproNumb: item.ppro_numb || '',
            pproSeri: item.ppro_seri || '',
            matlCode: item.matl_code || '',
            pproQtys: item.ppro_qtys || 0.000,
            pproPric: item.ppro_pric || 0.00,
            reqeDept: item.reqe_dept || '',
            pproMemo: item.ppro_memo || '',
            pursNumb: item.purs_numb || '',
        }));
        const postData = { pproNumb: selectedPpro, setaMaps: convertdData }
        await fwUtil.submitData(postData, subsUpdateApi, subsHandleSearch);
        subsGridRef.current.instance.endCustomLoading();
        subsUpdateAlertHide();
    }, [subsGridRef, subsHandleSearch, subsUpdateAlertHide]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async (type) => {
        if (type === 'main') {
            const gridInstance = mainGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.deleteData(mainGridRef, mainDeleteApi, mainDeleKey, mainHandleSearch);
            }
            mainDeleteAlertHide();
        } else {
            const gridInstance = subsGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.deleteData(subsGridRef, subsDeleteApi, subsDeleKey, subsHandleSearch);
            }
            subsDeleteAlertHide();
        }

    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, mainDeleteAlertHide, subsDeleteAlertHide]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "진행구분", value: closGubu, valueChange: closGubuChange, search: mainHandleSearch, items: closGubuList })}
                {ToolbarSelcBox({ caption: "일자구분", value: dateGubu, valueChange: dateGubuChange, search: mainHandleSearch, items: dateGubuList })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: pproNumb, valueChange: pproNumbChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'ins':
                    setMainInsertPopupVisible(true);
                    break;
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };
        
        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsSelectPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                notify('상세코드를 선택 후 시도해주세요.', 'warning', 2000);
                return;
            }
    
            switch (act) {              
                case 'upd':
                    setSubsUpdateAlertVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            if (type === 'main' && act !== 'ins') {
                notify('발주를 선택 후 시도해주세요.', 'warning', 2000);
                return;
            }
        }

        if (closGubu === "발주진행") {
            if (type === 'main' ) {
                handleMainAction();
            } else if (type === 'subs') {
                handleSubsAction();
            }
        } else {
            notify("발주진행 조회에서만 가능합니다", 'warning', 2000);
        }

    }, [mainGridRef, subsGridRef, closGubu]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => handleActionClick('main', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('main', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'none'}
            >
                {CommCol({ dataField: "ppro_numb", caption: "발주번호" })}
                {CommCol({ dataField: "damd_name", caption: "발주담당" })}
                {DateCol({ dataField: "ppro_date", caption: "발주일자" })}
                {CommCol({ dataField: "ppro_usag", caption: "용도" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "clie_teln", caption: "업체전화" })}
                {CommCol({ dataField: "clie_faxn", caption: "업체팩스" })}
                {CommCol({ dataField: "clie_damd", caption: "업체담당" })}
                {DateCol({ dataField: "pdel_date", caption: "납품일자" })}
                {CommCol({ dataField: "pays_cond", caption: "지불형태" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "insp_damd", caption: "검수자(방법)" })}
                {CommCol({ dataField: "ppro_remk", caption: "특기사항" })}
                {CommCol({ dataField: "ppro_stat", caption: "진행상태" })}
                {CommCol({ dataField: "afte_conf", caption: "결재진행" })}
                {DateCol({ dataField: "appr_date", caption: "승인요청일" })}
                {DateCol({ dataField: "rele_date", caption: "발주처리일" })}
                {CommCol({ dataField: "prin_yesn", caption: "발주서출력여부" })}
                {CommCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {CommCol({ dataField: "ppro_cwon", caption: "발주금액" })}                                                
            </MainGrid>
            <PproMainInsert
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <PproMainUpdate
                refresh={mainHandleSearch}
                mainGrid={mainGridRef}
                targetApi={mainTargetApi}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onDeleteClick('main')}
                message={'해당 발주를 삭제하시겠습니까?'}
            />   
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={fwUtil.findSelectedRowKey(mainGridRef) ? subaKey : subbKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
                edit
            >           
                {CommCol({ dataField: "ppro_seri", caption: "순번", allowEditing: false, visible: fwUtil.findSelectedRowKey(mainGridRef) ? true : false })}
                {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
                {CommCol({ dataField: "matl_name", caption: "자재품명", allowEditing: false })}
                {CommCol({ dataField: "matl_spec", caption: "자재규격", allowEditing: false })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량", check: true })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위", allowEditing: false })}
                {DeciCol({ dataField: "ppro_pric", caption: "발주단가", check: true })}
                {DeciCol({ dataField: "ppro_cwon", caption: "발주금액", allowEditing: false })}
                {CommCol({ dataField: "ppro_memo", caption: "비고", check: true })}
                {CommCol({ dataField: "reqe_dept", caption: "수요처", allowEditing: false })}
            </SubsGrid>         
            <PproSubsSelect
                mainGrid={mainGridRef}
                refresh={subsHandleSearch}
                selectApi={subsSelectApi}
                insertApi={subsInsertApi}
                visible={subsSelectPopupVisible}
                hiding={subsSelectPopupHide}
            />  
            <UpdateAler
                grid={subsGridRef}
                visible={subsUpdateAlertVisible}
                hiding={subsUpdateAlertHide}
                confirm={onUpdateClick}
                message={'해당 발주 상세품목를 수정하시겠습니까?'}
            />  
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={() => onDeleteClick('subs')}
                message={'해당 발주 상세품목을 삭제하시겠습니까?'}
            />                      
        </div>
    );
};


export default PProRegist;