// PreMaintResultStatus - 결과 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "결과";
const mainRole = "현황";
const subsTitle = "예방정비";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                     // 페이지 메뉴 구분
const code = "F32";                                     // 페이지 메뉴 코드
const mainKey = 'main_numb';                            // 메인 데이터 키
const subsKey = ['main_numb', 'chck_seri'];             // 서브 데이터 키

const mainSearchApi = 'privat/com/code/main/search';    // 메인 조회 api
const subsSearchApi = 'privat/com/code/subs/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        chck_type: 0,
        main_numb: "001",
        equi_numb: "E001",
        prem_seri: "S001",
        plan_date: "2024-05-01",
        prem_date: "2024-05-15",
        prem_damd: "정기 점검",
        equi_gubu: "A",
        equi_name: "설비 1",
        user_name: "사용자 A",
        user_date: "2024-04-01",
        modi_name: "사용자 B",
        modi_date: "2024-04-20"
    },
    {
        chck_type: 0,
        main_numb: "002",
        equi_numb: "E002",
        prem_seri: "S002",
        plan_date: "2024-05-02",
        prem_date: "2024-05-16",
        prem_damd: "정기 점검",
        equi_gubu: "B",
        equi_name: "설비 2",
        user_name: "사용자 C",
        user_date: "2024-04-02",
        modi_name: "사용자 D",
        modi_date: "2024-04-21"
    },
];    


const tempSubsData = [
    {
        chck_type: 0,
        main_numb: "001",
        prem_seri: "001",
        chck_seri: "01",
        chck_part: "파트 A",
        chck_gubu: "구분 A",
        chck_term: "주기 A",
        chck_desc: "설명 A",
        prem_resu: "결과 A",
        plan_date: "2024-05-01",
        prem_date: "2024-05-15",
        prem_damd: "예방 설명 A",
        prem_memo: "메모 A",
        prem_remk: "비고 A",
        user_name: "사용자 A",
        user_date: "2024-04-01",
        modi_name: "사용자 B",
        modi_date: "2024-04-20"
    },
    {
        chck_type: 0,
        main_numb: "001",
        prem_seri: "002",
        chck_seri: "02",
        chck_part: "파트 B",
        chck_gubu: "구분 B",
        chck_term: "주기 B",
        chck_desc: "설명 B",
        prem_resu: "결과 B",
        plan_date: "2024-05-02",
        prem_date: "2024-05-16",
        prem_damd: "예방 설명 B",
        prem_memo: "메모 B",
        prem_remk: "비고 B",
        user_name: "사용자 C",
        user_date: "2024-04-02",
        modi_name: "사용자 D",
        modi_date: "2024-04-21"
    },
    {
        chck_type: 0,
        main_numb: "002",
        prem_seri: "001",
        chck_seri: "01",
        chck_part: "파트 C",
        chck_gubu: "구분 C",
        chck_term: "주기 C",
        chck_desc: "설명 C",
        prem_resu: "결과 C",
        plan_date: "2024-05-03",
        prem_date: "2024-05-17",
        prem_damd: "예방 설명 C",
        prem_memo: "메모 C",
        prem_remk: "비고 C",
        user_name: "사용자 E",
        user_date: "2024-04-03",
        modi_name: "사용자 F",
        modi_date: "2024-04-22"
    }
];  

function PreMaintResultStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { mainNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            subsGridDataUpdate(tempSubsData.filter(item => item.main_numb === keyData));
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            subsHandleSearch();
            fwUtil.multipleSelectRow(mainGridRef, key, isSelected);
        }
    }, [subsHandleSearch, mainGridRef]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "설비명", value: equiName, valueChange: equiNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}           
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "prem_seri", caption: "점검순번" })}
                {DateCol({ dataField: "plan_date", caption: "계획일자" })}
                {DateCol({ dataField: "prem_date", caption: "점검일자" })}
                {CommCol({ dataField: "prem_damd", caption: "점검자" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                    {CommCol({ dataField: "chck_part", caption: "점검PART" })}
                    {CommCol({ dataField: "chck_gubu", caption: "점검구분" })}
                    {CommCol({ dataField: "chck_term", caption: "점검주기" })}
                    {CommCol({ dataField: "chck_desc", caption: "점검항목" })}
                    {CommCol({ dataField: "prem_resu", caption: "점검결과" })}
                    {DateCol({ dataField: "plan_date", caption: "계획일자" })}
                    {DateCol({ dataField: "prem_date", caption: "점검일자" })}
                    {CommCol({ dataField: "prem_damd", caption: "점검자" })}
                    {CommCol({ dataField: "prem_memo", caption: "비고" })}
                    {CommCol({ dataField: "prem_remk", caption: "특기사항" })}
            </SubsGrid>                         
        </div>
    );
};


export default PreMaintResultStatus;