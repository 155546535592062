// OrderRegist - 수주 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DeciCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { OrderRegistInsert } from './Regist-insert';
import { OrderRegistUpdate } from './Regist-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "수주";
const role = "관리";
const gridSize = 10;

const gubu = "mes";                                      // 페이지 메뉴 구분
const code = "B13";                                      // 페이지 메뉴 코드
const mainKey = 'orde_numb';                             // 데이터 키
const deleKey = (data) => {                              // 삭제 데이터 키
    return { ordeNumb: data }
};                                                         

const searchApi = '/privat/sal/order/register/search';   // 조회 api
const targetApi = '/privat/sal/order/register/target';   // 타겟 api
const insertApi = '/privat/sal/order/register/insert';   // 타겟 api
const updateApi = '/privat/sal/order/register/update';   // 수정 api
const deleteApi = '/privat/sal/order/register/delete';   // 삭제 api

const exportFormats = ['xlsx', 'pdf'];
const closList = ['진행', '완료', '전체'];
const dateList = ['납기일자', '등록일자'];

function OrderRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [closGubu, setClosGubu] = useState("전체")
    const [dateGubu, setDateGubu] = useState("납기일자")
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");      
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const closGubuChange = useCallback((e) => { setClosGubu(e) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []); 
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);  
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, closGubu: closGubu === "완료" ? 0 : closGubu === "진행" ? 1 : 2, dateGubu: dateGubu, clieName: clieName, itemName: itemName };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [dateValue, duraYesn, closGubu, dateGubu, clieName, itemName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "진행구분", value: closGubu, valueChange: closGubuChange, search: handleSearch, items: closList })}
                {ToolbarSelcBox({ caption: "일자구분", value: dateGubu, valueChange: dateGubuChange, search: handleSearch, items: dateList })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "품목명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "clie_orde", caption: "오더번호" })}
                {CommCol({ dataField: "orde_line", caption: "라인번호" })}
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "item_name", caption: "품목명" })}
                {CommCol({ dataField: "cite_code", caption: "고객품목번호" })}
                {CommCol({ dataField: "cite_name", caption: "고객품명" })}
                {CommCol({ dataField: "cite_spec", caption: "고객규격" })}
                {DateCol({ dataField: "orde_date", caption: "수주일자" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                {DeciCol({ dataField: "orde_qtys", caption: "오더수량" })}
                {CommCol({ dataField: "orde_unit", caption: "단위" })}
                {DeciCol({ dataField: "orde_pric", caption: "수주단가" })}
                {DeciCol({ dataField: "orde_kwon", caption: "수주금액" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "pays_cond", caption: "결재조건" })}
                {CommCol({ dataField: "orde_memo", caption: "비고" })}
                {BoolCol({ dataField: "plan_yesn", caption: "생산계획완료유무" })}
                {DeciCol({ dataField: "work_qtys", caption: "작업지시수량" })}
                {BoolCol({ dataField: "work_yesn", caption: "작업지시완료유무" })}
                {DeciCol({ dataField: "deli_qtys", caption: "납품수량" })}
                {BoolCol({ dataField: "deli_yesn", caption: "납품완료유무" })}
                {BoolCol({ dataField: "insp_repo", caption: "검사성적서 첨부유무" })}
            </PagingGrid>
            <OrderRegistInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <OrderRegistUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 수주를 삭제하시겠습니까?'}
            />             
        </div>
    );
};


export default OrderRegist;