import React, { useState, useCallback, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { getComCodeGubu as getGubu } from '../../../../../api/set';
import { FormBool, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    main_gubu: '', 
    main_code: '', 
    main_yesn: false, 
    main_name: '', 
    main_memo: '',
};

export function ComCodeMainUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedLastRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('관리코드를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            const sendData = { mainCode: selectedRowKey };
            const res = await fwUtil.targetData(sendData, targetApi);
            const resData = {
                main_gubu: res.main_gubu || '',
                main_code: res.main_code || '',
                main_yesn: res.main_yesn || false,
                main_name: res.main_name || '',
                main_memo: res.main_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		


    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainCode: data.main_code || '',
            mainYesn: data.main_yesn || false,
            mainName: data.main_name || '',
            mainMemo: data.main_memo || '',
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
            <PopupForm
                title={'관리코드 수정'}       
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 300 : 600}  
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 1 : 2}>
                        {FormText({ value: data.main_gubu, onValueChange: updateField('main_gubu'), label: '업무분류', disabled: true, required: true })}
                    </GroupItem>
                    {FormText({ value: data.main_code, onValueChange: updateField('main_code'), label: '관리코드', disabled: true })}  
                    {FormBool({ value: data.main_yesn, onValueChange: updateField('main_yesn'), label: '사용여부' })}  
                </GroupItem>
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '관리코드명', required: true })}
                {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
            </PopupForm>            
    );
}