import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const listDataSource = fwUtil.generateDataSource();

export function PreMaintRegistUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 발주 그리드
    const listRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            listDataSource.store().insert({ init: true }).then(() => listDataSource.reload());
            const selectedRowData = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const item of selectedRowData) {
                const newData = {
                    chck_seri: item.chck_seri || '',
                    chck_part: item.chck_part || '',
                    chck_gubu: item.chck_gubu || '',
                    chck_term: item.chck_term || '',
                    chck_desc: item.chck_desc || '',
                    chck_memo: item.chck_memo || '',
                };
                listDataSource.store().insert(newData);
            }
            
            listDataSource.reload();
        }
    }, [hiding, mainGrid]);

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mainKey = fwUtil.findSelectedRowKey(mainGrid);
        const listData = listRef?.current?.instance?.getDataSource()?.items();
        const postPremData = listData.map((item) => ({
            chckSeri: item.chck_seri || '',
            chckPart: item.chck_part || '',
            chckGubu: item.chck_gubu || '',
            chckTerm: item.chck_term || '',
            chckDesc: item.chck_desc || '',
            chckMemo: item.chck_memo || '',
        }));        
        const postData = {
            mainNumb: mainKey || '',
            setAmaps: postPremData || [],
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [updateApi, refresh, hiding, alertHide, listRef, mainGrid]);

    return (
        <>
        <PopupForm
            title={'예방정비 항목 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'예방정비 항목'}
                        gridRef={listRef} 
                        gridDataSource={listDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART", check: true })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분", check: true })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기", check: true })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목", check: true })}
                        {CommCol({ dataField: "chck_resu", caption: "비고", check: true })}
                    </AddingGrid>                    
                </div>
            }         
        />
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 수정 하시겠습니까?'}
        />  
        </>
    );
}