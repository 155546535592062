import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const tempRes = [
    { main_numb: "001", chck_seri: "001", chck_part: "Part1", chck_gubu: "Gubu1", chck_term: "Term1", chck_desc: "Desc1", chck_resu: "Resu1" },
    { main_numb: "001", chck_seri: "002", chck_part: "Part2", chck_gubu: "Gubu2", chck_term: "Term2", chck_desc: "Desc2", chck_resu: "Resu2" },
];

const oldsDataSource = fwUtil.generateDataSource();
const newsDataSource = fwUtil.generateDataSource();

export function PreMaintRegistInsert(props) {
    const {
        mainGrid,
        refresh,
        targetApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 발주 그리드
    const oldsRef = useRef(null);
    const newsRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            oldsDataSource.store().insert({ init: true }).then(() => oldsDataSource.reload());
            newsDataSource.store().insert({ init: true }).then(() => oldsDataSource.reload());
            const sendData= { mainNumb: selectedRowKey };
            //const res = fwUtil.submitData(sendData, targetApi, null);
            const res = tempRes;
            for (const item of res) {
                const newData = {
                    chck_seri: item.chck_seri || '',
                    chck_part: item.chck_part || '',
                    chck_gubu: item.chck_gubu || '',
                    chck_term: item.chck_term || '',
                    chck_desc: item.chck_desc || '',
                    chck_memo: item.chck_memo || '',
                };
                oldsDataSource.store().insert(newData);
            }
            
            oldsDataSource.reload();
        }
    }, [hiding, mainGrid]);

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mainKey = fwUtil.findSelectedRowKey(mainGrid);
        const oldsData = oldsRef?.current?.instance?.getDataSource()?.items();
        const postPremData = oldsData.map((item) => ({
            chckSeri: item.chck_seri || '',
            chckPart: item.chck_part || '',
            chckGubu: item.chck_gubu || '',
            chckTerm: item.chck_term || '',
            chckDesc: item.chck_desc || '',
            chckMemo: item.chck_memo || '',
        }));        
        const postData = {
            mainNumb: mainKey || '',
            setAmaps: postPremData || [],
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [insertApi, refresh, hiding, alertHide, oldsRef, mainGrid]);

    return (
        <>
        <PopupForm
            title={'예방정비 항목 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'기존 항목'}
                        gridRef={oldsRef} 
                        gridDataSource={oldsDataSource}
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번" })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART" })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분" })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기" })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목" })}
                        {CommCol({ dataField: "chck_resu", caption: "비고" })}  
                    </AddingGrid>
                    <AddingGrid
                        title={'신규 항목'}
                        gridRef={newsRef} 
                        gridDataSource={newsDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART", check: true })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분", check: true })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기", check: true })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목", check: true })}
                        {CommCol({ dataField: "chck_resu", caption: "비고", check: true })}
                    </AddingGrid>                    
                </div>
            }         
        />
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 등록 하시겠습니까?'}
        />  
        </>
    );
}