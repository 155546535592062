import React, { useCallback, useState } from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, { GroupItem } from 'devextreme-react/form';
import { Button, Popup, ScrollView, ValidationGroup } from 'devextreme-react';
import { PopupToolbar } from './popup-item';
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { FormPass } from '../form/form-items';
import * as fwUtil from '../../util';
import './popup.scss';

// 알림 다이얼로그
const PopupAler = (props) => {
    const {
        grid,
        visible, hiding, confirm,
        message,
    } = props;

    const [alertData, setAlertData] = useState([]);

    const onShowing = () => {
        if (grid) {
            const selectedData = fwUtil.findSelectedRowKey(grid);
            if (selectedData) {
                setAlertData(fwUtil.getAlertData(selectedData));
            } else {
                setAlertData([]);
                hiding();
            }
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-alert-popup' }}
            onShowing={onShowing}
            hideOnOutsideClick
        >   
            <div className="fw-popup-content fw-column-flex-box">
                {alertData && alertData.map((item, index) => (
                    <span className="alert-key" key={index}>
                        {typeof item === 'object'
                            ? Object.values(item).map((v, i) => <span key={i}>{v} </span>)
                            : item
                        }
                    </span>
                ))}
                <span className="alert-message">{message}</span>
                <div className="alert-button-wrapper fw-row-flex-box">
                    <Button 
                        text="확인"
                        stylingMode="contained"
                        onClick={confirm}
                    />
                    <Button 
                        text="취소"
                        className="fw-cancle-button"
                        stylingMode="outlined"
                        onClick={hiding}
                    />
                </div>
            </div>
        </Popup>
    );
};

// 경고 다이얼로그
const PopupWarn = (props) => {
    const {
        warning,
        visible, hiding, confirm,
        message,
    } = props;

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-warning-popup' }}
            hideOnOutsideClick
        >   
            <div className="fw-popup-content fw-column-flex-box">
                <span className="warning-key">
                    {warning}
                </span>
                <span className="warning-message">{message}</span>
                <div className="warning-button-wrapper fw-row-flex-box">
                    <Button 
                        text="확인"
                        stylingMode="contained"
                        onClick={confirm}
                    />
                    <Button 
                        text="취소"
                        className="fw-cancle-button"
                        stylingMode="outlined"
                        onClick={hiding}
                    />
                </div>
            </div>
        </Popup>
    );
};

// 주소 다이얼로그
const PopupAddr = (props) => {
    const {
        visible, hiding, confirm
    } = props;
    const oncomplete = (data) => {
        hiding();
        confirm(data);
    };

    if (!visible) {
        return;
    }

    return (
        <>
        <Popup
            title='주소찾기'
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={true}
            wrapperAttr={{ class: 'fw-daum-popup' }}
            hideOnOutsideClick
        >   
            <div className="fw-popup-content">
                <DaumPostcodeEmbed 
                    style={{
                        width: 350,
                        height: 500,
                    }}
                    onComplete={oncomplete}
                />
            </div>
        </Popup>  
        </>
    );
};


// 비밀번호 변경 다이얼로그
const PopupPass = (props) => {
    const {
        data, updateField,
        visible, hiding,
    } = props;

    const onSubmit = useCallback(() => {
        //비밀번호 변경 로직
        const postData = {
            curr_pass: data.curr_pass,
            pass_word: data.pass_word,
        }
    }, []);

    // 동작 버튼 클릭
    const confirm = useCallback(({ validationGroup }) => {
        const validationResult = fwUtil.valditData(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <Popup
            title='비밀번호 변경'
            visible={visible}
            onHiding={hiding}
            width={350}
            height={'auto'}
            maxWidth={'100%'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-pass-popup' }}
            hideOnOutsideClick
        >   
            <div className="fw-popup-content fw-column-flex-box">
                <ValidationGroup>
                    <Form>
                        <GroupItem cssClass='fw-current-password-wrapper'> 
                            {FormPass({ value: data.curr_pass, onValueChange: updateField('curr_pass'), label: '현재 비밀번호', required: true })}
                        </GroupItem>
                        {FormPass({ value: data.pass_word, onValueChange: updateField('pass_word'), label: '비밀번호', required: true })}
                        {FormPass({ value: data.conf_pass, onValueChange: updateField('conf_pass'), label: '비밀번호 확인', required: true, confirm: true, password: data.pass_word })}
                    </Form>
                </ValidationGroup>  
                <PopupToolbar
                    type={'수정'}
                    onSubmit={confirm}
                    onClose={hiding}
                />
            </div>
        </Popup>
    );
};

const PopupForm = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, additionalChild
    } = props;

    const hideOutsideClick = fwUtil.ternFunc(false, outSideClick);
    const showCloseButton = fwUtil.ternFunc(true, closeButton);
    const deferRendering = fwUtil.ternFunc(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = useCallback(({ validationGroup }) => {
        const validationResult = fwUtil.valditData(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width }
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >   
            {loading && 
                <div className={loading? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden' }>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup>
                    <div className={'fw-popup-content fw-form-popup fw-column-flex-box' }>
                        <div className={`fw-popup-form fw-column-flex-box`}>
                            <Form>
                                {children}
                            </Form>
                            <div className={'fw-additional-child-wrapper'}>
                                {additionalChild}
                            </div>
                        </div>
                        { onSubmit &&
                        <PopupToolbar
                            type={type}
                            onSubmit={onActionClick}
                            onClose={hiding}
                        />                            
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
    </Popup>
    )
};

const PopupGrid = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, 
    } = props;

    const hideOutsideClick = fwUtil.ternFunc(false, outSideClick);
    const showCloseButton = fwUtil.ternFunc(true, closeButton);
    const deferRendering = fwUtil.ternFunc(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.valditData(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width }
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >   
            {loading && 
                <div className={loading? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden' }>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup>
                    <div className={'fw-popup-content fw-grid-popup fw-column-flex-box' }>
                        {children}
                        { type &&
                        <PopupToolbar
                            type={type}
                            onSubmit={onActionClick}
                            onClose={hiding}
                        />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
    </Popup>
    )
};

const PopupRepo = (props) => {
    const {
        title, width, loading,
        visible, hiding, showing, shown, 
        outSideClick, closeButton, renderDefer,
    } = props;

    const hideOutsideClick = fwUtil.ternFunc(false, outSideClick);
    const showCloseButton = fwUtil.ternFunc(true, closeButton);
    const deferRendering = fwUtil.ternFunc(true, renderDefer);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width }
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >   
            {loading && 
                <div className={loading? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden' }>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >

            </ScrollView>
    </Popup>
    )
};

export {
    PopupAler,
    PopupWarn,
    PopupAddr,
    PopupPass,
    PopupForm,
    PopupGrid,
    PopupRepo
};