// ProcloseStatus - 매입마감 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "매입마감";
const mainRole = "현황";
const subsTitle = "상세";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                             // 페이지 메뉴 구분
const code = "C61";                                             // 페이지 메뉴 코드
const mainKey = 'clie_code';                                    // 메인 데이터 키
const subsKey = 'proc_numb';                                    // 서브 데이터 키

const mainSearchApi = 'privat/com/bom/status/header/search';    // 메인 조회 api
const subsSearchApi = 'privat/com/bom/status/detail/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        clie_code: 1,
        clie_name: "고객1",
        ware_qtys: 100,
        ware_pric: 5000,
    },
    {
        clie_code: 2,
        clie_name: "고객2",
        ware_qtys: 150,
        ware_pric: 7000,
    },
];

const tempSubsData = [
    {
        proc_numb: 1,
        proc_date: "2024-05-23",
        clie_code: 1,
        clie_name: "고객1",
        ware_date: "2024-05-24",
        matl_code: "M001",
        matl_bane: "자재1",
        matl_spec: "규격1",
        matl_unit: "개",
        ware_qtys: 50,
        ware_pric: 2000,
        ware_kwon: 100000,
        ware_loca: "창고1",
        ware_numb: "W001",
        work_numb: "WK001",
        proc_memo: "비고1",
    },
    {
        proc_numb: 2,
        proc_date: "2024-05-24",
        clie_code: 2,
        clie_name: "고객2",
        ware_date: "2024-05-25",
        matl_code: "M002",
        matl_bane: "자재2",
        matl_spec: "규격2",
        matl_unit: "개",
        ware_qtys: 70,
        ware_pric: 2500,
        ware_kwon: 175000,
        ware_loca: "창고2",
        ware_numb: "W002",
        work_numb: "WK002",
        proc_memo: "비고2",
    },
    // 나머지 데이터 추가
];

function ProcloseStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [clieName, setClieName] = useState(""); 
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);  

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };
        //await fwUtil.searchData(sendData, mainSearchApi, mainGridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { clieCode: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            subsGridDataUpdate(tempSubsData.filter(item => item.clie_code === keyData)); 
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeProcloses = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "고객사명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, before: mainToolbarBeforeProcloses }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {CommCol({ dataField: "ware_pric", caption: "입고금액" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }} 
                toolbar={{ default: false }} 
                selection={'single'}
            >
                {CommCol({ dataField: "proc_numb", caption: "매입마감번호" })}
                {DateCol({ dataField: "proc_date", caption: "확정일자" })}
                {CommCol({ dataField: "clie_code", caption: "업체번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {DateCol({ dataField: "ware_date", caption: "입고일자" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_bane", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "matl_unit", caption: "구매단위" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "ware_pric", caption: "입고단가" })}
                {DeciCol({ dataField: "ware_kwon", caption: "입고금액" })}
                {CommCol({ dataField: "ware_loca", caption: "입고처" })}
                {CommCol({ dataField: "ware_numb", caption: "입고번호" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "proc_memo", caption: "비고" })}
            </SubsGrid>                            
        </div>
    );
};


export default ProcloseStatus;