import React, { useMemo, useCallback, useState } from 'react';
import { useAuth } from '../../../../contexts/auth';
import { Popup } from 'devextreme-react';
import { PopupAler } from '../popup/popup';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import { UserPanelUpdate } from './UserPanel-update';
import List from 'devextreme-react/list';
import ColorChanger from '../change-theme/ColorChanger';
import ModeChanger from '../change-theme/ModeChanger';
import './UserPanel.scss';

export default function UserPanel({ menuMode }) {
  const { user, signOut } = useAuth();
  const [themePopupVisible, setThemePopupVisible] = useState(false);
  const [signoutPopupVisible, setSignoutPopupVisible] = useState(false);
  const [updatePopupVisible, setUpdatePopupVisible] = useState(false);

  const themeHide = useCallback(() => {
    setThemePopupVisible(false);
  }, []);

  const signoutHide = useCallback(() => {
    setSignoutPopupVisible(false);
  }, []);

  const updatePopupHide = useCallback(() => {
    setUpdatePopupVisible(false);
  }, []);

  const menuItems = useMemo(() => ([
    {
      text: '내정보',
      icon: 'user',
      onClick: () => setUpdatePopupVisible(true)
    },
    {
      text: '로그아웃',
      icon: 'runner',
      onClick: () => setSignoutPopupVisible(true)
    },
    {
      text: '설정',
      icon: 'preferences',
      onClick: () => setThemePopupVisible(true)
    }
  ]), [setUpdatePopupVisible, setSignoutPopupVisible, setThemePopupVisible]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <div
            style={{
              background: `url(${user.avatarUrl}) no-repeat #fff`,
              backgroundSize: 'cover'
            }}
            className={'user-image'} />
        </div>
        <div className={'user-name'}>{user.userName} {user.posiName} {user.deptName? `(${user.deptName})` : ""}</div>
      </div>
      
      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
      <Popup
        visible={themePopupVisible}
        onHiding={themeHide}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title="설정"
        width={'auto'}
        height={'auto'}
      >
        <div className={'popup-content fw-column-flex-box'}>
          <div className={'preference-row fw-row-flex-box'}>
            <div className={'row-title'}>테마 색상 팔레트</div>
            <div className={'row-content fw-row-flex-box'}>
              <ColorChanger />
            </div>
          </div>
          <div className={'preference-row fw-row-flex-box'}>
            <div className={'row-title'}>모드 설정</div>
            <div className={'row-content switch-content fw-row-flex-box'}>
              <ModeChanger />
            </div>
          </div>
        </div>
      </Popup>
      <PopupAler
        visible={signoutPopupVisible}
        hiding={signoutHide}
        confirm={signOut}
        message={'로그아웃 하시겠습니까?'}
      />
      <UserPanelUpdate
        user={user}
        visible={updatePopupVisible}
        hiding={updatePopupHide}
      />
    </div>
  );
}
