import { ternaryFunction } from "../../util";
import TreeList, { RowDragging } from 'devextreme-react/tree-list';
import "./tree-body.scss";

export const ReOrdingTree = (props) => {
    return (
        <TreeList
            ref={props.treeInstance}
            dataSource={props.dataSource}
            keyExpr={props.keyExpr}
            parentIdExpr={props.parentIdExpr}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={ternaryFunction(true, props.columnAutoWidth)}
            autoExpandAll={true}
            height={782}
        >
            <RowDragging
                onDragChange={props.onDragChange}
                onReorder={props.onReorder}
                allowDropInsideItem={ternaryFunction(false, props.allowDropInsideItem)}
                allowReordering={true}
                showDragIcons={ternaryFunction(true, props.showDragIcons)}
            />                
            {props.children}
        </TreeList>
    )
};