import { DateBox } from 'devextreme-react';
import { ternaryFunction, getFirstDayText, getLastDayText, getToDayText } from '../../util';

const startDateBox = (props) => {
    return (
        <DateBox
            acceptCustomValue={ternaryFunction(false,props.acceptCustomValue)}
            openOnFieldClick={ternaryFunction( true, props.openOnFieldClick)}
            placeholder="시작일"
            hint='시작일'
            pickerType={props.pickerType}
            type='date'
            dateOutOfRangeMessage="시작일 형태가 잘못되었습니다."
            value={ternaryFunction(getFirstDayText(), props.value)}
            onValueChanged={props.onValueChanged}
            disabled={ternaryFunction(true, props.disabled)}
            width={ternaryFunction(120, props.width)}
            displayFormat={ternaryFunction('yyyy-MM-dd', props.displayFormat)}
        />
    )
}

const complDateBox = (props) => {
    return (
        <DateBox
            acceptCustomValue={ternaryFunction(false, props.acceptCustomValue)}
            openOnFieldClick={ternaryFunction(true, props.openOnFieldClick)}
            placeholder="종료일"
            hint='종료일'
            pickerType={props.pickerType}
            type='date'
            dateOutOfRangeMessage="종료일 형태가 잘못되었습니다."
            value={ternaryFunction(getLastDayText(), props.value)}
            onValueChanged={props.onValueChanged}
            disabled={ternaryFunction(true, props.disabled)}
            width={ternaryFunction(120, props.width)}
            displayFormat={ternaryFunction('yyyy-MM-dd', props.displayFormat)}
        />
    )
}

const selectDateBox = (props) => {
    return (
        <DateBox
            acceptCustomValue={ternaryFunction(false, props.acceptCustomValue)}
            openOnFieldClick={ternaryFunction(true, props.openOnFieldClick)}
            placeholder="날짜선택"
            hint='날짜선택'
            pickerType={props.pickerType}
            type='date'
            dateOutOfRangeMessage="날짜 형태가 잘못되었습니다."
            value={ternaryFunction(getToDayText(), props.openOnFieldClick)}
            onValueChanged={props.onValueChanged}
            disabled={ternaryFunction(true, props.disabled)}
            width={ternaryFunction(140, props.width)}
            displayFormat={ternaryFunction('yyyy-MM-dd', props.displayFormat)}
        />
    )
}

export { 
    startDateBox,
    complDateBox,
    selectDateBox
}