// ClientVehicleStatus - 거래처 차량 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { VehicleStatusInsert } from './VehicleStatus-insert';
import { VehicleStatusUpdate } from './VehicleStatus-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "거래처";
const mainRole = "현황";
const subsTitle = "차량";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                        // 페이지 메뉴 구분
const code = "A22";                                        // 페이지 메뉴 코드
const mainKey = 'clie_code';                               // 메인 데이터 키
const subsKey = ['clie_code', 'cars_numb'];                // 서브 데이터 키
const deleKey = (data) => {                                // 삭제 데이터 키
    return { 
        clieCode: data.clie_code, 
        carsNumb: data.cars_numb 
    }
};                                                          

const mainSearchApi = 'privat/com/clie/search';                // 메인 조회 api
const subsSearchApi = 'privat/com/clie/vehicle/search';        // 서브 조회 api
const subsTargetApi = 'privat/com/clie/vehicle/target';        // 서브 타겟 api
const subsInsertApi = 'privat/com/clie/vehicle/insert';        // 서브 등록 api
const subsUpdateApi = 'privat/com/clie/vehicle/update';        // 서브 수정 api
const subsDeleteApi = 'privat/com/clie/vehicle/delete';        // 서브 삭제 api

const exportFormats = ['xlsx', 'pdf'];
const enabStat = ['사용중', '미사용'];

function ClientVehicleStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [clieName, setClieName] = useState("");    
    const [enabYesn, setEnabYesn] = useState("사용중");    
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const enabYesnChange = useCallback((e) => { setEnabYesn(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { clieName: clieName, enabYesn: enabYesn === "사용중" ? true : false };
        await fwUtil.searchData(sendData, mainSearchApi, mainGridDataUpdate);
    }, [clieName, enabYesn, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedRowKey(mainGridRef);
        if (keyData) {
            const sendData = { clieCode: keyData };
            await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);
    
    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = subsGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.deleteData(subsGridRef, subsDeleteApi, deleKey, subsHandleSearch);
        }
        deleteAlertHide();
    }, [subsGridRef, subsHandleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: " 거래처명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {ToolbarSelcBox({ caption: " 사용여부", value: enabYesn, valueChange: enabYesnChange, search: mainHandleSearch, items: enabStat })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const subsHandleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        if (mainSelectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
            if (subsSelectedRowKey) {
                if (act === 'upd') {
                    setUpdatePopupVisible(true);
                    return;
                }
                if (act === 'del') {
                    setDeleteAlertVisible(true);
                    return;
                }                
            } else {
                notify('차량을 선택 후 시도해주세요.', 'warning', 2000);
            }
        } else {
            notify('거래처를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [mainGridRef, subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                toolbar={{ default: false, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명(한글)" })}
                {CommCol({ dataField: "addr_knam", caption: "주소(한글)" })}  
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "업체구분" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}
                {CommCol({ dataField: "upta_name", caption: "업태" })}
                {CommCol({ dataField: "jong_moks", caption: "종목" })}                                                         
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                ins={{ onClick: () => subsHandleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => subsHandleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => subsHandleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "cars_numb", caption: "차량번호" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드(제품번호)" })}
                {CommCol({ dataField: "matl_name", caption: "자재명(제품명)" })}
                {DeciCol({ dataField: "aver_qtys", caption: "평균입고수량(예상수량)" })}
                {DeciCol({ dataField: "aver_weig", caption: "평균입고중량(예상중량)" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "업체구분" })}
                {CommCol({ dataField: "zips_code", caption: "우편번호" })}
                {CommCol({ dataField: "addr_knam", caption: "주소" })}
            </SubsGrid>         
            <VehicleStatusInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                insertApi={subsInsertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <VehicleStatusUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                targetApi={subsTargetApi}
                updateApi={subsUpdateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 차량 정보를 삭제하시겠습니까?'}
            />                 
        </div>
    );
};


export default ClientVehicleStatus;