// WorkOrderRegist - 작업지시 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { getWorkOrderDateGubu as getDate } from '../../../../../api/set';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { WorkOrderRegistInsert } from './Regist-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "작업지시";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "D22";                                         // 페이지 메뉴 코드
const mainKey = 'plan_numb';                                // 데이터 키   

const searchApi = 'privat/mfg/work-order/regist/search';     // 조회 api
const insertApi = 'privat/mfg/work-order/regist/insert';     // 등록 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "plan_numb": "P123456",
        "orde_numb": "O987654",
        "clie_code": "C001",
        "clie_name": "ABC Corporation",
        "proj_code": "PRJ001",
        "proj_name": "Project Alpha",
        "comp_date": "2024-05-01",
        "deli_date": "2024-06-15",
        "clie_orde": "ORD12345",
        "cite_code": "ITM001",
        "cite_name": "Item A",
        "orde_qtys": 100,
        "matl_date": "2024-04-20",
        "pain_date": "2024-04-25",
        "irpt_date": "2024-04-30",
        "ship_date": "2024-06-10",
        "plan_memo": "First batch delivery"
    },
    {
        "plan_numb": "P123457",
        "orde_numb": "O987655",
        "clie_code": "C002",
        "clie_name": "XYZ Industries",
        "proj_code": "PRJ002",
        "proj_name": "Project Beta",
        "comp_date": "2024-05-10",
        "deli_date": "2024-06-20",
        "clie_orde": "ORD12346",
        "cite_code": "ITM002",
        "cite_name": "Item B",
        "orde_qtys": 200,
        "matl_date": "2024-04-22",
        "pain_date": "2024-04-28",
        "irpt_date": "2024-05-02",
        "ship_date": "2024-06-15",
        "plan_memo": "Urgent order"
    },
    {
        "plan_numb": "P123458",
        "orde_numb": "O987656",
        "clie_code": "C003",
        "clie_name": "LMN Enterprises",
        "proj_code": "PRJ003",
        "proj_name": "Project Gamma",
        "comp_date": "2024-05-15",
        "deli_date": "2024-06-25",
        "clie_orde": "ORD12347",
        "cite_code": "ITM003",
        "cite_name": "Item C",
        "orde_qtys": 150,
        "matl_date": "2024-04-25",
        "pain_date": "2024-05-01",
        "irpt_date": "2024-05-05",
        "ship_date": "2024-06-20",
        "plan_memo": "Second batch delivery"
    }
]

function WorkOrderRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [dateGubu, setDateGubu] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const [dateList, setDateList] = useState([]);
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const dateListChange = useCallback((e) => { setDateList(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [dateResult] = await Promise.all([getDate()]);

                if (dateResult && dateResult.length > 0) {
                    dateListChange(dateResult);
                    dateGubuChange(dateResult[0]);
                }                                                 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [dateListChange, dateGubuChange]);

    // 조회
    const handleSearch = useCallback(async () => {
        if (dateGubu) {
            const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, dateGubu: dateGubu, itemName: itemName, clieName: clieName };        
            // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
            gridDataUpdate(tempData);
        }
    }, [dateValue, duraYesn, dateGubu, itemName, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, dateGubu]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = useCallback (() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "일자유형", value: dateGubu, valueChange: dateGubuChange, search: handleSearch, items: dateList })}
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    }, [dateGubu, dateGubuChange, itemName, itemNameChange, clieName, clieNameChange, handleSearch, dateList]);

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 등록 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "plan_numb", caption: "계획번호" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "proj_code", caption: "프로젝트코드" })}
                {CommCol({ dataField: "proj_name", caption: "프로젝트명" })}
                {DateCol({ dataField: "comp_date", caption: "생산완료일" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                {CommCol({ dataField: "clie_orde", caption: "오더번호" })}
                {CommCol({ dataField: "cite_code", caption: "품목번호" })}
                {CommCol({ dataField: "cite_name", caption: "품명" })}
                {DeciCol({ dataField: "work_qtys", caption: "작업수량" })}
                {DateCol({ dataField: "matl_date", caption: "소재입고일" })}
                {DateCol({ dataField: "pain_date", caption: "피막완료일" })}
                {DateCol({ dataField: "irpt_date", caption: "성적서작성일" })}
                {DateCol({ dataField: "ship_date", caption: "납품일" })}
                {CommCol({ dataField: "plan_memo", caption: "비고" })}
            </PagingGrid>        
            <WorkOrderRegistInsert
                mainGrid={gridRef}
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />                 
        </div>
    );
};


export default WorkOrderRegist;