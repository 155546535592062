// InfeReportStatus - 성적서 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "성적서";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "D12";                                         // 페이지 메뉴 코드
const mainKey = 'orde_numb';                                // 데이터 키                                                                                    

const searchApi = 'privat/qcl/report/status/search';     // 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        orde_numb: "123456",
        repo_gubu: "보고구분1",
        clie_code: "C001",
        clie_name: "고객명1",
        cite_name: "제품명1",
        cite_spec: "규격1",
        insp_date: "2024-06-15",
        file_qtys: 3,
        indx_keys: "파일키1",
        repo_memo: "비고1"
    },
    {
        orde_numb: "789012",
        repo_gubu: "보고구분2",
        clie_code: "C002",
        clie_name: "고객명2",
        cite_name: "제품명2",
        cite_spec: "규격2",
        insp_date: "2024-06-20",
        file_qtys: 1,
        indx_keys: "파일키2",
        repo_memo: "비고2"
    }
];  

function InfeReportStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);  

    // 조회
    const handleSearch = useCallback(async () => {

        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, itemName: itemName, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);

    }, [dateValue, duraYesn, itemName, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "cite_name", caption: "제품명" })}
                {CommCol({ dataField: "cite_spec", caption: "규격" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {CommCol({ dataField: "file_qtys", caption: "첨부파일수" })}
                {CommCol({ dataField: "indx_keys", caption: "파일키" })}
                {CommCol({ dataField: "repo_memo", caption: "비고" })}
            </PagingGrid>        

        </div>
    );
};


export default InfeReportStatus;