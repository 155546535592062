// InfeReportRegist - 성적서 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { getInfeReportClosGubu as getSele } from '../../../../../api/set';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { InfeReportRegistInsert } from './Regist-insert';
import { InfeReportRegistUpdate } from './Regist-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "성적서";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "D12";                                         // 페이지 메뉴 코드
const mainKey = 'orde_numb';                                // 데이터 키   
const deleKey = (data) => {                                 // 삭제 데이터 키
    return { 
        ordeNumb: data.orde_numb,
    }
};                                                                                                  

const searchApi = 'privat/qcl/report/regist/search';     // 조회 api
const insertApi = 'privat/qcl/report/regist/insert';     // 등록 api
const updateApi = 'privat/qcl/report/regist/update';     // 수정 api
const deleteApi = 'privat/qcl/report/regist/delete';     // 삭제 api

const tempData = [
    {
        orde_numb: "123456",
        repo_gubu: "보고구분1",
        clie_code: "C001",
        clie_name: "고객명1",
        cite_name: "제품명1",
        cite_spec: "규격1",
        insp_date: "2024-06-15",
        file_qtys: 3,
        indx_keys: "파일키1",
        repo_memo: "비고1"
    },
    {
        orde_numb: "789012",
        repo_gubu: "보고구분2",
        clie_code: "C002",
        clie_name: "고객명2",
        cite_name: "제품명2",
        cite_spec: "규격2",
        insp_date: "2024-06-20",
        file_qtys: 1,
        indx_keys: "파일키2",
        repo_memo: "비고2"
    }
];  

function InfeReportRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [seleGubu, setSeleGubu] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const [seleList, setSeleList] = useState([]);
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const seleListChange = useCallback((e) => { setSeleList(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [seleResult] = await Promise.all([getSele()]);
                if (seleResult && seleResult.length > 0) {
                    seleListChange(seleResult);
                    seleGubuChange(seleResult[0]);
                }                                                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [seleListChange, seleGubuChange]);

    // 조회
    const handleSearch = useCallback(async () => {
        if (seleGubu) {
            const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, seleGubu: seleGubu, itemName: itemName, clieName: clieName };        
            // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
            gridDataUpdate(tempData);
        }
    }, [dateValue, duraYesn, seleGubu, itemName, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, seleGubu]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "유형", value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: seleList })}
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);    

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'ins') {
                if (seleGubu === "진행") {
                    setInsertPopupVisible(true);
                    return;
                }
                notify('성적서 등록은 진행 중인 항목만 가능합니다.', 'warning', 2000);
            }
            if (act === 'upd') {
                if (seleGubu === "완료") {
                    setUpdatePopupVisible(true);
                    return;
                }
                notify('성적서 수정은 완료된 항목만 가능합니다.', 'warning', 2000);
                return;
            }
            if (act === 'del') {
                if (seleGubu === "완료") {
                    setDeleteAlertVisible(true);
                    return;
                }
                notify('성적서 삭제는 완료된 항목만 가능합니다.', 'warning', 2000);
                return;                

            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef, seleGubu]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "cite_name", caption: "제품명" })}
                {CommCol({ dataField: "cite_spec", caption: "규격" })}
                {DateCol({ dataField: "insp_date", caption: seleGubu === "완료" ? "검사일자" : "납기일자" })}
                {CommCol({ dataField: "file_qtys", caption: "첨부파일수", visible: seleGubu === "완료" })}
                {CommCol({ dataField: "indx_keys", caption: "파일키", visible: seleGubu === "완료" })}
                {CommCol({ dataField: "repo_memo", caption: "비고" })}
            </PagingGrid>        
            <InfeReportRegistInsert
                mainGrid={gridRef}
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <InfeReportRegistUpdate
                mainGrid={gridRef}
                refresh={handleSearch}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 성적서를 삭제하시겠습니까?'}
            />                         
        </div>
    );
};


export default InfeReportRegist;