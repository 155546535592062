import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormMemo, FormNumb, FormText, FormBool } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    work_code: '', 
    enab_yesn: false,
    work_name: '', 
    work_dept: '', 
    mach_kind: '', 
    rout_code: '', 
    rout_name: '', 
    work_pric: 0.00, 
    work_memo: '',    
};

export function WorkerStatusUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedLastRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('작업자 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            const sendData = { workCode: selectedRowKey };
            const res = await fwUtil.targetData(sendData, targetApi);
            const resData = {
                work_code: res.work_code || '', 
                enab_yesn: res.enab_yesn === 1 ? true : false, 
                work_name: res.work_name || '', 
                work_dept: res.work_dept || '', 
                mach_kind: res.mach_kind || '', 
                rout_code: res.rout_code || '', 
                rout_name: res.rout_name || '', 
                work_pric: res.work_pric || 0.00, 
                work_memo: res.work_memo || '',
            };

            dataUpdate(resData);
        }
    }, [visible, hiding, mainGrid, targetApi]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workCode: data.work_code || '', 
            enabYesn: data.enab_yesn || false,
            workName: data.work_name || '', 
            workDept: data.work_dept || '', 
            machKind: data.mach_kind || '', 
            routCode: data.rout_code || '', 
            routName: data.rout_name || '', 
            workPric: data.work_pric || 0.00, 
            workMemo: data.work_memo || '',
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'작업자 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600 }  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormText({ value: data.work_code, onValueChange: updateField('work_code'), label: '작업자코드', required: true, maxLength: 10, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.work_name, onValueChange: updateField('work_name'), label: '작업자명', required: true, maxLength: 20 })}
                {FormText({ value: data.work_dept, onValueChange: updateField('work_dept'), label: '작업부서', required: true, maxLength: 20 })}
                {FormText({ value: data.mach_kind, onValueChange: updateField('mach_kind'), label: '기종' })}
                {FormNumb({ value: data.work_pric, onValueChange: updateField('work_pric'), label: '작업단가', length: [12, 2] })}
                {FormText({ value: data.rout_code, onValueChange: updateField('rout_code'), label: '공정코드', maxLength: 10 })}
                {FormText({ value: data.rout_name, onValueChange: updateField('rout_name'), label: '공정명', maxLength: 20 })}                
            </GroupItem>
            {FormMemo({ value: data.work_memo, onValueChange: updateField('work_memo'), label: '비고' })}
        </PopupForm>
    );
}