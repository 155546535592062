import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    allo_cont: '',
    allo_yesn: false,
    allo_path: '',
};

export function UriStatusInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            alloCont: 0,
            alloYesn: data.allo_yesn || false,
            alloPath: data.allo_path || ''
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'URI 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormText({ value: data.allo_cont, onValueChange: updateField('allo_cont'), label: '일련번호', disabled: true })}                
                {FormBool({ value: data.allo_yesn, onValueChange: updateField('allo_yesn'), label: '사용여부' })}                            
            </GroupItem>
            {FormText({ value: data.allo_path, onValueChange: updateField('allo_path'), label: '허용 URI', required: true })}    
        </PopupForm>        
    );
}