import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { getCaliEqui as getEqui, getCaliClie as getClie } from '../../../../../api/set';
import { FormDate, FormGrid, FormMemo, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    main_numb: '',
    equi_numb: '',
    equi_name: '',
    cali_seri: '',
    clie_code: '',
    clie_name: '',
    cali_numb: '',
    cali_date: fwUtil.getToDayText(),
    cali_ndat: fwUtil.getToDayText(),
    cali_desc: '',
    cali_memo: '',
}

const tempRes = {
    main_numb: '123456',
    equi_numb: 'EQ-78910',
    equi_name: 'Hydraulic Press',
    cali_seri: '001',
    clie_code: 'CL-456',
    clie_name: 'Client Corp',
    cali_numb: 'CAL-2023-001',
    cali_date: fwUtil.getToDayText(),  // Assuming today's date is '2023-05-25'
    cali_ndat: fwUtil.getToDayText(),  // Assuming next calibration date is '2024-05-25'
    cali_desc: 'Calibration of pressure gauge',
    cali_memo: 'All readings within acceptable range'
};


const tempEqui = [
    { main_numb: "MQ001", equi_numb: "EQ001", equi_name: "설비 1" },
    { main_numb: "MQ002", equi_numb: "EQ002", equi_name: "설비 2" },
    { main_numb: "MQ003", equi_numb: "EQ003", equi_name: "설비 3" }
];

const tempClie = [
    { clie_code: "C001", clie_name: "교정기관 1" },
    { clie_code: "C002", clie_name: "교정기관 2" },
    { clie_code: "C003", clie_name: "교정기관 3" }
];

export function CalibRegistUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 설비 그리드
    const equiGridRef = useRef(null);
    const [equiData, setEquiData] = useState([]);
    const equiGridUpdate = useCallback((e) => setEquiData(e), []);
    const equiKeyExpr = 'main_numb';
    // 업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';    
    // 그리드 팝업
    const [equiPopupVisible, setEquiPopupVisible] = useState(false);
    const equiPopupHiding = useCallback(() => setEquiPopupVisible(false), []);
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 설비 조회
    const equiHandleSearch = useCallback(async() => {
        const sendData = { equiNumb: "", equiName: "" };
        const res = await getEqui(sendData);
        //equiGridUpdate(res);
        equiGridUpdate(tempEqui);
    }, [equiGridUpdate]);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await getClie(sendData);
        //clieGridUpdate(res);
        clieGridUpdate(tempClie);
    }, [clieGridUpdate]);    

    // 설비 팝업 열릴 때
    const equiPopupOnShowing = useCallback(() => {
        equiHandleSearch();
    }, [equiHandleSearch]);

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 설비 팝업 더블 클릭
    const equiOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, equi_numb: e.data.equi_numb, equi_name: e.data.equi_name });
        equiPopupHiding();
    }, [data, dataUpdate, equiPopupHiding]);

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);    

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedLastRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('검교정 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            const sendData = { mainNumb: selectedRowKey.main_numb, caliSeri: selectedRowKey.main_seri };
            //const res = await fwUtil.targetData(sendData, targetApi);
            const res = tempRes;
            const resData = {
                main_numb: res.main_numb || '',
                equi_numb: res.equi_numb || '',
                equi_name: res.equi_name || '',
                cali_seri: res.cali_seri || '',
                clie_code: res.clie_code || '',
                clie_name: res.clie_name || '',
                cali_numb: res.cali_numb || '',
                cali_date: res.cali_date || fwUtil.getToDayText(),
                cali_ndat: res.cali_ndat || fwUtil.getToDayText(),
                cali_desc: res.cali_desc || '',
                cali_memo: res.cali_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainNumb: data.main_numb || '',
            equiNumb: data.equi_numb || '',
            equiName: data.equi_name || '',
            caliSeri: data.cali_seri || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            caliNumb: data.cali_numb || '',
            caliDate: data.cali_date || fwUtil.getToDayText(),
            caliNdat: data.cali_ndat || fwUtil.getToDayText(),
            caliDesc: data.cali_desc || '',
            caliMemo: data.cali_memo || '',
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        onHiding();
    }, [data, updateApi, refresh, onHiding]);

    return (
        <>
        <PopupGrid
            title={'설비번호 선택'}   
            type={'선택'}
            visible={equiPopupVisible}  
            showing={equiPopupOnShowing}  
            hiding={equiPopupHiding}
        >   
            <ScrollGrid
                title={'설비'}
                role={'목록'}
                gridDataSource={equiData}
                gridRef={equiGridRef}
                mainKey={equiKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={equiOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupGrid
            title={'교정기관 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            showing={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'교정기관'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={clieOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "기관코드" })}
                {CommCol({ dataField: "clie_name", caption: "기관명" })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'검교정 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormGrid({ value: data.equi_numb, onClick: () => setEquiPopupVisible(true), label: '설비번호', required: true })}
                {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), label: '설비명', isEditing: true })}
                {FormText({ value: data.main_numb, onValueChange: updateField('main_numb'), label: '등록순번', isEditing: true })}
                {FormText({ value: data.cali_seri, onValueChange: updateField('cali_seri'), label: '순번', disabled: true })}
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '교정기관코드', required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '교정기관명', isEditing: true })}
                {FormDate({ value: data.cali_date, onValueChange: updateField('cali_date'), label: '교정일자' })}           
                {FormDate({ value: data.cali_ndat, onValueChange: updateField('cali_ndat'), label: '차기교정일' })}
                <GroupItem colSpan={isXSmall || isSmall ? 1 : 2}>
                    {FormText({ value: data.cali_numb, onValueChange: updateField('cali_numb'), label: '교정번호' })}
                </GroupItem>
            </GroupItem>
            {FormMemo({ value: data.cali_desc, onValueChange: updateField('cali_desc'), label: '교정내용' })}
            {FormMemo({ value: data.cali_memo, onValueChange: updateField('cali_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}