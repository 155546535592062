import { Routes, Route, Navigate } from 'react-router-dom';
import { company } from './focuswin/script/constant-list';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './focuswin/script/components';

export default function Content() {
  return (
      <SideNavBarLayout title={company.name}>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={element}
            />
          ))}
          <Route
            path='*'
            element={<Navigate to='/home' />}
          />
        </Routes>
      </SideNavBarLayout>
  );
}

