// ProcloseCancle - 매입마감 취소
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as CancleAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "매입마감";
const role = "취소";
const gridSize = 10;

const gubu = "mes";                                            // 페이지 메뉴 구분
const code = "C63";                                            // 페이지 메뉴 코드
const mainKey = 'proc_numb';                                   // 데이터 키                                                
    
const searchApi = 'privat/mat/proclose/cancle/search';         // 조회 api
const cancleApi = 'privat/mat/proclose/cancle/cancle';         // 취소 api

const tempData = [
    {
        proc_numb: "P001",
        proc_date: "2024-05-20",
        clie_code: "C001",
        clie_name: "ABC Company",
        ware_date: "2024-05-22",
        matl_code: "M001",
        matl_name: "Material X",
        matl_spec: "Spec 1",
        ware_unit: "EA",
        ware_qtys: 100,
        ware_pric: 10.25,
        ware_kwon: 1025,
        ware_loca: "Warehouse A",
        ware_numb: "W001",
        work_numb: "W001",
        proc_memo: "Received from supplier XYZ"
    },
    {
        proc_numb: "P002",
        proc_date: "2024-05-21",
        clie_code: "C002",
        clie_name: "XYZ Corporation",
        ware_date: "2024-05-23",
        matl_code: "M002",
        matl_name: "Material Y",
        matl_spec: "Spec 2",
        ware_unit: "KG",
        ware_qtys: 75,
        ware_pric: 8.5,
        ware_kwon: 637.5,
        ware_loca: "Warehouse B",
        ware_numb: "W002",
        work_numb: "W002",
        proc_memo: "Delivered by courier Z"
    },
];

function ProcloseCancle() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 취소 알림창
    const [cancleAlertVisible, setCancleAlertVisible] = useState(false);
    const cancleAlertHide = useCallback(() => { setCancleAlertVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 취소 경고 확인 클릭
    const onCancleClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            wareNumb: item.ware_numb,
            prosNumb: item.pros_numb,
            matlCode: item.matl_code,
            lotsNumb: item.lots_numb,
            wareQtys: item.ware_qtys,
            warePric: item.ware_pric,
            wareDate: item.ware_date,
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.submitData(postData, cancleApi, handleSearch);
        gridRef.current.instance.endCustomLoading();
        cancleAlertHide();
    }, [gridRef, handleSearch, cancleAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "고객사명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };
    const toolbarAfterItems = () => {
        return (
            <Item location='after' locateInMenu='auto'>
                {ToolbarButton({ icon: 'trash', text: '취소', hidden: false, disabled: authList.auth_dele === 0, onClick: handleCancleClick })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 취소 버튼 클릭
    const handleCancleClick = useCallback(async () => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);
        if (selectedRowKey) {
            setCancleAlertVisible(true);
            return;
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);    

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}             
                toolbar={{ default: true, before: toolbarBeforeItems, after: toolbarAfterItems } }
                selection={'multiple'}
            >
                {CommCol({ dataField: "proc_numb", caption: "매입마감번호" })}
                {DateCol({ dataField: "proc_date", caption: "확정일자" })}
                {CommCol({ dataField: "clie_code", caption: "업체번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {DateCol({ dataField: "ware_date", caption: "입고일자" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "ware_unit", caption: "구매단위" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "ware_pric", caption: "입고단가" })}
                {DeciCol({ dataField: "ware_kwon", caption: "입고금액" })}
                {CommCol({ dataField: "ware_loca", caption: "입고처" })}
                {CommCol({ dataField: "ware_numb", caption: "입고번호" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "proc_memo", caption: "비고" })}
            </PagingGrid>        
            <CancleAlert 
                grid={gridRef}
                visible={cancleAlertVisible}
                hiding={cancleAlertHide}
                confirm={onCancleClick}
                message={'해당 매입마감를 취소하시겠습니까?'}
            />               
        </div>
    );
};


export default ProcloseCancle;