import React, { useState } from "react";
import { useTheme } from "../../contexts/theme";

export default function ColorChanger() {
    const { colors, applyColor, getAccent } = useTheme();
    const [activePalette, setActivePalette] = useState(getAccent());

    function handleChangeThemeColor(color) {
        setActivePalette(color);
        applyColor(color);
    };

    return (
        <>
            {colors.map((item, index) => (
                <div
                    key={index} 
                    className={item.value === activePalette? 'active color-palette' : 'color-palette'}
                    onClick={() => handleChangeThemeColor(item.value)}
                    title={item.text}
                    style={{
                        backgroundColor: item.color
                    }}
                >
                </div>
            ))}
        </>
    )
}

