import { Button, SelectBox, TextBox, Tooltip } from "devextreme-react"

const ToolbarButton = (props) => {
    const {
        icon, text, 
        hidden, disabled, 
        onClick
    } = props;

    const buttonId = `fw-${text}-button-wrapper`;

    return (
        <div id={buttonId} className={'fw-tooltip-button-wrapper'}>
            <Button
                icon={icon}
                text={text}
                type='default'
                stylingMode='contained'
                visible={!hidden}
                disabled={disabled}
                onClick={onClick}
                elementAttr={{ title: text }}
            />
            {disabled && (
                <Tooltip
                    target={`#${buttonId}`}
                    position={'top'}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                >
                    <span>{text} 권한이 없습니다.</span>
                </Tooltip>
            )}
        </div>
    )
};

const SearchButton = (props) => {
    const {
        search
    } = props;

    return (
        <ToolbarButton
            icon="search"
            text="조회"
            onClick={search}
        />
    )
}

const ToolbarTextBox = (props) => {
    const {
        caption, width, value, valueChange, search
    } = props;

    return (
        <TextBox
            placeholder={caption}
            width={width}
            value={value}
            onValueChange={valueChange}
            onEnterKey={search}
        />
    )
}

const ToolbarSelcBox = (props) => {
    const {
        caption, items, width, value, valueChange, search
    } = props;

    return (
        <SelectBox
            placeholder={caption}
            items={items}
            width={width}
            value={value}
            onValueChange={valueChange}
            onEnterKey={search}
        />
    )
}

export { 
    ToolbarButton,
    SearchButton,
    ToolbarTextBox,
    ToolbarSelcBox
};