import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import notify from 'devextreme/ui/notify';

const defaultData = {
    cata_code: '',
    cata_name: '',
    catb_code: '',
    catb_name: '',
    catc_code: '',
    catc_name: '',
    catd_code: '',
    catd_name: '',
    puro_unit: '',
    stoc_unit: '',
    safe_qtys: '',
    enab_yesn: true,
    regi_date: fwUtil.getToDayText(),
    unit_weig: '',
    qtys_weig: '',
    matl_memo: '',
};

const cataSearchApi = '/privat/sys/set/matlcode/cata/list';
const catbSearchApi = '/privat/sys/set/matlcode/catb/list';
const catcSearchApi = '/privat/sys/set/matlcode/catc/list';
const catdSearchApi = '/privat/sys/set/matlcode/catd/list';

export function MatlCreateInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 분류 그리드
    const cateKeyExpr = 'cate_code';
    const cateGridRef = useRef(null);
    const [cateData, setCateData] = useState([]);
    const cateGridUpdate = useCallback((e) => setCateData(e), []);
    // 분류 그리드 팝업
    const [popupLoading, setPopupLoading] = useState(false);
    const [catePopupType, setCatePopupType] = useState('');
    const [catePopupVisible, setCatePopupVisible] = useState(false);
    const catePopupHiding = useCallback(() => setCatePopupVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            cataCode: data.cata_code || '',
            cataName: data.cata_name || '',
            catbCode: data.catb_code ? data.catb_code.slice(-2) : '',
            catbName: data.catb_name || '',
            catcCode: data.catc_code ? data.catc_code.slice(-3) : '',
            catcName: data.catc_name || '',
            catdCode: data.catd_code || '',
            catdName: data.catd_name || '',
            matlCode: `${data.catc_code || '---'}${data.catd_code || '--'}`,
            matlName: `${data.catb_name || '----'} ${data.catc_name || '----'} ${data.catd_name || '----'}`,
            puroUnit: data.puro_unit || '',
            stocUnit: data.stoc_unit || '',
            safeQtys: data.safe_qtys || 0.000,
            unitWeig: data.unit_weig || 0.000,
            qtysWeig: data.qtys_weig || 0.000,
            enabYesn: data.enab_yesn || false,
            regiDate: data.regi_date || null,
            matlMemo: data.matl_memo || ''
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    // 분류 조회
    const cateHandleSearch = useCallback(async () => {
        setPopupLoading(true);
        let sendData;
        let api;
        switch (catePopupType) {
            case '대분류':
                sendData = { cateName: "" };
                api = cataSearchApi;
                break;
            case '중분류':
                sendData = { cataCode: data.cata_code };
                api = catbSearchApi;
                break;
            case '소분류':
                sendData = { catbCode: data.catb_code };
                api = catcSearchApi;
                break;
            case '재질':
                sendData = { cateName: "" };
                api = catdSearchApi;
                break;
            default:
                break;
        }
    
        if (sendData) {
            await fwUtil.searchData(sendData, api, cateGridUpdate);
        }
        setPopupLoading(false);
    }, [catePopupType, data]);

    // 분류 팝업 오픈
    const onGridPopupClick = useCallback((type) => {
        setCatePopupType(type);
        setCatePopupVisible(true);
    }, [setCatePopupType, setCatePopupVisible]);

    // 분류 팝업 열릴 때
    const catePopupOnShowing = useCallback(() => {
        cateHandleSearch(catePopupType);
    }, [cateHandleSearch, catePopupType]);

    // 분류 팝업 더블 클릭
    const cateOnRowDblClick = useCallback((e) => {
        let cateType;
        switch (catePopupType) {
            case '대분류':
                cateType = 'cata';
                break;
            case '중분류':
                cateType = 'catb';
                break;
            case '소분류':
                cateType = 'catc';
                break;
            case '재질':
                cateType = 'catd';
                break;
            default:
                notify("분류 유형이 올바르게 지정되지 않았습니다", 'error', 2000);
                break;
        }
        
        if (cateType) {
            dataUpdate({ ...data, [`${cateType}_code`]: e.data.cate_code, [`${cateType}_name`]: e.data.cate_name });
        } else {
            notify("데이터 입력 중 오류가 발생했습니다", 'error', 2000);
        }

        catePopupHiding();
    }, [data, dataUpdate, catePopupHiding, catePopupType]);

    return (
        <>
        <PopupGrid
            title={`${catePopupType} 선택`}   
            type={'선택'}
            loading={popupLoading}
            visible={catePopupVisible}  
            showing={catePopupOnShowing}  
            hiding={catePopupHiding}
        >   
            <ScrollGrid
                gridDataSource={cateData}
                gridRef={cateGridRef}
                mainKey={cateKeyExpr}
                width={isXSmall || isSmall ? 300 : 600 }
                height={450}
                onRowDblClick={cateOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "cate_code", caption: `${catePopupType}코드`, width: 100 })}
                {CommCol({ dataField: "cate_name", caption: `${catePopupType}명` })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupForm
            title={'자재 생성'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormGrid({ value: data.cata_code, onClick: () => onGridPopupClick('대분류'), label: '대분류코드', required: true })}
                {FormText({ value: data.cata_name, onValueChange: updateField('cata_name'), label: '대분류명', isEditing: true })}
                {FormGrid({ value: data.catb_code, onClick: () => onGridPopupClick('중분류'), label: '중분류코드', required: true })}
                {FormText({ value: data.catb_name, onValueChange: updateField('catb_name'), label: '중분류명', isEditing: true })}
                {FormGrid({ value: data.catc_code, onClick: () => onGridPopupClick('소분류'), label: '소분류코드', required: true })}
                {FormText({ value: data.catc_name, onValueChange: updateField('catc_name'), label: '소분류명', isEditing: true })}
                {FormGrid({ value: data.catd_code, onClick: () => onGridPopupClick('재질'), label: '재질코드', required: true })}
                {FormText({ value: data.catd_name, onValueChange: updateField('catd_name'), label: '재질명', isEditing: true })}                                                
                {FormText({ value: data.puro_unit, onValueChange: updateField('puro_unit'), label: '구매단위', required: true })}
                {FormText({ value: data.stoc_unit, onValueChange: updateField('stoc_unit'), label: '저장단위', required: true })}
                {FormNumb({ value: data.unit_weig, onValueChange: updateField('unit_weig'), label: '단위중량(m당)', length: [15, 3] })}
                {FormNumb({ value: data.qtys_weig, onValueChange: updateField('qtys_weig'), label: '단위중량(개당)', length: [15, 3] })}
                {FormNumb({ value: data.safe_qtys, onValueChange: updateField('safe_qtys'), label: '안전재고', length: [15, 3] })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자', required: true })}                
            </GroupItem>
            {FormMemo({ value: data.matl_memo, onValueChange: updateField('matl_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}