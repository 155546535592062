import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { getBomMatl as getMatl, getBomMatlGubu as getMatlGubu } from '../../../../../api/set';
import { FormBool, FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { Item } from 'devextreme-react/cjs/data-grid';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, BtnsCol, DeciCol, SelcCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    item_code: '',
    item_name: '',
    boms_qtys: 1.000,
    regi_date: fwUtil.getToDayText(),
    item_gubu: '',
    enab_yesn: false,
    boms_memo: '',
};

const matlDataSource = fwUtil.generateDataSource();

export function BomRegistUpdate(props) {
    const {
        mainRefresh, subsRefresh,
        mainGrid,
        mainTargetApi, subsTargetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 구성품목 그리드
    const matlRef = useRef(null);
    const [matlGridKey, setMatlGridKey] = useState('');
    const matlGridKeyChange = useCallback((e) => setMatlGridKey(e), []);
    // 팝업 그리드
    const matlGridRef = useRef(null);
    const [matlData, setMatlData] = useState([]);
    const matlGridUpdate = useCallback((e) => setMatlData(e), []);
    const matlKeyExpr = 'item_code';
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const [matlGubu, setMatlGubu] = useState('');
    const [matlGubuList, setMatlGubuList] = useState([]);
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const matlGubuChange = useCallback((e) => setMatlGubu(e), []);
    const matlGubuListChange = useCallback((e) => setMatlGubuList(e), []);
    // 그리드 팝업
    const [matlPopupVisible, setMatlPopupVisible] = useState(false);
    const matlPopupHiding = useCallback(() => setMatlPopupVisible(false), []);
    // 그리드 추가 툴바 아이템
    const matlToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품구분", value: matlCode, valueChange: matlCodeChange, search: matlHandleSearch })}
                {ToolbarTextBox({ caption: "구성품번", value: matlName, valueChange: matlNameChange, search: matlHandleSearch })}
                {ToolbarSelcBox({ caption: "구성품명", value: matlGubu, valueChange: matlGubuChange, search: matlHandleSearch, items: matlGubuList })}
                {SearchButton({ search: matlHandleSearch })}
            </Item>
        )
    };

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedLastRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('제품 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            matlDataSource.store().insert({ init: true }).then(() => matlDataSource.reload());
            const sendData = { itemCode: selectedRowKey };
            const itemRes = await fwUtil.targetData(sendData, mainTargetApi);
            const matlRes = await fwUtil.targetData(sendData, subsTargetApi);
            const resData = {
                item_code: itemRes.item_code || '',
                item_name: itemRes.item_name || '',
                item_gubu: itemRes.item_gubu || '',
                enab_yesn: itemRes.enab_yesn || false,
                boms_qtys: itemRes.boms_qtys || '',
                regi_date: itemRes.regi_date || fwUtil.getToDayText(),
                boms_memo: itemRes.boms_memo || '',
            };

            for (const matl of matlRes) {
                const newData = {
                    matl_code: matl.matl_code || '',
                    matl_name: matl.matl_name || '',
                    matl_gubu: matl.matl_gubu || '',
                    boms_qtys: matl.boms_qtys || 0.000,
                    matl_memo: matl.matl_memo || '',
                };
                matlDataSource.store().insert(newData);
            }
            
            matlDataSource.reload();
            dataUpdate(resData);
        }
    }, [visible, hiding, mainGrid, mainTargetApi, subsTargetApi]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [matlGubuResult] = await Promise.all([getMatlGubu()]);
                matlGubuListChange(matlGubuResult);
                matlGubuChange(matlGubuResult[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [matlGubuListChange]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const matlData = matlRef?.current?.instance?.getDataSource()?.items();
        const postMatlData = matlData.map((item) => ({
            itemCode: data.item_code || "",
            matlCode: item.matl_code || "",
            matlName: item.matl_name || "",
            matlGubu: item.matl_gubu || "원자재",
            bomsQtys: item.boms_qtys || 0.000,
            regiDate: data.regi_date || fwUtil.getToDayText(),
            matlMemo: item.metl_memo || "",
        }));        
        const postData = {
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            itemGubu: data.item_gubu || '',
            bomsQtys: data.boms_qtys || 1,
            regiDate: data.regi_date || fwUtil.getToDayText(),
            enabYesn: data.enab_yesn || false,
            bomsMemo: data.boms_memo || '',
            setaMaps: postMatlData || [],
        };

        await fwUtil.submitData(postData, updateApi, mainRefresh);
        subsRefresh();
        setLoading(false);
        hiding();
    }, [data, updateApi, mainRefresh, subsRefresh, hiding]);

    // 구성 품목 설정 클릭
    const onMatlGridSearchClick = useCallback((e) => {
        matlGridKeyChange(e.row.data.rowKey);
        setMatlPopupVisible(true);
    }, [matlGridKeyChange, setMatlPopupVisible]);

    // 팝업 조회
    const matlHandleSearch = useCallback(async() => {
        const sendData = { itemCode: matlCode, itemName: matlName, itemGubu: matlGubu };
        const res = await getMatl(sendData);
        matlGridUpdate(res);
    }, [matlCode, matlName, matlGubu, matlGridUpdate]);

    useEffect(() => {
        matlGubu && matlHandleSearch();
    }, [matlGubu, matlHandleSearch]);

    // 팝업 열릴 때
    const matlPopupOnShowing = useCallback(() => {
        matlHandleSearch();
    }, [matlHandleSearch]);

    // 팝업 더블 클릭
    const matlOnRowDblClick = useCallback((e) => {
        const gridItemCode = { matl_code: e.data.item_code };
        const gridItemName = { matl_name: e.data.item_name };
        const gridItemGubu = { matl_gubu: e.data.item_gubu };
        matlDataSource.store().update(matlGridKey, gridItemCode);
        matlDataSource.store().update(matlGridKey, gridItemName);
        matlDataSource.store().update(matlGridKey, gridItemGubu);

        matlDataSource.reload();
        matlPopupHiding();
    }, [data, dataUpdate, matlPopupHiding, matlDataSource, matlGridKey]);

    const matlGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'구성품목'}
                role={'목록'}
                gridDataSource={matlData}
                gridRef={matlGridRef}
                mainKey={matlKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={matlOnRowDblClick}
                toolbar={{ default: false, before: matlToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "구성품번" })}
                {CommCol({ dataField: "item_name", caption: "구성품명" })}
                {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
            </ScrollGrid>            
        )
    }, [matlData, matlGridRef, matlKeyExpr, matlOnRowDblClick, matlToolbarBeforeItems]);

    return (
        <>
        <PopupGrid
            title={'구성품목 등록'}   
            visible={matlPopupVisible}  
            showing={matlPopupOnShowing}  
            hiding={matlPopupHiding}
        >   
            {matlGrid}
        </PopupGrid>      
        <PopupForm
            title={'BOM 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'구성품목'}
                        gridRef={matlRef} 
                        gridDataSource={matlDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "matl_code", caption: "구성품번", allowHeaderFiltering: false, allowSorting: false })}
                        {CommCol({ dataField: "matl_name", caption: "구성품명", allowHeaderFiltering: false, allowSorting: false })}
                        {SelcCol({ dataField: "matl_gubu", caption: "품목구분", items: matlGubuList, allowHeaderFiltering: false, allowSorting: false })}
                        {DeciCol({ dataField: "boms_qtys", caption: "구성수량", allowHeaderFiltering: false, allowSorting: false, check: true })}
                        {CommCol({ dataField: "matl_memo", caption: "비고", allowHeaderFiltering: false, allowSorting: false, check: true })}      
                        {BtnsCol({ onClick: onMatlGridSearchClick, allowDelete: true })}     
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', required: true, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용유무' })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '품명/규격', disabled: true })}
                {FormText({ value: data.item_gubu, onValueChange: updateField('item_gubu'), label: '품목구분', disabled: true })}
                {FormNumb({ value: data.boms_qtys, onValueChange: updateField('boms_qtys'), label: '구성수량', disabled: true, length: [15, 3] })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자', required: true })}
            </GroupItem>
            {FormMemo({ value: data.boms_memo, onValueChange: updateField('boms_memo'), label: '비고' })}
        </PopupForm>   
        </>
    );
}