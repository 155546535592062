// ComCodeStatus - 공통코드 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { getComCodeGubu as getGubu } from '../../../../../api/set';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarSelcBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { ComCodeMainInsert } from './Main-insert';
import { ComCodeMainUpdate } from './Main-update';
import { ComCodeSubsInsert } from './Subs-insert';
import { ComCodeSubsUpdate } from './Subs-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "관리코드";
const mainRole = "관리";
const subsTitle = "상세코드";
const subsRole = "관리";
const gridSize = 5;

const gubu = "mes";                                     // 페이지 메뉴 구분
const code = "A1";                                      // 페이지 메뉴 코드
const mainKey = 'main_code';                            // 메인 데이터 키
const subsKey = ['main_code', 'subs_code'];             // 서브 데이터 키
const mainDeleKey = (data) => {                         // 삭제 데이터 키
    return { 
        mainCode: data, 
    }
};
const subsDeleKey = (data) => {                         // 삭제 데이터 키
    return { 
        mainCode: data.main_code, 
        subsCode: data.subs_code 
    }
};

const mainSearchApi = 'privat/com/code/main/search';    // 메인 조회 api
const mainTargetApi = 'privat/com/code/main/target';    // 메인 타겟 api
const mainInsertApi = 'privat/com/code/main/insert';    // 메인 등록 api
const mainUpdateApi = 'privat/com/code/main/update';    // 메인 수정 api
const mainDeleteApi = 'privat/com/code/main/delete';    // 메인 삭제 api
const subsSearchApi = 'privat/com/code/subs/search';    // 서브 조회 api
const subsTargetApi = 'privat/com/code/subs/target';    // 서브 타겟 api
const subsInsertApi = 'privat/com/code/subs/insert';    // 서브 등록 api
const subsUpdateApi = 'privat/com/code/subs/update';    // 서브 수정 api
const subsDeleteApi = 'privat/com/code/subs/delete';    // 서브 삭제 api

const exportFormats = ['xlsx', 'pdf'];

function ComCodeStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [codeGubu, setCodeGubu] = useState("");    
    const [gubuList, setGubuList] = useState([]);    
    const codeGubuChange = useCallback((e) => { setCodeGubu(e) }, []);
    const gubuListChange = useCallback((e) => { setGubuList(e) }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);    
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [gubuResult] = await Promise.all([getGubu()]);
                if (gubuResult && gubuResult.length > 0) {
                    gubuListChange(gubuResult);
                    codeGubuChange(gubuResult[0]);
                }                                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuListChange, codeGubuChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { bizsGubu: codeGubu };
        await fwUtil.searchData(sendData, mainSearchApi, mainGridDataUpdate);
    }, [codeGubu, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { mainCode: keyData };
            await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async (type) => {
        if (type === 'main') {
            const gridInstance = mainGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.deleteData(mainGridRef, mainDeleteApi, mainDeleKey, mainHandleSearch);
            }
            mainDeleteAlertHide();
        } else {
            const gridInstance = subsGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.deleteData(subsGridRef, subsDeleteApi, subsDeleKey, subsHandleSearch);
            }
            subsDeleteAlertHide();
        }

    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, mainDeleteAlertHide, subsDeleteAlertHide]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "사업구분", value: codeGubu, valueChange: codeGubuChange, search: mainHandleSearch, items: gubuList })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };
        
        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                notify('상세코드를 선택 후 시도해주세요.', 'warning', 2000);
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            notify('관리코드를 선택 후 시도해주세요.', 'warning', 2000);
            return;
        }

        if (type === 'main') {
            handleMainAction();
        } else if (type === 'subs') {
            handleSubsAction();
        }

    }, [mainGridRef, subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('main', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: false, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_gubu", caption: "업무분류" })}
                {CommCol({ dataField: "main_code", caption: "관리코드", allowHeaderFiltering: false })}
                {CommCol({ dataField: "main_name", caption: "관리코드명", allowHeaderFiltering: false })}
                {BoolCol({ dataField: "main_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "main_memo", caption: "비고", allowHeaderFiltering: false, allowSorting: false })}
                {CommCol({ dataField: "user_idno", caption: "최초등록자" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_idno", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}                                                        
            </MainGrid>
            <ComCodeMainInsert
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <ComCodeMainUpdate
                refresh={mainHandleSearch}
                mainGrid={mainGridRef}
                targetApi={mainTargetApi}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onDeleteClick('main')}
                message={'해당 관리코드를 삭제하시겠습니까?'}
            />   
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "main_gubu", visible: false })}
                {CommCol({ dataField: "subs_code", caption: "상세코드" })}
                {CommCol({ dataField: "subs_name", caption: "상세코드명" })}
                {BoolCol({ dataField: "subs_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "subs_memo", caption: "비고" })}
                {CommCol({ dataField: "user_idno", caption: "최초등록자" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_idno", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}
            </SubsGrid>         
            <ComCodeSubsInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                targetApi={mainTargetApi}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />
            <ComCodeSubsUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                targetApi={subsTargetApi}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={() => onDeleteClick('subs')}
                message={'해당 상세코드를 삭제하시겠습니까?'}
            />                      
        </div>
    );
};


export default ComCodeStatus;