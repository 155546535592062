import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { exportDataGrid as exportExcel } from 'devextreme/excel_exporter';
import { exportDataGrid as exportPdf } from 'devextreme/pdf_exporter';
import { SearchPopupBtn, SearchPopup, UpdateBtn, DeleteBtn, InsertBtn, AlertPopup as DeleteAlert, DropDownActions, DropDownDownloads } from './grid-header-items';
import { Workbook } from 'exceljs';
import { jsPDF } from 'jspdf';
import * as fowinUtil from '../../../util';
import notify from 'devextreme/ui/notify';
import saveAs from 'file-saver';
import 'jspdf-autotable';
import './grid-header.scss';

function GridHeader(props) {
    const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
    const [searchPopupVisible, setSearchPopupVisible] = useState(false);
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);
    const searchHide = useCallback(() => { setSearchPopupVisible(false); }, []);
    const insertHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    const updateHide = useCallback(() => { setUpdatePopupVisible(false); }, []);
    const deleteHide = useCallback(() => { setDeletePopupVisible(false); }, []);

    const deleteRow = useCallback(async () => {
        try {
            console.log('delete start');
            let keyData;
            const selectedRowKey = fowinUtil.findSelectedRowKey(props.gridInstance);
            if (typeof selectedRowKey === 'object') {
                if (props.delKey) {
                    keyData = props.delKey(selectedRowKey)
                } else {
                    keyData = {};
                    Object.keys(selectedRowKey).forEach(key => {
                        const camelCaseKey = key.replace(/_(\w)/g, (_, letter) => letter.toUpperCase());
                        keyData[camelCaseKey] = selectedRowKey[key];
                    });
                }
            } else {
                keyData = { [props.delKey]: selectedRowKey };
            }
            const axiosInstance = fowinUtil.axiosInstanceCreate();
            const response = await axiosInstance.post(props.api?.delete, keyData);
            fowinUtil.notifyResponse(response.data);
            console.log('delete response === ', response);
        } catch (error) {
            console.log('error === ', error);
        } finally {
            deleteHide();
            props.reSearch();
        }
    });

    function handleRowActionClick(actionType) {
        const rowKey = props.gridInstance?.current?.instance?.getSelectedRowKeys();

        if (actionType === "insert") {
            if (props.relation && props.relation.insert) {
                if (props.relationKey && props.relationKey()) {
                    setInsertPopupVisible(true);
                } else {
                    notify("데이터를 선택 후 시도해주세요", 'warning', 2000);
                }
            } else {
                setInsertPopupVisible(true);
            }
        } else if (actionType === "update") {
            if (props.relation && props.relation.update) {
                if (props.relationKey && props.relationKey()) {
                    setUpdatePopupVisible(true);
                } else {
                    notify("데이터를 선택 후 시도해주세요", 'warning', 2000);
                }
            } else {
                if (fowinUtil.EmptyToNull(rowKey)) {
                    setUpdatePopupVisible(true);
                } else {
                    notify("데이터를 선택 후 시도해주세요", 'warning', 2000);
                }
            }
        } else if (actionType === "delete") {
            if (fowinUtil.EmptyToNull(rowKey)) {
                setDeletePopupVisible(true);
            } else {
                notify("데이터를 선택 후 시도해주세요", 'warning', 2000);
            }
        }
    };

    function createActionProps(actionProps, onClickHandler) {
        return {
            visible: actionProps && !actionProps?.hidden,
            disabled: actionProps?.disabled,
            onClick: onClickHandler
        };
    };
    
    function createExportActionProps(exportProps, exportTo) {
        return {
            visible: exportProps && !exportProps.hidden,
            disabled: exportProps?.disabled,
            onClick: () => exportTo === 'excel' ? exportToExcel(props.gridInstance, props.fileName) : exportToPdf(props.gridInstance, props.fileName),
            exportTo: exportTo
        };
    };

    return (
        <>
            <div className={'grid-header fw-column-flex-box'}>
                <div className={'title'}>{ props.searchPanel? props.title : '' }</div>
                <div className={'grid-action-bar fw-row-flex-box'}>
                    {props.searchPanel && 
                        <div className={'grid-search-form fw-row-flex-box'} style={{ display: isLarge? 'flex' : 'none' }}>
                            {props.searchPanel}
                        </div>
                    }
                    {props.titlePanel &&
                    <div className={'grid-title-panel fw-row-flex-box'}>
                        <span className={'title'}>{props.title}</span>
                        {props.titlePanel}
                    </div> 
                    }
                    <SearchPopupBtn 
                        hidden={isLarge || !props.searchPanel ? true : false}
                        onClick={() => setSearchPopupVisible(true)}
                    />
                    <div className='grid-additional-section'>
                        <InsertBtn 
                            title={props.title}
                            hidden={fowinUtil.ternaryFunction(true, props.insert?.hidden)}
                            visible={isLarge}
                            disabled={props.insert?.disabled}
                            onClick={() => handleRowActionClick('insert')}
                        />
                        <UpdateBtn
                            title={props.title}
                            hidden={fowinUtil.ternaryFunction(true, props.update?.hidden)}
                            visible={isLarge}
                            disabled={props.update?.disabled}
                            onClick={() => handleRowActionClick('update')}
                        />
                        <DeleteBtn
                            title={props.title}
                            hidden={fowinUtil.ternaryFunction(true, props.delete?.hidden)}
                            visible={isLarge}
                            disabled={props.delete?.disabled}
                            onClick={() => handleRowActionClick('delete')}
                        />
                        {props.children}
                        <DropDownDownloads
                            title={props.fileName}
                            hidden={isXSmall || isSmall || isMedium || !props.export || props.export?.hidden ? true : false}
                            disabled={props.export?.disabled}
                            expExc={createExportActionProps(props.export, 'excel')}
                            expPdf={createExportActionProps(props.export, 'pdf')}                   
                        />
                        <DropDownActions
                            title={props.fileName}
                            hidden={isLarge || ['insert', 'update', 'delete', 'export'].every(key => props[key]?.hidden || !props[key])}
                            disabled={['insert', 'update', 'delete', 'export'].every(key => props[key]?.disabled || !props[key])}
                            ins={createActionProps(props.insert, () => setInsertPopupVisible(true))}
                            upd={createActionProps(props.update, () => handleRowActionClick('update'))}
                            del={createActionProps(props.delete, () => handleRowActionClick('delete'))}                         
                            expExc={createExportActionProps(props.export, 'excel')}
                            expPdf={createExportActionProps(props.export, 'pdf')}
                        />
                    </div>
                </div>
                <SearchPopup
                    visible={searchPopupVisible}
                    onHiding={searchHide}
                    isMedium={isMedium}
                    searchPanel={props.searchPanel}
                />
                <DeleteAlert 
                    visible={deletePopupVisible}
                    onHiding={deleteHide}
                    confirm={deleteRow}
                    selectedData={fowinUtil.findSelectedRowKey(props.gridInstance)}
                    message={
                        props.delete?.action ? 
                        `${props.delete?.target}를(을) ${props.delete?.action}하시겠습니까?`  :
                        `${props.delete?.target}를(을) 삭제하시겠습니까?`
                    }
                />            
                {props.insert?.popup({
                    visible: insertPopupVisible,
                    onHiding: insertHide,
                    handleSearch: props.reSearch,
                    relationKey: props.relationKey && props.relationKey(),
                    insertApi: props.api?.insert,
                    gridInstance: props.insert.gridInstance
                })}
                {props.update?.popup({
                    visible: updatePopupVisible,
                    onHiding: updateHide,
                    handleSearch: props.reSearch,
                    selectedRowKey: fowinUtil.findSelectedRowKey(props.gridInstance),
                    relationKey: props.relationKey && props.relationKey(),
                    targetApi: props.api?.target,
                    updateApi: props.api?.update,
                    gridInstance: props.update.gridInstance
                })}
            </div>
        </>
    )
};

export {
    GridHeader
};

const exportToExcel = (gridInstance, fileName) => {
    try {
        if (gridInstance.current) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet(fileName + '_sheet');

            exportExcel({
                component: gridInstance.current.instance,
                worksheet,
                customizeCell: ({ gridCell, excelCell }) => {
                    excelCell.font = { name: '맑은 고딕', size: 12, color: { argb: '#000' } };
                    excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
                    if (gridCell.rowType === 'header' || gridCell.column.dataField === 'rowNumber') {
                        excelCell.font = { name: '맑은 고딕', size: 12, color: { argb: '#000' }, bold: true };
                        excelCell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '#D3DADF' }
                        };
                    }
                },
                autoFilterEnabled: true,
            }).then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName + '.xlsx');
                });
            });
        }
    } catch (error) {
        notify("파일 생성 중 오류가 발생했습니다.", "info", 1000);
    }
};

const exportToPdf = (gridInstance, fileName) => {
    try {
        if (gridInstance.current) {
            const doc = new jsPDF('landscape');
            const fontFilePath = 'fonts/malgun.ttf';
            doc.addFont(fontFilePath, 'malgun', 'normal');
            doc.setFont('malgun', 'normal');
            exportPdf({
                jsPDFDocument: doc,
                component: gridInstance.current.instance,
                indent: 40,
            }).then(() => {
                doc.save(fileName + '.pdf');
            });
        }
    } catch (error) {
        notify("파일 생성 중 오류가 발생했습니다.", "info", 1000);
    }
};

