// EqulStatus - 설비 관리
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { EqulInfoDetailPanel } from './Info-panel';
import { EqulStatusInsert } from './Status-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "설비";
const role = "관리";
const gridSize = 10;

const gubu = "mes";                                        // 페이지 메뉴 구분
const code = "F1";                                         // 페이지 메뉴 코드
const mainKey = 'main_numb';                               // 데이터 키
const deleKey = (data) => {                                // 삭제 데이터 키
    return { mainNumb: data }
}                                                          

const searchApi = 'privat/equ/equl/registration/search';   // 조회 api
const targetApi = 'privat/equ/equl/registration/target';   // 타겟 api
const insertApi = 'privat/equ/equl/registration/insert';   // 관리 api
const updateApi = 'privat/equ/equl/registration/update';   // 수정 api
const deleteApi = 'privat/equ/equl/registration/delete';   // 삭제 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        main_numb: 1,
        equi_numb: "E12345",
        equi_gubu: "A",
        equi_name: "Pump",
        menu_comp: "Company XYZ",
        equi_type: "Model 123",
        equi_seno: "SN12345",
        equi_spec: "1000L/h",
        equi_loca: "Plant 1",
        reci_date: "2023-05-24",
        insp_cycl: "Monthly",
        uses_dept: "Maintenance",
        main_name: "John Doe",
        subs_name: "Jane Smith",
        menu_date: "2022-03-15",
        equi_memo: "Needs regular maintenance"
    },
    {
        main_numb: 2,
        equi_numb: "E67890",
        equi_gubu: "B",
        equi_name: "Compressor",
        menu_comp: "Company ABC",
        equi_type: "Model 456",
        equi_seno: "SN67890",
        equi_spec: "2000L/h",
        equi_loca: "Plant 2",
        reci_date: "2023-06-15",
        insp_cycl: "Quarterly",
        uses_dept: "Operations",
        main_name: "Alice Brown",
        subs_name: "Bob White",
        menu_date: "2022-07-20",
        equi_memo: "Check oil level regularly"
    },
    {
        main_numb: 3,
        equi_numb: "E11223",
        equi_gubu: "C",
        equi_name: "Generator",
        menu_comp: "Company LMN",
        equi_type: "Model 789",
        equi_seno: "SN11223",
        equi_spec: "5000L/h",
        equi_loca: "Plant 3",
        reci_date: "2023-07-30",
        insp_cycl: "Yearly",
        uses_dept: "Production",
        main_name: "Charlie Green",
        subs_name: "Diana Blue",
        menu_date: "2023-01-10",
        equi_memo: "Scheduled for upgrade next year"
    }
];

function EqulStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 키
    const [keyData, setKeyData] = useState('');
    const keyDataChange = useCallback((e) => setKeyData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [equlName, setEqulName] = useState("");    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equlNameChange = useCallback((e) => { setEqulName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 상세 패널
    const [isPanelOpened, setPanelOpened] = useState(false);
    const panelOpendChange = useCallback(() => {
        setPanelOpened(!isPanelOpened);
        gridRef.current?.instance.option('focusedRowIndex', -1);
    }, [isPanelOpened]);
    // 관리 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equlName: equlName };
        //await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, equlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 행 클릭
    const onRowClick = useCallback(({ key }) => {
        keyDataChange(key);
        setPanelOpened(true);
    }, [keyDataChange, setPanelOpened]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: " 설비명", value: equlName, valueChange: equlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const handleDelClick = useCallback(() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);
        if (selectedRowKey) {
            setDeleteAlertVisible(true)
        } else {
            notify("데이터를 선택 후 시도해주세요", "warning", 2000);
        }
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                del={{ onClick: handleDelClick, disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "main_numb", caption: "등록순번" })}
                {CommCol({ dataField: "equi_numb", caption: "관리번호" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "menu_comp", caption: "제작회사" })}
                {CommCol({ dataField: "equi_type", caption: "규격/형식" })}
                {CommCol({ dataField: "equi_seno", caption: "기기번호" })}
                {CommCol({ dataField: "equi_spec", caption: "용량/공칭능력" })}
                {CommCol({ dataField: "equi_loca", caption: "설비장소" })}
                {DateCol({ dataField: "reci_date", caption: "설치일자" })}
                {CommCol({ dataField: "insp_cycl", caption: "점검주기" })}
                {CommCol({ dataField: "uses_dept", caption: "사용부서" })}
                {CommCol({ dataField: "main_name", caption: "담당자(정)" })}
                {CommCol({ dataField: "subs_name", caption: "담당자(부)" })}
                {DateCol({ dataField: "menu_date", caption: "제작일자" })}
                {CommCol({ dataField: "equi_memo", caption: "비고" })}
            </PagingGrid>
            <EqulStatusInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <EqulInfoDetailPanel 
                gridRef={gridRef}
                keyData={keyData}
                refresh={handleSearch}
                tar={{ api: targetApi }}
                upd={{ api: updateApi, disabled: authList.auth_upda === 0 }}
                isPanelOpened={isPanelOpened}
                panelOpendChange={panelOpendChange}
                authList={authList}
            />            
            <DeleteAlert
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 설비를 삭제하시겠습니까?'}
            />                 
        </div>
    );
};


export default EqulStatus;