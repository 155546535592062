// StockAdjustRegist - 재고조정 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { StockAdjustRegistInsert } from './Adjust-regist-insert';
import { StockAdjustRegistUpdate } from './Adjust-regist-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "재고조정";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "C52";                                         // 페이지 메뉴 코드
const mainKey = ['matl_code', 'item_gubu'];                 // 데이터 키                                                   

const searchApi = 'privat/mat/stock/adjust/regist/search';   // 조회 api
const targetApi = 'privat/mat/stock/adjust/regist/target';   // 타겟 api
const insertApi = 'privat/mat/stock/adjust/regist/insert';   // 등록 api
const updateApi = 'privat/mat/stock/adjust/regist/update';   // 수정 api

const exportFormats = ['xlsx', 'pdf'];

const itemGubuList = ["전체", "원자재", "반제품", "제품"];

function StockAdjustRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [itemGubu, setItemGubu] = useState("");
    const [matlCode, setMatlCode] = useState("");
    const [matlName, setMatlName] = useState("");  
    const itemGubuChange = useCallback((e) => { setItemGubu(e) }, []);    
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { itemGubu: itemGubu, matlCode: matlCode, matlName: matlName };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [itemGubu, matlCode, matlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "자재분류", value: itemGubu, valueChange: itemGubuChange, search: handleSearch, items: itemGubuList })}
                {ToolbarTextBox({ caption: "자재번호", value: matlCode, valueChange: matlCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    // 수정 버튼 클릭
    const handleUpdateClick = useCallback(() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            setUpdatePopupVisible(true);
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: handleUpdateClick, disabled: authList.auth_upda === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "item_gubu", caption: "구분", groupIndex: 0 })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
                {DeciCol({ dataField: "qtys_weig", caption: "단위중량" })}
                {DeciCol({ dataField: "stoc_weig", caption: "재고중량" })}
                {CommCol({ dataField: "lots_numb", caption: "LOT NO." })}
                {DeciCol({ dataField: "lots_qtys", caption: "LOT NO. 재고량" })}
                {CommCol({ dataField: "stoc_unit", caption: "재고단위" })}
                {DateCol({ dataField: "stoc_qpri", caption: "재고단가" })}
                {DeciCol({ dataField: "stoc_kwon", caption: "제고금액" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                {CommCol({ dataField: "matl_gubu", caption: "자재구분" })}
                {CommCol({ dataField: "stoc_memo", caption: "비고" })}
            </PagingGrid>
            <StockAdjustRegistInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <StockAdjustRegistUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />                    
        </div>
    );
};


export default StockAdjustRegist;