import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { getCata } from '../../../../../api/set';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormRegx, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    catb_code: '', 
    catb_name: '', 
    regi_date: fwUtil.getToDayText(), 
    enab_yesn: true
};

export function CatbStatusInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 중분류 그리드
    const cateGridRef = useRef(null);
    const [cateData, setCateData] = useState([]);
    const cateGridUpdate = useCallback((e) => setCateData(e), []);
    const cateKeyExpr = 'cate_code';
    // 그리드 팝업
    const [catePopupVisible, setCatePopupVisible] = useState(false);
    const catePopupHiding = useCallback(() => setCatePopupVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            catbCode: `${data.cata_code || '-'}${data.catb_code || '--'}`,
            catbName: data.catb_name || '',
            regiDate: data.regi_date || null,
            enabYesn: data.enab_yesn || false,
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    // 대분류 조회
    const cateHandleSearch = useCallback(async() => {
        const sendData = { cateName: "" };
        const res = await getCata(sendData);
        cateGridUpdate(res);
    }, [cateGridUpdate]);

    // 대분류 팝업 열릴 때
    const catePopupOnShowing = useCallback(() => {
        cateHandleSearch();
    }, [cateHandleSearch]);

    // 대분류 팝업 더블 클릭
    const cateOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, ['cata_code']: e.data.cate_code });
        catePopupHiding();
    }, [data, dataUpdate, catePopupHiding]);

    return (
        <>
        <PopupGrid
            title={'대분류 선택'}   
            type={'선택'}
            visible={catePopupVisible}  
            showing={catePopupOnShowing}  
            hiding={catePopupHiding}
        >   
            <ScrollGrid
                title={'대분류'}
                role={'목록'}
                gridDataSource={cateData}
                gridRef={cateGridRef}
                mainKey={cateKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={cateOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "cate_code", caption: '대분류코드', width: 100 })}
                {CommCol({ dataField: "cate_name", caption: '대분류명' })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'중분류 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600 }  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormGrid({ value: data.cata_code, onClick: () => setCatePopupVisible(true), label: '대분류코드', required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormRegx({ value: data.catb_code, onValueChange: updateField('catb_code'), label: '중분류코드', required: true, rule: /^[a-zA-Z0-9]{2}$/, message: "영어 숫자 조합 2자리를 입력해주세요!" })}
                {FormText({ value: data.catb_name, onValueChange: updateField('catb_name'), label: '중분류명', required: true })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
            </GroupItem>
        </PopupForm>     
        </>
    );
}