import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormRegx, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    cate_code: '', 
    cate_name: '', 
    regi_date: fwUtil.getToDayText(), 
    enab_yesn: true
};

export function CataStatusInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            cataCode: data.cate_code || '',
            cataName: data.cate_name || '',
            regiDate: data.regi_date || null,
            enabYesn: data.enab_yesn || false,
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return (
        <PopupForm
            title={'대분류 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600 }  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormRegx({ value: data.cate_code, onValueChange: updateField('cate_code'), label: '대분류코드', required: true, rule: /[A-Z]/, regxMessage: "영어 대문자 1자리만 입력가능합니다!" })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.cate_name, onValueChange: updateField('cate_name'), label: '대분류명', required: true })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
            </GroupItem>
        </PopupForm>     
    );
}