import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    addr_ipnb: '',
    addr_yesn: false,
    addr_memo: '',
};

export function IpStatusInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            addrIpnb: data.addr_ipnb || '',
            addrYesn: data.addr_yesn || false,
            addrMemo: data.addr_memo || ''
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'IP 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormText({ value: data.addr_ipnb, onValueChange: updateField('addr_ipnb'), label: '허용 IP', required: true })}                
                {FormBool({ value: data.addr_yesn, onValueChange: updateField('addr_yesn'), label: '사용여부' })}                          
            </GroupItem>
            {FormMemo({ value: data.addr_memo, onValueChange: updateField('addr_memo'), label: '허용사유', required: true })}                  
        </PopupForm>        
    );
}