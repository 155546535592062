import { TotalItem } from "devextreme-react/cjs/data-grid"

const TotalSumItem = (props) => {
    const {dataField, caption} = props;
    const customizeSumText = (item) => {
        const formattedValue = new Intl.NumberFormat().format(item.value);
        return `${caption}: ${formattedValue}`;
    };

    return (
        <TotalItem 
            column={dataField}
            summaryType='sum'
            customizeText={customizeSumText}
        />
    )
};

const TotalCntItem = (props) => {
    const {dataField, caption} = props;
    const customizeSumText = (item) => {
        const formattedValue = new Intl.NumberFormat().format(item.value);
        return `${caption}: ${formattedValue}`;
    };

    return (
        <TotalItem 
            column={dataField}
            summaryType='count'
            customizeText={customizeSumText}
        />
    )
};

const TotalCstItem = (props) => {
    const {dataField, caption} = props;
    const customizeSumText = (item) => {
        const formattedValue = new Intl.NumberFormat().format(item.value);
        return `${caption}: ${formattedValue}`;
    };

    return (
        <TotalItem 
            showInColumn={dataField}
            summaryType='custom'
            name="custom"
            customizeText={customizeSumText}
        />
    )
};

export { 
    TotalSumItem,
    TotalCntItem,
    TotalCstItem
};