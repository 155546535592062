import * as fowin from './focuswin/pages';
import * as fowinUtil from './focuswin/script/util';
import { withNavigationWatcher } from './contexts/navigation';

let routes = [];

async function fetchRoutes() {
    try {
        const axiosInstance = fowinUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('/public/api/menu/routerList');
        return response.data.userMenu;
    } catch (error) {
        console.error("appRoutes.setting.error : " + error);
        return [];
    }
};

async function generateDynamicRoutes() {
    const menuList = await fetchRoutes();
    const menuMap = menuList.map(menuitem => {
        let menuCode = menuitem.menu_code.trim();
        let menuHigh = menuitem.menu_high.trim();
        let menuName = menuitem.menu_name.trim();
        let menuPath = menuitem.menu_path.trim();
        let menuFile = menuitem.menu_file.trim();

        if (!fowinUtil.nueCheck(menuPath) && !fowinUtil.nueCheck(menuFile)) {
            return {
                mkey: menuCode,
                skey: menuHigh,
                name: menuName,
                path: menuPath,
                element: fowin[menuFile]
            };
        } else {
            return null;
        }
    }).filter(route => route !== null);

    const homeRoute = {
        path: '/home',
        element: fowin.Home
    };

    menuMap.push(homeRoute);

    routes = menuMap.map(route => ({
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    }));
}
await generateDynamicRoutes();

export default routes;
