// InspProsRegist - 수입검사 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { InspProsRegistInsert } from './Regist-insert';
import { InspProsRegistUpdate } from './Regist-update';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "수입검사";
const mainRole = "등록";
const subsTitle = "검사품목";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                                  // 페이지 메뉴 구분
const code = "E12";                                                  // 페이지 메뉴 코드
const mainKey = 'insp_numb';                                         // 메인 데이터 키
const subsKey = ['insp_numb', 'insp_seri'];                          // 서브 데이터 키

const subsDeleKey = (data) => {                                      // 삭제 데이터 키
    return { 
        inspNumb: data.insp_numb, 
        inspSeri: data.insp_seri 
    }
};

const mainSearchApi = 'privat/qcl/pros/register/header/search';      // 메인 조회 api
const subsSearchApi = 'privat/qcl/pros/register/detail/search';      // 서브 조회 api
const subsInsertApi = 'privat/qcl/pros/register/detail/insert';      // 서브 등록 api
const subsUpdateApi = 'privat/qcl/pros/register/detail/insert';      // 서브 수정 api
const subsDeleteApi = 'privat/qcl/pros/register/detail/insert';      // 서브 삭제 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        insp_numb: 'INS001',
        insp_date: '2024-05-01',
        matl_code: 'MAT001',
        matl_name: 'Material A',
        lots_qtys: 100,
        pros_numb: 'PO001-001',
        clie_name: 'Client A',
        pros_date: '2024-04-15',
        deli_date: '2024-04-20',
        work_numb: 'WN001',
        rout_numb: 'RN001',
        rout_name: 'Process A'
    },
    {
        insp_numb: 'INS002',
        insp_date: '2024-05-02',
        matl_code: 'MAT002',
        matl_name: 'Material B',
        lots_qtys: 200,
        pros_numb: 'PO002-002',
        clie_name: 'Client B',
        pros_date: '2024-04-16',
        deli_date: '2024-04-21',
        work_numb: 'WN002',
        rout_numb: 'RN002',
        rout_name: 'Process B'
    },
    {
        insp_numb: 'INS003',
        insp_date: '2024-05-03',
        matl_code: 'MAT003',
        matl_name: 'Material C',
        lots_qtys: 150,
        pros_numb: 'PO003-003',
        clie_name: 'Client C',
        pros_date: '2024-04-17',
        deli_date: '2024-04-22',
        work_numb: 'WN003',
        rout_numb: 'RN003',
        rout_name: 'Process C'
    }
];

const tempSubsData = [
    {
        insp_numb: 'INS001',
        insp_seri: '01',
        insp_cate: 'Category A',
        insp_spec: 'Spec A',
        insp_math: 'Method A',
        insp_resu: 'Result A',
        work_name: 'Worker A',
        item_remk: 'Remark A',
        item_memo: 'Memo A'
    },
    {
        insp_numb: 'INS001',
        insp_seri: '02',
        insp_cate: 'Category B',
        insp_spec: 'Spec B',
        insp_math: 'Method B',
        insp_resu: 'Result B',
        work_name: 'Worker B',
        item_remk: 'Remark B',
        item_memo: 'Memo B'
    },
    {
        insp_numb: 'INS002',
        insp_seri: '01',
        insp_cate: 'Category C',
        insp_spec: 'Spec C',
        insp_math: 'Method C',
        insp_resu: 'Result C',
        work_name: 'Worker C',
        item_remk: 'Remark C',
        item_memo: 'Memo C'
    },
    {
        insp_numb: 'INS003',
        insp_seri: '01',
        insp_cate: 'Category D',
        insp_spec: 'Spec D',
        insp_math: 'Method D',
        insp_resu: 'Result D',
        work_name: 'Worker D',
        item_remk: 'Remark D',
        item_memo: 'Memo D'
    },
    {
        insp_numb: 'INS003',
        insp_seri: '02',
        insp_cate: 'Category E',
        insp_spec: 'Spec E',
        insp_math: 'Method E',
        insp_resu: 'Result E',
        work_name: 'Worker E',
        item_remk: 'Remark E',
        item_memo: 'Memo E'
    }
];
function InspProsRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [seleGubu, setSeleGubu] = useState("구매입고");
    const [prosNumb, setProsNumb] = useState("");
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        if (seleGubu) {
            const sendData = { seleGubu: seleGubu, prosNumb: prosNumb };        
            // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
            mainGridDataUpdate(tempMainData);
        }
    }, [seleGubu, prosNumb, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { inspNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            const convertedData = tempSubsData.filter(item => item.insp_numb === keyData);
            subsGridDataUpdate(convertedData);
        }
    }, [seleGubu, mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch, seleGubu]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
    }, [subsGridRef]);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = subsGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.deleteData(subsGridRef, subsDeleteApi, subsDeleKey, subsHandleSearch);
        }
        subsDeleteAlertHide();
    }, [subsGridRef, subsHandleSearch, subsDeleteAlertHide]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "입고유형", value: seleGubu, valueChange: seleGubuChange, search: mainHandleSearch, items: ['구매입고', '외주입고'] })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                notify('상세 데이터를 선택 후 시도해주세요.', 'warning', 2000);
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            notify('메인 데이터를 선택 후 시도해주세요.', 'warning', 2000);
            return;
        }

        handleSubsAction();

    }, [mainGridRef, subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}               
                onRowClick={mainGridClick}           
                toolbar={{ default: false, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {DeciCol({ dataField: "lots_qtys", caption: "검사수량" })}
                {CommCol({ dataField: "pros_numb", caption: "발주번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {DateCol({ dataField: "pros_date", caption: "발주일자" })}
                {DateCol({ dataField: "deli_date", caption: "납품일자" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "insp_seri", caption: "순번" })}
                {CommCol({ dataField: "insp_cate", caption: "검사항목" })}
                {CommCol({ dataField: "insp_spec", caption: "기준(규격)" })}
                {CommCol({ dataField: "insp_math", caption: "검사방법" })}
                {CommCol({ dataField: "insp_resu", caption: "검사결과" })}
                {CommCol({ dataField: "work_name", caption: "작업자" })}
                {CommCol({ dataField: "item_remk", caption: "특기사항" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}    
            </SubsGrid>         
            <InspProsRegistInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                targetApi={subsSearchApi}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />
            <InspProsRegistUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />                      
        </div>
    );
};


export default InspProsRegist;