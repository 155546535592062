import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupGrid } from '../../../../script/components/popup/popup';
import { getPursSelcItemGubu as getGubu } from '../../../../../api/set';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { Item } from 'devextreme-react/cjs/data-grid';
import { ToolbarTextBox, ToolbarSelcBox, SearchButton } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const keyExpr = 'matl_code';  // 데이터 키

export function PursRegistSelect(props) {
    const {
        refresh,
        selectApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const matlGridRef = useRef(null);
    // 검색 조건
    const [matlName, setMatlName] = useState('');
    const [itemGubu, setItemGubu] = useState('');
    const [gubuList, setGubuList] = useState([]);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const itemGubuChange = useCallback((e) => setItemGubu(e), []);
    const gubuListChange = useCallback((e) => setGubuList(e), []);
    // 조회
    const handleSearch = useCallback(async () => {
        if (itemGubu) {
            const sendData = { itemGubu: itemGubu,  matlName: matlName };
            await fwUtil.searchData(sendData, selectApi, dataUpdate);
        }
    }, [itemGubu, matlName, dataUpdate, selectApi]);    

    useEffect(() => {
        handleSearch();
    }, [itemGubu, handleSearch]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [gubuResult] = await Promise.all([getGubu()]);
                gubuListChange(gubuResult);
                itemGubuChange(gubuResult[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuListChange, itemGubuChange]);

    // 열릴 때
    const onShowing = () => {
        setLoading(true);
    };

    // 선택 데이터 조회
    const onShown = useCallback(async () => {
        await handleSearch();
        setLoading(false);
    }, [handleSearch, setLoading]);

    // 제출
    const onSubmit = useCallback(async () => {
        const selectedRowKey = fwUtil.findSelectedRowKey(matlGridRef);
        if (selectedRowKey) {
            setLoading(true);
            const selectedData = matlGridRef.current.instance.getSelectedRowsData() || [];

            const convertdData = selectedData.map((item) => ({
                itemGubu: item.item_gubu || '',
                matlCode: item.matl_code || ''
            }));
            const postData = { setaMaps: convertdData }
            await fwUtil.submitData(postData, insertApi, refresh);
            matlGridRef.current.instance.deselectAll();
            setLoading(false);
            hiding();
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 200);
        }
                
    }, [insertApi, hiding, matlGridRef, refresh]);

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(matlGridRef, key, isSelected);
    }, [matlGridRef]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = useCallback(() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {ToolbarSelcBox({ caption: "제품구분", value: itemGubu, valueChange: itemGubuChange, search: handleSearch, items: gubuList })}
                {SearchButton({ search: handleSearch })}
            </Item>
        );
    }, [matlName, matlNameChange, itemGubu, itemGubuChange, handleSearch, gubuList]);

    const gridBody = useMemo(() => (
        <ScrollGrid
            title="자재"
            role="목록"
            gridDataSource={data}
            gridRef={matlGridRef}
            mainKey={keyExpr}
            width={isXSmall ? 300 : isSmall ? 550 : 860}
            height={600}
            onRowClick={onRowClick}
            selection={'multiple'}
            toolbar={{ default: false, before: toolbarBeforeItems }}
        >
            {CommCol({ dataField: "matl_code", caption: "구성품번" })}
            {CommCol({ dataField: "matl_name", caption: "구성품명" })}
            {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
        </ScrollGrid>
    ), [data, matlGridRef, onRowClick, toolbarBeforeItems, isXSmall, isSmall]);

    return ( 
        <PopupGrid
            title={'자재 목록'}   
            type={'추가'}
            loading={loading}
            visible={visible}  
            showing={onShowing}
            shown={onShown}  
            hiding={hiding}
            onSubmit={onSubmit}
        >   
            {gridBody}
        </PopupGrid>
    );
}