import * as fwUtil from '../focuswin/script/util';

export const bankDataSource = [
    "한국은행",
    "KB국민은행",
    "우리은행",
    "SC제일은행",
    "한국씨티은행",
    "하나은행",
    "신한은행",
    "케이뱅크",
    "카카오뱅크",
    "토스뱅크",
    "한국산업은행",
    "중소기업은행",
    "한국수출입은행",
    "수협은행",
    "NH농협은행",
    "대구은행",
    "부산은행",
    "경남은행",
    "광주은행",
    "전북은행",
    "제주은행"
]

export const getDept = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/deptcode/list');
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getDeptList.error === ', error);
        return [];
    }
};

export const getPosi = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/position/list');
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// comCode
export const getComCodeGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/maincode/index/list');
        const data = response.data.dataSet;
        const filteredData = data.map(item => item.bizs_gubu);
        return filteredData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};
// client
export const getClientGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('/privat/sys/set/client/gubu/list');
        const data = response.data.dataSet.map(item => item.send_gubu);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// client/vehicle
export const getClientVehicleMatl = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/client/vehicle/matlcode', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getClientVehicleGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/client/vehicle/itemgubu');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// matl
export const getCata = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/matlcode/cata/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// item
export const getItemGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/com/item/register/itemgubu/list');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getWorkGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/com/item/register/workgubu/list');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// item - bom
export const getBomItem = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/com/bom/register/bomitem/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getBomMatl = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/com/bom/register/itemcode/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getBomItemGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/com/bom/register/gubucode/alist');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getBomMatlGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/com/bom/register/gubucode/blist');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// order
export const getOrderClie = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/order/client/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getOrderItem = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/order/item/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// ship
export const getShipClie = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/ship/register/client/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// purs
export const getPursSelcItemGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/pro/purs/select/itemgubu');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// ppro
export const getPproClosGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/pro/ppro/register/closgubu');
        const data = response.data.dataSet.map(item => item.clos_gubu);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getPproDateGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/pro/ppro/register/dategubu');
        const data = response.data.dataSet.map(item => item.date_gubu);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getPproClie = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/pro/ppro/register/client', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getPproDamd = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/pro/ppro/register/damdname');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getPproPays = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/pro/ppro/register/payscond');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// forwarding
export const getForwMatl = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/mat/forwarding/register/matl/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// stock/adjust
export const getStockAdjustMatl = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/mat/stock/adjust', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// equl
export const getEquiGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/equl/gubu/list');
        const data = response.data.dataSet.map(item => item.subs_name);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getMainEqui = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/equ/main/equl/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getMainClie = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/equ/main/clie/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// premaint
export const getPremDamd = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/equ/prem/damd/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// calib
export const getCaliEqui = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/equ/main/equl/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getCaliClie = async (sendData) => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('privat/sys/set/equ/main/clie/list', sendData);
        const data = response.data.dataSet;
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// plan
export const getPlanCLosGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        //const response = await axiosInstance.post('privat/sys/set/plan/closgubu/list');
        //const data = response.data.dataSet.map(item => item.subs_name);
        const data = ["계획신규", "계획완료"];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getPlanDateGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        //const response = await axiosInstance.post('privat/sys/set/plan/dategubu/list');
        //const data = response.data.dataSet.map(item => item.subs_name);
        const data = ["납기일자", "계획일자"];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// work-order
export const getWorkOrderDateGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        //const response = await axiosInstance.post('privat/sys/set/work-order/dategubu/list');
        //const data = response.data.dataSet.map(item => item.subs_name);
        const data = ["납기일자", "계획일자"];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// product
export const getProductRoutList = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        //const response = await axiosInstance.post('privat/sys/set/work-order/dategubu/list');
        //const data = response.data.dataSet.map(item => item.subs_name);
        const data = ["01: 소재", "02: 가공", "03: 세척", "04: 도장", "05: 외주", "06: 포장"];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getProductNameList = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        //const response = await axiosInstance.post('privat/sys/set/work-order/dategubu/list');
        //const data = response.data.dataSet.map(item => item.subs_name);
        const data = ["홍길동 [CS120001]", "김철수 [CS120002]", "차연정 [CS120003]", "반기문 [CS120004]",];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

// qcl/report
export const getInfeReportClosGubu = async () => {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        //const response = await axiosInstance.post('privat/sys/set/work-order/dategubu/list');
        //const data = response.data.dataSet.map(item => item.subs_name);
        const data = ["진행", "완료"];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};