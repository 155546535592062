import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    orde_numb: '',
    clie_code: '',
    clie_name: '',
    item_name: '',
    item_spec: '',
    insp_date: fwUtil.getToDayText(),
    repo_memo: '',
};

export function InfeReportRegistUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);        

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowData = fwUtil.findSelectedRowData(mainGrid);
        if (!selectedRowData) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            const i = selectedRowData.length;
            const newData = {
                orde_numb: selectedRowData[i-1].orde_numb || '',
                clie_code: selectedRowData[i-1].clie_code || '',
                clie_name: selectedRowData[i-1].clie_name || '',
                item_name: selectedRowData[i-1].cite_name || '',
                item_spec: selectedRowData[i-1].cite_spec || '',
                insp_date: selectedRowData[i-1].insp_date || fwUtil.getToDayText(),
                repo_memo: selectedRowData[i-1].repo_memo || '',                
            }
            dataUpdate(newData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            orde_numb: data.orde_numb || '',
            clie_code: data.clie_code || '',
            clie_name: data.clie_name || '',
            item_name: data.item_name || '',
            item_spec: data.item_spec || '',
            insp_date: data.insp_date || null,
            repo_memo: data.repo_memo || '',            
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'성적서 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600 } 
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '고객코드', isEditing: true, required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', isEditing: true, required: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', isEditing: true, required: true })}
                {FormText({ value: data.item_spec, onValueChange: updateField('item_spec'), label: '제품규격', isEditing: true, required: true })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자', required: true })}
            </GroupItem>
            {FormMemo({ value: data.repo_memo, onValueChange: updateField('repo_memo'), label: '비고' })}            
        </PopupForm>
    );
}