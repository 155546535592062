// WareHousingRegistPart -부분 입고
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { WhareHousingRegistPartInsert } from './Regist-part-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "부분";
const mainRole = "입고";
const subsTitle = "발주품목";
const subsRole = "상세";
const gridSize = 5;

const gubu = "mes";                                                            // 페이지 메뉴 구분
const code = "A42";                                                            // 페이지 메뉴 코드
const mainKey = 'ppro_numb';                                                   // 메인 데이터 키
const subsKey = ['ppro_numb', 'ppro_seri'];                                    // 서브 데이터 키

const mainSearchApi = 'privat/mat/warehousing/register/full/header/search';    // 메인 조회 api
const subsSearchApi = 'privat/mat/warehousing/register/full/detail/search';    // 서브 조회 api
const subsInsertApi = 'privat/mat/warehousing/register/full/detail/insert';    // 메인 등록 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        "ppro_numb": "PP20240522-001",
        "clie_name": "ABC Company",
        "clie_teln": "010-1234-5678",
        "clie_faxn": "02-1234-5678",
        "clie_damd": "John Doe",
        "pdel_date": "2024-06-01",
        "pays_cond": "현금",
        "deli_plac": "서울 본사",
        "insp_damd": "검수팀",
        "ppro_remk": "긴급 발주",
        "damd_name": "김철수",
        "ppro_date": "2024-05-22",
        "ppro_usag": "생산 재료",
        "ppro_stat": "진행 중"
    },
    {
        "ppro_numb": "PP20240522-002",
        "clie_name": "XYZ Corporation",
        "clie_teln": "010-2345-6789",
        "clie_faxn": "02-2345-6789",
        "clie_damd": "Jane Smith",
        "pdel_date": "2024-06-02",
        "pays_cond": "카드",
        "deli_plac": "부산 공장",
        "insp_damd": "품질관리팀",
        "ppro_remk": "일반 발주",
        "damd_name": "박영희",
        "ppro_date": "2024-05-23",
        "ppro_usag": "포장 재료",
        "ppro_stat": "대기 중"
    },
    {
        "ppro_numb": "PP20240522-003",
        "clie_name": "LMN Industries",
        "clie_teln": "010-3456-7890",
        "clie_faxn": "02-3456-7890",
        "clie_damd": "Michael Johnson",
        "pdel_date": "2024-06-03",
        "pays_cond": "계좌이체",
        "deli_plac": "인천 물류센터",
        "insp_damd": "검수팀",
        "ppro_remk": "정기 발주",
        "damd_name": "이순신",
        "ppro_date": "2024-05-24",
        "ppro_usag": "보관 재료",
        "ppro_stat": "완료"
    }
];

const tempSubsData = [
    {
        "ppro_numb": "PP20240522-001",
        "ppro_seri": "001",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "MAT-001",
        "matl_name": "자재 A",
        "matl_spec": "사양 1",
        "ppro_qtys": 100,
        "rece_qtys": 80,
        "puro_unit": "kg",
        "ppro_pric": 1000,
        "ppro_cwon": 100000,
        "reqe_dept": "서울 본사",
        "ppro_memo": "긴급 발주"
    },
    {
        "ppro_numb": "PP20240522-001",
        "ppro_seri": "002",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "MAT-002",
        "matl_name": "자재 B",
        "matl_spec": "사양 2",
        "ppro_qtys": 50,
        "rece_qtys": 50,
        "puro_unit": "kg",
        "ppro_pric": 2000,
        "ppro_cwon": 100000,
        "reqe_dept": "서울 본사",
        "ppro_memo": "정기 발주"
    },
    {
        "ppro_numb": "PP20240522-002",
        "ppro_seri": "001",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "MAT-003",
        "matl_name": "자재 C",
        "matl_spec": "사양 3",
        "ppro_qtys": 200,
        "rece_qtys": 150,
        "puro_unit": "개",
        "ppro_pric": 1500,
        "ppro_cwon": 300000,
        "reqe_dept": "부산 공장",
        "ppro_memo": "일반 발주"
    },
    {
        "ppro_numb": "PP20240522-002",
        "ppro_seri": "002",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "MAT-004",
        "matl_name": "자재 D",
        "matl_spec": "사양 4",
        "ppro_qtys": 75,
        "rece_qtys": 70,
        "puro_unit": "개",
        "ppro_pric": 2500,
        "ppro_cwon": 187500,
        "reqe_dept": "부산 공장",
        "ppro_memo": "비정기 발주"
    },
    {
        "ppro_numb": "PP20240522-003",
        "ppro_seri": "001",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "MAT-005",
        "matl_name": "자재 E",
        "matl_spec": "사양 5",
        "ppro_qtys": 120,
        "rece_qtys": 100,
        "puro_unit": "리터",
        "ppro_pric": 3000,
        "ppro_cwon": 360000,
        "reqe_dept": "인천 물류센터",
        "ppro_memo": "정기 발주"
    },
    {
        "ppro_numb": "PP20240522-003",
        "ppro_seri": "002",
        "work_numb": "",
        "rout_numb": "",
        "rout_name": "",
        "matl_code": "MAT-006",
        "matl_name": "자재 F",
        "matl_spec": "사양 6",
        "ppro_qtys": 90,
        "rece_qtys": 90,
        "puro_unit": "리터",
        "ppro_pric": 4000,
        "ppro_cwon": 360000,
        "reqe_dept": "인천 물류센터",
        "ppro_memo": "비정기 발주"
    }
];

function WareHousingRegistPart() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [seleGubu, setSeleGubu] = useState("구매");
    const [wareNumb, setWareNumb] = useState("");
    const [prosNumb, setProsNumb] = useState("");
    const [matlName, setMatlName] = useState("");
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 등록 팝업창
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, seleGubu: seleGubu, wareNumb: wareNumb, prosNumb: prosNumb, matlName: matlName, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, seleGubu, wareNumb, prosNumb, matlName, clieName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { seleGubu: seleGubu, pproNumb: keyData.pproNumb };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            const convertedData = tempSubsData.filter(item => item.ppro_numb === keyData);
            subsGridDataUpdate(convertedData);
        }
    }, [seleGubu, mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    const subsGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.multipleSelectRow(subsGridRef, key, isSelected);
        }
    }, [subsGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "입고유형", value: seleGubu, valueChange: seleGubuChange, search: mainHandleSearch, items: ['구매', '외주'] })}
                {ToolbarTextBox({ caption: "입고번호", value: wareNumb, valueChange: wareNumbChange, search: mainHandleSearch })}
                {ToolbarTextBox({ caption: "발주번호", value: prosNumb, valueChange: prosNumbChange, search: mainHandleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: mainHandleSearch })}
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 등록 버튼 클릭
    const subsHandleInsertClick = useCallback((act) => {
        const subsSelectedRowKey = fwUtil.findSelectedRowKey(subsGridRef);

        if (subsSelectedRowKey) {
            setSubsInsertPopupVisible(true);
        } else {
            notify('발주를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [subsGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}        
                toolbar={{ default: true, before: mainToolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "ppro_numb", caption: "발주번호" })} 
                {CommCol({ dataField: "clie_name", caption: "업체명" })} 
                {CommCol({ dataField: "clie_teln", caption: "업체전화" })} 
                {CommCol({ dataField: "clie_faxn", caption: "업체팩스" })} 
                {CommCol({ dataField: "clie_damd", caption: "업체담당자" })} 
                {DateCol({ dataField: "pdel_date", caption: "납품일자" })} 
                {CommCol({ dataField: "pays_cond", caption: "지불형태" })} 
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })} 
                {CommCol({ dataField: "insp_damd", caption: "검수자(방법)" })} 
                {CommCol({ dataField: "ppro_remk", caption: "특기사항" })} 
                {CommCol({ dataField: "damd_name", caption: "발주담당자" })} 
                {DateCol({ dataField: "ppro_date", caption: "발주일자" })} 
                {CommCol({ dataField: "ppro_usag", caption: "용도" })} 
                {CommCol({ dataField: "ppro_stat", caption: "진행상태" })}                              
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                onRowClick={subsGridClick}  
                ins={{ onClick: subsHandleInsertClick, disabled: authList.auth_inse === 0 }}  
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }} 
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "ppro_seri", caption: "순번" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호", visible: seleGubu === "외주" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호", visible: seleGubu === "외주" })}
                {CommCol({ dataField: "rout_name", caption: "공정명", visible: seleGubu === "외주" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재품명" })}
                {CommCol({ dataField: "matl_spec", caption: "자재규격" })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위" })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {DeciCol({ dataField: "ppro_pric", caption: "발주단가" })}
                {DeciCol({ dataField: "ppro_cwon", caption: "발주금액" })}
                {DeciCol({ dataField: "rece_qtys", caption: "기입고량" })}
                {CommCol({ dataField: "lots_numb", caption: "Lot No", visible: seleGubu === "구매" })}
                {DeciCol({ dataField: "ware_qtys ", caption: "입고예정" })}
                {DeciCol({ dataField: "ware_pric ", caption: "입고단가" })}
                {DeciCol({ dataField: "ware_cwon ", caption: "입고금액" })}
                {CommCol({ dataField: "ppro_memo", caption: "비고" })}
            </SubsGrid>      
            <WhareHousingRegistPartInsert
                seleGubu={seleGubu}
                mainGrid={subsGridRef}
                subsRefresh={subsHandleSearch}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />                      
        </div>
    );
};


export default WareHousingRegistPart;