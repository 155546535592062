import { ScrollView, ValidationGroup, Form } from 'devextreme-react';
import { PanelToolbar } from '../detail-panel-toolbar/PanelToolbar';
import '../detail-panel.scss';

export function PanelBody(props) {
    const {
        isEditing, toggleEditHandler, 
        onDeleteClick, onSaveClick, 
        additionalWork, upd,
        children,
    } = props;

    return (
        <ScrollView className='fw-detail-panel-scroll' width={'100%'} height={'100%'}>
            <ValidationGroup>
                <div className='fw-detail-panel-data-part border'>
                    <Form className={isEditing? 'plain-styled-form' : 'plain-styled-form view-mode'}>
                        {children}
                    </Form>
                </div>
                <PanelToolbar 
                    isEditing={isEditing}
                    toggleEditHandler={toggleEditHandler}
                    onDeleteClick={onDeleteClick}
                    onSaveClick={onSaveClick}
                    additionalWork={additionalWork}
                    upd={upd}
                />                
            </ValidationGroup>
        </ScrollView>
    )
};