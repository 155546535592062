// StockAdjustStatus - 재고조정 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "재고조정";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "C51";                                         // 페이지 메뉴 코드
const mainKey = 'adju_numb';                                // 데이터 키                                                   

const searchApi = 'privat/mat/stock/adjust/status/search';   // 조회 api

const exportFormats = ['xlsx', 'pdf'];

const itemGubuList = ["전체", "원자재", "반제품", "제품"];

function StockAdjustStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [itemGubu, setItemGubu] = useState("");
    const [matlCode, setMatlCode] = useState("");
    const [matlName, setMatlName] = useState("");  
    const itemGubuChange = useCallback((e) => { setItemGubu(e) }, []);    
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []); 

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { itemGubu: itemGubu, matlCode: matlCode, matlName: matlName };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [itemGubu, matlCode, matlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "자재분류", value: itemGubu, valueChange: itemGubuChange, search: handleSearch, items: itemGubuList })}
                {ToolbarTextBox({ caption: "자재번호", value: matlCode, valueChange: matlCodeChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "adju_numb", caption: "재고조정번호" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "item_gubu", caption: "구분" })}
                {CommCol({ dataField: "lots_numb", caption: "LOT NO." })}
                {DeciCol({ dataField: "orig_qtys", caption: "조정 전 수량" })}
                {DeciCol({ dataField: "adju_qtys", caption: "조정 후 수량", check: true })}
                {DeciCol({ dataField: "orig_pric", caption: "조정 전 단가" })}
                {DeciCol({ dataField: "adju_pric", caption: "조정 후 단가", check: true })}
                {CommCol({ dataField: "adju_memo", caption: "조정사유" })}
            </PagingGrid>          
        </div>
    );
};


export default StockAdjustStatus;