import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComSearchPanel as SearchPanel } from '../../../../script/components/search-panel/search-panel';
import { GridHeader as GridTool } from '../../../../script/components/data-grid/data-grid-header/grid-header';
import { DataGridPaging as GridBody } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { LoadPanel, TextBox } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import * as fowinUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";
import CustomStore from 'devextreme/data/custom_store';

const gubu = "mes";           // 페이지 메뉴 구분
const code = "B14";           // 페이지 메뉴 코드
const keyExpr = 'hist_numb';  // 데이터 키
const delKey = 'histNumb'     // 삭제 데이터 키

const searchApi = 'privat/sal/order/history/search';     // 조회 api

function OrderInfoStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridHeadPageSize = 10;
    const gridHeadInstance = useRef(null);
    // 조회 상태값
    const [start, setStart] = useState(fowinUtil.getFirstDayText());
    const [compl, setCompl] = useState(fowinUtil.getLastDayText());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const [citeName, setCiteName] = useState("");      
    const startChang = useCallback((e) => { setStart(e.value) }, []);
    const complChang = useCallback((e) => { setCompl(e.value) }, []);
    const duraYesnChang = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChang = useCallback((e) => { setClieName(e) }, []);
    const citeNameChang = useCallback((e) => { setCiteName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: start, compl: compl, duraYesn: duraYesn, clieName: clieName, citeName: citeName };
        return await fowinUtil.searchData(sendData, searchApi);
    }, [start, compl, duraYesn, clieName, citeName, searchApi]);

    const searchOption = (
        <SearchPanel
            duraYesn={duraYesn}
            startChang={startChang}
            complChang={complChang}
            checkChang={duraYesnChang}
            searchClick={handleSearch}
        >        
            <TextBox key={'OrderInfoStatusclieName'}
                placeholder={'고객명'} value={clieName}
                onValueChange={clieNameChang}
                showClearButton={false}
                width={'auto'}
            />
            <TextBox key={'OrderInfoStatusItemName'}
                placeholder={'고객품목명'} value={citeName}
                onValueChange={citeNameChang}
                showClearButton={false}
                width={'auto'}
            />                
        </SearchPanel>
    );

    const dataSource = useCallback(
        new CustomStore({
            key: keyExpr,
            loadMode: 'virtual',
            load: () => handleSearch()
        }),
        [keyExpr, handleSearch]
    );

    if (loading) {
        return <LoadPanel visible={true} />
    }

    return ( 
        <>
            <GridTool 
                title={'수주변경 내역'} fileName={'수주변경내역'} 
                gridInstance={gridHeadInstance} 
                searchPanel={searchOption}
                export={{ hidden: false, disabled: authList.auth_dnlo === 0 }}
            />
            <GridBody
                dataSource={dataSource}
                pPageSize={gridHeadPageSize}
                gridInstance={gridHeadInstance}
            >
                {CommCol({ dataField: "hist_numb", caption: "수주변경일련번호" })}
                {CommCol({ dataField: "chck_gubu", caption: "수주변경구분" })}
                {CommCol({ dataField: "chck_remk", caption: "변경내역" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객사명" })}
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "cite_code", caption: "고객품목번호" })}
                {CommCol({ dataField: "cite_name", caption: "고객품명" })}
                {CommCol({ dataField: "cite_spec", caption: "고객품목규격" })}
                {DateCol({ dataField: "deli_bdat", caption: "변경전 납기일자" })}
                {DateCol({ dataField: "deli_adat", caption: "변경후 납기일자" })}
                {DeciCol({ dataField: "orde_bqty", caption: "변경전 오더수량" })}
                {DeciCol({ dataField: "orde_aqty", caption: "변경후 오더수량" })}
            </GridBody>
        </>
    );
}

export default OrderInfoStatus;

