// PursStatus - 요청 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "요청";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                  // 페이지 메뉴 구분
const code = "C11";                                  // 페이지 메뉴 코드
const mainKey = ['purs_numb', 'matl_code'];          // 데이터 키                                                

const searchApi = 'privat/pro/purs/status/search';   // 조회 api

const exportFormats = ['xlsx', 'pdf'];

function PursStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [matlName, setMatlName] = useState("");
    const [pursNumb, setPursNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const pursNumbChange = useCallback((e) => { setPursNumb(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, matlName: matlName, pursNumb: pursNumb };        
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [dateValue, duraYesn, matlName, pursNumb, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "구매요청번호", value: pursNumb, valueChange: pursNumbChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "purs_numb", caption: "구매요청번호" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고량" })}
                {DeciCol({ dataField: "requ_qtys", caption: "요청수량" })}
                {CommCol({ dataField: "pros_unit", caption: "발주단위" })}
                {DeciCol({ dataField: "pros_pric", caption: "발주단가" })}
                {DeciCol({ dataField: "pros_cwon", caption: "발주요청금액" })}
                {CommCol({ dataField: "rece_plac", caption: "입고처" })}
                {DateCol({ dataField: "deli_date", caption: "납품요청일" })}
                {CommCol({ dataField: "purs_stat", caption: "구매상태" })}
                {CommCol({ dataField: "purs_memo", caption: "구매메모" })}
                {CommCol({ dataField: "user_name", caption: "사용자명" })}
                {DateCol({ dataField: "user_date", caption: "사용자일시" })}
                {CommCol({ dataField: "modi_name", caption: "수정자명" })}
                {DateCol({ dataField: "modi_date", caption: "수정일시" })}      
            </PagingGrid>        
        </div>
    );
};


export default PursStatus;