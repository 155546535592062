// UserStatus - 사용자 정보 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { UserInfoDetailPanel } from './Info-panel';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "사용자";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                        // 페이지 메뉴 구분
const code = "R11";                                        // 페이지 메뉴 코드
const mainKey = 'user_idno';                               // 데이터 키

const searchApi = 'privat/sys/info/registration/search';   // 조회 api
const targetApi = 'privat/sys/info/registration/target';   // 타겟 api

const exportFormats = ['xlsx', 'pdf'];

function UserStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 키
    const [keyData, setKeyData] = useState('');
    const keyDataChange = useCallback((e) => setKeyData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(false);
    const [userName, setUserName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    // 상세 패널
    const [isPanelOpened, setPanelOpened] = useState(false);
    const panelOpendChange = useCallback(() => {
        setPanelOpened(!isPanelOpened);
        gridRef.current?.instance.option('focusedRowIndex', -1);
    }, [isPanelOpened]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, userName: userName };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [dateValue, duraYesn, userName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 행 클릭
    const onRowClick = useCallback(({ key }) => {
        keyDataChange(key);
        setPanelOpened(true);
    }, [keyDataChange, setPanelOpened]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: " 사용자명", value: userName, valueChange: userNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "user_idno", caption: "사용자ID" })}
                {CommCol({ dataField: "user_name", caption: "성명[한글]" })}
                {CommCol({ dataField: "user_enam", caption: "성명[영문]" })}
                {CommCol({ dataField: "posi_name", caption: "직위명" })}
                {CommCol({ dataField: "dept_name", caption: "부서명" })}
                {DateCol({ dataField: "regi_date", caption: "입사일" })}
                {DateCol({ dataField: "resi_date", caption: "퇴사일" })}
                {CommCol({ dataField: "tele_numb", caption: "전 화" })}
                {CommCol({ dataField: "cell_numb", caption: "휴대폰" })}
                {CommCol({ dataField: "faxs_numb", caption: "팩 스" })}
                {CommCol({ dataField: "mail_idno", caption: "E-Mail" })}
                {DateCol({ dataField: "user_date", caption: "등록일" })}
                {CommCol({ dataField: "modi_name", caption: "수정자" })}
                {DateCol({ dataField: "modi_date", caption: "수정일" })}
            </PagingGrid>
            <UserInfoDetailPanel 
                gridRef={gridRef}
                keyData={keyData}
                tar={{ api: targetApi }}
                isPanelOpened={isPanelOpened}
                panelOpendChange={panelOpendChange}
                authList={authList}
            />
        </div>
    );
};


export default UserStatus;