// ShipRegist - 출하 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { ShipRegistInsert } from './Regist-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "출하";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                       // 페이지 메뉴 구분
const code = "B22";                                       // 페이지 메뉴 코드
const key = 'orde_numb';                                  // 데이터 키

const searchApi = 'privat/sal/ship/register/search';      // 조회 api
const insertApi = 'privat/sal/ship/register/insert';      // 등록 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        chck_type: 0,
        orde_numb: "O001",
        clie_code: "C001",
        clie_name: "ABC Company",
        cite_code: "S001",
        cite_name: "Site A",
        cite_spec: "Spec A",
        deli_date: "2024-05-01",
        orde_qtys: 100,
        deli_qtys: 50,
        stoc_qtys: 70,
        ship_qtys: 50, // Min(100-50, 70)
        ship_pric: null,
        ship_kwon: null,
        taxs_kwon: null,
        ship_remk: ""
    },
    {
        chck_type: 0,
        orde_numb: "O002",
        clie_code: "C002",
        clie_name: "XYZ Corporation",
        cite_code: "S002",
        cite_name: "Site B",
        cite_spec: "Spec B",
        deli_date: "2024-05-02",
        orde_qtys: 150,
        deli_qtys: 100,
        stoc_qtys: 60,
        ship_qtys: 50, // Min(150-100, 60)
        ship_pric: null,
        ship_kwon: null,
        taxs_kwon: null,
        ship_remk: ""
    },
    {
        chck_type: 0,
        orde_numb: "O003",
        clie_code: "C003",
        clie_name: "LMN Industries",
        cite_code: "S003",
        cite_name: "Site C",
        cite_spec: "Spec C",
        deli_date: "2024-05-03",
        orde_qtys: 200,
        deli_qtys: 150,
        stoc_qtys: 100,
        ship_qtys: 50, // Min(200-150, 100)
        ship_pric: null,
        ship_kwon: null,
        taxs_kwon: null,
        ship_remk: ""
    }
];

function ShipRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);           
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, clieName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const gridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.multipleSelectRow(gridRef, key, isSelected);
        }
    }, [gridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "고객사명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    // 등록 버튼 클릭
    const handleInsertClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            setInsertPopupVisible(true);
        } else {
            notify('수주를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={key}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={gridClick}
                ins={{ onClick: handleInsertClick, disabled: authList.auth_inse === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "cite_code", caption: "제품번호" })}
                {CommCol({ dataField: "cite_name", caption: "제품명" })}
                {CommCol({ dataField: "cite_spec", caption: "규격" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                {DeciCol({ dataField: "orde_qtys", caption: "수주수량" })}
                {DeciCol({ dataField: "deli_qtys", caption: "기출하량" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
            </PagingGrid>
            <ShipRegistInsert
                mainGrid={gridRef}
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />                  
        </div>
    );
};


export default ShipRegist;