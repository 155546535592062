import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';

// 사용자 인증과 관련된 API 호출
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';
import notify from 'devextreme/ui/notify';
import { useNavigate } from 'react-router-dom';
import userInfo from '../utils/default-user';

function AuthProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [_userMenu, setMenu] = useState([]);

  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
        setMenu(result.userMenu);
      } 
      setLoading(false);
    })();
  }, []);

  const getMenu = useCallback(() => _userMenu, [_userMenu]);

  const getDetailAuth = useCallback((gubu, code) => {

    const userAuthData = _userMenu.filter(item => item.menu_gubu === gubu && item.menu_code === code);
    if (!userAuthData || userAuthData.length === 0) {
      navigate('/home');
      notify('잘못된 접근입니다', 'warning', 2000);
      return [{ auth_acce: false }]
    }
    return userAuthData;
  }, [_userMenu, navigate]);

  // 로그인
  const signIn = useCallback(async (userIdno, passWord) => {
    const result = await sendSignInRequest(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      setMenu(result.userMenu);
    }
    return result;
  }, []);

  // 로그아웃
  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result.isOk) {
      setUser(undefined);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setLoading, getMenu, getDetailAuth }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
