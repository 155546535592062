import DataGrid, { LoadPanel, Pager, Paging, Scrolling, Sorting, Selection, HeaderFilter, Editing, FilterRow, Toolbar, Item, SearchPanel, ColumnChooser, Export, GroupPanel, Grouping } from 'devextreme-react/data-grid';
import { Button, ScrollView } from 'devextreme-react';
import { ternaryFunction } from '../../../util';
import { GridToolbar } from '../data-grid-toolbar/grid-toolbar';
import * as fwUtil from '../../../util';
import './grid-body.scss';
import { Fragment } from 'react';


const onToolbarPreparing = (e) => {  
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function(item) {
        if (item.name === "columnChooserButton" && item.options) { 
            item.options.text = "열선택기"
            item.showText = "always";       
            item.options.stylingMode = 'contained'       
        }
        if (item.name === "exportButton" && item.options) { 
            item.options.text = "다운로드"
            item.showText = "always";       
            item.options.stylingMode = 'contained'     
        }
    })   
}

const DataGridPaging = (props) => {
    const optionsSorting = ternaryFunction('single', props.pSorting);        // single, multiple
    const optionsSelection = ternaryFunction('single', props.pSelection);    // single, multiple
    const optionColumnLines = ternaryFunction(true, props.showColumnLines);  // true, false

    return (
        <Fragment>
            <DataGrid
                ref={props.gridInstance}                                     // ref
                dataSource={props.dataSource}                                // dataSource
                className={`fw-grid-row-${props.pPageSize}`}                 // css auto size
                visible={true}                                               // visible
                hoverStateEnabled={true}                                     // 호버 상태
                rowAlternationEnabled={true}                                 // 행 구분
                columnAutoWidth={true}                                       // 열 길이 자동 조절
                showColumnLines={optionColumnLines}                          // 열 구분선
                showRowLines={true}                                          // 행 구분선
                showBorders={true}                                           // 그리드 외곽선
                allowColumnReordering={true}                                 // 열 순서 변경
                allowColumnResizing={true}                                   // 열 길이 조정
                allowFiltering={true}                                        // 필터 기능
                allowSorting={optionsSorting}                                // 정렬 기능
                onCellPrepared={(e) => onCellPrepared(e, props.fieldList)}   // 셀 툴팁
                onSelectionChanged={props.onSelectionChanged}                // 행 선택 변경 
                onRowClick={props.onRowClick}                                // 행 선택 이벤트
                onRowDblClick={props.onRowDblClick}                          // 행 더블 클릭 이벤트
                keyboardNavigation={null}                                    // 키보드 이벤트 비활성화
                keyExpr={props.keyExpr}
                width={'100%'}
            >
                <LoadPanel enabled={true} shading={true} />
                <Paging enabled={true} defaultPageSize={props.pPageSize} />
                <FilterRow visible={ternaryFunction(false, props.filterRow)} />
                <Pager showInfo={true} showNavigationButtons={false} showPageSizeSelector={false} />
                <Scrolling columnRenderingMode="virtual" />
                <HeaderFilter visible={true} />
                <Sorting mode={optionsSorting} />
                <Selection mode={optionsSelection} />
                {props.cellEdit && 
                <Editing 
                    mode="cell" 
                    allowUpdating={true}                 
                    confirmDelete={false}
                    selectTextOnEditStart={true}
                    startEditAction={'click'}/>
                }
                {props.children}
            </DataGrid>
            <div className={'fw-fake-pager'} />
        </Fragment>
    )
};

const DataGridScroll = (props) => {
    const defaultNoDataText = "표시할 데이터가 없습니다";
    const optionsSorting = ternaryFunction('single', props.pSorting);       // single, multiple
    const optionsSelection = ternaryFunction('single', props.pSelection);   // single, multiple
    const optionColumnLines = ternaryFunction(true, props.showColumnLines); // true, false
    const optionLoadPanel = ternaryFunction(true, props.loadEnabled);       // true, false
    const optionScrolling = ternaryFunction('standard', props.scrollingMode)

    return (
        <DataGrid       
            ref={props.gridInstance}                                        // ref
            dataSource={props.dataSource}                                   // dataSource
            visible={true}                                                  // visible
            keyExpr={props.keyExpr}                                         // data key값
            hoverStateEnabled={true}                                        // 호버 상태
            rowAlternationEnabled={true}                                    // 행 구분
            columnAutoWidth={true}                                          // 열 길이 자동 조절
            showColumnLines={optionColumnLines}                             // 열 구분선
            showRowLines={true}                                             // 행 구분선
            showBorders={true}                                              // 그리드 외곽선
            allowColumnReordering={true}                                    // 열 순서 변경
            allowColumnResizing={true}                                      // 열 길이 조정
            allowFiltering={true}                                           // 필터 기능
            allowSorting={optionsSorting}                                   // 정렬 기능
            onCellPrepared={(e) => onCellPrepared(e, props.fieldList)}      // 셀 툴팁            
            onRowClick={props.onRowClick}                                   // 행 선택 이벤트
            onRowDblClick={props.onRowDblClick}                             // 행 더블 클릭 이벤트
            keyboardNavigation={null}                                       // 키보드 이벤트 비활성화
            width={props.width}
            height={props.height}     
            noDataText={ternaryFunction(defaultNoDataText, props.noDataText)}
            repaintChangesOnly={true}
        >
            <LoadPanel enabled={optionLoadPanel} />
            <Paging enabled={false} />
            <FilterRow visible={ternaryFunction(false, props.filterRow)} />
            <Scrolling mode={optionScrolling} columnRenderingMode={optionScrolling} />
            <HeaderFilter visible={true} />
            <Sorting mode={optionsSorting} />
            <Selection mode={optionsSelection} />
            {props.cellEdit && <Editing mode="cell" allowUpdating={true} />}
            {props.children}
        </DataGrid>
    )
};

const DropDownGrid = (props) => {
    function onRowDblClick(e) {
        if (props.disabled) { return; }
        dropGridRowDblClick(e, props.dataChang)
    }

    return (
        <DataGridScroll
            dataSource={props.dataSource}
            hoverStateEnabled={true}
            showBorders={true}
            keyExpr={props.keyExpr}
            onRowDblClick={(e) => onRowDblClick(e)}
            filterRow={ternaryFunction(true, props.filterRow)}
            height={ternaryFunction(260, props.height)}
            noDataText={props.noDataText}
        >
            {props.children}
        </DataGridScroll>    
    )
}

const InstantGrid = (props) => {
    return (
        <DataGrid
            ref={props.gridInstance}
            dataSource={props.dataSource}
            allowColumnReordering={true}
            allowColumnResizing={true} 
            showBorders={true}
            showColumnLines={true}
            columnAutoWidth={true}   
            width={props.width}
            height={266}
        >
            <Paging enabled={false} />
            <Editing
                mode="cell"
                allowUpdating={props.enabled || props.onlyUpdate}
                allowAdding={props.enabled}
                allowDeleting={props.enabled || props.onlyDelete}
                confirmDelete={false}
                selectTextOnEditStart={true}
                startEditAction={'click'}
            />
            <Scrolling mode='standard' columnRenderingMode="standard" />
            <Toolbar>
                <Item location='before'>
                    <span className='fw-additional-label'>{props.title}</span>
                </Item>
                <Item location='after'>
                    {props.enabled &&
                        <Button
                            icon={'add'}
                            onClick={() => props.dataSource.store().insert({}).then(() => props.dataSource.reload())}
                        />  
                    }                     
                    {props.button && props.button }
                </Item>  
            </Toolbar>
            {props.children}
        </DataGrid>
    );
}

const PagingGrid = (props) => {
    const { 
        title, role, width, height,
        pageSize, mainKey, gridRef, gridDataSource, 
        onRowClick, onRowDblClick,
        dateValue, dateValueChange, duraYesn, duraYesnChange, 
        edit, toolbar, ins, upd, del, exp, 
        selection, children,
        dispose,
        grouping
    } = props;

    const fileName = `${title}-현황`;

    if (dispose) {
        return null;
    }

    return (
        <ScrollView>
            <DataGrid
                className={`fw-page-grid-row-${pageSize}`}
                width={width}
                height={height}
                keyExpr={mainKey}
                ref={gridRef}
                dataSource={gridDataSource}
                onRowClick={onRowClick}
                onRowDblClick={onRowDblClick}
                onExporting={fwUtil.exportData(fileName)}
                onToolbarPreparing={onToolbarPreparing}
                noDataText='표시할 정보가 없습니다'
                showBorders
                focusedRowEnabled={selection === 'multiple' ? true : false}   
                allowColumnReordering
                allowColumnResizing
                columnAutoWidth
                showColumnLines
                showRowLines
                rowAlternationEnabled
                repaintChangesOnly
            >   
                <LoadPanel enabled />
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Scrolling columnRenderingMode='virtual'/>  
                <Paging enabled={true} defaultPageSize={pageSize} />
                <Pager showInfo={true} showNavigationButtons={false} showPageSizeSelector={false} />
                <Selection {...getSelectionProps(selection)} />
                { grouping && <GroupPanel visible /> }
                { grouping && <Grouping autoExpandAll /> }
                { toolbar && <ColumnChooser enabled /> }
                { toolbar && <SearchPanel visible placeholder='검색' /> }
                { exp && <Export enabled allowExportSelectedData formats={exp.formats} /> }
                { edit && <Editing mode="cell" allowUpdating /> }       
                { toolbar && GridToolbar({
                    title: `${title} ${role}`,
                    dateValue: dateValue ,
                    dateValueChange: dateValueChange,
                    duraYesn: duraYesn,
                    duraYesnChange: duraYesnChange,
                    ins: ins,
                    upd: upd,
                    del: del,
                    exp: exp,
                    defaultItems: toolbar.default,
                    toolbarBeforeItems: toolbar.before,
                    toolbarAfterItems: toolbar.after
                })}
                {children}
            </DataGrid>   
            <div className={'fw-fake-pager'} />         
        </ScrollView>
    );
};

const ScrollGrid = (props) => {
    const {
        title, role, width, height,
        mainKey, gridRef, gridDataSource, 
        onRowClick, onRowDblClick,
        dateValue, dateValueChange, duraYesn, duraYesnChange, 
        edit, toolbar, ins, upd, del, exp, 
        selection, children,
        dispose
    } = props;

    const fileName = `${title}-현황`;

    if (dispose) {
        return null;
    }

    return (
        <ScrollView>
            <DataGrid       
                className={`fw-scroll-grid`}
                width={width}
                height={height}
                keyExpr={mainKey}
                ref={gridRef}
                dataSource={gridDataSource}
                onRowClick={onRowClick}
                onRowDblClick={onRowDblClick}
                onExporting={fwUtil.exportData(fileName)}
                onToolbarPreparing={onToolbarPreparing}
                noDataText='표시할 정보가 없습니다'         
                focusedRowEnabled={selection ? true : false}   
                showBorders
                allowColumnReordering
                allowColumnResizing
                columnAutoWidth
                showColumnLines
                showRowLines
                rowAlternationEnabled
                repaintChangesOnly
            >
                <LoadPanel enabled />
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Scrolling columnRenderingMode='standard' mode='standard'/>
                <Paging enabled={false} />
                <Selection {...getSelectionProps(selection)} />
                { toolbar && <ColumnChooser enabled /> }
                { toolbar && <SearchPanel visible placeholder='검색' /> }
                { exp && <Export enabled allowExportSelectedData formats={exp.formats} /> }
                { edit && <Editing mode="cell" allowUpdating /> }
                { toolbar && GridToolbar({
                        title: `${title} ${role}`,
                        dateValue: dateValue ,
                        dateValueChange: dateValueChange,
                        duraYesn: duraYesn,
                        duraYesnChange: duraYesnChange,
                        ins: ins,
                        upd: upd,
                        del: del,
                        exp: exp,
                        defaultItems: toolbar.default,
                        toolbarBeforeItems: toolbar.before,
                        toolbarAfterItems: toolbar.after
                    })}            
                {children}
            </DataGrid>
        </ScrollView>
    )
};

const AddingGrid = (props) => {
    const {
        title, width, height,
        gridRef, gridDataSource,
        allowEdit,  allowInsert, allowUpdate, allowDelete, additionTool,
        children
    } = props;
    const gridHeight = fwUtil.ternFunc(266, height);

    return (
        <DataGrid
            ref={gridRef}
            dataSource={gridDataSource}
            width={width}
            height={gridHeight} 
            showBorders
            showColumnLines
            columnAutoWidth 
            allowColumnResizing
            allowColumnReordering  
        >
            <Paging enabled={false} />
            { allowEdit && 
            <Editing
                mode="cell"
                startEditAction={'click'}
                allowAdding={allowInsert}
                allowUpdating={allowUpdate}
                allowDeleting={allowDelete}
                confirmDelete={false}
                selectTextOnEditStart
            />
            }
            <Scrolling mode='standard' columnRenderingMode="standard" />
            <Toolbar>
                <Item location='before'>
                    <span className='fw-additional-label'>{title}</span>
                </Item>
                <Item location='after'>
                    { allowInsert &&
                        <Button
                            icon={'add'}
                            onClick={() => gridDataSource.store().insert({}).then(() => gridDataSource.reload())}
                        />  
                    }                     
                    { additionTool && additionTool }
                </Item>  
            </Toolbar>
            {children}
        </DataGrid>
    );
}

export { 
    DataGridPaging, 
    DataGridScroll,
    DropDownGrid,
    InstantGrid,
    PagingGrid,
    ScrollGrid,
    AddingGrid,
}

const getSelectionProps = (selection) => {
    switch (selection) {
        case 'single':
            return { showCheckBoxesMode: 'none', mode: 'single' };
        case 'multiple':
            return { selectAllMode: 'allPages', showCheckBoxesMode: 'always', mode: 'multiple' };
        default:
            return { mode: 'none' };
    }
};

function onCellPrepared(e, fieldList) {
    const matchingField = fieldList?.find(field => field.dataField === e.column.dataField);
    if (matchingField && matchingField.tooltip) {
        e.cellElement.setAttribute("title", matchingField.tooltip);
    }
};

function dropGridRowDblClick(e, dataChang) {
    dataChang(e);
};