import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const listDataSource = fwUtil.generateDataSource();

export function InspProsRegistUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 발주 그리드
    const listRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            listDataSource.store().insert({ init: true }).then(() => listDataSource.reload());
            const selectedRowData = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const item of selectedRowData) {
                const newData = {
                    insp_numb: item.insp_numb || '',
                    insp_seri: item.insp_seri || '',
                    insp_cate: item.insp_cate || '',
                    insp_spec: item.insp_spec || '',
                    insp_math: item.insp_math || '',
                    insp_resu: item.insp_resu || '',
                    work_name: item.work_name || '',
                    item_remk: item.item_remk || '',
                    item_memo: item.item_memo || '',       
                };
                listDataSource.store().insert(newData);
            }
            
            listDataSource.reload();
        }
    }, [hiding, mainGrid]);

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mainKey = fwUtil.findSelectedRowKey(mainGrid);
        const listData = listRef?.current?.instance?.getDataSource()?.items();
        const postInspData = listData.map((item) => ({
            inspNumb: item.insp_numb || '',
            inspSeri: item.insp_seri || '',
            inspCate: item.insp_cate || '',
            inspSpec: item.insp_spec || '',
            inspMath: item.insp_math || '',
            inspResu: item.insp_resu || '',
            workName: item.work_name || '',
            itemRemk: item.item_remk || '',
            itemMemo: item.item_memo || '',            
        }));        
        const postData = {
            setAmaps: postInspData || [],
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [updateApi, refresh, hiding, alertHide, listRef, mainGrid]);

    return (
        <>
        <PopupForm
            title={'수입검사 항목 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'수입검사 항목'}
                        gridRef={listRef} 
                        gridDataSource={listDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "insp_numb", caption: "검사번호", allowEditing: false })}
                        {CommCol({ dataField: "insp_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "insp_cate", caption: "검사항목", check: true })}
                        {CommCol({ dataField: "insp_spec", caption: "기준(규격)", check: true })}
                        {CommCol({ dataField: "insp_math", caption: "검사방법", check: true })}
                        {CommCol({ dataField: "insp_resu", caption: "검사결과", check: true })}
                        {CommCol({ dataField: "work_name", caption: "작업자", check: true })}
                        {CommCol({ dataField: "item_remk", caption: "특기사항", check: true })}
                        {CommCol({ dataField: "item_memo", caption: "비고", check: true })}      
                    </AddingGrid>                    
                </div>
            }         
        />
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 수정 하시겠습니까?'}
        />  
        </>
    );
}