import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormRegx, FormText, FormNumb } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    cata_code: '',
    catb_code: '',
    catc_code: '', 
    catc_name: '', 
    regi_date: fwUtil.getToDayText(), 
    enab_yesn: true,
    cate_hori: 0,
    cate_vert: 0,
    cate_thic: 0,
    cate_leng: 0,
};

const cataSearchApi = '/privat/sys/set/matlcode/cata/list';
const catbSearchApi = '/privat/sys/set/matlcode/catb/list';

export function CatcStatusInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 분류 그리드
    const cateKeyExpr = 'cate_code';
    const cateGridRef = useRef(null);
    const [cateData, setCateData] = useState([]);
    const cateGridUpdate = useCallback((e) => setCateData(e), []);
    // 분류 그리드 팝업
    const [popupLoading, setPopupLoading] = useState(false);
    const [catePopupType, setCatePopupType] = useState('');
    const [catePopupVisible, setCatePopupVisible] = useState(false);
    const catePopupHiding = useCallback(() => setCatePopupVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            catcCode: `${data.catb_code || '--'}${data.catc_code || '---'}`,
            catcName: data.catc_name || '',
            regiDate: data.regi_date || null,
            enabYesn: data.enab_yesn || false,
            cateHori: data.cate_hori || 0,
            cateVert: data.cate_vert || 0,
            cateThic: data.cate_thic || 0,
            cateLeng: data.cate_leng || 0,            
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    // 분류 조회
    const cateHandleSearch = useCallback(async () => {
        setPopupLoading(true);
        let sendData;
        let api;
        switch (catePopupType) {
            case '대분류':
                sendData = { cateName: "" };
                api = cataSearchApi;
                break;
            case '중분류':
                sendData = { cataCode: data.cata_code };
                api = catbSearchApi;
                break;
            default:
                break;
        }
    
        if (sendData) {
            await fwUtil.searchData(sendData, api, cateGridUpdate);
        }
        setPopupLoading(false);
    }, [catePopupType, data]);

    // 분류 팝업 오픈
    const onGridPopupClick = useCallback((type) => {
        setCatePopupType(type);
        setCatePopupVisible(true);
    }, [setCatePopupType, setCatePopupVisible]);

    // 분류 팝업 열릴 때
    const catePopupOnShowing = useCallback(() => {
        cateHandleSearch(catePopupType);
    }, [cateHandleSearch, catePopupType]);

    // 분류 팝업 더블 클릭
    const cateOnRowDblClick = useCallback((e) => {
        let cateType;
        switch (catePopupType) {
            case '대분류':
                cateType = 'cata';
                break;
            case '중분류':
                cateType = 'catb';
                break;
            default:
                notify("분류 유형이 올바르게 지정되지 않았습니다", 'error', 2000);
                break;
        }
        
        if (cateType) {
            dataUpdate({ ...data, [`${cateType}_code`]: e.data.cate_code, [`${cateType}_name`]: e.data.cate_name });
        } else {
            notify("데이터 입력 중 오류가 발생했습니다", 'error', 2000);
        }

        catePopupHiding();
    }, [data, dataUpdate, catePopupHiding, catePopupType]);

    return (
        <>
        <PopupGrid
            title={`${catePopupType} 선택`}   
            type={'선택'}
            loading={popupLoading}
            visible={catePopupVisible}  
            showing={catePopupOnShowing}  
            hiding={catePopupHiding}
        >   
            <ScrollGrid
                gridDataSource={cateData}
                gridRef={cateGridRef}
                mainKey={cateKeyExpr}
                width={isXSmall || isSmall ? 300 : 600 }
                height={450}
                onRowDblClick={cateOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "cate_code", caption: `${catePopupType}코드`, width: 100 })}
                {CommCol({ dataField: "cate_name", caption: `${catePopupType}명` })}
            </ScrollGrid>
        </PopupGrid>                
        <PopupForm
            title={'소분류 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600 }  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormGrid({ value: data.cata_code, onClick: () => onGridPopupClick('대분류'), label: '대분류코드', required: true })}
                {FormGrid({ value: data.catb_code, onClick: () => onGridPopupClick('중분류'), label: '중분류코드', required: true })}
                {FormRegx({ value: data.catc_code, onValueChange: updateField('catc_code'), label: '소분류코드', required: true, rule: /^[a-zA-Z0-9]{3}$/, message: "영어 숫자 조합 3자리를 입력해주세요!" })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.catc_name, onValueChange: updateField('catc_name'), label: '소분류명', required: true })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
                {FormNumb({ value: data.cate_hori, onValueChange: updateField('cate_hori'), label: '가로', length: [10, 0] })}
                {FormNumb({ value: data.cate_vert, onValueChange: updateField('cate_vert'), label: '세로', length: [10, 0] })}
                {FormNumb({ value: data.cate_thic, onValueChange: updateField('cate_thic'), label: '두께', length: [10, 0] })}
                {FormNumb({ value: data.cate_leng, onValueChange: updateField('cate_leng'), label: '길이', length: [10, 0] })}
            </GroupItem>
        </PopupForm>     
        </>
    );
}