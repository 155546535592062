import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupRepo } from '../../../../script/components/popup/popup';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    ppro_numb: '',
    clie_code: '',
    clie_name: '',
    clie_teln: '',
    clie_faxn: '',
    clie_damd: '',
    pdel_date: null,
    pays_cond: '',
    insp_damd: '',
    deli_plac: '',
    ppro_remk: '',
    damd_name: '',
    ppro_date: fwUtil.getToDayText(),
    ppro_usag: '',
    afte_conf: false,
};

export function CalibHistoryReport(props) {
    const {
        targetApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    return (
        <PopupRepo 
            title={'측정장비 관리대장 출력'}       
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            width={isXSmall || isSmall ? 300 : 600}  
        >

        </PopupRepo>            
    );
}