import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../../../api/auth';

export default function CreateAccountForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ userIdno: '', passWord: '' });

  const userNameRegex = /^[a-zA-Z가-힣]+$/;
  const cellNumbRegex = /^[0-9]+$/;
  const userIdnoRegex = /^[a-zA-Z0-9]{4,16}$/;

  function validateValue(params, reg) {
    const value = params.value;
    if (!reg.test(value)) {
      return false;
    }
    return true;
  };

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { userIdno, passWord } = formData.current;
    setLoading(true);

    const result = await createAccount(userIdno, passWord);
    setLoading(false);

    if (result.isOk) {
      navigate('/login');
    } else {
      notify(result.message, 'error', 2000);
    }
  }, [navigate]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.passWord,
    []
  );


  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
      <Item
          dataField={'userName'}
          editorType={'dxTextBox'}
          editorOptions={userNameEditorOptions}
        >
          <RequiredRule message="이름을 입력해주세요" />
          <CustomRule 
            validationCallback={(e) => validateValue(e, userNameRegex)} 
            message="이름은 한글 또는 영문만 입력가능합니다."
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'cellNumb'}
          editorType={'dxTextBox'}
          editorOptions={cellNumbEditorOptions}
        >
          <RequiredRule message="휴대폰 번호를 입력해주세요( - 제외 )" />
          <CustomRule 
            validationCallback={(e) => validateValue(e, cellNumbRegex)} 
            message="휴대폰 번호는 숫자만 입력가능합니다."
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'userIdno'}
          editorType={'dxTextBox'}
          editorOptions={userIdnoEditorOptions}
        >
          <RequiredRule message="아이디를 입력해주세요" />
          <CustomRule 
            validationCallback={(e) => validateValue(e, userIdnoRegex)} 
            message="아이디는 4-16자까지의 길이의 영문자, 숫자 조합만 가능합니다."
          />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'passWord'}
          editorType={'dxTextBox'}
          editorOptions={passWordEditorOptions}
        >
          <RequiredRule message="비밀번호를 입력해주세요" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassWord'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPassWordEditorOptions}
        >
          <RequiredRule message="비밀번호 확인을 해주세요" />
          <CustomRule
            message={'비밀번호와 일치하지 않습니다'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : '승인 요청'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            이미 계정이 있으시다면? <Link to={'/login'}>로그인</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
}

const userNameEditorOptions = { stylingMode: 'filled', placeholder: '이름', mode: 'text' };
const cellNumbEditorOptions = { stylingMode: 'filled', placeholder: '휴대폰 번호(- 제외)', mode: 'text' };
const userIdnoEditorOptions = { stylingMode: 'filled', placeholder: '아이디', mode: 'text' };
const passWordEditorOptions = { stylingMode: 'filled', placeholder: '비밀번호', mode: 'password' };
const confirmedPassWordEditorOptions = { stylingMode: 'filled', placeholder: '비밀번호 확인', mode: 'password' };
