// ForwardingStatus - 불출 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item, Summary } from 'devextreme-react/data-grid';
import { TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "불출";
const role = "현황";
const gridSize = 10;

const gubu = "mes";                                        // 페이지 메뉴 구분
const code = "C31";                                        // 페이지 메뉴 코드
const mainKey = 'forw_numb';                               // 데이터 키

const searchApi = 'privat/mat/forwarding/status/search';   // 조회 api

const tempData = [
    {
        "forw_numb": "FO23050001",
        "work_numb": "WK123456789012",
        "rout_numb": "001",
        "rout_name": "공정1",
        "forw_dept": "생산부",
        "matl_code": "M12345",
        "item_gubu": "02",
        "gubu_name": "반제품",
        "matl_name": "Material A",
        "lots_numb": "LOT001",
        "forw_qtys": 100.50,
        "forw_pric": 5.75,
        "forw_kwon": 575.00,
        "forw_date": "2024-05-21",
        "forw_memo": "메모1",
        "user_name": "사용자1",
        "user_date": "2024-05-21",
        "modi_name": "수정자1",
        "modi_date": "2024-05-22"
    },
    {
        "forw_numb": "FO23050002",
        "work_numb": "WK987654321098",
        "rout_numb": "002",
        "rout_name": "공정2",
        "forw_dept": "조립부",
        "matl_code": "M67890",
        "item_gubu": "03",
        "gubu_name": "원자재",
        "matl_name": "Material B",
        "lots_numb": "LOT002",
        "forw_qtys": 250.00,
        "forw_pric": 3.50,
        "forw_kwon": 875.00,
        "forw_date": "2024-05-21",
        "forw_memo": "메모2",
        "user_name": "사용자2",
        "user_date": "2024-05-21",
        "modi_name": "수정자2",
        "modi_date": "2024-05-22"
    },
    {
        "forw_numb": "FO23050003",
        "work_numb": "WK123459876543",
        "rout_numb": "003",
        "rout_name": "공정3",
        "forw_dept": "포장부",
        "matl_code": "M54321",
        "item_gubu": "02",
        "gubu_name": "반제품",
        "matl_name": "Material C",
        "lots_numb": "LOT003",
        "forw_qtys": 75.75,
        "forw_pric": 4.25,
        "forw_kwon": 322.94,
        "forw_date": "2024-05-21",
        "forw_memo": "메모3",
        "user_name": "사용자3",
        "user_date": "2024-05-21",
        "modi_name": "수정자3",
        "modi_date": "2024-05-22"
    }
];

const exportFormats = ['xlsx', 'pdf'];

function ForwardingStatus() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [forwNumb, setForwNumb] = useState("");    
    const [workNumb, setWorkNumb] = useState("");    
    const [matlName, setMatlName] = useState("");    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const forwNumbChange = useCallback((e) => { setForwNumb(e) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, forwNumb: forwNumb, workNumb: workNumb, matlName: matlName };
        //await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, forwNumb, workNumb, matlName, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);


    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "불출번호", value: forwNumb, valueChange: forwNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "작업지시번호", value: workNumb, valueChange: workNumbChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "자재명", value: matlName, valueChange: matlNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.multipleSelectRow(gridRef, key, isSelected);
    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}      
                onRowClick={onRowClick}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "forw_dept", caption: "불출부서" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "gubu_name", caption: "구분" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "lots_numb", caption: "LOT NO." })}
                {DeciCol({ dataField: "forw_qtys", caption: "불출수량" })}
                {DeciCol({ dataField: "forw_pric", caption: "불출단가" })}
                {DeciCol({ dataField: "forw_kwon", caption: "불출금액" })}
                {DateCol({ dataField: "forw_date", caption: "불출일자" })}
                {CommCol({ dataField: "forw_memo", caption: "비고" })}
                <Summary >
                    {TotalSumItem({ dataField: 'forw_qtys', caption: "불출 총수량" })}
                    {TotalSumItem({ dataField: 'forw_kwon', caption: "불출 총금액" })}
                </Summary>                
            </PagingGrid> 
        </div>
    );
};


export default ForwardingStatus;