// WorkOrderChange -작업지시 변경
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, NumbCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const mainTitle = "작업지시";
const mainRole = "변경";
const subsTitle = "작업진행";
const subsRole = "현황";
const gridSize = 5;

const gubu = "mes";                                                            // 페이지 메뉴 구분
const code = "D23";                                                            // 페이지 메뉴 코드
const mainKey = 'work_numb';                                                   // 메인 데이터 키
const subsKey = ['work_numb', 'prod_seri'];                                    // 서브 데이터 키
const mainDeleKey = (data) => {                                                // 삭제 데이터 키
    return { workNumb: data }
};

const mainSearchApi = 'privat/mfg/workOrder/change/header/search';    // 메인 조회 api
const mainDeleteApi = 'privat/mfg/workOrder/change/header/delete';    // 메인 삭제 api
const subsSearchApi = 'privat/mfg/workOrder/change/detail/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        "work_numb": "W1234567890",
        "plan_numb": "P1234567890",
        "orde_numb": "O1234567890",
        "proj_code": "PJ1234",
        "proj_name": "Project ABC",
        "item_code": "IT1234",
        "item_name": "Item XYZ",
        "file_qtys": 5,
        "work_qtys": 100,
        "work_date": "2024-05-26",
        "star_date": "2024-05-27",
        "clos_date": "2024-05-30",
        "work_clos": true,
        "indx_keys": "WO1234567890"
    },
    {
        "work_numb": "W0987654321",
        "plan_numb": "P0987654321",
        "orde_numb": "O0987654321",
        "proj_code": "PJ5678",
        "proj_name": "Project DEF",
        "item_code": "IT5678",
        "item_name": "Item UVW",
        "file_qtys": 3,
        "work_qtys": 200,
        "work_date": "2024-06-01",
        "star_date": "2024-06-02",
        "clos_date": "2024-06-05",
        "work_clos": false,
        "indx_keys": "WO0987654321"
    }
]

const tempSubsData = [
    {
        "work_numb": "W1234567890",
        "prod_seri": 1,
        "comp_date": "2024-05-27",
        "rout_numb": "R001",
        "rout_name": "Assembly",
        "star_time": "08:00",
        "clos_time": "12:00",
        "work_name": "Worker A",
        "prod_memo": "Initial assembly completed"
    },
    {
        "work_numb": "W1234567890",
        "prod_seri": 2,
        "comp_date": "2024-05-28",
        "rout_numb": "R002",
        "rout_name": "Painting",
        "star_time": "13:00",
        "clos_time": "17:00",
        "work_name": "Worker B",
        "prod_memo": "Painting completed"
    },
    {
        "work_numb": "W0987654321",
        "prod_seri": 1,
        "comp_date": "2024-06-02",
        "rout_numb": "R003",
        "rout_name": "Inspection",
        "star_time": "09:00",
        "clos_time": "11:00",
        "work_name": "Worker C",
        "prod_memo": "Inspection completed"
    }
]

function WorkOrderChange() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [workNumb, setWorkNumb] = useState("");
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { workNumb: workNumb };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [workNumb, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { workNumb: keyData.workNumb };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            const convertedData = tempSubsData.filter(item => item.work_numb === keyData);
            subsGridDataUpdate(convertedData);
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.multipleSelectRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(mainGridRef, mainDeleteApi, mainDeleKey, mainHandleSearch);
        deleteAlertHide();
    }, [mainGridRef, mainHandleSearch, deleteAlertHide]);    

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "작업지시번호", value: workNumb, valueChange: workNumbChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    // 삭제 버튼 클릭
    const handleDeleteClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGridRef);

        if (selectedRowKey) {
            setDeleteAlertVisible(true);
            return;
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [mainGridRef]);    

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                del={{ onClick: handleDeleteClick, disabled: authList.auth_dele === 0 }}          
                toolbar={{ default: false, before: mainToolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "plan_numb", caption: "계획번호" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "proj_code", caption: "프로젝트코드" })}
                {CommCol({ dataField: "proj_name", caption: "프로젝트명" })}
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "item_name", caption: "품목명" })}
                {NumbCol({ dataField: "file_qtys", caption: "첨부파일수" })}
                {NumbCol({ dataField: "work_qtys", caption: "지시수량" })}
                {DateCol({ dataField: "work_date", caption: "작업지시일" })}
                {DateCol({ dataField: "star_date", caption: "생산시작일" })}
                {CommCol({ dataField: "clos_date", caption: "생산완료일" })}
                {BoolCol({ dataField: "work_clos", caption: "생산완료여부" })}
                {CommCol({ dataField: "indx_keys", caption: "파일첨부키" })}
            </MainGrid>
            <DeleteAlert 
                grid={mainGridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 작업지시를 삭제하시겠습니까?'}
            />  
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                selection={'single'}
                toolbar={{ default: false }}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "prod_seri", caption: "일보순번" })}
                {DateCol({ dataField: "comp_date", caption: "작업일자" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {TimeCol({ dataField: "star_time", caption: "작업시작" })}
                {TimeCol({ dataField: "clos_time", caption: "작업종료" })}
                {CommCol({ dataField: "work_name", caption: "작업자" })}
                {CommCol({ dataField: "prod_memo", caption: "비고" })}
            </SubsGrid>                            
        </div>
    );
};


export default WorkOrderChange;