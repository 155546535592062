// InspRoutRegist - 중간검사 등록
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { InspRoutRegistInsert } from './Regist-insert';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";

const title = "중간검사";
const role = "등록";
const gridSize = 10;

const gubu = "mes";                                         // 페이지 메뉴 구분
const code = "E22";                                         // 페이지 메뉴 코드
const mainKey = ['work_numb', 'prod_seri'];                 // 데이터 키   

const searchApi = 'privat/qcl/rout/regist/search';          // 조회 api
const insertApi = 'privat/qcl/rout/regist/insert';          // 등록 api

const exportFormats = ['xlsx', 'pdf'];

const tempData = [
    {
        "work_numb": "WO12345",
        "prod_seri": "001",
        "clie_code": "C001",
        "clie_name": "ABC Company",
        "item_code": "I001",
        "item_name": "Product A",
        "rout_numb": "R001",
        "rout_name": "Assembly",
        "work_qtys": 100,
        "comp_date": "2024-05-27",
        "star_time": "08:00:00",
        "clos_time": "16:00:00",
        "work_name": "John Doe",
        "prod_memo": "Testing purposes"
    },
    {
        "work_numb": "WO67890",
        "prod_seri": "002",
        "clie_code": "C002",
        "clie_name": "XYZ Corporation",
        "item_code": "I002",
        "item_name": "Product B",
        "rout_numb": "R002",
        "rout_name": "Packaging",
        "work_qtys": 150,
        "comp_date": "2024-05-28",
        "star_time": "09:00:00",
        "clos_time": "17:00:00",
        "work_name": "Jane Smith",
        "prod_memo": "Sample data"
    }
]

function InspRoutRegist() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [workNumb, setWorkNumb] = useState("");
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { workNumb: workNumb };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [workNumb, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = useCallback (() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품명", value: workNumb, valueChange: workNumbChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    }, [workNumb, workNumbChange, handleSearch]);

    // 등록 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-row-flex-box'>
            <PagingGrid
                title={title}
                role={role}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol ({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol ({ dataField: "prod_seri", caption: "일보순번" })}
                {CommCol ({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol ({ dataField: "clie_name", caption: "고객명" })}
                {CommCol ({ dataField: "item_code", caption: "품목번호" })}
                {CommCol ({ dataField: "item_name", caption: "품목명" })}
                {CommCol ({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol ({ dataField: "rout_name", caption: "공정명" })}
                {DeciCol ({ dataField: "work_qtys", caption: "작업수량" })}
                {DateCol ({ dataField: "comp_date", caption: "작업일자" })}
                {TimeCol ({ dataField: "star_time", caption: "시작시간" })}
                {TimeCol ({ dataField: "clos_time", caption: "종료시간" })}
                {CommCol ({ dataField: "work_name", caption: "작업자" })}
                {CommCol ({ dataField: "prod_memo", caption: "비고" })}
            </PagingGrid>        
            <InspRoutRegistInsert
                mainGrid={gridRef}
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />                 
        </div>
    );
};


export default InspRoutRegist;