// 사이드바 네비게이션 트리에 사용되는 라우터 경로
function menuLoop(pUserMenu, pMenuGubu, pMenuCode) {
  let result = pUserMenu.map(menuItem => {
    let menuGubu = menuItem.menu_gubu.trim();
    let menuCode = menuItem.menu_code.trim();
    let menuHigh = menuItem.menu_high.trim();
    let menuName = menuItem.menu_name.trim();
    let menuPath = menuItem.menu_path.trim();
    let menuIcon = menuItem.menu_icon.trim();
    if (menuGubu === pMenuGubu && menuHigh === pMenuCode) {
      if (menuPath === "") {
        return ({
          text: menuName,
          icon: menuIcon,
          items: menuLoop(pUserMenu, menuGubu, menuCode),
        });
      } else {
        return ({
          text: menuName,
          path: menuPath,
        });
      }
    } else {
      return null;
    }
  }).filter(item => item !== null);
  return result;
};

export function CreateTreeNavigation(userMenu) {
  let menuData = [];

  const homeRoute = {
    text: '메인화면',
    path: '/home',
    icon: 'home'
  };
  menuData.push(homeRoute);

  const mesRoute = {
    text: 'MES',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48"><defs><mask id="ipSSettingWeb0"><g fill="none"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M24 40H7a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v12.059"/><path fill="#fff" stroke="#fff" stroke-width="4" d="M4 11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v9H4z"/><circle r="2" fill="#000" transform="matrix(0 -1 -1 0 10 14)"/><circle r="2" fill="#000" transform="matrix(0 -1 -1 0 16 14)"/><circle cx="37" cy="34" r="3" stroke="#fff" stroke-width="4"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M37 41v-4m0-6v-4m-6.062 10.5l3.464-2m5.196-3l3.464-2m-12.124 0l3.464 2m5.196 3l3.464 2"/></g></mask></defs><path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSSettingWeb0)"/></svg>',
    items: userMenu.map(menuItem => {
      let menuGubu = menuItem.menu_gubu.trim();
      let menuCode = menuItem.menu_code.trim();
      let menuHigh = menuItem.menu_high.trim();
      let menuName = menuItem.menu_name.trim();
      let menuIcon = menuItem.menu_icon.trim();
      if (menuHigh === "" && menuGubu === 'mes') {
        return {
          text: menuName,
          icon: menuIcon,
          items: menuLoop(userMenu, menuGubu, menuCode),
        };
      } else {
        return null;
      }
    }).filter(item => item !== null),
  };
  menuData.push(mesRoute);

  const apsRoute = {
    text: 'APS',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M7 1v2H3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h7.755A8 8 0 0 1 22 9.755V4a1 1 0 0 0-1-1h-4V1h-2v2H9V1zm16 15a6 6 0 1 1-12 0a6 6 0 0 1 12 0m-7-4v4.414l2.293 2.293l1.414-1.414L18 15.586V12z"/></svg>',
    items: userMenu.map(menuItem => {
      let menuGubu = menuItem.menu_gubu.trim();
      let menuCode = menuItem.menu_code.trim();
      let menuHigh = menuItem.menu_high.trim();
      let menuName = menuItem.menu_name.trim();
      let menuIcon = menuItem.menu_icon.trim();
      if (menuHigh === "" && menuGubu === 'aps') {
        return {
          text: menuName,
          icon: menuIcon,
          items: menuLoop(userMenu, menuGubu, menuCode),
        };
      } else {
        return null;
      }
    }).filter(item => item !== null),
  };
  menuData.push(apsRoute);

  return menuData;
};

export function createMenuTreeList(userMenu) {
  let menuData = [];
  const homeRoute = {
      menu_keys: 'home',
      menu_code: '1',
      menu_high: '',
      menu_gubu: 'root',
      menu_icon: 'home',
      menu_name: '메인화면',
      icon: 'home'
  };
  menuData.push(homeRoute);

  const mesRoute = {
      menu_keys: 'mes',
      menu_code: '2',
      menu_high: '',
      menu_gubu: 'root',
      menu_icon: 'home',
      menu_name: 'MES',
      icon: 'home'
  };
  menuData.push(mesRoute);

  const apsRoute = {
      menu_keys: 'aps',
      menu_code: '3',
      menu_high: '',
      menu_gubu: 'root',
      menu_icon: 'home',
      menu_name: 'APS',
      icon: 'home'
  };
  menuData.push(apsRoute);

  const updateHighMenu = userMenu.map(item => {
      const menuHigh = item.menu_high.trim();
      const menuKey = `${item.menu_gubu}-${item.menu_code}`;
      const menuParn = `${item.menu_gubu}-${menuHigh}`;

      if (menuHigh === '') {
          return {
              menu_keys: menuKey,
              menu_parn: item.menu_gubu === 'aps' ? 'aps' : 'mes',
              ...item,                
      };
      } else {
          return {
              menu_keys: menuKey,
              menu_parn: menuParn,
              ...item
          };
      }
  });
  menuData.push(...updateHighMenu);

  return menuData;
};
