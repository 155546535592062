import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    addr_ipnb: '',
    addr_yesn: false,
    addr_memo: '',
};

export function IpStatusUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('IP 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            const sendData = { addrIpnb: selectedRowKey };
            const res = await fwUtil.targetData(sendData, targetApi);
            const resData = {
                addr_ipnb: res.addr_ipnb || '',
                addr_yesn: res.addr_yesn === 1 ? true : false,
                addr_memo: res.addr_memo || '',
            };

            dataUpdate(resData);
        }
    }, [visible, hiding, mainGrid, targetApi]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            alloCont: data.addr_ipnb || 0,
            alloYesn: data.addr_yesn || false,
            alloPath: data.addr_memo || ''
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'IP 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormText({ value: data.addr_ipnb, onValueChange: updateField('addr_ipnb'), label: '허용 IP', disabled: true, required: true })}                
                {FormBool({ value: data.addr_yesn, onValueChange: updateField('addr_yesn'), label: '사용여부' })}                          
            </GroupItem>
            {FormMemo({ value: data.addr_memo, onValueChange: updateField('addr_memo'), label: '허용사유', required: true })}     
        </PopupForm>        
    );
}