//URIStatus - URI 관리
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Item } from 'devextreme-react/data-grid';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { ToolbarSelcBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { UriStatusInsert } from './UriStatus-insert';
import { UriStatusUpdate } from './UriStatus-update';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const title = "URI";
const role = "관리";
const gridSize = 10;

const mainKey = 'allo_cont';                         // 데이터 키
const deleKey = (data) => {                          // 삭제 데이터 키
    return { alloCont: data }
}        

const searchApi = '/privat/sys/path/uri/search';     //  조회 api
const targetApi = '/privat/sys/path/uri/target';     //  타겟 api
const insertApi = '/privat/sys/path/uri/insert';     //  등록 api
const updateApi = '/privat/sys/path/uri/update';     //  수정 api
const deleteApi = '/privat/sys/path/uri/delete';     //  삭제 api

const exportFormats = ['xlsx', 'pdf'];
const filterStatusList = ['사용중', '미사용', '전체'];

function UriStatus(props) {
    const { authList } = props;
    // 그리드
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);        
    // 검색 조건
    const [status, SetStatus] = useState(filterStatusList[0]);
    const statusChange = useCallback((e) => { SetStatus(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { alloYesn: status === "사용중"? 0 : status === "미사용"? 1 : 2 };
        await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
    }, [status, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, status]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.deleteData(gridRef, deleteApi, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "사용여부", value: status, valueChange: statusChange, items: filterStatusList })}
            </Item>
        )
    };

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.findSelectedRowKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            notify('데이터를 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [gridRef]);

    return (
        <div className='fw-horizeon-grid'>
            <div className='fw-grid-page fw-row-flex-box'>
                <PagingGrid
                    title={title}
                    role={role}
                    pageSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                    exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, before: toolbarBeforeItems }}
                    selection={'single'}
                >
                    {NumbCol({ dataField: "allo_cont", caption: "일련번호", width: 140 })}
                    {BoolCol({ dataField: "allo_yesn", caption: "사용여부", allowHeaderFiltering: false, allowSorting: false, width: 80 })}
                    {CommCol({ dataField: "allo_path", caption: "허용URI", minWidth: 140 })}
                </PagingGrid>                
                <UriStatusInsert
                    refresh={handleSearch}
                    insertApi={insertApi}
                    visible={insertPopupVisible}
                    hiding={insertPopupHide}
                />
                <UriStatusUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    targetApi={targetApi}
                    updateApi={updateApi}
                    visible={updatePopupVisible}
                    hiding={updatePopupHide}
                />            
                <DeleteAlert 
                    grid={gridRef}
                    visible={deleteAlertVisible}
                    hiding={deleteAlertHide}
                    confirm={onDeleteClick}
                    message={'해당 URI를 삭제하시겠습니까?'}
                />                 
            </div>            
        </div>
    );
};


export default UriStatus;