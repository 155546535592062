// MaintHistory - 정비 이력
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarButton, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { MaintHistoryReport } from './History-report';
import * as fwUtil from '../../../../script/util';
import notify from "devextreme/ui/notify";


const mainTitle = "정비";
const mainRole = "이력";
const subsTitle = "보수";
const subsRole = "이력";
const gridSize = 5;

const gubu = "mes";                                                // 페이지 메뉴 구분
const code = "F22";                                                // 페이지 메뉴 코드
const mainKey = 'main_numb';                                       // 메인 데이터 키
const subsKey = ['main_numb', 'main_seri'];                        // 서브 데이터 키

const mainSearchApi = 'privat/equ/maint/history/header/search';    // 메인 조회 api
const mainReportApi = 'privat/equ/maint/history/header/report';    // 메인 보고 api
const subsSearchApi = 'privat/equ/maint/history/detail/search';    // 서브 조회 api

const exportFormats = ['xlsx', 'pdf'];

const tempMainData = [
    {
        "chck_type": 0,
        "main_numb": "001",
        "equi_numb": "EQ001",
        "equi_name": "Pump",
        "equi_spec": "Spec A",
        "menu_comp": "Company A",
        "reci_date": "2023-01-10",
        "uses_dept": "Production",
        "main_name": "John Doe",
        "subs_name": "Jane Smith",
        "equi_memo": "Regular maintenance",
        "user_name": "Admin",
        "user_date": "2023-01-11",
        "modi_name": "Admin",
        "modi_date": "2023-01-12"
    },
    {
        "chck_type": 0,
        "main_numb": "002",
        "equi_numb": "EQ002",
        "equi_name": "Compressor",
        "equi_spec": "Spec B",
        "menu_comp": "Company B",
        "reci_date": "2023-02-15",
        "uses_dept": "Maintenance",
        "main_name": "Alice Brown",
        "subs_name": "Bob White",
        "equi_memo": "Urgent repair needed",
        "user_name": "Admin",
        "user_date": "2023-02-16",
        "modi_name": "Admin",
        "modi_date": "2023-02-17"
    },
]

const tempSubsData = [
    {
        "chck_type": 0,
        "main_numb": "001",
        "equi_numb": "EQ001",
        "main_seri": "0001",
        "equi_name": "Pump",
        "main_date": "2023-01-10",
        "main_desc": "Replaced seal",
        "main_kwon": 200.50,
        "main_memo": "Routine maintenance",
        "user_name": "Admin",
        "user_date": "2023-01-11",
        "modi_name": "Admin",
        "modi_date": "2023-01-12"
    },
    {
        "chck_type": 0,
        "main_numb": "002",
        "equi_numb": "EQ002",
        "main_seri": "0001",
        "equi_name": "Compressor",
        "main_date": "2023-02-15",
        "main_desc": "Replaced valve",
        "main_kwon": 350.75,
        "main_memo": "Urgent repair",
        "user_name": "Admin",
        "user_date": "2023-02-16",
        "modi_name": "Admin",
        "modi_date": "2023-02-17"
    },
    {
        "chck_type": 0,
        "main_numb": "002",
        "equi_numb": "EQ002",
        "main_seri": "0002",
        "equi_name": "Compressor",
        "main_date": "2023-03-20",
        "main_desc": "Inspection",
        "main_kwon": 100.00,
        "main_memo": "Needs further inspection",
        "user_name": "Admin",
        "user_date": "2023-03-21",
        "modi_name": "Admin",
        "modi_date": "2023-03-22"
    }
]

function MaintHistory() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    // 권한
    const { getDetailAuth } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.getDefaultDateRange());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);
    // 관리대장 출력 팝업
    const [reportPopupVisible, setReportPopupVisible] = useState(false);
    const reportPopupHide = useCallback(() => setReportPopupVisible(false), []);    

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        // await fwUtil.searchData(sendData, searchApi, gridDataUpdate);
        mainGridDataUpdate(tempMainData);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.findSelectedLastRowKey(mainGridRef);
        if (keyData) {
            const sendData = { saleNumb: keyData };
            //await fwUtil.searchData(sendData, subsSearchApi, subsGridDataUpdate);
            subsGridDataUpdate(tempSubsData.filter(item => item.main_numb === keyData));
        }
    }, [mainGridRef, subsGridDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const authData = await getDetailAuth(gubu, code)[0];
            setAuthList(authData);
            if (!authData.auth_acce) {
                navigate('/home');
                notify('잘못된 접근입니다', 'warning', 2000);
            }
            setLoading(false);
        };
        fetchData();
    }, [getDetailAuth, navigate]);

    // 추가 툴바 아이템
    const mainToolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "설비명", value: equiName, valueChange: equiNameChange, search: mainHandleSearch })}
                {SearchButton({ search: mainHandleSearch })}
            </Item>
        )
    };

    const mainToolbarAfterItems = () => {
        return (
            <Item location='after' locateInMenu='auto'>
                {ToolbarButton({ icon: "textdocument", text: "관리대장", onClick: onReportClick, disabled: authList.auth_dnlo === 0 })}
            </Item>
        )
    };    

    const onReportClick = useCallback(() => {
        const selectedRow = fwUtil.findSelectedRowKey(mainGridRef);
        if (selectedRow) {
            setReportPopupVisible(true);
        } else {
            notify('설비 선택 후 시도해주세요', 'warning', 2000);
        }

    }, []);        

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <div className='fw-grid-page fw-column-flex-box'>
            <MainGrid
                title={mainTitle}
                role={mainRole}
                pageSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}  
                toolbar={{ default: false, before: mainToolbarBeforeItems, after: mainToolbarAfterItems }} 
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "equi_spec", caption: "규격 및 사양" })}
                {CommCol({ dataField: "menu_comp", caption: "제작회사" })}
                {DateCol({ dataField: "reci_date", caption: "설치일자" })}
                {CommCol({ dataField: "uses_dept", caption: "사용부서" })}
                {CommCol({ dataField: "main_name", caption: "담당자(정)" })}
                {CommCol({ dataField: "subs_name", caption: "담당자(부)" })}
                {CommCol({ dataField: "equi_memo", caption: "비고" })}
            </MainGrid>
            <MaintHistoryReport
                targetApi={mainReportApi}
                visible={reportPopupVisible}
                hiding={reportPopupHide}
            />
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                pageSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ formats: exportFormats, disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_seri", caption: "순번" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "main_date", caption: "보수일자" })}
                {CommCol({ dataField: "main_desc", caption: "보수내용" })}
                {CommCol({ dataField: "main_kwon", caption: "수리비용" })}
                {CommCol({ dataField: "main_memo", caption: "비고" })}
            </SubsGrid>                     
        </div>
    );
};


export default MaintHistory;