import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { getStockAdjustMatl as getMatl } from '../../../../../api/set';
import { FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { SearchButton, ToolbarSelcBox, ToolbarTextBox } from '../../../../script/components/data-grid/data-grid-toolbar/grid-toolbar-items';
import { Item } from 'devextreme-react/cjs/data-grid';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    matl_code: '',
    matl_name: '',
    item_gubu: '',
    orig_qtys: 0.000,
    orig_lots: '',
    orig_hqty: 0.000,
    orig_loca: '',
    orig_pric: 0.00,
    adju_lots: '',
    adju_loca: '',
    adju_qtys: 0.000,
    adju_pric: 0.00,
    adju_memo: '',
};

const gubuList = ["완제품", "반제품", "원자재"];

const tempData = [
    {
        matl_code: "MAT001",
        matl_name: "자재 1",
        item_gubu: "원자재",
        orig_qtys: 150,
        orig_lots: "LOT001",
        orig_hqty: 100,
        orig_loca: "창고 A",
        orig_pric: 10.50
    },
    {
        matl_code: "MAT002",
        matl_name: "자재 2",
        item_gubu: "반제품",
        orig_qtys: 200,
        orig_lots: "LOT002",
        orig_hqty: 150,
        orig_loca: "창고 B",
        orig_pric: 15.75
    },
    {
        matl_code: "MAT003",
        matl_name: "자재 3",
        item_gubu: "완제품",
        orig_qtys: 300,
        orig_lots: "LOT003",
        orig_hqty: 200,
        orig_loca: "창고 C",
        orig_pric: 20.25
    }
];

export function StockAdjustRegistInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 자재(제품) 그리드
    const matlGridRef = useRef(null);
    const [matlData, setMatlData] = useState([]);
    const matlGridUpdate = useCallback((e) => setMatlData(e), []);
    const matlKeyExpr = 'matl_code';
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const [itemGubu, setItemGubu] = useState('완제품');
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const itemGubuChange = useCallback((e) => setItemGubu(e), []);
    // 그리드 팝업
    const [matlPopupVisible, setMatlPopupVisible] = useState(false);
    const matlPopupHiding = useCallback(() => setMatlPopupVisible(false), []);
    // 그리드 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarSelcBox({ caption: "자재구분", value: itemGubu, valueChange: itemGubuChange, search: matlHandleSearch, items: gubuList })}
                {ToolbarTextBox({ caption: "자재코드(제품번호)", value: matlCode, valueChange: matlCodeChange, search: matlHandleSearch })}
                {ToolbarTextBox({ caption: "자재명(제품명)", value: matlName, valueChange: matlNameChange, search: matlHandleSearch })}
                {SearchButton({ search: matlHandleSearch })}
            </Item>
        )
    };

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            itemGubu: data.item_gubu || '',
            adjuLots: data.adju_lots || '',
            origQtys: data.orig_qtys || 0.000,
            adjuQtys: data.adju_qtys || 0.000,
            origPric: data.orig_pric || 0.00,
            adjuPric: data.adju_pric || 0.00,
            adjuMemo: data.adju_memo || '',
        };

        await fwUtil.submitData(postData, insertApi, refresh);
        setLoading(false);
        onHiding();
    }, [data, insertApi, refresh, onHiding]);

    // 자재(제품) 조회
    const matlHandleSearch = useCallback(async() => {
        if (itemGubu) {
            const sendData = { matlCode: matlCode, matlName: matlName, itemGubu: itemGubu };
            const res = await getMatl(sendData);
            //matlGridUpdate(res);
            matlGridUpdate(tempData);
        }
    }, [matlCode, matlName, itemGubu, matlGridUpdate]);

    useEffect(() => {
        matlHandleSearch();
    }, [itemGubu, matlHandleSearch]);

    // 자재(제품) 팝업 열릴 때
    const matlPopupOnShowing = useCallback(() => {
        matlHandleSearch();
    }, [matlHandleSearch]);

    // 자재(제품) 팝업 더블 클릭
    const matlOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 
            matl_code: e.data.matl_code,
            matl_name: e.data.matl_name,
            item_gubu: e.data.item_gubu,
            orig_qtys: e.data.orig_qtys,
            orig_lots: e.data.orig_lots,
            orig_hqty: e.data.orig_hqty,
            orig_loca: e.data.orig_loca,
            orig_pric: e.data.orig_pric,
        });
        matlPopupHiding();
    }, [data, dataUpdate, matlPopupHiding]);

    return (
        <>
        <PopupGrid
            title={'재고조정 자재(제품) 선택'}   
            type={'선택'}
            visible={matlPopupVisible}  
            showing={matlPopupOnShowing}  
            hiding={matlPopupHiding}
        >   
            <ScrollGrid
                title={'자재(제품)'}
                role={'목록'}
                gridDataSource={matlData}
                gridRef={matlGridRef}
                mainKey={matlKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                onRowDblClick={matlOnRowDblClick}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재품명" })}
                {CommCol({ dataField: "item_gubu", caption: "자재구분" })}
                {DeciCol({ dataField: "orig_qtys", caption: "수량" })}
                {CommCol({ dataField: "orig_lots", caption: "LOT NO." })}
                {CommCol({ dataField: "orig_hqty", caption: "LOT NO. 재고" })}
                {CommCol({ dataField: "orig_loca", caption: "저장위치" })}
                {DeciCol({ dataField: "orig_pric", caption: "재고단가" })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupForm
            title={'재고조정 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : 600}  
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                {FormGrid({ value: data.matl_code, onClick: () => setMatlPopupVisible(true), label: '자재번호' })}
                {FormText({ value: data.item_gubu, onValueChange: updateField('item_gubu'), label: '구분', isEditing: true })}
                {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '자재품명', isEditing: true })}
                {FormText({ value: data.orig_qtys, onValueChange: updateField('orig_qtys'), label: '총재고량', isEditing: true })}
                {FormText({ value: data.orig_loca, onValueChange: updateField('orig_loca'), label: '저장우치', isEditing: true })}
                {FormText({ value: data.orig_pric, onValueChange: updateField('orig_pric'), label: '재고단가', isEditing: true })}                
                {FormText({ value: data.orig_lots, onValueChange: updateField('orig_lots'), label: 'LOT NO.', isEditing: true })}
                {FormText({ value: data.orig_hqty, onValueChange: updateField('orig_hqty'), label: 'LOT 재고', isEditing: true })}
                {FormText({ value: data.adju_lots, onValueChange: updateField('adju_lots'), label: '조정 LOT NO.' })}
                {FormText({ value: data.adju_loca, onValueChange: updateField('adju_loca'), label: '조정위치' })}
                {FormText({ value: data.adju_qtys, onValueChange: updateField('adju_qtys'), label: '조정재고량' })}
                {FormText({ value: data.adju_pric, onValueChange: updateField('adju_pric'), label: '조정단가' })}
            </GroupItem>
            {FormMemo({ value: data.adju_memo, onValueChange: updateField('adju_memo'), label: '조정사유' })}
        </PopupForm>        
        </>
    );
}