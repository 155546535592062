import React, { useEffect, useState, useCallback } from 'react';
import { GroupItem } from 'devextreme-react/form';
import { FormDate, FormImag, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { useScreenSize } from '../../../../../utils/media-query';
import { getEqulGubu as getGubu } from '../../../../../api/set';
import { PanelHeader } from '../../../../script/components/detail-panel/detail-panel-header/PanelHeader';
import { PanelBody } from '../../../../script/components/detail-panel/detail-panel-body/PanelBody';
import * as fwUtil from '../../../../script/util';

const tempGubuList = ["01 : 건축 및 구축물", "02 : 운영설비", "02 : 생산설비", "03 : 금형", "04 : 치공구", "05 : 측정장비"];

const defaultData = {
    main_numb: '',
    equi_numb: '',
    equi_gubu: '',
    equi_name: '',
    menu_comp: '',
    equi_type: '',
    equi_loca: '',
    equi_seno: '',
    equi_spec: '',
    reci_date: fwUtil.getToDayText(),
    insp_cycl: '',
    uses_dept: '',
    main_name: '',
    subs_name: '',
    menu_date: fwUtil.getToDayText(),
    equi_memo: '',
    equl_imag: null,
};

const tempRes = {
    main_numb: 'EQ22040001',
    equi_numb: "E44556",
    equi_gubu: "D",
    equi_name: "Air Conditioner",
    menu_comp: "Company PQR",
    equi_type: "Model 101",
    equi_loca: "Plant 4",
    equi_seno: "SN44556",
    equi_spec: "3000BTU",
    reci_date: "2023-09-10",
    insp_cycl: "Biannually",
    uses_dept: "Administration",
    main_name: "Eve Black",
    subs_name: "Frank Gray",
    menu_date: "2023-02-05",
    equi_memo: "Filter needs replacement",
    equl_imag: null
};

export function EqulInfoDetailPanel(props) {
    const { 
        gridRef, keyData, refresh,
        tar, upd, 
        isPanelOpened, panelOpendChange 
    } = props;
    
    const { isLarge, isMedium } = useScreenSize();
    // 패널 상태값
    const [isPinned, setIsPinned] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 설비 구분 리스트
    const [gubuList, setGubuList] = useState([]);
    const gubuChange = useCallback((e) => setGubuList(e), []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                //const [gubuResult] = await Promise.all([getGubu()]);
                const gubuResult = tempGubuList;
                gubuChange(gubuResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [gubuChange, data]);

    // 핀
    useEffect(() => {
        changePanelPinned();
    }, [isPinned]);

    const changePanelPinned = useCallback(() => {
        gridRef.current?.instance.updateDimensions();
    }, [gridRef]);

    const onPinClick = useCallback(() => {
        setIsPinned(!isPinned);
        gridRef.current?.instance.updateDimensions();
    }, [isPinned, gridRef]);

    // 패널 닫기
    const onClosePanelClick = useCallback(() => {
        panelOpendChange();
        setIsPinned(false);
    }, [panelOpendChange]);

    // 수정 상태 전환
    const toggleEditHandler = useCallback(() => {
        setIsEditing(!isEditing);
    }, [isEditing]);

    // 선택 데이터 조회
    useEffect(() => {
        const fetchData = async () => {
            if (keyData) {
                const sendData = { mainNumb: keyData };
                //const res = await fwUtil.targetData(sendData, tar.api);
                const res = tempRes;
                const data = {
                    main_numb: res.main_numb || '',
                    equi_numb: res.equi_numb || '',
                    equi_gubu: res.equi_gubu || '',
                    equi_name: res.equi_name || '',
                    menu_comp: res.menu_comp || '',
                    equi_type: res.equi_type || '',
                    equi_loca: res.equi_loca || '',
                    equi_seno: res.equi_seno || '',
                    equi_spec: res.equi_spec || '',
                    reci_date: res.reci_date || fwUtil.getToDayText(),
                    insp_cycl: res.insp_cycl || '',
                    uses_dept: res.uses_dept || '',
                    main_name: res.main_name || '',
                    subs_name: res.subs_name || '',
                    menu_date: res.menu_date || fwUtil.getToDayText(),
                    equi_memo: res.equi_memo || '',
                    equl_imag: res.equl_imag || null,
                };
                dataUpdate(data);   
            }
        };
        fetchData();
    }, [keyData, tar, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        dataUpdate({ ...data, [field]: value });
    };

    // 저장 버튼 클릭
    const onSaveClick = ({ validationGroup }) => {
        const validationResult = fwUtil.valditData(validationGroup);
        if (validationResult) {
            onSubmit();
            setIsEditing(!isEditing);    
        }
    };

    // 수정
    const onSubmit = useCallback(async () => {
        const postData = new FormData();

        postData.append('mainNumb', data.main_numb || '' );
        postData.append('equiNumb', data.equi_numb || '' );
        postData.append('equiGubu', data.equi_gubu || '' );
        postData.append('equiName', data.equi_name || '' );
        postData.append('menuComp', data.menu_comp || '' );
        postData.append('equiType', data.equi_type || '' );
        postData.append('equiLoca', data.equi_loca || '' );
        postData.append('equiSeno', data.equi_seno || '' );
        postData.append('equiSpec', data.equi_spec || '' );
        postData.append('reciDate', data.reci_date || null );
        postData.append('inspCycl', data.insp_cycl || '' );
        postData.append('usesDept', data.uses_dept || '' );
        postData.append('mainName', data.main_name || '' );
        postData.append('subsName', data.subs_name || '' );
        postData.append('menuDate', data.menu_date || null );
        postData.append('equiMemo', data.equi_memo || '' );
        postData.append('equlImag', data.equl_imag || null );

        fwUtil.submitData(postData, upd?.api, refresh);
    }, [data, upd, refresh]);

    return (
        <div id='fw-mes-equ-equl-detail-panel' className={fwUtil.classNames({ 'fw-detail-panel': true, 'open': isPanelOpened, 'pin': isPinned && (isLarge || isMedium) })}>
            <div className='fw-panel-wrapper'>
                <PanelHeader
                    title={data.main_numb}
                    isPinned={isPinned}
                    onPinClick={onPinClick}
                    onClosePanelClick={onClosePanelClick}
                    isLarge={isLarge}
                    isMedium={isMedium}
                />
                <PanelBody
                    isEditing={isEditing}
                    toggleEditHandler={toggleEditHandler}
                    onSaveClick={onSaveClick}
                    upd={upd}
                >
                    <GroupItem colCount={2} cssClass='fw-image-row'>
                        {FormImag({ value: fwUtil.bufferToBlob(data.equl_imag), onValueChange: updateField('equl_imag'), isEditing: !isEditing, label: '설비 이미지', labelHide: true, size: 140 })}
                        <GroupItem>
                            {FormText({ value: data.equi_numb, onValueChange: updateField('equi_numb'), isEditing: !isEditing, label: '관리번호', required: true})}
                            {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), isEditing: !isEditing, label: '설비명', required: true })}
                            {FormSelc({ value: data.equi_gubu, onValueChange: updateField('equi_gubu'), isEditing: !isEditing, label: '설비구분', items: gubuList, required: true })}                                                                                                                                                                                                          
                        </GroupItem>
                    </GroupItem>
                    <GroupItem cssClass='contact-fields-group single-row'>
                        <GroupItem colCount={2}>
                            {FormText({ value: data.equi_seno, onValueChange: updateField('equi_seno'), isEditing: !isEditing, label: '기기번호' })}              
                            {FormText({ value: data.menu_comp, onValueChange: updateField('menu_comp'), isEditing: !isEditing, label: '제작회사' })}
                            {FormText({ value: data.equi_type, onValueChange: updateField('equi_type'), isEditing: !isEditing, label: '규격/형식' })} 
                            {FormText({ value: data.uses_dept, onValueChange: updateField('uses_dept'), isEditing: !isEditing, label: '사용부서' })}
                            {FormText({ value: data.equi_loca, onValueChange: updateField('equi_loca'), isEditing: !isEditing, label: '설치장소' })}
                            {FormDate({ value: data.reci_date, onValueChange: updateField('reci_date'), isEditing: !isEditing, label: '설치일자' })}
                            {FormText({ value: data.main_name, onValueChange: updateField('main_name'), isEditing: !isEditing, label: '담당자(정)' })}
                            {FormText({ value: data.subs_name, onValueChange: updateField('subs_name'), isEditing: !isEditing, label: '담당자(부)' })}
                            {FormText({ value: data.insp_cycl, onValueChange: updateField('insp_cycl'), isEditing: !isEditing, label: '점검주기' })}
                            {FormDate({ value: data.menu_date, onValueChange: updateField('menu_date'), isEditing: !isEditing, label: '제작일자' })}                             
                        </GroupItem>   
                        {FormMemo({ value: data.equi_spec, onValueChange: updateField('equi_spec'), isEditing: !isEditing, label: '용량/공칭능력' })}
                        {FormMemo({ value: data.equi_memo, onValueChange: updateField('equi_memo'), isEditing: !isEditing, label: '비고' })}  
                    </GroupItem>
                </PanelBody>
            </div>
        </div>
    )
};