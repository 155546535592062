import React, { useEffect } from 'react';
import { Popup, Button, CheckBox as DevCheckBox, DropDownButton, Tooltip, SelectBox } from 'devextreme-react';
import { useMemo } from 'react';
import { getAlertData } from '../../../util';

// 조회 버튼
const SearchBtn = (props) => {
    if (props.hidden) { return null; }
    return (
        <Button
            icon={'find'}
            text={'조회'}
            onClick={props.onClick}
            disabled={props.disabled}
        />
    )
};

// 조회 다이얼로그 버튼
const SearchPopupBtn = (props) => {
    if (props.hidden) { return null; }
    return (
        <Button
            icon={'find'}
            text={'조회설정'}
            onClick={props.onClick}
            disabled={props.disabled}
        />
    )
};

// 조회 다이얼로그
const SearchPopup = (props) => {
    return (
        <Popup
            visible={props.visible}
            onHiding={props.onHiding}
            hideOnOutsideClick={true}
            showCloseButton={true}
            title="조회 설정"
            width={props.isMedium? 600:330 }
            height={'auto'}
            minHeight={"40%"}
        >   
            <div className={'fw-search-popup fw-column-flex-box'}>
                {props.searchPanel}
            </div>
        </Popup>
    )
};

// 상태 선택박스
const SelectStatusBox = (props) => {
    return (
        <SelectBox
            placeholder={'선택하세요'} value={props.value}
            onValueChange={props.onValueChange}
            width={100}
            height={37}
            items={['사용중', '미사용', '전체']}
        />
    )
}

// 버튼 컴포넌트
const CustomButton = (props) => {
    const buttonId = `fw-${props.type}-button-wrapper`;
    return (
        <div id={buttonId} className={'fw-tooltip-button-wrapper'}>
            <Button
                icon={props.icon}
                text={props.text}
                visible={props.visible}
                onClick={props.onClick}
                disabled={props.disabled}
            />
            {props.disabled && (
                <Tooltip
                    target={`#${buttonId}`}
                    position={'top'}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                >
                    <span>{props.text} 권한이 없습니다.</span>
                </Tooltip>
            )}
        </div>
    );
};

// 등록 버튼
const InsertBtn = (props) => {
    if (props.hidden) { return null; }
    return <CustomButton type={`${props.title}-add`} icon="add" text="등록" {...props} />;
};

// 수정 버튼
const UpdateBtn = (props) => {
    if (props.hidden) { return null; }
    return <CustomButton type={`${props.title}-update`} icon="edit" text="수정" {...props} />;
};

// 삭제 버튼
const DeleteBtn = (props) => {
    if (props.hidden) { return null; }
    return <CustomButton type={`${props.title}-delete`} icon="trash" text="삭제" {...props} />;
};

// 다운로드 버튼
const DownLoadBtn = (props) => {
    if (props.hidden) { return null; }
    const icon = props.exportTo === "excel" ? 'fw-export-excel' : 'fw-export-pdf';
    return <CustomButton type={`${props.title}-download`} icon={icon} text="다운로드" {...props} />;
};

// 초기화 버튼
const ResetBtn = (props) => {
    if (props.hidden) { return null; }
    return <CustomButton type={`${props.title}-reset`} icon="refresh" text="초기화" {...props} />;
};

// 문서화 버튼
const DocumentBtn = (props) => {
    if (props.hidden) { return null; }
    return <CustomButton type={`${props.title}-document`} icon="textdocument" text={`${props.title}출력`} {...props} />;
};

// 취소버튼
const CancleBtn = (props) => {
    if (props.hidden) { return null; }
    return <CustomButton type={`${props.title}-document`} icon="undo" text="취소" {...props} />;
};


// 체크 박스
const CheckBox = (props) => {
    if (props.hidden) { return null; }
    return (
        <DevCheckBox 
            value={props.value} 
            onValueChanged={props.onValueChanged}
        />
    )
};

// 알림 다이얼로그
const AlertPopup = (props) => {
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                props.confirm();
            } else if (event.key === 'Escape') {
                props.onHiding(); 
            }
        };

        if (props.visible) {
            document.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [props.visible, props.confirm, props.onHiding]);

    return (
        <Popup
            visible={props.visible}
            onHiding={props.onHiding}
            hideOnOutsideClick={true}
            showCloseButton={false}
            width={'auto'}
            height={'auto'}
            wrapperAttr={{ class: 'fw-alert-popup' }}
        >   
            <div className={'fw-popup-content fw-column-flex-box'}>
            {getAlertData(props.selectedData) && getAlertData(props.selectedData).map((item, index) => (
                <span className={'alert-key'} key={index}>
                    {typeof item === 'object' ? Object.values(item).map((value, i) => <span key={i}>{value} </span>) : item}
                </span>
            ))}
                <span className={'alert-message'}>{props.message}</span>
                <div className={'alert-button-wrapper fw-row-flex-box'}>
                    <Button 
                        text='확인'
                        stylingMode='contained'
                        onClick={props.confirm}
                        
                    />
                    <Button 
                        text='취소'
                        className='fw-cancle-button'
                        stylingMode='outlined'
                        onClick={props.onHiding}
                    />
                </div>
            </div>
        </Popup>
    )
};

const DropDownDownloads = (props) => {
    const { expExc, expPdf } = props;
    const actionLists = useMemo(() => ([
        {
            id: 1,
            text: '엑셀',
            icon: 'fw-export-excel',
            onClick: expExc?.onClick,
            visible: expExc?.visible,
            disabled: expExc?.disabled,
        }, 
        {
            id: 2,
            text: 'PDF',
            icon: 'fw-export-pdf',
            onClick: expPdf?.onClick,
            visible: expPdf?.visible,
            disabled: expPdf?.disabled,
        }        
    ]), [props]);

    if (props.hidden) { return null; }

    return (
        <div id={`${props.title}-fw-dropdown-button-wrapper`} className={'fw-tooltip-button-wrapper'}>
            <DropDownButton 
                text="다운로드" 
                items={actionLists}
                stylingMode='contained'
                className={'fw-dropdown-button'}
                disabled={props.disabled}
                icon='download'
                displayExpr='text'
                showArrowIcon={false}
                keyExpr='id'
                width={100}
            />
            {props.disabled && (
                <Tooltip
                    target={`#${props.title}-fw-dropdown-button-wrapper`}
                    position={'top'}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                >
                    <span>다운로드 권한이 없습니다.</span>
                </Tooltip>
            )}
        </div>
    )
};

const DropDownActions = (props) => {
    const { ins, upd, del, expExc, expPdf } = props;
    const actionLists = useMemo(() => ([
        {
            id: 1,
            text: '등록',
            icon: 'add',
            onClick: ins?.onClick,
            visible: ins?.visible,
            disabled: ins?.disabled,
        },
        {
            id: 2,
            text: '수정',
            icon: 'edit',
            onClick: upd?.onClick,
            visible: upd?.visible,
            disabled: upd?.disabled,
        },
        {
            id: 3,
            text: '삭제',
            icon: 'trash',
            onClick: del?.onClick,
            visible: del?.visible,
            disabled: del?.disabled,
        },
        {
            id: 4,
            text: '다운로드',
            icon: 'fw-export-excel',
            onClick: expExc?.onClick,
            visible: expExc?.visible,
            disabled: expExc?.disabled,
        }, 
        {
            id: 5,
            text: '다운로드',
            icon: 'fw-export-pdf',
            onClick: expPdf?.onClick,
            visible: expPdf?.visible,
            disabled: expPdf?.disabled,
        }        
    ]), [props]);

    if (props.hidden) { return null; }
    return (
        <DropDownButton 
            text="작업" 
            items={actionLists}
            stylingMode='contained'
            disabled={props.disabled}
            className={'fw-dropdown-button'}
            icon='toolbox'
            displayExpr='text'
            showArrowIcon={false}
            keyExpr='id'
            width={110}
        />
    )
};

export { 
    SearchBtn,
    SelectStatusBox,
    InsertBtn,
    UpdateBtn,
    DeleteBtn,
    SearchPopupBtn,
    DownLoadBtn,
    ResetBtn,
    DocumentBtn,
    CancleBtn,
    CheckBox,
    SearchPopup,
    AlertPopup,
    DropDownActions,
    DropDownDownloads,
}