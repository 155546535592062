import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { getItemGubu, getWorkGubu } from '../../../../../api/set';
import { FormBool, FormDate, FormMemo, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, SelcCol, BoolCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';

const defaultData = {
    regi_date: fwUtil.getToDayText(),
    sage_yesn: false,
    enab_yesn: false,
    item_memo: '',
    item_code: '',
    item_name: '',
    item_gubu: '',
    item_pric: 0,
    qtys_weig: 0.000,
    item_unit: '',
    item_desc: '',
    item_quli: '',
    proj_code: '',
    proj_name: '',
};

const routDataSource = fwUtil.generateDataSource();

export function ItemRegistInsert(props) {
    const {
        mainRefresh, subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 제품구분
    const [itemGubuList, setItemGubuList] = useState([]);
    const itemGubuListChange = useCallback((e) => setItemGubuList(e), []);
    // 공정구분
    const [workGubuList, setWorkGubuList] = useState([]);
    const workGubuListChange = useCallback((e) => setWorkGubuList(e), []);
    // 공정 그리드
    const routRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(() => {
        routDataSource.store().insert({ init: true }).then(() => routDataSource.reload());
        dataUpdate(defaultData);
    }, [dataUpdate, routDataSource]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [itemGubuResult, workGubuResult] = await Promise.all([getItemGubu(), getWorkGubu()]);
                itemGubuListChange(itemGubuResult);
                workGubuListChange(workGubuResult);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [itemGubuListChange, workGubuListChange]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const routData = routRef?.current?.instance?.getDataSource()?.items();
        const postRoutData = routData.map((item) => ({
            routNumb: item.rout_numb || "",
            routName: item.rout_name || "",
            workGubu: item.work_gubu || "",
            lastRout: item.last_rout || false,
        }));        
        const postData = {
            enabYesn: data.enab_yesn || false,
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            itemGubu: data.item_gubu === "완제품" ? '01' : '02',
            itemPric: data.item_pric || 0.00,
            qtysWeig: data.qtys_weig || 0.000,
            itemUnit: data.item_unit || '',
            itemDesc: data.item_desc || '',
            itemQuli: data.item_quli || '',
            citeNumb: data.cite_numb || '',
            projCode: data.proj_code || '',
            projName: data.proj_name || '',
            regiDate: data.regi_date || fwUtil.getToDayText(),
            itemMemo: data.item_memo || '',
            setaMaps: postRoutData || [],
        };

        await fwUtil.submitData(postData, insertApi, mainRefresh);
        subsRefresh();
        setLoading(false);
        alertHide();
        hiding();
    }, [data, insertApi, mainRefresh, subsRefresh, hiding, alertHide, routRef]);

    return (
        <>
        <PopupForm
            title={'제품 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall || isSmall ? 300 : 600 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'작업공정'}
                        gridRef={routRef} 
                        gridDataSource={routDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "rout_numb", caption: "공정번호", allowHeaderFiltering: false, allowSorting: false, maxLength: 2 })}
                        {CommCol({ dataField: "rout_name", caption: "공정명", allowHeaderFiltering: false, allowSorting: false })}
                        {SelcCol({ dataField: "work_gubu", caption: "작업구분", items: workGubuList, allowHeaderFiltering: false, allowSorting: false })}
                        {BoolCol({ dataField: "last_rout", caption: "완료공정", allowHeaderFiltering: false, allowSorting: false })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', required: true })}
                {FormSelc({ value: data.item_gubu, onValueChange: updateField('item_gubu'), label: '제품구분', items: itemGubuList, required: true })}
                {FormText({ value: data.item_unit, onValueChange: updateField('item_unit'), label: '제품단위', required: true, maxLength: 10 })}
                {FormText({ value: data.cite_numb, onValueChange: updateField('cite_numb'), label: '고객품번' })}
                {FormNumb({ value: data.item_pric, onValueChange: updateField('item_pric'), label: '제품단가', length: [15, 2] })}
                {FormNumb({ value: data.qtys_weig, onValueChange: updateField('qtys_weig'), label: '제품중량', length: [15, 3] })}
                {FormText({ value: data.item_desc, onValueChange: updateField('item_desc'), label: '규격' })}
                {FormText({ value: data.item_quli, onValueChange: updateField('item_quli'), label: '재질' })}
                {FormText({ value: data.proj_code, onValueChange: updateField('proj_code'), label: '프로젝트코드' })}
                {FormText({ value: data.proj_name, onValueChange: updateField('proj_name'), label: '프로젝트명' })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
            </GroupItem>
            {FormMemo({ value: data.item_memo, onValueChange: updateField('item_memo'), label: '비고' })}
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            warning={"등록된 제품정보는 추후 삭제가 불가능합니다."}
            message={'등록 하시겠습니까?'}
        />          
        </>
    );
}