import React, { useState, useCallback, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { getProductRoutList as getRout, getProductNameList as getWork } from '../../../../../api/set';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormNumb, FormSelc, FormText, FormTime } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    work_numb: '',
    prod_seri: '',
    item_code: '',
    rout_numb: '',
    work_name: '',
    star_time: null,
    clos_time: null,
    comp_date: fwUtil.getToDayText(),
    last_rout: false,
    work_qtys: 0.00,
    infe_qtys: 0.00,
    infe_ca01: false,
    infe_ca02: false,
    infe_ca03: false,
    infe_ca04: false,
    infe_ca05: false,
    infe_ca06: false,
    infe_ca07: false,
    infe_ca08: false,
    infe_ca09: false,
    infe_ca10: false,
    ca10_memo: '',
};

export function ProductChangeUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpwork = useCallback((e) => setData(e), [setData]);        
    // 공정, 작업자 리스트
    const [routGubuList, setRoutGubuList] = useState([]);
    const [workNameList, setWorkNameList] = useState([]);
    const routGubuChange = useCallback((e) => { setRoutGubuList(e) }, []);
    const workNameChange = useCallback((e) => { setWorkNameList(e) }, []);

    // 공정, 작업자 리스트 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [routResult, workResult] = await Promise.all([getRout(), getWork()]);
                if (routResult && routResult.length > 0) {
                    routGubuChange(routResult);
                }  
                
                if (workResult && workResult.length > 0) {
                    workNameChange(workResult);
                }                                                 
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [routGubuChange, workNameChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowData = fwUtil.findSelectedRowData(mainGrid);
        const r = selectedRowData.length;
        if (r && r>0) {
            const newData = {
                ...defaultData,
                work_numb: selectedRowData[r-1].work_numb || '',
                prod_seri: selectedRowData[r-1].prod_seri || '',
                item_code: selectedRowData[r-1].item_code || '',
                rout_numb: selectedRowData[r-1].rout_numb || '',
                work_name: selectedRowData[r-1].work_name || '',
                star_time: selectedRowData[r-1].star_time || null,
                clos_time: selectedRowData[r-1].clos_time || null,
                comp_date: selectedRowData[r-1].comp_date || fwUtil.getToDayText(),
                last_rout: selectedRowData[r-1].last_rout || false,
                work_qtys: selectedRowData[r-1].work_qtys || 0.00,
                infe_qtys: selectedRowData[r-1].infe_qtys || 0.00,
                infe_ca01: selectedRowData[r-1].infe_ca01 || false,
                infe_ca02: selectedRowData[r-1].infe_ca02 || false,
                infe_ca03: selectedRowData[r-1].infe_ca03 || false,
                infe_ca04: selectedRowData[r-1].infe_ca04 || false,
                infe_ca05: selectedRowData[r-1].infe_ca05 || false,
                infe_ca06: selectedRowData[r-1].infe_ca06 || false,
                infe_ca07: selectedRowData[r-1].infe_ca07 || false,
                infe_ca08: selectedRowData[r-1].infe_ca08 || false,
                infe_ca09: selectedRowData[r-1].infe_ca09 || false,
                infe_ca10: selectedRowData[r-1].infe_ca10 || false,
                ca10_memo: selectedRowData[r-1].ca10_memo || '',                
            }
            console.log(newData);
            dataUpwork(newData);            
        } else {
            notify('선택 항목 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        }
    }, [hiding, mainGrid, dataUpwork]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpwork({ ...data, [field]: value });
    };		    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);

        const postData = {
            workNumb: data.work_numb || '',
            prodSeri: data.prod_seri || '',
            itemCode: data.item_code || '',
            routNumb: data.rout_numb || '',
            workName: data.work_name || '',
            starTime: data.star_time ? fwUtil.formatTime(data.star_time) : null,
            closTime: data.clos_time ? fwUtil.formatTime(data.clos_time) : null,
            compWork: data.comp_date || fwUtil.getToDayText(),
            lastRout: data.last_rout || false,
            workQtys: data.work_qtys || 0.00,
            infeQtys: data.infe_qtys || 0.00,
            infeCa01: data.infe_ca01 || false,
            infeCa02: data.infe_ca02 || false,
            infeCa03: data.infe_ca03 || false,
            infeCa04: data.infe_ca04 || false,
            infeCa05: data.infe_ca05 || false,
            infeCa06: data.infe_ca06 || false,
            infeCa07: data.infe_ca07 || false,
            infeCa08: data.infe_ca08 || false,
            infeCa09: data.infe_ca09 || false,
            infeCa10: data.infe_ca10 || false,
            ca10Memo: data.ca10_memo || '',
        };

        await fwUtil.submitData(postData, updateApi, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'작업실적 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : isMedium ? 600 : 1200 } 
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 4}>
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2 } colSpan={isXSmall || isSmall ? 1 : 2 } cssClass='remk-form prod-info'>
                    {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', isEditing: true, required: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품명', isEditing: true, required: true })}
                    {FormSelc({ value: data.rout_numb, onValueChange: updateField('rout_numb'), label: '작업공정', required: true, items: routGubuList })}
                    {FormSelc({ value: data.work_name, onValueChange: updateField('work_name'), label: '작업자', required: true, items: workNameList })}
                    {FormTime({ value: data.star_time, onValueChange: updateField('star_time'), label: '시작시간', required: true })}
                    {FormTime({ value: data.clos_time, onValueChange: updateField('clos_time'), label: '종료시간', required: true })}
                    {FormDate({ value: data.comp_date, onValueChange: updateField('comp_date'), label: '작업일자', required: true })}
                    {FormBool({ value: data.last_rout, onValueChange: updateField('last_rout'), label: '최종공정' })}
                    {FormNumb({ value: data.work_qtys, onValueChange: updateField('work_qtys'), label: '완료수량', length: [10, 2] })}
                </GroupItem>
                <GroupItem colCount={isXSmall || isSmall ? 2 : 4 } colSpan={isXSmall || isSmall ? 1 : 2 } cssClass='remk-form prod-infe'>
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 2 : 4 }>
                        {FormText({ value: data.infe_qtys, onValueChange: updateField('infe_qtys'), label: '불량수량', length: [10, 2] })}
                    </GroupItem>
                    <GroupItem colCount={isXSmall || isSmall ? 2 : 4 } colSpan={isXSmall || isSmall ? 2 : 4 }>
                        {FormBool({ value: data.infe_ca01, onValueChange: updateField('infe_ca01'), label: '소재' })}
                        {FormBool({ value: data.infe_ca02, onValueChange: updateField('infe_ca02'), label: '셋팅' })}
                        {FormBool({ value: data.infe_ca03, onValueChange: updateField('infe_ca03'), label: '외관' })}
                        {FormBool({ value: data.infe_ca04, onValueChange: updateField('infe_ca04'), label: '치수' })}
                        {FormBool({ value: data.infe_ca05, onValueChange: updateField('infe_ca05'), label: '탭/나사' })}
                        {FormBool({ value: data.infe_ca06, onValueChange: updateField('infe_ca06'), label: '용접' })}
                        {FormBool({ value: data.infe_ca07, onValueChange: updateField('infe_ca07'), label: '전/후처리' })}
                        {FormBool({ value: data.infe_ca08, onValueChange: updateField('infe_ca08'), label: '조립/작동' })}
                        {FormBool({ value: data.infe_ca09, onValueChange: updateField('infe_ca09'), label: '취급부주의' })}
                        {FormBool({ value: data.infe_ca10, onValueChange: updateField('infe_ca10'), label: '기타' })}
                    </GroupItem>
                    <GroupItem colSpan={isXSmall || isSmall ? 2 : 4 }>
                        {FormMemo({ value: data.ca10_memo, onValueChange: updateField('ca10_memo'), label: '기타내용' })}
                    </GroupItem>
                </GroupItem>
            </GroupItem>
        </PopupForm>
    );
}