import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormBool, FormDate } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import * as fwUtil from '../../../../script/util';
import notify from 'devextreme/ui/notify';

const defaultData = {
    ware_date: fwUtil.getToDayText(),
    insp_okey: true,
    last_rout: false,
};

const pproDataSource = fwUtil.generateDataSource();

export function WhareHousingRegistPartInsert(props) {
    const {
        seleGubu, mainGrid,
        subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 발주 그리드
    const pproRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.findSelectedLastRowKey(mainGrid);
        if (!selectedRowKey) {
            notify('발주 정보를 불러오지 못했습니다', 'error', 2000);
            hiding();
        } else {
            pproDataSource.store().insert({ init: true }).then(() => pproDataSource.reload());
            const pproSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const ppro of pproSelected) {
                const newData = {
                    ppro_numb: ppro.ppro_numb || '',
                    ppro_seri: ppro.ppro_seri || '',
                    work_numb: ppro.work_numb || '',
                    rout_numb: ppro.rout_numb || '',
                    rout_name: ppro.rout_name || '',
                    matl_code: ppro.matl_code || '',
                    matl_name: ppro.matl_name || '',
                    matl_spec: ppro.matl_spec || '',
                    puro_unit: ppro.puro_unit || '',
                    ppro_qtys: ppro.ppro_qtys || 0.000,
                    ppro_pric: ppro.ppro_pric || 0.00,
                    ppro_cwon: ppro.ppro_cwon || 0.00,
                    rece_qtys: ppro.rece_qtys || 0.000,
                    lots_numb: ppro.lots_numb || '',
                    ware_qtys: ppro.ware_qtys || 0.000,
                    ware_pric: ppro.ware_pric || 0.00,
                    ware_cwon: ppro.ware_cwon || 0.00,
                    ppro_memo: ppro.ppro_memo || '',
                };
                pproDataSource.store().insert(newData);
            }
            
            pproDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const pproData = pproRef?.current?.instance?.getDataSource()?.items();
        const postPproData = pproData.map((item) => ({    
            pproNumb: item.ppro_numb || "",
            pproSeri: item.ppro_seri || "",
            clieCode: item.clie_code || "",
            clieName: item.clie_name || "",
            workNumb: item.work_numb || "",
            routNumb: item.rout_numb || "",
            routName: item.rout_name || "",
            matlCode: item.matl_code || "",
            itemGubu: item.item_gubu || "",
            matlName: item.matl_name || "",
            matlSpec: item.matl_spec || "",
            lotsNumb: item.lots_numb || "",
            wareQtys: item.ware_qtys || 0.000,
            warePric: item.ware_pric || 0.00, 
            wareCwon: item.ware_cwon || 0.00, 
            wareMemo: item.ware_memo || "", 
        }));        
        const postData = {
            seleGubu: seleGubu,
            wareDate: data.ware_date || fwUtil.getToDayText(),
            inspOkey: data.insp_okey || false,
            lastRout: data.last_rout ? 1 : 0,
            setaMaps: postPproData || [],
        };

        await fwUtil.submitData(postData, insertApi, subsRefresh);
        setLoading(false);
        alertHide();
        hiding();
    }, [data, insertApi, subsRefresh, hiding, alertHide, pproRef, seleGubu]);

    return (
        <>
        <PopupForm
            title={'부분입고'}       
            type={'입고'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-column-flex-box'>
                    <AddingGrid
                        title={'발주상세품목'}
                        gridRef={pproRef} 
                        gridDataSource={pproDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "ppro_numb", caption: "발주번호", allowEditing: false })} 
                        {CommCol({ dataField: "ppro_seri", caption: "순번" , allowEditing: false})}
                        {CommCol({ dataField: "work_numb", caption: "작업지시번호", visible: seleGubu === "외주", allowEditing: false })}
                        {CommCol({ dataField: "rout_numb", caption: "공정번호", visible: seleGubu === "외주", allowEditing: false })}
                        {CommCol({ dataField: "rout_name", caption: "공정명", visible: seleGubu === "외주", allowEditing: false })}
                        {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
                        {CommCol({ dataField: "matl_name", caption: "자재품명", allowEditing: false })}
                        {CommCol({ dataField: "matl_spec", caption: "자재규격", allowEditing: false })}
                        {CommCol({ dataField: "puro_unit", caption: "구매단위", allowEditing: false })}
                        {DeciCol({ dataField: "ppro_qtys", caption: "발주수량", allowEditing: false })}
                        {DeciCol({ dataField: "ppro_pric", caption: "발주단가", allowEditing: false })}
                        {DeciCol({ dataField: "ppro_cwon", caption: "발주금액", allowEditing: false })}
                        {DeciCol({ dataField: "rece_qtys", caption: "기입고량", allowEditing: false })}
                        {CommCol({ dataField: "lots_numb", caption: "Lot No", visible: seleGubu === "구매", check: true })}
                        {DeciCol({ dataField: "ware_qtys ", caption: "입고예정", check: true })}
                        {DeciCol({ dataField: "ware_pric ", caption: "입고단가", check: true })}
                        {DeciCol({ dataField: "ware_cwon ", caption: "입고금액", check: true })}
                        {CommCol({ dataField: "ppro_memo", caption: "비고", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={4}>
                <GroupItem colCount={isXSmall ? 2 : 4 } colSpan={isXSmall || isSmall ? 4 : isMedium ? 3 : 2 }>
                    <GroupItem colSpan={2}>
                        {FormDate({ value: data.ware_date, onValueChange: updateField('ware_date'), label: '등록일자' })}
                    </GroupItem>
                    {FormBool({ value: data.insp_okey, onValueChange: updateField('insp_okey'), label: '입고검사 확인' })}
                    {FormBool({ value: data.last_rout, onValueChange: updateField('last_rout'), label: '생산완료 여부' })}
                </GroupItem>
            </GroupItem>
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            warning={"수정된 내용을 바탕으로 해당 품목들이 입고처리 됩니다."}
            message={'해당 품목을 부분입고 하시겠습니까?'}
        />  
        </>
    );
}